/* eslint-disable jsx-a11y/media-has-caption */
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import cx from 'classnames';
import { RecordRTCPromisesHandler } from 'recordrtc';
import FFMPEG from 'react-ffmpeg';
import { toast } from 'react-toastify';

import styles from './styles.module.css';
import { Button, CloseBtn } from '../Button/Button';
import { Modal } from '../Modal';
import { RoundCheckbox } from '../Input';
import {
  FlipIcon,
  MicIcon,
  MicMuteIcon,
  MuteIcon,
  StartIcon,
  WarningIcon,
} from '../../theme/icons';
import { getCurrenLang } from '../../redux/selector';
import { timerStatus, useInterval } from '../../hooks/useInterval';
import { delay, getCamAndMics } from '../../utils/helper';
import { WarnNotifModal } from '../NotificationModal';

const VideoRecorder = ({ recVideoRaw, setRecVideoRaw, setError }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const cancel = t?.common.cancel;
  const yes = t?.common.yes;
  const no = t?.common.no;
  const error_occurred = t?.dashboard.hcp.profile_patient.error_occurred;
  const access_to_device = t?.validation.access_to_device;
  const mute_text = t?.dashboard.hcp.profile_patient.video_bank.mute?.toLowerCase();
  const flip = t?.dashboard.hcp.profile_patient.video_bank.flip?.toLowerCase();
  const start_text = t?.dashboard.hcp.profile_patient.video_bank.start?.toLowerCase();
  const end_text = t?.dashboard.hcp.profile_patient.video_bank.end?.toLowerCase();
  const reset = t?.common?.reset?.toLowerCase();
  const do_you_want_audio_to_be_recorded =
    t?.dashboard.hcp.profile_patient.video_bank.do_you_want_audio_to_be_recorded;
  const noise_suppression =
    t?.dashboard.hcp.profile_patient.video_bank.noise_suppression || 'Noise suppression';
  const echo_cancellation =
    t?.dashboard.hcp.profile_patient.video_bank.echo_cancellation || 'Echo cancellation';
  const remove_sound = t?.dashboard.hcp.profile_patient.video_bank.remove_sound;

  // Local state
  const [srcForView, setSrcForView] = useState('');
  const [stream, setStream] = useState<any>(null);
  const [camList, setCamList] = useState([]);
  const [front, setFront] = useState(false);
  const [startRec, setstartRec] = useState(false);
  const [mute, setMute] = useState(true);
  const [noiseSuppression, setNoiseSuppression] = useState(true);
  const [echoCancellation, setEchoCancellation] = useState(true);
  const [duration, setDuration] = useState(0);
  const [status, setStatus] = useState(timerStatus.STOPPED);
  const [isDeviceDeny, setIsDeviceDeny] = useState<any>('');
  const [isMicAlert, setMicAlert] = useState(false);
  const [isAlowedAlert, setAlowedAlert] = useState(true);
  const refVideo: any = useRef(null);
  const recorderRef: any = useRef(null);
  const [loading, setLoading] = useState(false);

  // Record video logik
  const cancelRecord = (): void => {
    if (stream) {
      stream.getTracks().forEach((x: any) => x.stop());
    }
  };

  // Delete attached video
  const deleteVideo = (): void => {
    setSrcForView('');
    const copy = [{ ...recVideoRaw[0] }];
    copy[0].file = null;
    setRecVideoRaw(copy);
    // formik.setErrors({});
    // formik.setFieldValue('fileDuration', 0);
    // formik.setFieldValue('fileNameOrigin', '');
    // formik.setFieldValue('isRecordVideo', false);
    cancelRecord();
    setIsDeviceDeny(() => false);
  };

  // Calculating video recording duration
  useInterval(
    async () => {
      if (timerStatus.STARTED) {
        setDuration(duration + 1000);
      }
    },
    // passing null stops the interval
    status === timerStatus.STARTED ? 1000 : null,
  );

  useEffect(() => {
    if (refVideo) {
      if (!refVideo.current) {
        return;
      }
      refVideo.current.srcObject = stream;
    }
  }, [stream, refVideo]);

  useEffect(() => {
    if (!srcForView) {
      (async (): Promise<void> => {
        try {
          const mediaTrackConstraints: MediaTrackConstraints = {
            // By setting noiseSuppression to true,
            // the browser will attempt to reduce background noise from the captured audio.
            // Keep in mind that the effectiveness of this feature may vary depending on
            // the user's device and browser support.
            noiseSuppression,
            echoCancellation,
          };
          // get aprove use devices
          const audio = mute ? false : mediaTrackConstraints;
          // const strm = await navigator.mediaDevices.getUserMedia({ audio: !mute, video: true });
          const strm = await navigator.mediaDevices.getUserMedia({ audio, video: true });
          // stop all track if exist
          strm.getTracks().forEach((x: any) => x.stop());
          // get device list
          if (!camList) {
            await getCamAndMics(setCamList, setLoading);
          }
          const cameraStream = await navigator.mediaDevices.getUserMedia({
            audio,
            // audio: !mute,
            video: {
              facingMode: front ? 'user' : 'environment',
            },
          });
          setStream(cameraStream);
        } catch (error) {
          setIsDeviceDeny(error);
        }
      })();
    }
  }, [front, srcForView, mute, noiseSuppression, echoCancellation]);

  const startRecord = async (): Promise<void> => {
    // formik.setErrors({});
    setSrcForView('');
    setstartRec(() => true);
    if (stream) {
      stream.getTracks().forEach((x: any) => x.stop());
    }
    await delay(0);
    const mediaTrackConstraints: MediaTrackConstraints = {
      // By setting noiseSuppression to true,
      // the browser will attempt to reduce background noise from the captured audio.
      // Keep in mind that the effectiveness of this feature may vary depending on
      // the user's device and browser support.
      noiseSuppression,
      echoCancellation,
    };
    const audio = mute ? false : mediaTrackConstraints;
    const cameraStream = await navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: front ? 'user' : 'environment',
      },
      // audio: !mute,
      audio,
    });
    setStream(cameraStream);
    recorderRef.current = new RecordRTCPromisesHandler(cameraStream, {
      type: 'video',
      // mimeType: 'video/webm;codecs=h264',
      // mimeType: 'video/mp4',
      mimeType: 'video/webm;codecs=vp8',
      timeSlice: 1000,
      disableLogs: true,
    });
    setDuration(0);
    setStatus(timerStatus.STARTED);
    recorderRef.current.startRecording();
  };

  const start = (): void => {
    if (mute && isAlowedAlert) {
      setMicAlert(() => true);
    } else {
      startRecord();
    }
  };

  const stopRecord = async (): Promise<void> => {
    setLoading(() => true);
    setStatus(timerStatus.STOPPED);
    await recorderRef.current.stopRecording();
    const result: any = await recorderRef.current.getBlob();
    const url = URL.createObjectURL(result);
    const currentName = 'Record';
    let defaultType = 'video/webm';
    let defaultName = `${currentName}.webm`;

    if (result.type.includes('mp4') || result.type.includes('MP4')) {
      defaultType = 'video/mp4';
      defaultName = `${currentName}.mp4`;
    }
    if (result.type.includes('mov') || result.type.includes('MOV')) {
      defaultType = 'video/quicktime';
      defaultName = `${currentName}.mov`;
    }
    if (result.type.includes('hevc') || result.type.includes('HEVC')) {
      defaultType = 'video/quicktime';
      defaultName = `${currentName}.hevc`;
    }

    const blobToFile = new File([result], defaultName, { type: defaultType });
    const copy = [{ ...recVideoRaw[0] }];
    copy[0].file = blobToFile;
    copy[0].name = defaultName;
    copy[0].type = defaultType;
    setRecVideoRaw(copy);
    setSrcForView(url);
    // formik.setFieldValue('fileDuration', duration);
    // formik.setFieldValue('fileNameOrigin', defaultName);
    setSrcForView(url);
    if (stream) {
      stream.getTracks().forEach((x: any) => x.stop());
    }
    await recorderRef.current.reset();
    setstartRec(() => false);
    if (duration < 5000) {
      // formik.setTouched({ file: true });
      setError(t?.validation.min_record_video_duration);
    }
    recorderRef.current = null;
    setLoading(() => false);
  };

  // Remove sound from recorded video
  const removeSound = async (): Promise<void> => {
    if (srcForView) {
      setLoading(() => true);
      try {
        await FFMPEG.process(recVideoRaw[0].file, '-c:v copy -an', (e: any) => {
          const muteFile = e?.result;
          const copy = [{ ...recVideoRaw[0] }];
          copy[0].file = muteFile;
          setRecVideoRaw(copy);
          setMute(() => true);
          const url = URL.createObjectURL(muteFile);
          setSrcForView(url);
          setLoading(false);
        });
      } catch (error) {
        setLoading(false);
        toast.error(error_occurred);
      }
    }
  };

  // Auto stop video recording
  useEffect(() => {
    if (duration >= 29000) {
      setDuration(() => 30000);
      stopRecord();
    }
  }, [duration]);

  const replaceCam = async (): Promise<void> => {
    setFront(!front);
  };

  const onMute = async (): Promise<void> => {
    setMute(!mute);
  };

  const resetRecordedVideo = async (): Promise<void> => {
    setDuration(0);
    // formik.setErrors({});
    setError('');
    // formik.setTouched({});
    setSrcForView('');
    const copy = [{ ...recVideoRaw[0] }];
    copy[0].file = null;
    setRecVideoRaw(copy);
    // formik.setFieldValue('fileNameOrigin', '');
  };

  const closeAlert = (): void => {
    setMicAlert(() => false);
    setAlowedAlert(() => false);
  };

  const turnOnMic = (): void => {
    onMute();
    setMicAlert(() => false);
  };

  // Noise reduction handler
  const onNoiseReduction = (e: any, noiseType: string): void => {
    const currentVal = e.target.checked;
    if (noiseType === 'noise-suppression') {
      setNoiseSuppression(currentVal);
    }
    if (noiseType === 'echo-cancellation') {
      setEchoCancellation(currentVal);
    }
  };

  const isDisableRemoveSound = !srcForView || loading || mute;
  const micWarnContant = (
    <div className={styles['device-deny-modal__content-wrapper']}>
      {do_you_want_audio_to_be_recorded}
    </div>
  );

  return (
    <>
      <div className={styles['add-video__video-player-wrapper']}>
        <video
          ref={refVideo}
          autoPlay={!srcForView}
          playsInline
          controls={!!srcForView}
          muted
          className={cx({
            [styles.form__recorder]: true,
            [styles.hide]: srcForView,
          })}
        />
        <video
          src={srcForView}
          controls
          className={cx({
            [styles.form__recorder]: true,
            [styles.hide]: !srcForView,
          })}
        />
        <div
          className={cx({
            [styles['form__recorder-btn-cont']]: true,
            [styles['form__recorder-btn-cont--reset']]: !startRec && srcForView,
          })}
        >
          {!startRec && !srcForView && (
            <div className={styles['form__recorder-btn-item']}>
              <div className={styles['form__recorder-btn']} onClick={onMute} role="presentation">
                {mute ? <img src={MicMuteIcon} alt="Mic" /> : <img src={MicIcon} alt="Mic" />}
              </div>
              <div className={styles['form__recorder-btn-name']}>{mute_text}</div>
            </div>
          )}

          {!startRec && !srcForView && (
            <div className={styles['form__recorder-btn-item']}>
              <div
                className={styles['form__recorder-btn']}
                onClick={replaceCam}
                role="presentation"
              >
                <img src={FlipIcon} alt="Flip" />
              </div>
              <div className={styles['form__recorder-btn-name']}>{flip}</div>
            </div>
          )}

          {!startRec && srcForView && (
            <div className={styles['form__recorder-btn-item']}>
              <div
                className={styles['form__recorder-btn']}
                onClick={resetRecordedVideo}
                role="presentation"
              >
                &#8634;
              </div>
              <div className={styles['form__recorder-btn-name']}>{reset}</div>
            </div>
          )}

          {!startRec && !srcForView && (
            <div
              className={cx({
                [styles['form__recorder-btn-item']]: true,
                [styles.hide]: startRec,
              })}
            >
              <div className={styles['form__recorder-btn']} onClick={start} role="presentation">
                <img src={StartIcon} className={styles['start-icon']} alt="Start" />
              </div>
              <div className={styles['form__recorder-btn-name']}>{start_text}</div>
            </div>
          )}
          <div
            className={cx({
              [styles['form__recorder-btn-item']]: true,
              [styles.hide]: !startRec,
            })}
          >
            <div
              className={cx({
                [styles['form__recorder-btn']]: true,
                [styles['form__recorder-btn-stop']]: true,
              })}
              onClick={stopRecord}
              role="presentation"
            >
              X
            </div>
            <div className={styles['form__recorder-btn-name']}>{end_text}</div>
          </div>
        </div>
        <div className={styles['form__recorder-timer-cont']}>
          <div className={styles['form__recorder-timer-big-circle']}>
            <div className={styles['form__recorder-timer-sm-circle']} />
          </div>
          <div>{moment(duration).format('mm:ss')}</div>
        </div>
      </div>

      {/* Noise reduction */}
      <div className={styles['form__noise-reduction-container']}>
        <div className={styles['form__noise-reduction-item']}>
          <RoundCheckbox
            htmlId="noiseSuppression"
            name="noiseSuppression"
            checked={noiseSuppression}
            onChangeMethod={(e: any): void => onNoiseReduction(e, 'noise-suppression')}
            isTouched={false}
            hasErrors={false}
            disabled={mute}
            label={noise_suppression}
          />
          <div>{}</div>
        </div>
        <div className={styles['form__noise-reduction-item']}>
          <RoundCheckbox
            htmlId="echoCancellation"
            name="echoCancellation"
            checked={echoCancellation}
            onChangeMethod={(e: any): void => onNoiseReduction(e, 'echo-cancellation')}
            isTouched={false}
            hasErrors={false}
            disabled={mute}
            label={echo_cancellation}
          />
        </div>
        <div
          onClick={isDisableRemoveSound ? (): null => null : removeSound}
          aria-hidden
          className={cx({
            [styles['form__noise-reduction-item']]: true,
            [styles['form__btn--remove-sound']]: true,
            [styles['form__btn--remove-sound-disable']]: isDisableRemoveSound,
          })}
        >
          <MuteIcon />
          <div className={styles['form__btn--remove-sound-label']}>{remove_sound}</div>
        </div>
      </div>

      {isDeviceDeny && (
        <Modal onClose={deleteVideo}>
          <CloseBtn close={deleteVideo} />
          <img
            className={styles['device-deny-modal__warn-img']}
            src={WarningIcon}
            alt="Warning icon"
          />
          <div className={styles['device-deny-modal__content-wrapper']}>{access_to_device}</div>
          <Button
            buttonType="button"
            buttonName={cancel}
            buttonClass={styles.modal__btn}
            buttonMethod={deleteVideo}
            disabledButton={loading}
          />
        </Modal>
      )}

      {isMicAlert && (
        <WarnNotifModal
          onClose={closeAlert}
          content={micWarnContant}
          cancelBtnName={no}
          actionBtnName={yes}
          actionMethod={turnOnMic}
        />
      )}
    </>
  );
};

export default VideoRecorder;
