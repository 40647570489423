import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';

import overviewStyles from '../styles.module.css';
import style from './style.module.css';
import { PatientInformationIcon } from '../../../theme/icons';
import { WoundImagesCardProps } from '../types';
import { getCurrenLang } from '../../../redux/selector';
import { CardTitle, EndMessage, PatientMedia } from '../Elements';
import useCheckRole from '../../../hooks/useCheckRole';
import { spinner } from '../../../common/Loader';
import { MEDIA_PER_PAGE } from '../../../utils/variables';
import { useGetAllPatientInformations } from '../../../graphql/patients';
import { initialPage } from '../../../utils/helper';

const InformationCard = ({ patientId, loading }: WoundImagesCardProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const loading_t = t?.header.loading;
  const patient_information = t?.dashboard.hcp.profile_patient.patient_information;
  const noMoreMedia = t?.dashboard.hcp.profile_patient.noMoreMedia || 'No more media';
  const no_patient_information =
    t?.dashboard.hcp.profile_patient.no_patient_information ||
    'There is currently no patient information sent to this patient. ';

  // Endpoints
  const {
    _getAllPatientInfo,
    allPatientInfo,
    loadingAllPatientInfo,
    errorAllPatientInfo,
    allPatientInfoData,
    totalElements,
    totalPages,
  } = useGetAllPatientInformations();

  // Local state
  const [patientsMedia, setPatientsMedia] = useState<any>(null);
  const [itemsPerPageCumulative, setItemsPerPageCumulative] = useState(MEDIA_PER_PAGE);

  const { isAdmin } = useCheckRole();
  const endMessage = totalPages === 1;

  // Send request to the database
  useEffect(() => {
    if (patientId) {
      _getAllPatientInfo({
        patientId,
        listProps: {
          page: initialPage,
          itemsPerPage: itemsPerPageCumulative,
          sortBy: 'name',
          sortDirection: '',
        },
        searchProps: {
          searchField: 'name',
          searchValue: '',
        },
      });
    }
  }, [patientId]);

  // After receiving the list of patients info from the database, display them on the UI
  useEffect(() => {
    if (allPatientInfo && !loadingAllPatientInfo) {
      setPatientsMedia(allPatientInfo);
    } else if (!loadingAllPatientInfo) {
      setPatientsMedia(null);
    }
  }, [allPatientInfoData]);

  // Show message if loading documents from patients is error
  useEffect(() => {
    if (errorAllPatientInfo) {
      toast.error(errorAllPatientInfo);
    }
  }, [errorAllPatientInfo]);

  // Get next page from DB
  const setNextPage = (): void => {
    const nextNumberItemsPerPage = itemsPerPageCumulative + MEDIA_PER_PAGE;
    setItemsPerPageCumulative(nextNumberItemsPerPage);
    _getAllPatientInfo({
      patientId,
      listProps: {
        page: initialPage,
        itemsPerPage: nextNumberItemsPerPage,
        sortBy: 'name',
        sortDirection: '',
      },
      searchProps: {
        searchField: 'name',
        searchValue: '',
      },
    });
  };

  // JSX
  const loadingJSX = (loading || loadingAllPatientInfo) && spinner;

  const noData =
    isAdmin ||
    (patientsMedia?.length === 0 && (
      <div className={overviewStyles.nodata}>{no_patient_information}</div>
    ));

  const endMsgJsx = (
    <EndMessage
      totalItems={totalElements}
      ITEMS_PER_PAGE={MEDIA_PER_PAGE}
      description={noMoreMedia}
    />
  );

  return (
    <div className={overviewStyles['overview__sm-card-container']}>
      {loadingJSX}
      <CardTitle icon={<PatientInformationIcon />} title={patient_information} />

      <div className={style.notifications__container}>
        {/* List */}
        {patientsMedia && !noData ? (
          <InfiniteScroll
            dataLength={patientsMedia.length}
            next={(): any => setNextPage()}
            hasMore={!endMessage}
            loader={<div className={style.notifications__loader}>{`${loading_t || ''}...`}</div>}
            scrollableTarget="scrollablePIDiv"
            endMessage={endMsgJsx}
          >
            {patientsMedia.map((videoData: any) => (
              <PatientMedia key={`media${videoData.id}`} data={videoData} />
            ))}
          </InfiniteScroll>
        ) : (
          <></>
        )}
        {noData}
      </div>
    </div>
  );
};

export default InformationCard;
