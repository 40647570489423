import { loader } from 'graphql.macro';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { useMutationRequest } from '../../hooks/feature/useMutationRequest';
import { UseLogoutProps } from '../types';
import { path, storage } from '../../utils';

// Mutations
const mutationLogoutAdmin = loader('./gql/mutationAdminLogout.graphql');
const mutationLogoutHcp = loader('./gql/mutationLogout.graphql');

// Logout
export const useLogout = (isAdmin: boolean): UseLogoutProps => {
  const [logout, { errorText, data }] = useMutationRequest<any>(
    isAdmin ? mutationLogoutAdmin : mutationLogoutHcp,
    {},
  );
  const history = useHistory();
  const today = moment().format('DD-MM-YYYY');
  const fromLS = storage.get('user');
  const currentHcpEmail = fromLS && fromLS.email;

  const onLogout = (): void => {
    logout();
  };
  if (data?.adminLogout || data?.hcpLogout) {
    const redirectTo = isAdmin ? path.adminSignIn : path.signIn;

    history.push(redirectTo);
    storage.save('user', '');
    storage.save(currentHcpEmail, today);
  }
  return {
    error: errorText,
    onLogout,
  };
};
