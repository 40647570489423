import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { Tooltip } from 'antd';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import pageStyles from '../../styles.module.css';
import style from './style.module.css';
import patientStyles from '../style.module.css';
import {
  GetState,
  getCurrenLang,
  getErrorPatientProfile,
  getLoadingPatientProfile,
  getPatientProfile,
} from '../../../redux/selector';
import { Button, Loading, MainTitle } from '../../../common';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import { capitalizeFirstLetter, deepCopyObj, getQueryParams } from '../../../utils/helper';
import { path } from '../../../utils';
import useCheckRole from '../../../hooks/useCheckRole';
import { ADD_PATIENT_INFO_KEY, PERMISSIONS, StatusKeyNameEnum } from '../../../utils/enums';
import useCheckPermissions from '../../../hooks/useCheckPermissions';
import { ArrowLeftBlack, ArrowRightBlack, DeleteIcon } from '../../../theme/icons';
import { PopupWithTwoButtons } from '../../../common/Popup';
import { KindOfInfoType, UploadFileType, UrlType } from './types';
import { allowedPicTypes, initUploadFile, initUrl } from './InitValues';
import {
  useCreateHcpDocument,
  useRemoveFileFromAws,
  useRemoveVideoFileFromAws,
} from '../../../graphql/attachments';
import UploadFile from '../../../components/AddPatientInformation/UploadFile';
import { useQueryPatientProfile } from '../../../graphql/patients';
import { fillPatientProfile } from '../../../redux/patient';
import { SuccessNotifModal } from '../../../common/NotificationModal';
import RecordVideo from '../../../components/AddPatientInformation/RecordVideo';
import Url from '../../../components/AddPatientInformation/Url';
import ExternalContent from '../../../components/AddPatientInformation/ExternalContent';

const AddPatientInformationPage = (): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const patient_list = t?.title.patient_list;
  const patient_information = t?.dashboard.hcp.profile_patient.patient_information;
  const add_patient_information = t?.dashboard.hcp.profile_patient.add_patient_information;
  const patient_not_found = t?.common.patient_not_found;
  const upload_files = t?.dashboard.hcp.profile_patient.upload_files || 'Upload Files';
  const record_video = t?.dashboard.hcp.profile_patient.record_video;
  const include_link = t?.dashboard.hcp.profile_patient.include_link;
  const file_was_not_deleted = t?.dashboard.hcp.profile_patient.file_was_not_deleted;
  const url_t = t?.dashboard.hcp.profile_patient.url_t?.toUpperCase();
  const document_successfully_saved = t?.dashboard.hcp.profile_patient.document_successfully_saved;
  const ok = t?.notifications.ok;
  const cancel = t?.common.cancel;
  const save = t?.common.save;
  const contact_otm = t?.common.contact_otm;
  const limit_exceeded = t?.dashboard.hcp.profile_patient.limit_exceeded;
  const exit = t?.common.exit;
  const warning_unsaved_changes = t?.common.warning_unsaved_changes;
  const click_ok =
    t?.dashboard.hcp.profile_patient.click_ok ||
    'Click OK or Cancel on the active menu before opening the next menu item.';
  const error_occurred = t?.dashboard.hcp.profile_patient.error_occurred;

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId, userStatus } = getQueryParams(location.search);
  const patientId = Number(userId);
  const patientStatus = String(userStatus);
  const { isAdmin } = useCheckRole();
  const isPermissionEditProfile = useCheckPermissions(PERMISSIONS.EDIT_PATIENT_PROFILE_INFO_OTM);

  // Endpoints
  const { _getProfileById } = useQueryPatientProfile(patientStatus);
  const { _createHcpDocument, createdData, createError, createLoading } = useCreateHcpDocument();
  const { _removeFile, deleteFromAwsLoading } = useRemoveFileFromAws();
  const { _removeVideo, deleteVideoFromAwsLoading } = useRemoveVideoFileFromAws();

  // Local state
  const [isOpenForm, setOpenForm] = useState<ADD_PATIENT_INFO_KEY | ''>('');
  const [hasChanges, setChanges] = useState(false);
  const [isExitWarn, setExitWarn] = useState(false);
  const [isLimitExceeded, setLimitExceeded] = useState(false);

  const [uploadFile, setUploadFile] = useState<UploadFileType[]>([]);
  const [uploadFileRaw, setUploadFileRaw] = useState<UploadFileType[]>([
    ...deepCopyObj(initUploadFile),
  ]);
  const [recVideo, setRecVideo] = useState<UploadFileType[]>([]);
  const [recVideoRaw, setRecVideoRaw] = useState<UploadFileType[]>([
    ...deepCopyObj(initUploadFile),
  ]);
  const [url, setUrl] = useState<UrlType[]>([]);
  const [urlRaw, setUrlRaw] = useState<UrlType[]>([...deepCopyObj(initUrl)]);
  const [editorState, setEditorState] = useState<any>([]);
  const [editorStateRaw, setEditorStateRaw] = useState<any>(EditorState.createEmpty());
  const [fileSizes, setFileSizes] = useState(0);
  const [isSuccessCreatedMsg, setSuccessCreatedMsg] = useState(false);

  // Redux
  const profileData: any = useSelector<any>((state: GetState) => getPatientProfile(state));
  const profileLoading = useSelector<any>((state: GetState) => getLoadingPatientProfile(state));
  const profileError: any = useSelector<any>((state: GetState) => getErrorPatientProfile(state));
  const success_created_msg = document_successfully_saved?.replace(
    '<FullName>',
    `${profileData?.firstName || ''} ${profileData?.lastName || ''}`,
  );

  const addInfoConfig = useMemo(() => {
    const uploadFileAtt: UploadFileType[] = [];
    const recVideoAtt: UploadFileType[] = [];
    const urlAtt: UrlType[] = [];
    const editorStateAtt: any[] = [];

    uploadFile.map((item: UploadFileType) => {
      if (item.file) {
        uploadFileAtt.push(item);
      }
      return null;
    });
    recVideo.map((item: UploadFileType) => {
      if (item.file) {
        recVideoAtt.push(item);
      }
      return null;
    });
    editorState.map((item: any) => {
      const hasText = item.getCurrentContent().hasText();
      if (hasText) {
        const contentHTML = draftToHtml(convertToRaw(item.getCurrentContent()));
        editorStateAtt.push({
          content: contentHTML,
        });
      }
      return null;
    });
    url.map((item: UrlType) => {
      if (item.url) {
        const result: any = { ...item };
        result.name = item.url;
        urlAtt.push(result);
      }
      return null;
    });

    return [
      {
        title: capitalizeFirstLetter(upload_files),
        key: ADD_PATIENT_INFO_KEY.UPLOAD_FILE,
        attachments: uploadFileAtt,
      },
      {
        title: record_video,
        key: ADD_PATIENT_INFO_KEY.RECORD_VIDEO,
        attachments: recVideoAtt,
      },
      {
        title: include_link,
        key: ADD_PATIENT_INFO_KEY.EXTERNAL_CONTENT,
        attachments: editorStateAtt,
      },
      {
        title: url_t,
        key: ADD_PATIENT_INFO_KEY.URL,
        attachments: urlAtt,
      },
    ];
  }, [t, uploadFile, recVideo, editorState, url]);

  // Counting all document items
  const checkNumberOfAttachments = (): boolean => {
    const numberOfFileAttachments = uploadFile.length;
    const numberOfRecordVideos = recVideo.length;
    const numberOfAttachedUrls = url.length;
    const numberExtenalContents = editorState.length;

    const limitItems = 100;
    const counter =
      numberOfFileAttachments + numberOfAttachedUrls + numberOfRecordVideos + numberExtenalContents;
    const isLimitExceded = counter >= limitItems;
    setLimitExceeded(isLimitExceded);
    return isLimitExceded;
  };

  // Check unsaved data
  useEffect(() => {
    let hasChange = false;
    if (uploadFile.length || recVideo.length || editorState.length || url.length) {
      hasChange = true;
    }
    const hasText = editorStateRaw.getCurrentContent().hasText();
    if (
      uploadFileRaw[0].file ||
      uploadFileRaw[0].description ||
      recVideoRaw[0].file ||
      recVideoRaw[0].description ||
      hasText ||
      urlRaw[0].url ||
      urlRaw[0].description
    ) {
      hasChange = true;
    }
    setChanges(hasChange);
    checkNumberOfAttachments();
  }, [uploadFile, recVideo, editorState, url, uploadFileRaw, recVideoRaw, editorStateRaw, urlRaw]);

  // No data available for this user
  const notAllowed = useMemo(() => {
    if (!userStatus || !patientId || profileError) {
      return true;
    }
    if (userStatus === StatusKeyNameEnum.invite_sent) {
      return true;
    }
    if (userStatus === StatusKeyNameEnum.pending) {
      return true;
    }
    return false;
  }, [patientId, userStatus, profileError]);

  // Send request to the database
  useEffect(() => {
    if (!notAllowed) {
      _getProfileById({
        variables: {
          patientId,
        },
      });
    }
  }, [notAllowed, patientId]);

  // Clear state after componentWillUnmount
  useEffect(
    () => (): void => {
      dispatch(fillPatientProfile(null));
    },
    [],
  );

  // If the document is successfully created
  useEffect(() => {
    if (createdData) {
      setSuccessCreatedMsg(() => true);
    }
  }, [createdData]);

  // If the document create error
  useEffect(() => {
    if (!createLoading && createError) {
      toast.error(createError);
    }
  }, [createError]);

  // Delete files from AWS and local state
  const deleteAttachment = async (ind: number, type: string): Promise<void> => {
    switch (type) {
      case ADD_PATIENT_INFO_KEY.UPLOAD_FILE:
        {
          const newUpload = [...uploadFile];
          const isImage = allowedPicTypes.includes(newUpload[ind].type);
          const removeFromAws = isImage ? _removeFile : _removeVideo;
          const keyField = isImage ? 'pictureUuidKey' : 'videoKey';
          try {
            await removeFromAws({
              variables: {
                [keyField]: newUpload[ind].uuid,
              },
            });
            newUpload.splice(ind, 1);
            setUploadFile(newUpload);
          } catch (error) {
            toast.error(file_was_not_deleted);
          }
        }
        break;
      case ADD_PATIENT_INFO_KEY.RECORD_VIDEO:
        {
          const newRecord = [...recVideo];
          try {
            await _removeVideo({
              variables: {
                videoKey: newRecord[ind].uuid,
              },
            });
            newRecord.splice(ind, 1);
            setRecVideo(newRecord);
          } catch (error) {
            toast.error(file_was_not_deleted);
          }
        }
        break;
      case ADD_PATIENT_INFO_KEY.EXTERNAL_CONTENT:
        {
          const newEditorState = [...editorState];
          newEditorState.splice(ind, 1);
          setEditorState(newEditorState);
        }
        break;
      case ADD_PATIENT_INFO_KEY.URL:
        {
          const newUrl = [...url];
          newUrl.splice(ind, 1);
          setUrl(newUrl);
        }
        break;
      default:
        break;
    }
  };

  // Delete from AWS all attachments
  const deleteAllAttachments = async (): Promise<void> => {
    const uuIdFiles = uploadFile.map((item: UploadFileType) => item.uuid);
    const uuIdRecords = recVideo.map((item: UploadFileType) => item.uuid);

    const deleteFromAws = async (arr: string[], type: string): Promise<void> => {
      async function* asyncGenerator(): AsyncGenerator<number, void, unknown> {
        let j = 0;
        while (j < arr.length) {
          yield (j += 1);
        }
      }
      // eslint-disable-next-line no-restricted-syntax
      for await (const j of asyncGenerator()) {
        const i = j - 1;
        await deleteAttachment(i, type);
      }
    };
    await deleteFromAws(uuIdFiles, ADD_PATIENT_INFO_KEY.UPLOAD_FILE);
    await deleteFromAws(uuIdRecords, ADD_PATIENT_INFO_KEY.RECORD_VIDEO);
  };

  // Save document to database (create document)
  const onSubmit = (): void => {
    const hcpFiles: any = [];
    const hcpVideos: any = [];
    const hcpUrls: any = [];
    const hcpContents: any = [];
    const newUploadFile = [...uploadFile];
    const newUrl = [...url];
    let isError = false;

    uploadFile.map((file: UploadFileType, i: number) => {
      if (file.description && !file.file) {
        newUploadFile[i].isError = true;
        isError = true;
      }
      if (!file.uuid) return null;
      const isImage = allowedPicTypes.includes(file.type);
      if (isImage) {
        const normalizeFile = {
          name: file.name,
          fileUuid: file.uuid,
          description: file.description || null,
        };
        hcpFiles.push(normalizeFile);
      } else {
        const normalizeVideoFile = {
          name: file.name,
          videoUuid: file.uuid,
          thumbnailUuid: file.thumbnail,
          description: file.description || null,
        };
        hcpVideos.push(normalizeVideoFile);
      }
      return null;
    });

    recVideo.map((rec: UploadFileType) => {
      if (!rec.uuid) return null;
      const normalizeRecords = {
        name: rec.name,
        videoUuid: rec.uuid,
        thumbnailUuid: rec.thumbnail,
        description: rec.description || null,
      };
      hcpVideos.push(normalizeRecords);
      return null;
    });

    url.map((urlItem: UrlType, i: number) => {
      if ((urlItem.description || urlItem.url) && !urlItem.isDone) {
        newUrl[i].isError = true;
        isError = true;
      }
      if (urlItem.isDone) {
        const normalizeUrl = {
          url: urlItem.url,
          description: urlItem.description || null,
        };
        hcpUrls.push(normalizeUrl);
      }
      return null;
    });
    if (editorState.length) {
      editorState.map((itemEditor: any) => {
        const hasText = itemEditor.getCurrentContent().hasText();
        if (hasText) {
          const contentHTML = draftToHtml(convertToRaw(itemEditor.getCurrentContent()));
          hcpContents.push({
            content: JSON.stringify(contentHTML),
          });
        } else {
          isError = true;
        }
        return null;
      });
    }

    if (isError) {
      setUploadFile(newUploadFile);
      setUrl(newUrl);
      toast.error(error_occurred);
      return;
    }

    _createHcpDocument({
      variables: {
        hcpContents,
        hcpUrls,
        hcpFiles,
        hcpVideos,
        patientId,
      },
    });
  };

  // Return back to current page
  const returnBackToPage = (): void => {
    history.push({
      pathname: path.patient_information,
      search: location.search,
    });
  };

  // Open item menu
  const openItemForm = (type: ADD_PATIENT_INFO_KEY): void => {
    setOpenForm(type);
  };

  // Cancel form button handler
  const onCancel = (): void => {
    if (hasChanges) {
      setExitWarn(() => true);
      return;
    }
    returnBackToPage();
  };

  // Confirm exit from patient information page
  const onConfirmExit = async (): Promise<void> => {
    setExitWarn(() => false);
    setOpenForm('');
    await deleteAllAttachments();
    returnBackToPage();
  };

  // Clouse Exit warning popup
  const onCloseExitWarn = (): void => {
    setExitWarn(() => false);
  };

  const checkRawData = (itemKey: ADD_PATIENT_INFO_KEY, isActive: boolean): boolean => {
    if (isActive) {
      return false;
    }
    if (
      itemKey !== ADD_PATIENT_INFO_KEY.UPLOAD_FILE &&
      (uploadFileRaw[0].file || uploadFileRaw[0].description)
    ) {
      return true;
    }
    if (
      itemKey !== ADD_PATIENT_INFO_KEY.RECORD_VIDEO &&
      (recVideoRaw[0].file || recVideoRaw[0].description)
    ) {
      return true;
    }

    const hasText = editorStateRaw.getCurrentContent().hasText();
    if (itemKey !== ADD_PATIENT_INFO_KEY.EXTERNAL_CONTENT && hasText) {
      return true;
    }

    if (itemKey !== ADD_PATIENT_INFO_KEY.URL && (urlRaw[0].url || urlRaw[0].description)) {
      return true;
    }
    return false;
  };

  const getFormJsx = (type: ADD_PATIENT_INFO_KEY): JSX.Element => {
    switch (type) {
      case ADD_PATIENT_INFO_KEY.UPLOAD_FILE:
        return (
          <UploadFile
            uploadFile={uploadFile}
            setUploadFile={setUploadFile}
            fileSizes={fileSizes}
            setFileSizes={setFileSizes}
            setOpenForm={setOpenForm}
            uploadFileRaw={uploadFileRaw}
            setUploadFileRaw={setUploadFileRaw}
          />
        );
      case ADD_PATIENT_INFO_KEY.RECORD_VIDEO:
        return (
          <RecordVideo
            recVideo={recVideo}
            setRecVideo={setRecVideo}
            setOpenForm={setOpenForm}
            recVideoRaw={recVideoRaw}
            setRecVideoRaw={setRecVideoRaw}
          />
        );
      case ADD_PATIENT_INFO_KEY.EXTERNAL_CONTENT:
        return (
          <ExternalContent
            editorState={editorState}
            setEditorState={setEditorState}
            setOpenForm={setOpenForm}
            editorStateRaw={editorStateRaw}
            setEditorStateRaw={setEditorStateRaw}
          />
        );
      case ADD_PATIENT_INFO_KEY.URL:
        return (
          <Url
            url={url}
            setUrl={setUrl}
            setOpenForm={setOpenForm}
            urlRaw={urlRaw}
            setUrlRaw={setUrlRaw}
          />
        );
      default:
        return <></>;
    }
  };

  // Clouse success message popup
  const clouseSuccessMsg = (): void => {
    setSuccessCreatedMsg(() => false);
    returnBackToPage();
  };

  // Breadcrumb path
  const routes = useMemo(
    () => [
      {
        path: isAdmin ? path.adminDashboardList : path.dashboardList,
        breadcrumbName: patient_list,
      },
      {
        path: isAdmin
          ? `${path.patient_overview_admin}${location.search}`
          : `${path.patient_overview}${location.search}`,
        breadcrumbName: profileData ? `${profileData.firstName} ${profileData.lastName}` : '-',
      },
      {
        path: isAdmin
          ? `${path.patient_information_admin}${location.search}`
          : `${path.patient_information}${location.search}`,
        breadcrumbName: patient_information,
      },
      {
        path: add_patient_information,
        breadcrumbName: add_patient_information,
      },
    ],
    [t, profileData],
  );

  const arrowStyle = {
    width: '13px',
    height: '20px',
    stroke: '#000000',
  };

  // JSX
  const loadingJSX = (profileLoading ||
    deleteFromAwsLoading ||
    deleteVideoFromAwsLoading ||
    createLoading) && <Loading />;

  return (
    <div className={pageStyles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={cx([pageStyles.main__wrapper])}>
        <MainTitle title={add_patient_information} />

        {(notAllowed || !isPermissionEditProfile) && !profileLoading ? (
          <div className={patientStyles['profile-user__notfound']}>
            {isPermissionEditProfile ? contact_otm : patient_not_found}
          </div>
        ) : (
          <div className={pageStyles['flex-container']}>
            <div className={pageStyles['left-section']}>
              {addInfoConfig.map((item: KindOfInfoType) => {
                const isActive = item.key === isOpenForm;
                const isNotAllowedOpen = checkRawData(item.key, isActive);
                return (
                  <div className={style['add-patient-info__item-wrapper']} key={item.key}>
                    <Tooltip title={isNotAllowedOpen ? click_ok : ''}>
                      <div
                        className={cx({
                          [style['add-patient-info__item-container']]: true,
                          [style['add-patient-info__item-container--active']]: isActive,
                          [style[
                            'add-patient-info__item-container--not-allowed'
                          ]]: isNotAllowedOpen,
                        })}
                        aria-hidden
                        onClick={isNotAllowedOpen ? () => null : (): void => openItemForm(item.key)}
                      >
                        <div className={style['add-patient-info__item-name']}>{item.title}</div>
                        {isActive ? (
                          <ArrowLeftBlack {...arrowStyle} />
                        ) : (
                          <ArrowRightBlack {...arrowStyle} />
                        )}
                      </div>
                    </Tooltip>
                    {item.attachments.map((attachmentItem: any, i: number) => (
                      <div
                        key={attachmentItem.name}
                        className={style['add-patient-info__attachment-container']}
                      >
                        {!attachmentItem.content && (
                          <div className={style['add-patient-info__attachment-text-wrapper']}>
                            <div className={style['add-patient-info__attachment-name']}>
                              {attachmentItem.name}
                            </div>
                            <div className={style['add-patient-info__attachment-description']}>
                              {attachmentItem.description}
                            </div>
                          </div>
                        )}

                        {attachmentItem.content && (
                          <div
                            className={style['add-patient-info__attachment-content']}
                            dangerouslySetInnerHTML={{ __html: attachmentItem.content }}
                          />
                        )}
                        <div
                          className={style['attachments__delete-icon']}
                          onClick={(): Promise<void> => deleteAttachment(i, item.key)}
                          aria-hidden
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })}
              {/* Limit warning */}
              {isLimitExceeded && (
                <div className={style['add-patient-info__warning']}>{limit_exceeded}</div>
              )}

              {/* Buttons */}
              <div className={style['add-patient-info__btn-cont']}>
                <Button
                  buttonClass={style['add-patient-info__btn']}
                  buttonType="button"
                  buttonName={cancel}
                  buttonMethod={onCancel}
                  disabledButton={
                    !!loadingJSX ||
                    uploadFileRaw[0].file ||
                    uploadFileRaw[0].description ||
                    recVideoRaw[0].file ||
                    recVideoRaw[0].description ||
                    urlRaw[0].url ||
                    urlRaw[0].description ||
                    editorStateRaw.getCurrentContent().hasText()
                  }
                />
                <Button
                  buttonClass={style['add-patient-info__btn']}
                  buttonType="button"
                  buttonName={save}
                  buttonMethod={onSubmit}
                  disabledButton={
                    !hasChanges ||
                    !!loadingJSX ||
                    isLimitExceeded ||
                    uploadFileRaw[0].file ||
                    uploadFileRaw[0].description ||
                    recVideoRaw[0].file ||
                    recVideoRaw[0].description ||
                    urlRaw[0].url ||
                    urlRaw[0].description ||
                    editorStateRaw.getCurrentContent().hasText()
                  }
                />
              </div>
            </div>

            {/* Form */}
            {!!isOpenForm && (
              <div className={pageStyles['right-section']}>{getFormJsx(isOpenForm)}</div>
            )}
          </div>
        )}
      </div>

      {/* Popups */}
      {/* Warning popup if has not saved changes */}
      {isExitWarn && (
        <PopupWithTwoButtons
          title={warning_unsaved_changes}
          content=""
          confirmButtonName={exit}
          closeButtonName={cancel}
          onConfirm={onConfirmExit}
          onClosePopup={onCloseExitWarn}
        />
      )}

      {/* Success message if the document successfully created */}
      {isSuccessCreatedMsg && (
        <SuccessNotifModal
          onClose={clouseSuccessMsg}
          btnName={ok}
          description={success_created_msg}
        />
      )}
    </div>
  );
};

export default AddPatientInformationPage;
