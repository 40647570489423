/* eslint-disable no-nested-ternary */
import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';

import overviewStyles from '../styles.module.css';
import style from './style.module.css';
import { PatientExerciseLibraryIcon } from '../../../theme/icons';
import { PrescribedExerciseMediaCardProps } from '../types';
import { getCurrenLang } from '../../../redux/selector';
import { CardTitle, EndMessage } from '../Elements';
import useCheckRole from '../../../hooks/useCheckRole';
import useCheckPermissions from '../../../hooks/useCheckPermissions';
import { EXERCISE_TYPE, PERMISSIONS } from '../../../utils/enums';
import {
  useChangePatientAllowArchiveVideos,
  useGetCurrentPatientExerciseList,
} from '../../../graphql/videoBank';
import { ShowArchiveWarnType } from '../../Video/PrescribedExerciseList/types';
import { WarnNotifModal } from '../../../common/NotificationModal';
import { RoundCheckbox } from '../../../common/Input';
import { Options } from '../../../utils/model';
import { getStatusFilterOverviewTabVideobank } from '../../../utils/variables';
import { initialPage } from '../../../utils/helper';
import { Loading } from '../../../common';
import PrescribedExerciseItem from '../PrescribedItem/PrescribedExerciseItem';
import PrescribedFolder from '../PrescribedItem/PrescribedFolder';

const PrescribedExerciseMediaCard = ({
  patient,
  patientId,
  updateProfileData,
  loading,
}: PrescribedExerciseMediaCardProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const on = t?.common.on;
  const off = t?.common.off;
  const save = t?.common.save;
  const cancel = t?.common.cancel;
  const all = t?.common.all;
  const folder_t = t?.common.folder;
  const loading_ = t?.header.loading;
  const prescribed = t?.dashboard.hcp.profile_patient.video_bank.exercise_prescribed_this_patient;
  const no_more_exercises = t?.dashboard.hcp.profile_patient.video_bank.no_more_exercises;
  const prescribed_exercise_media =
    t?.dashboard.hcp.profile_patient.prescribed_exercise_media || 'Prescribed Exercise Media';
  const allow_patient_unarchive_exercise =
    t?.dashboard.admin.profile_patient.allow_patient_unarchive_exercise;
  const you_dont_have_permission = t?.common.you_dont_have_permission;
  const patient_be_able_archive =
    t?.dashboard.hcp.profile_patient.video_bank.patient_be_able_archive;
  const patient_not_be_able_archive =
    t?.dashboard.hcp.profile_patient.video_bank.patient_not_be_able_archive;
  const filter_by_status = t?.dashboard.hcp.profile_patient.video_bank.filter_by_status;

  const EXERC_PER_PAGE = 25;
  const { isHcp, isAdmin } = useCheckRole();
  const isPermissionEditProfile = useCheckPermissions(PERMISSIONS.EDIT_PATIENT_PROFILE_INFO_OTM);

  // Endpoint
  const {
    _changePatientAllowArchiveVideos,
    changePatientAllowArchiveVideosData,
    changePatientAllowArchiveVideosError,
    changePatientAllowArchiveVideosLoading,
  } = useChangePatientAllowArchiveVideos();

  // get exercise list
  const {
    _getCurrentPatientExerciseList,
    exerciseList,
    exerciseListData,
    loadingList,
    errorList,
  } = useGetCurrentPatientExerciseList(false);

  const showArchiveWarnInit = {
    msg: '',
    solution: null,
    isShow: false,
  };

  // Local state
  const [exerciseListState, setExersiseListState] = useState<any>();
  const [itemsPerPageCumulative, setItemsPerPageCumulative] = useState(EXERC_PER_PAGE);
  const [filteringStatus, setFilteringStatus] = useState<string>('');
  const [folderFilter, setFolderFilter] = useState(false);
  const [isShowArchiveWarn, setShowArchiveWarn] = useState<ShowArchiveWarnType>({
    ...showArchiveWarnInit,
  });

  const [sortBy, setSortby] = useState<string>('name');
  const [sortDirectionName, setSortDirectionName] = useState<string>('');

  // Get List
  useEffect(() => {
    if (isAdmin) {
      setExersiseListState([]);
      return;
    }
    if (patientId) {
      _getCurrentPatientExerciseList(
        initialPage,
        EXERC_PER_PAGE,
        sortBy,
        sortDirectionName,
        patientId,
        folderFilter,
        filteringStatus,
      );
    }
  }, [patientId, filteringStatus, folderFilter]);

  // Show error
  useEffect(() => {
    if (changePatientAllowArchiveVideosError) {
      toast.error(changePatientAllowArchiveVideosError);
    }
  }, [changePatientAllowArchiveVideosError]);

  // If success update patient data
  useEffect(() => {
    if (changePatientAllowArchiveVideosData) {
      updateProfileData();
      setShowArchiveWarn({ ...showArchiveWarnInit });
    }
  }, [changePatientAllowArchiveVideosData]);

  // Fill List
  useEffect(() => {
    if (exerciseList && !loadingList) {
      setExersiseListState(exerciseList.list);
    }
  }, [exerciseListData]);

  // Show error
  useEffect(() => {
    if (errorList) {
      toast.error(errorList);
    }
  }, [errorList]);

  // Togler handler allow patient unarchive videos by HCP
  const switchHandler = (checked: boolean): void => {
    const text = checked ? patient_be_able_archive : patient_not_be_able_archive;
    setShowArchiveWarn({
      msg: text,
      solution: checked,
      isShow: true,
    });
  };

  const changePatientAllow = (solution: boolean | null): void => {
    _changePatientAllowArchiveVideos({
      variables: { patientId, solution },
    });
  };

  // Change filter
  const onChange = (statusName: string): void => {
    if (filteringStatus.includes(statusName)) {
      return;
    }
    setFilteringStatus(statusName);
  };

  // Select the "All" option
  const onCheckAll = (): void => {
    if (filteringStatus) {
      setFilteringStatus('');
    }
  };

  // Select the "Folder" option
  const onCheckFolder = (checked: boolean): void => {
    setFolderFilter(!checked);
  };

  const endMessage = exerciseList?.totalPages === 1;
  const totalItems = exerciseList?.totalItems;

  // Get next page from DB
  const setNextPage = (): void => {
    const nextNumberItemsPerPage = itemsPerPageCumulative + EXERC_PER_PAGE;
    setItemsPerPageCumulative(nextNumberItemsPerPage);
    _getCurrentPatientExerciseList(
      initialPage,
      nextNumberItemsPerPage,
      sortBy,
      sortDirectionName,
      patientId,
      folderFilter,
      filteringStatus,
    );
  };

  // JSX
  const loadingJSX = (loadingList || loading) && <Loading />;
  const archiveWarnContent = (
    <div className={style['prescrebed__archive-warn-content']}>{isShowArchiveWarn.msg}</div>
  );
  const noData = exerciseListState && exerciseListState.length === 0 && !loadingList && (
    <div className={overviewStyles.nodata}>{prescribed}</div>
  );
  const infinitLoaderJsx = (
    <div className={style['overview__nomore-exercises']}>
      <h4>
        {loading_}
        ...
      </h4>
    </div>
  );
  const endMsgJsx = (
    <EndMessage
      totalItems={totalItems}
      ITEMS_PER_PAGE={EXERC_PER_PAGE}
      description={no_more_exercises}
    />
  );

  return (
    <div className={overviewStyles['overview__card-container']}>
      {loadingJSX}
      <CardTitle icon={<PatientExerciseLibraryIcon />} title={prescribed_exercise_media} />

      {/* Set patient rights to archive/unarchive exercises */}
      {isHcp && (
        <Tooltip title={!isPermissionEditProfile ? you_dont_have_permission : ''}>
          <div className={style['prescrebed__archive-switch-wrapper']}>
            <Switch
              checkedChildren={<span>{on}</span>}
              unCheckedChildren={<span>{off}</span>}
              loading={changePatientAllowArchiveVideosLoading}
              checked={patient?.isAllowArchiveVideos || false}
              onChange={switchHandler}
              disabled={!isPermissionEditProfile}
            />
            <div className={style['prescrebed__archive-label']}>
              {allow_patient_unarchive_exercise}
            </div>
          </div>
        </Tooltip>
      )}
      {/* Filter */}
      <div className={style['prescrebed__filter-container']}>
        <div className={style['prescrebed__filter-title']}>{filter_by_status}</div>
        <div className={style['prescrebed__filter-row']}>
          <RoundCheckbox
            htmlId="Filter-status-All"
            name={all}
            label={all}
            checked={!filteringStatus}
            onChangeMethod={onCheckAll}
            isValid
            isTouched
            hasErrors={false}
          />
          {getStatusFilterOverviewTabVideobank(t).map((menuItem: Options, i: number) => (
            <RoundCheckbox
              key={`FilterMenuElem${String(i)}`}
              htmlId={`Filter${menuItem.value}`}
              name={menuItem.label}
              label={menuItem.label}
              checked={filteringStatus === menuItem.label}
              onChangeMethod={(): void => onChange(menuItem.label)}
              isValid
              isTouched
              hasErrors={false}
            />
          ))}
          <div className={style['prescrebed__filter--item']}>
            <RoundCheckbox
              htmlId="Filter-status-Folder"
              name={folder_t}
              label={folder_t}
              checked={folderFilter}
              onChangeMethod={(): void => onCheckFolder(folderFilter)}
              isValid
              isTouched
              hasErrors={false}
            />
          </div>
        </div>
      </div>

      {noData}

      {!noData && (
        <div className={style['prescrebed__exercises-container']}>
          {/* List */}
          {exerciseListState && exerciseListState.length > 0 && (
            <InfiniteScroll
              dataLength={exerciseListState.length}
              next={(): any => setNextPage()}
              hasMore={!endMessage}
              loader={infinitLoaderJsx}
              scrollableTarget="scrollableVideobank"
              endMessage={endMsgJsx}
            >
              {exerciseListState.map((exercise: any, i: number) => (
                <Fragment key={`${exercise.type}_${exercise.currentId}${String(i)}`}>
                  {(exercise.type === EXERCISE_TYPE.PHOTO ||
                    exercise.type === EXERCISE_TYPE.VIDEO) && (
                    <PrescribedExerciseItem data={exercise} />
                  )}
                  {exercise.type === EXERCISE_TYPE.FOLDER && <PrescribedFolder data={exercise} />}
                </Fragment>
              ))}
            </InfiniteScroll>
          )}
        </div>
      )}

      {/* Popups */}
      {isShowArchiveWarn.isShow && (
        <WarnNotifModal
          onClose={(): void => setShowArchiveWarn({ ...showArchiveWarnInit })}
          content={archiveWarnContent}
          cancelBtnName={cancel}
          actionBtnName={save}
          actionMethod={(): void => changePatientAllow(isShowArchiveWarn.solution)}
        />
      )}
    </div>
  );
};

export default PrescribedExerciseMediaCard;
