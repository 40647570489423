import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import styles from '../styles.module.css';
import { getCurrenLang } from '../../../redux/selector';
import {
  ActionItem,
  SortSearchItem,
} from '../../Video/OrganisationVideoBank/components/HeaderItems';

const TableHeader = ({ sortByColumnName, sortBy }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const first_name = t && t.dashboard.hcp.table.first_name;
  const last_name = t && t.dashboard.hcp.table.last_name;
  const email_t = t && t.dashboard.hcp.table.email;
  const action = t?.dashboard.hcp.profile_patient.video_bank.action;
  const phone_number = t?.dashboard.hcp.table.phone_number || 'Phone Number';
  const date_invited = t?.dashboard.hcp.table.date_invited || 'Date Invited';

  return (
    <div className={styles['patients-invitations__header-container']}>
      <div className={styles['patients-invitations__action']}>
        <ActionItem name={action} />
      </div>
      {/* First name */}
      <div className={styles['patients-invitations__first-name']}>
        <SortSearchItem
          fieldName={first_name}
          t={t}
          sortName="patient.firstName"
          sortByColumnName={sortByColumnName}
          sortBy={sortBy}
        />
      </div>
      {/* Last name */}
      <div className={styles['patients-invitations__last-name']}>
        <SortSearchItem
          fieldName={last_name}
          t={t}
          sortName="patient.lastName"
          sortByColumnName={sortByColumnName}
          sortBy={sortBy}
        />
      </div>
      {/* Email */}
      <div className={styles['patients-invitations__email']}>
        <SortSearchItem
          fieldName={email_t}
          t={t}
          sortName="patient.email"
          sortByColumnName={sortByColumnName}
          sortBy={sortBy}
        />
      </div>
      {/* Phone Number */}
      <div className={styles['patients-invitations__phone-number']}>
        <SortSearchItem
          fieldName={phone_number}
          t={t}
          sortName="patient.phone"
          sortByColumnName={sortByColumnName}
          sortBy={sortBy}
        />
      </div>
      {/* Date invited */}
      <div className={styles['patients-invitations__date-invited']}>
        <SortSearchItem
          t={t}
          fieldName={date_invited}
          sortName="dateInvited"
          sortByColumnName={sortByColumnName}
          sortBy={sortBy}
        />
      </div>
    </div>
  );
};

export default TableHeader;
