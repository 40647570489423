import React, { CSSProperties, ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { Space, Tooltip, Button as ButtonAntd } from 'antd';
import moment from 'moment';
import { toast } from 'react-toastify';
import { SearchOutlined } from '@ant-design/icons';

import styles from '../styles.module.css';
import style from './style.module.css';
import './style.css';
import '../../theme/pagination.css';
import {
  getCurrenLang,
  getCurrentFormatDate,
  GetState,
  getUserCountry,
  getUserLang,
} from '../../redux/selector';
import { Button, Loading, MainTitle } from '../../common';
import { path } from '../../utils';
import {
  deepCopyObj,
  fetchTableData,
  filterDefault,
  filterDefaultHcp,
  getInsurancePolicyLabel,
  getQueryParams,
  getSearchByDateParam,
  getSortDirection,
  getSorterKey,
  getSrc,
  initialPage,
  setQueryParams,
} from '../../utils/helper';
import { hcpArray, patientArray, sorting } from '../../utils/variables';
import { PersonOrangeIcon, ScvIcon } from '../../theme/icons';
import {
  useAcceptJoin,
  useDeactivetePatien,
  useFindMatchesInHcpList,
  useFindMatchesInPatientList,
  useHcpList,
} from '../../graphql/dashboard';
import { downloadFile, exportToPdfTable } from '../../utils/helper_charts';
import { LIST_TYPE, PERMISSIONS, StatusKeyNameEnum, TEAM_CATEGORY } from '../../utils/enums';
import { useDeactiveteHcp } from '../../graphql/hcpProfile';
import { usePatientsList, usePatientsListForExportToCSV } from '../../graphql/patients';
import { BreadcrumbAnt } from '../../utils/routers/Breadcrumb';
import TableView from '../../components/Table';
import {
  FilterDropdown,
  SearchByDate,
  SortFieeld,
  State,
  TSorting,
  TSortState,
  UserProps,
} from '../../components/Table/types';
import useCheckRole from '../../hooks/useCheckRole';
import { timerStatus, useInterval } from '../../hooks/useInterval';
import { searchByDateInit } from './Init';
import { TStatusOptions } from '../../utils/model';
import { config } from '../../utils/configs';
import { DatePickerRange } from '../../common/DatePicker';
import FilterJsx from '../../components/Table/components/FilterJsx';
import PatientStatusGuide from '../../components/Table/components/PatientStatusGuide';
import useCheckPermissions from '../../hooks/useCheckPermissions';
import { GuideTitle } from '../../common/Giude';
import SearchClient from '../../components/Search/SearchClient';

const DashboardListPage = ({ dataQa }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const patient_list = t?.title.patient_list;
  const hcp_list = t?.title.hcp_list;
  const search_patient = t?.invite_patient.search_patient;
  const search_hcp = t?.modal.add_hcp;
  const to_t = t?.dashboard.hcp.table.to?.toLowerCase();
  const search = t?.common.search;
  const reset = t?.common.reset;
  const show = t?.common.show;
  const search_results = t?.common.search_results;
  const from_t = t?.dashboard.hcp.profile_patient.video_bank.from;
  const you_dont_have_permission = t?.common.you_dont_have_permission;
  const patient_status_guide =
    t?.dashboard.hcp.table.patient_status_guide ||
    'View the <link>Patient status guide<link> for more information.';
  const invite_hcp = t?.common.invite_hcp;
  const connect_patient = t?.common.connect_patient;
  const export_to_csv = t?.common.export_to_csv;
  const status_col_name = t?.dashboard.hcp.table.status;
  const first_name_col_name = t?.dashboard.hcp.profile_patient.information.first_name;
  const last_name_col_name = t?.dashboard.hcp.profile_patient.information.last_name;
  const dob_col_name =
    /* t?.dashboard.hcp.profile_patient.information.label_dob || */ 'Date of Birth';
  const procedure_col_name = t?.dashboard.hcp.table.procedure;
  const surgery_date_col_name = /* t?.dashboard.hcp.table.surgery_date */ 'Surgery Date';
  const email_col_name = t?.dashboard.hcp.table.email;
  const phone_col_name = t?.dashboard.hcp.table.phone_number || 'Phone Number';
  const organisation_col_name = t?.dashboard.hcp.table.org_name || 'Organisation Name';
  const last_activity_col_name = /* t?.dashboard.hcp.table.last_activity */ 'Last Used App';
  const dateFormat: any = useSelector<GetState>((state) => getCurrentFormatDate(state));
  const userCountry: any = useSelector<GetState>((state) => getUserCountry(state));
  const nhs_col_name = t && getInsurancePolicyLabel(t, userCountry);

  // Local state
  const [filter, setFilter] = useState<string>('');
  const [filterQuery, setFilterQuery] = useState<any>(null);
  const [pageSize, setPageSize] = useState<number>(15);
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [pageTotal, setPageTotal] = useState<number>(0);
  const [source, setSource] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState<any | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [searchByDOB, setSearchByDOB] = useState(deepCopyObj(searchByDateInit));
  const [searchByDOS, setSearchByDOS] = useState(deepCopyObj(searchByDateInit));
  const [query, setQuery] = useState<string>('');
  const [resend, setResend] = useState<boolean>(false);
  const [deactivateUser, setDeactivateUser] = useState<string>('');
  const [userDelete, setDeleteUser] = useState<UserProps | any>({});
  const [deactiveteUserLoading, setDeactiveteUserLoading] = useState<boolean>(false);
  const [deactiveteUserError, setDeactiveteUserError] = useState<string>('');
  const [seccessDeletedUser, setSeccessDeletedUser] = useState<boolean>(false);
  const [sortedInfo, setSortedInfo] = useState<TSortState | null>(null);
  const [sorterFieldState, setSorterFieldState] = useState<string>('');
  const [sortOrderState, setSortOrderState] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState(timerStatus.STOPPED);
  const [isAllowedLoader, setIsAllowedLoader] = useState(true);
  const [searchMatches, setSearchMatches] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [fileLoading, setFileLoading] = useState(false);

  const { isAdmin } = useCheckRole();
  const isPermissionAddAnotherHcp = useCheckPermissions(PERMISSIONS.INVITE_OR_ADD_HCP_OTM);
  const userLang: any = useSelector<GetState>((state) => getUserLang(state));
  const history = useHistory();
  const location = useLocation();
  const { category, page } = getQueryParams(location.search);
  const pageFromQuery = Number(page);
  const categoryFromQuery = String(category);
  const isPatientCategory = patientArray.includes(categoryFromQuery);
  const isHCPCategory = hcpArray.includes(categoryFromQuery);
  const defaultCategory = isAdmin ? LIST_TYPE.HCP_ADMIN : LIST_TYPE.PATIENT;
  let mode = LIST_TYPE.PATIENT;
  let pageTitle = '';
  if (isPatientCategory) {
    mode = LIST_TYPE.PATIENT;
    pageTitle = patient_list;
  }
  if (isHCPCategory) {
    mode = LIST_TYPE.HCP;
    pageTitle = hcp_list;
  }
  const isQueryPageError =
    (pageTotal && currentPage > pageTotal) || currentPage < 1 || !Number.isInteger(currentPage);
  const filterDefaultTable = mode === LIST_TYPE.PATIENT ? filterDefault : filterDefaultHcp;

  // Endpoints
  const {
    patients,
    total,
    _getListPatient,
    listLoading,
    pages,
    listError,
    dataPatients,
  } = usePatientsList();
  const {
    patientsForExportToCSV,
    _getListPatientForExportToCSV,
    listLoadingForExportToCSV,
    listErrorForExportToCSV,
    dataPatientsForExportToCSV,
  } = usePatientsListForExportToCSV();
  const {
    hcpList,
    _getList,
    totalLength,
    hcpListLoading,
    totalPages,
    hcpListError,
    dataHcp,
  } = useHcpList();
  const { _onAcceptJoin, responseAcceptJoin } = useAcceptJoin();
  const {
    _onDeactivetePatient,
    deactivetePatientLoading,
    deactivetePatientError,
    seccessDeletedPatient,
  } = useDeactivetePatien();
  const {
    _onDeactiveteHcp,
    deactiveteHcpLoading,
    deactiveteHcpError,
    seccessDeletedHcp,
  } = useDeactiveteHcp();

  const {
    totalPatients,
    _getMatchesPatientInDB,
    findPatientLoading,
  } = useFindMatchesInPatientList();
  const { totalHcps, _getMatchesHcpInDB, findHcpLoading } = useFindMatchesInHcpList();

  const _findMatchesInDB = useMemo(() => {
    if (isPatientCategory) {
      return _getMatchesPatientInDB;
    }
    if (isHCPCategory) {
      return _getMatchesHcpInDB;
    }
    return (): [] => [];
  }, [categoryFromQuery]);

  const getCurrentList = useMemo(() => {
    if (isPatientCategory) {
      return _getListPatient;
    }
    if (isHCPCategory) {
      return _getList;
    }
    return (): [] => [];
    // }, [category]);
  }, [categoryFromQuery]);

  // If changed tab
  useEffect(() => {
    if (category) {
      let sortBy = '';
      if (isPatientCategory) {
        sortBy = 'patient.status';
      }
      if (isHCPCategory) {
        sortBy = 'hcp.lastVisit';
      }
      const searchParam = {
        focuses: [],
        searchByDate: getSearchByDateParam(searchByDOB, searchByDOS),
      };
      const patientSearchParams = isPatientCategory ? searchParam : {};
      const variables = {
        listProps: {
          page: initialPage,
          itemsPerPage: pageSize,
          sortBy,
        },
        statuses: filterDefaultTable,
        ...patientSearchParams,
      };
      getCurrentList({ variables });
      setSource([]);
      setCurrentPage(initialPage);
      setFilter('');
      setFilterQuery(null);
      setSearchText('');
      setSearchedColumn('');
      setSortedInfo(null);
      setSorterFieldState('');
      setFilteredInfo(null);
      setSearchByDOB(deepCopyObj(searchByDateInit));
      setSearchByDOS(deepCopyObj(searchByDateInit));
      // setTableParams(variables);
    }
  }, [category]);

  useEffect(() => {
    if (isPatientCategory) {
      setDeactiveteUserLoading(deactivetePatientLoading);
      setDeactiveteUserError(deactivetePatientError);
      setSeccessDeletedUser(seccessDeletedPatient);
    }
    if (isHCPCategory) {
      setDeactiveteUserLoading(deactiveteHcpLoading);
      setDeactiveteUserError(deactiveteHcpError);
      setSeccessDeletedUser(seccessDeletedHcp);
    }
  }, [
    category,
    deactiveteHcpLoading,
    deactiveteHcpError,
    seccessDeletedHcp,
    deactivetePatientLoading,
    deactivetePatientError,
    seccessDeletedPatient,
  ]);

  useEffect(() => {
    if (!listLoading && !hcpListLoading) {
      if (isPatientCategory) {
        if (patients.length > 0) {
          setTotalItems(total);
          setPageTotal(pages);
        } else {
          setTotalItems(0);
          setPageTotal(0);
        }
      }
    }
  }, [dataPatients]);

  useEffect(() => {
    if (!hcpListLoading && !listLoading) {
      if (isHCPCategory) {
        if (hcpList.length > 0) {
          setTotalItems(totalLength);
          setPageTotal(totalPages);
        } else {
          setTotalItems(0);
          setPageTotal(0);
        }
      }
    }
  }, [totalLength, totalPages]);

  useEffect(() => {
    if (mode === LIST_TYPE.PATIENT) {
      if (!listLoading && !hcpListLoading) {
        const currentList = getSrc(mode, hcpList, patients);
        setSource(currentList);
        setIsAllowedLoader(() => true);
      }
      setSearchMatches(null);
    }
  }, [dataPatients]);

  useEffect(() => {
    if (mode === LIST_TYPE.HCP) {
      if (!hcpListLoading && !listLoading) {
        const currentList = getSrc(mode, hcpList, patients);
        setSource(currentList);
        setIsAllowedLoader(() => true);
      }
      setSearchMatches(null);
    }
  }, [dataHcp]);

  useEffect(() => {
    if (hcpListError) {
      setIsAllowedLoader(() => true);
      toast.error(hcpListError);
    }
    if (listError) {
      setIsAllowedLoader(() => true);
      toast.error(listError);
    }
  }, [listError, hcpListError]);

  const handleSearch = (
    searchtext: string,
    confirm: any,
    searchKey: string,
    columnName: string,
  ): void => {
    confirm();
    if (columnName === 'dob') {
      const newSearchByDOB = deepCopyObj(searchByDOB);
      newSearchByDOB.isSearch = Date.now();
      setSearchByDOB(newSearchByDOB);
      setStartDate(null);
      setEndDate(null);
      return;
    }
    if (columnName === 'dos') {
      const newSearchByDOS = deepCopyObj(searchByDOS);
      newSearchByDOS.isSearch = Date.now();
      setSearchByDOS(newSearchByDOS);
      setStartDate(null);
      setEndDate(null);
      return;
    }
    setSearchMatches(null);
    setFilter('');
    setFilterQuery(null);
    setSearchText(searchtext);
    setSearchedColumn(searchKey);
  };

  // Reset search results
  const handleReset = (clearFilters: any, columnName: string): void => {
    const newSearchByDOB = searchByDOB;
    const newSearchByDOS = searchByDOS;

    if (columnName === 'dob') {
      setSearchByDOB(deepCopyObj(searchByDateInit));
      newSearchByDOB.isSearch = null;
      newSearchByDOB.start = '';
      newSearchByDOB.end = '';
    } else if (columnName === 'dos') {
      setSearchByDOS(deepCopyObj(searchByDateInit));
      newSearchByDOS.isSearch = null;
      newSearchByDOS.start = '';
      newSearchByDOS.end = '';
    } else {
      setSorterFieldState('');
      setSortOrderState('');
      setSearchText('');
      setQuery('');
      setFilter('');
      setFilterQuery(null);
      setSortedInfo(null);
      setSearchMatches(null);
      setSearchInputValue('');
    }
    clearFilters();
    const searchParam = {
      focuses: filteredInfo?.[`procedure${mode}`] || [],
      searchByDate: getSearchByDateParam(newSearchByDOB, newSearchByDOS),
    };
    const patientSearchParams = isPatientCategory ? searchParam : {};
    const variables = {
      listProps: {
        page: initialPage,
        itemsPerPage: pageSize,
      },
      statuses: filteredInfo?.[`status${mode}`] || filterDefaultTable,
      ...patientSearchParams,
    };
    getCurrentList({ variables });
  };

  // Find search matches in DB
  const findMatches = (searchVal: string, column: string): void => {
    if (!searchVal) {
      setSearchMatches(null);
      setSearchInputValue('');
      return;
    }
    setSearchInputValue(searchVal);
    _findMatchesInDB({
      variables: {
        listProps: {
          page: initialPage,
          itemsPerPage: pageSize,
          sortBy: '',
        },
        searchProps: [
          {
            searchField: column,
            searchValue: searchVal,
          },
        ],
        statuses: filteredInfo?.[`status${mode}`] || filterDefaultTable,
        focuses: filteredInfo?.[`procedure${mode}`] || [],
        searchByDate: getSearchByDateParam(searchByDOB, searchByDOS),
      },
    });
  };

  // Show the result from the database in the search menu
  useEffect(() => {
    if (!searchInputValue) return;
    if (!findPatientLoading && !findHcpLoading) {
      const current = isPatientCategory ? totalPatients : totalHcps;
      setSearchMatches(current);
    }
  }, [totalPatients, totalHcps]);

  useEffect(() => {
    if (resend) {
      const searchParam = {
        focuses: [],
        searchByDate: getSearchByDateParam(searchByDOB, searchByDOS),
      };
      const patientSearchParams = isPatientCategory ? searchParam : {};
      const variables = {
        listProps: {
          page: currentPage,
          itemsPerPage: pageSize,
        },
        searchProps: filterQuery || [
          {
            searchField: searchedColumn,
            searchValue: searchText || '',
          },
        ],
        statuses: filterDefaultTable,
        ...patientSearchParams,
      };
      getCurrentList({ variables });
      setResend(false);
    }
  }, [resend]);

  useEffect(() => {
    if (isQueryPageError) {
      setCurrentPage(initialPage);
      history.push({
        pathname: location.pathname,
        search: setQueryParams({
          category: category || defaultCategory,
          page: initialPage,
        }),
      });
    }
  }, [isQueryPageError]);

  const refreshTable = (mutableValue?: TStatusOptions): void => {
    setCurrentPage(pageFromQuery);
    const searchParam = {
      focuses: filteredInfo?.[`procedure${mode}`] || [],
      searchByDate: getSearchByDateParam(searchByDOB, searchByDOS),
    };
    const patientSearchParams = isPatientCategory ? searchParam : {};
    const variables = {
      listProps: {
        page: pageFromQuery || initialPage,
        itemsPerPage: pageSize,
        ...getSortDirection(sorterFieldState, sortOrderState, isPatientCategory, isHCPCategory),
      },
      searchProps: filterQuery || [
        {
          searchField: searchedColumn,
          searchValue: searchText || '',
        },
      ],
      statuses: filteredInfo?.[`status${mode}`] || filterDefaultTable,
      ...patientSearchParams,
    };
    getCurrentList({ variables });
    // setTableParams(variables);
  };

  useEffect(() => {
    setCurrentPage(initialPage);
    const searchParam = {
      focuses: filteredInfo?.[`procedure${mode}`] || [],
      searchByDate: getSearchByDateParam(searchByDOB, searchByDOS),
    };
    const patientSearchParams = isPatientCategory ? searchParam : {};
    const variables = {
      listProps: {
        page: initialPage,
        itemsPerPage: pageSize,
        ...getSortDirection(sorterFieldState, sortOrderState, isPatientCategory, isHCPCategory),
      },
      searchProps: filterQuery || [
        {
          searchField: searchedColumn,
          searchValue: searchText || '',
        },
      ],
      statuses: filteredInfo?.[`status${mode}`] || filterDefaultTable,
      ...patientSearchParams,
    };
    getCurrentList({ variables });

    history.push({
      pathname: location.pathname,
      search: setQueryParams({
        category: category || defaultCategory,
        page: initialPage,
      }),
    });
  }, [searchedColumn, searchText, filterQuery, searchByDOB.isSearch, searchByDOS.isSearch]);

  useEffect(() => {
    if (responseAcceptJoin && responseAcceptJoin.acceptJoinToShare) {
      _getListPatient({
        variables: {
          listProps: { page: pageFromQuery || currentPage, itemsPerPage: pageSize },
          searchProps: filterQuery || [
            {
              searchField: searchedColumn,
              searchValue: searchText || '',
            },
          ],
        },
      });
    }
  }, [responseAcceptJoin]);

  // pooling lists
  useInterval(
    async () => {
      if (timerStatus.STARTED) {
        setIsAllowedLoader(() => false);
        const searchParam = {
          focuses: filteredInfo?.[`procedure${mode}`] || [],
          searchByDate: getSearchByDateParam(searchByDOB, searchByDOS),
        };
        const patientSearchParams = isPatientCategory ? searchParam : {};
        getCurrentList({
          variables: {
            listProps: {
              page: pageFromQuery || initialPage,
              itemsPerPage: pageSize,
              ...getSortDirection(
                sorterFieldState,
                sortOrderState,
                isPatientCategory,
                isHCPCategory,
              ),
            },
            searchProps: filterQuery || [
              {
                searchField: searchedColumn,
                searchValue: searchText || '',
              },
            ],
            statuses: filteredInfo?.[`status${mode}`] || filterDefaultTable,
            ...patientSearchParams,
          },
        });
      }
    },
    // passing null stops the interval
    status === timerStatus.STARTED ? config.pollInterval : null,
  );

  // when reload page
  useEffect(() => {
    if (page) {
      setCurrentPage(pageFromQuery);
      const searchParam = {
        focuses: filteredInfo?.[`procedure${mode}`] || [],
        searchByDate: getSearchByDateParam(searchByDOB, searchByDOS),
      };
      const patientSearchParams = isPatientCategory ? searchParam : {};
      const variables = {
        listProps: {
          page: pageFromQuery || initialPage,
          itemsPerPage: pageSize,
          sortBy: isPatientCategory ? 'patient.status' : 'hcp.lastVisit',
        },
        searchProps: filterQuery || [
          {
            searchField: searchedColumn,
            searchValue: searchText || '',
          },
        ],
        statuses: filteredInfo?.[`status${mode}`] || filterDefaultTable,
        ...patientSearchParams,
      };
      getCurrentList({ variables });
      history.push({
        pathname: location.pathname,
        search: setQueryParams({
          category: category || defaultCategory,
          page: pageFromQuery || initialPage,
        }),
      });
    }

    setStatus(timerStatus.STARTED);
  }, []);

  const _onAccept = (userAccept: any): void => {
    if (userAccept) {
      _onAcceptJoin({
        variables: {
          patientId: userAccept.id,
        },
      });
    }
  };

  const _showProfile = (userProf: UserProps, currMode: LIST_TYPE): void => {
    const userId = userProf.id;
    const userStatus = userProf.status;
    if (currMode === LIST_TYPE.PATIENT) {
      const pathnameProfilePatient = isAdmin ? path.patient_overview_admin : path.patient_overview;
      const pathnameProfilePatientInvitee = isAdmin
        ? path.adminDashboardList
        : path.patient_exercise_library;

      if (
        userStatus === StatusKeyNameEnum.invite_sent ||
        userStatus === StatusKeyNameEnum.pending
      ) {
        history.push({
          pathname: pathnameProfilePatientInvitee,
          search: setQueryParams({
            userId,
            userStatus,
          }),
        });
        return;
      }
      history.push({
        pathname: pathnameProfilePatient,
        search: setQueryParams({
          userId,
          userStatus,
        }),
      });
      return;
    }

    if (currMode === LIST_TYPE.HCP) {
      const pathnameHcpProfile = isAdmin
        ? path.hcp_profile_admin.replace(':hcpId', String(userId))
        : path.hcp_profile.replace(':hcpId', String(userId));
      history.push({ pathname: pathnameHcpProfile });
    }
  };

  const _redirectToMyTeam = (): void => {
    history.push({
      pathname: `${path.rehabTeams}?category=${TEAM_CATEGORY.MY_TEAM}&page=${initialPage}`,
    });
  };

  const _deactiveteAccount = (): void => {
    const id = userDelete && userDelete.id;
    if (isHCPCategory) {
      _onDeactiveteHcp(Number(id));
    }
    if (isPatientCategory) {
      _onDeactivetePatient(Number(id));
    }
  };

  // Date search handler
  const selectPeriodForDateSearch = (date: any, columnName: string, type: string): void => {
    const dateTransform = date ? moment(date).format('YYYY-MM-DD') : '';

    const fillData = (searchParams: SearchByDate) => {
      const newSearchParams = {
        isSearch: searchParams.isSearch,
        start: searchParams.start,
        end: searchParams.end,
      };
      if (type === 'start') {
        if (endDate && new Date(endDate) < new Date(date)) {
          setStartDate(null);
          newSearchParams.start = '';
        } else {
          setStartDate(date);
          newSearchParams.start = dateTransform;
        }
      }
      if (type === 'end') {
        setEndDate(date);
        newSearchParams.end = dateTransform;
      }
      return newSearchParams;
    };

    if (columnName === 'dob') {
      const params = fillData(searchByDOB);
      setSearchByDOB(params);
      return;
    }
    if (columnName === 'dos') {
      const params = fillData(searchByDOS);
      setSearchByDOS(params);
    }
  };

  // Set active status(color) for filter icon
  const checkFilterStatus = (searchKey: string, columnName: string): CSSProperties => {
    if (columnName === 'dob' && searchByDOB.isSearch) {
      return { color: '#1890ff' };
    }
    if (columnName === 'dos' && searchByDOS.isSearch) {
      return { color: '#1890ff' };
    }
    if (searchText && searchedColumn === searchKey) {
      return { color: '#1890ff' };
    }
    return {};
  };

  const getColumnSearchProps = (searchKey: string, name: string): any => {
    const isDatePicker = name === 'dob' || name === 'dos';
    let isShowBtnDisable = true;
    let isResetBtnDisable = true;
    switch (name) {
      case 'dob':
        if (startDate) {
          isShowBtnDisable = false;
        }
        if (searchByDOB.isSearch) {
          isResetBtnDisable = false;
        }
        break;
      case 'dos':
        if (startDate) {
          isShowBtnDisable = false;
        }
        if (searchByDOS.isSearch) {
          isResetBtnDisable = false;
        }
        break;
      default:
        if (query) {
          isShowBtnDisable = false;
        }
        if (searchText) {
          isResetBtnDisable = false;
        }
    }
    const newDate = new Date();
    const pickerAttr = name === 'dob' ? { maxDate: newDate } : {};

    return {
      filterDropdown: ({ confirm, clearFilters }: FilterDropdown): ReactElement => (
        <div className={style.dashbord}>
          {isDatePicker ? (
            <>
              <div className="search_picker">
                <DatePickerRange
                  name={`FilterStart${name}`}
                  onChange={(date: any): void => selectPeriodForDateSearch(date, name, 'start')}
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  currentLang={userLang}
                  startYear={name === 'dos' ? 2020 : newDate.getFullYear() - 110}
                  endYear={newDate.getFullYear() + (name === 'dob' ? 1 : 3)}
                  selectsRange={false}
                  placeholderText={from_t}
                  {...pickerAttr}
                />
              </div>
              <div className="search_picker">
                <DatePickerRange
                  name={`FilterEnd${name}`}
                  onChange={(date: any): void => selectPeriodForDateSearch(date, name, 'end')}
                  selected={endDate}
                  startDate={startDate}
                  endDate={endDate}
                  currentLang={userLang}
                  selectsEnd
                  startYear={name === 'dos' ? 2020 : newDate.getFullYear() - 110}
                  endYear={newDate.getFullYear() + (name === 'dob' ? 1 : 3)}
                  selectsRange={false}
                  minDate={startDate}
                  placeholderText={to_t}
                  {...pickerAttr}
                  disabled={!startDate}
                />
              </div>
            </>
          ) : (
            <input
              className={style.search_input}
              value={query}
              placeholder={`${search} ${name}`}
              onChange={(e): void => {
                setQuery(e.target.value);
                findMatches(e.target.value, searchKey);
              }}
              onKeyPress={(e): boolean | void =>
                e.key === 'Enter' && handleSearch(query, confirm, searchKey, name)
              }
              data-qa={dataQa}
            />
          )}
          {searchMatches !== null ? (
            <div className={styles.matches}>{`${search_results}: ${searchMatches}`}</div>
          ) : (
            <></>
          )}
          <Space>
            <ButtonAntd
              type="primary"
              onClick={(): void => handleSearch(query, confirm, searchKey, name)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 91, padding: '0 3px', textAlign: 'center' }}
              disabled={isShowBtnDisable}
            >
              {show}
            </ButtonAntd>
            <ButtonAntd
              onClick={(): void => handleReset(clearFilters, name)}
              type="primary"
              size="small"
              style={{ width: 91, padding: '0 3px', textAlign: 'center', borderRadius: 22 }}
              disabled={isResetBtnDisable}
            >
              {reset}
            </ButtonAntd>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean): JSX.Element => {
        if (isDatePicker) {
          const isFiltredObj = checkFilterStatus(searchKey, name);
          const isFiltred = isFiltredObj && Object.keys(isFiltredObj)?.length > 0;
          return <FilterJsx filtered={!!isFiltred} />;
        }
        return <SearchOutlined style={checkFilterStatus(searchKey, name)} />;
      },
      onFilter: (value: string, record: any): string =>
        record[searchedColumn] && record[searchedColumn] === 'string'
          ? record[searchedColumn].toString().toLowerCase().includes(value.toLowerCase())
          : '',
      onFilterDropdownVisibleChange: (visible: boolean): void => {
        if (visible) {
          if (searchedColumn === searchKey) {
            setQuery(query);
          } else {
            setQuery('');
          }
        } else {
          setSearchMatches(null);
          setStartDate(null);
          setEndDate(null);
        }
        setQuery('');
      },
    };
  };

  const onChangePagination = (pagination: State, filters: any, sorter: SortFieeld): void => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    if (pagination.current) {
      history.push({
        pathname: location.pathname,
        search: setQueryParams({
          category,
          page: pagination.current,
        }),
      });
    }
    const sorterField = sorter.column && sorter.column.name ? sorter.column.name : '';
    const sortField = getSorterKey(mode, sorterField);
    const sortOrder = sorting[sorter.order as keyof TSorting];
    setSorterFieldState(sortField);
    setSortOrderState(sortOrder);
    setSortedInfo({
      order: sorter.order,
      columnKey: sorter.columnKey,
    });
    setFilteredInfo(filters);
    return fetchTableData({
      sortField,
      sortOrder,
      pagination,
      filters,
      mode,
      getCurrentList,
      searchedColumn,
      searchText,
      filterQuery,
      searchByDate: getSearchByDateParam(searchByDOB, searchByDOS),
      isPatientCategory,
    });
  };

  // Search patient or HCP
  const changeFilter = ({ target: { value } }: any): void => {
    setFilter(value);
    if (value.length >= 2) {
      setFilterQuery([
        { searchField: `${isPatientCategory ? 'patient' : 'hcp'}.firstName`, searchValue: value },
        { searchField: `${isPatientCategory ? 'patient' : 'hcp'}.lastName`, searchValue: value },
      ]);
    } else {
      setFilterQuery(null);
    }
  };

  // Convert Data to CSV file
  const convertDataToCSV = (): string => {
    let csvContent = `data:text/csv;charset=utf-8,OnTheMend:,Patient List,\r\nDate:, ${moment().format(
      dateFormat.momentFormat,
    )}\r\n`;

    csvContent += `${status_col_name},${first_name_col_name},${last_name_col_name},${dob_col_name},${procedure_col_name},${surgery_date_col_name},${email_col_name},${phone_col_name},${nhs_col_name},${organisation_col_name},${last_activity_col_name}\r\n`;

    patientsForExportToCSV.map((row: any) => {
      const dob = row.dob ? moment(row.dob).format(dateFormat.momentFormat) : '';
      const surgeryDate = row.surgeryDate
        ? moment(row.surgeryDate).format(dateFormat.momentFormat)
        : '';
      const phone = row.phone ? row.phone : '';
      const lastVisit = row.lastVisit
        ? moment(row.lastVisit, 'x').format(dateFormat.momentFormat)
        : '';

      csvContent += `${row.status},${row.firstName},${row.lastName},${dob},${row.procedure},${surgeryDate},${row.email},${phone},${row.nhsNumber},${row.hospitalName},${lastVisit}\r\n`;
      return null;
    });

    return csvContent;
  };

  // Get table data with current filters and without pagination.
  const fetchTableDataForExportToCsv = (): void => {
    _getListPatientForExportToCSV({
      variables: {
        listProps: {
          page: initialPage,
          itemsPerPage: pageSize,
          ...getSortDirection(sorterFieldState, sortOrderState, isPatientCategory, isHCPCategory),
        },
        searchProps: filterQuery || [
          {
            searchField: searchedColumn,
            searchValue: searchText || '',
          },
        ],
        statuses: filteredInfo?.[`status${mode}`] || filterDefaultTable,
        focuses: filteredInfo?.[`procedure${mode}`] || [],
        searchByDate: getSearchByDateParam(searchByDOB, searchByDOS),
        withPagination: false,
      },
    });
  };

  // Download CSV file
  const downloadCSVHandler = (): void => {
    if (fileLoading) return;
    setFileLoading(true);
    fetchTableDataForExportToCsv();
  };

  // After we have received the list of patients, export it to CSV
  useEffect(() => {
    if (patientsForExportToCSV?.length > 0 && fileLoading && !listErrorForExportToCSV) {
      const csvFile = convertDataToCSV();
      downloadFile(csvFile, { firstName: '_', lastName: 'OnTheMend_Patients' }, 'List', 'csv');
      setFileLoading(false);
    }
  }, [dataPatientsForExportToCSV]);

  // If there is an error in getting the list, show it in the popap
  useEffect(() => {
    if (listErrorForExportToCSV) {
      toast.error(listErrorForExportToCSV);
      setFileLoading(false);
    }
  }, [listErrorForExportToCSV]);

  // Redirect to Connect Patient or Invite Hcp Page
  const redirectToinvitePage = (): void => {
    if (isPatientCategory) {
      history.push({ pathname: path.connectPatientAsHcp });
      return;
    }
    if (isAdmin) {
      history.push({ pathname: path.inviteHcpAsAdmin });
      return;
    }
    history.push({ pathname: path.inviteHcpAsHcp });
  };

  // JSX
  const loadingJSX = isAllowedLoader &&
    (listLoading ||
      hcpListLoading ||
      loading ||
      findPatientLoading ||
      findHcpLoading ||
      listLoadingForExportToCSV ||
      fileLoading) && <Loading />;

  const inviteBtn = (
    <div className={style['table__invite-btn-content']}>
      <PersonOrangeIcon />+
      <div className={style['table__invite-btn-text']}>
        {isPatientCategory ? connect_patient : invite_hcp}
      </div>
    </div>
  );

  /* Export to PDF */
  const exportToCsvJsx = (
    <Tooltip mouseEnterDelay={0.3} placement="bottom" title={export_to_csv}>
      <div
        className={cx({
          [style['icon-pdf']]: true,
          [style.disabled]: fileLoading || listLoading,
        })}
        onClick={downloadCSVHandler}
        role="presentation"
      >
        <ScvIcon />
      </div>
    </Tooltip>
  );

  // Breadcrumb path
  const routes = [
    {
      path: pageTitle,
      breadcrumbName: pageTitle,
    },
  ];
  const inviteBtnJsx = (
    <Tooltip title={isHCPCategory && !isPermissionAddAnotherHcp ? you_dont_have_permission : ''}>
      <div>
        <Button
          buttonType="button"
          buttonName=""
          buttonClass={style['table__invite-btn']}
          disabledButton={
            (isPatientCategory && isAdmin) || (isHCPCategory && !isPermissionAddAnotherHcp)
          }
          buttonMethod={redirectToinvitePage}
        >
          {inviteBtn}
        </Button>
      </div>
    </Tooltip>
  );

  return (
    <div className={styles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={styles.main__wrapper}>
        {/* Title */}
        <MainTitle title={pageTitle} content={isPatientCategory ? exportToCsvJsx : <></>} />

        {/* Patient Status guide */}
        <GuideTitle text={patient_status_guide} content={<PatientStatusGuide t={t} />} />

        <div className={style.table__row}>
          <SearchClient
            inputId="dashSearch"
            name="filter"
            placeholder={isPatientCategory ? search_patient : search_hcp}
            inputValue={filter}
            changeFilter={changeFilter}
          />
          <div className={style['table__invite-btn--bg']}>{inviteBtnJsx}</div>
        </div>

        {/* Table */}
        <TableView
          dataSrc={source}
          currentPage={currentPage}
          pageSize={pageSize}
          totalItems={totalItems}
          mode={mode}
          deactivateUser={deactivateUser}
          deactiveteUserLoading={deactiveteUserLoading}
          deactiveteUserError={deactiveteUserError}
          seccessDeletedUser={seccessDeletedUser}
          sortedInfo={sortedInfo}
          filteredInfo={filteredInfo}
          categoryFromQuery={categoryFromQuery}
          setResend={setResend}
          onChangePagination={onChangePagination}
          _deactiveteAccount={_deactiveteAccount}
          getColumnSearchProps={getColumnSearchProps}
          setDeactivateUser={setDeactivateUser}
          setDeleteUser={setDeleteUser}
          _onAccept={_onAccept}
          _showProfile={_showProfile}
          setSortedInfo={setSortedInfo}
          refreshTable={refreshTable}
          setLoading={setLoading}
          isAdmin={isAdmin}
          t={t}
          _redirectToMyTeam={_redirectToMyTeam}
        />

        <div className={style['table__invite-btn--sm']}>{inviteBtnJsx}</div>
      </div>
    </div>
  );
};

export default DashboardListPage;
