import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Tooltip } from 'antd';

import {
  GetState,
  getCurrenLang,
  getHcpAvatarPic,
  getHcpAvatarUuid,
} from '../../../../redux/selector';
import style from '../style.module.css';
import { Button, Confirmation, Loading, Modal } from '../../../../common';
import {
  useQueryUserPic,
  useRemoveUserPic,
  useRemoveUserPicAfterUpdate,
  useUpdateHcpPhoto,
} from '../../../../graphql/hcpProfile';
import { DeleteIcon, PersonGreyIcon } from '../../../../theme/icons';

import { deepCopyObj, uploadFiles } from '../../../../utils/helper';
import { InputTypeFileAsText } from '../../../../common/Input';
import { Popup } from '../../../../common/Popup';
import useCheckRole from '../../../../hooks/useCheckRole';
import { setHcpAvatarPic, setHcpAvatarUuid } from '../../../../redux/common';
import { CloseBtn } from '../../../../common/Button/Button';

const ChangeHcpPhoto = ({
  setOpenChangePhotoPage,
  setHasChanges,
  checkChanges,
}: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const ok = t?.common.ok;
  const cancel = t?.common.cancel;
  const save = t?.common.save;
  const uploading_file_error = t?.dashboard.hcp.profile_patient.uploading_file_error;
  const wrong_format = t?.help.wrong_format;
  const large_file = t?.dashboard.hcp.profile_patient.large_file;
  const success = t?.profile_hcp.success;
  const large_file_norm = large_file?.replace('<size>', '5MB');
  const add_photo = t?.common.add_photo_title;
  const edit_photo = t?.common.edit_photo || 'Edit Photo';
  const remove_avatar = t?.common.remove_avatar || 'Remove avatar';
  const help_others = t?.profile_hcp.help_others || 'Help others recognise you!';
  const change_photo_description =
    t?.profile_hcp.change_photo_description ||
    'Please use your profile image so other healthcare professionals can identify you.';
  const your_photo_was_updated = t?.profile_hcp.your_photo_was_updated || 'Your photo was updated';
  const file_size = t?.dashboard.hcp.profile_patient.file_size;
  const file_size_norm = file_size?.replace('<size>', '5MB');
  const your_photo_was_removed = t?.profile_hcp.your_photo_was_removed || 'Your photo was removed';

  const { _updateHcpPhoto, loadingHcpPhoto, errorHcpPhoto, updatedHcpPhoto } = useUpdateHcpPhoto();
  const {
    _removeUserPic,
    loadingRemoveUserPic,
    errorRemovePic,
    removePicData,
  } = useRemoveUserPic();
  const { _removeOldUserPic } = useRemoveUserPicAfterUpdate();
  const { _getUserPic } = useQueryUserPic();

  const dispatch = useDispatch();
  const avatar = useSelector<GetState>((state) => getHcpAvatarPic(state));
  const avatarUuid: any = useSelector<GetState>((state) => getHcpAvatarUuid(state));

  const { token } = useCheckRole();
  const filesSizeLimit = 5242880; // max 5Mb
  const initErr = {
    error: false,
    format: false,
    heavy: false,
  };

  const [loadingToAWS, setLoadingToAWS] = useState(false);
  const [fileInputKey, setFileInputKey] = useState<number>(Date.now());
  const [errorMessage, setErrorMessage] = useState(deepCopyObj(initErr));
  const [successModal, setSuccessModal] = useState(false);
  const [successRemopveAvatarModal, setSuccessRemopveAvatarModal] = useState(false);
  const [currentUuid, setCurrentuuid] = useState('');

  const initialValues: any = {
    file: null,
    preview: '',
  };

  const savePeriod = async (params: any): Promise<void> => {
    const { file } = params;
    let uuId = '';

    // Download photo to AWS
    try {
      setLoadingToAWS(() => true);
      const urlPic = 'pictures/upload-user-pic';
      const response: {
        attachmentPic: any[];
        attachmentVideo: any[];
        isLoadingErr: boolean;
      } = await uploadFiles([file], urlPic, '', token, false);
      if (!response.isLoadingErr) {
        uuId = response.attachmentPic[0].picUuid;
      } else {
        toast.error(uploading_file_error);
        setLoadingToAWS(() => false);
        return;
      }
    } catch (error) {
      setLoadingToAWS(() => false);
      toast.error(uploading_file_error);
      return;
    }
    setCurrentuuid(uuId);
    const request = {
      hcpPhoto: uuId,
    };
    setLoadingToAWS(() => false);
    _updateHcpPhoto(request);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (value: any): any => savePeriod(value),
  });
  const { values, dirty } = formik;

  useEffect(() => {
    setHasChanges(() => dirty);
  }, [dirty]);

  // If upload photo error
  useEffect(() => {
    if (errorHcpPhoto) {
      toast.error(errorHcpPhoto);
    }
  }, [errorHcpPhoto]);

  // If upload photo success
  useEffect(() => {
    if (updatedHcpPhoto) {
      _getUserPic(currentUuid);
      dispatch(setHcpAvatarUuid(currentUuid));
      setSuccessModal(() => updatedHcpPhoto);
      if (avatarUuid) {
        _removeOldUserPic(avatarUuid);
      }
    }
  }, [updatedHcpPhoto]);

  // If remove avatar error
  useEffect(() => {
    if (errorRemovePic) {
      toast.error(errorRemovePic);
    }
  }, [errorRemovePic]);

  // If remove avatar success
  useEffect(() => {
    if (removePicData) {
      setSuccessRemopveAvatarModal(() => true);
      dispatch(setHcpAvatarUuid(''));
      dispatch(setHcpAvatarPic(''));
    }
  }, [removePicData]);

  // Check the incoming file for the correct format, size and upload to AWS
  const onCheckIncomingFiles = async (e: any): Promise<void> => {
    setFileInputKey(Date.now());
    let wrongFormat = false;
    let newFile = null;
    let prew = '';
    try {
      const f = e.target.files;
      const filesArray = Array.from(f);
      if (filesArray.length) {
        const file: any = filesArray[0];
        const { type } = file;
        const img = new Image();
        prew = window.URL.createObjectURL(file);
        img.src = prew;
        if (!type) {
          wrongFormat = true;
        } else {
          newFile = file;
        }
      }
      if (wrongFormat) {
        window.URL.revokeObjectURL(prew);
        setErrorMessage({
          error: true,
          format: true,
          heavy: false,
        });
      } else if (newFile.size > filesSizeLimit) {
        window.URL.revokeObjectURL(prew);
        setErrorMessage({
          error: true,
          format: false,
          heavy: true,
        });
      } else {
        formik.setFieldValue('file', newFile);
        formik.setFieldValue('preview', prew);
      }
    } catch (error) {
      toast.error(uploading_file_error);
    }
  };

  // Delete user photo from AWS and DB
  const removeAvatar = (): void => {
    if (avatarUuid) {
      _removeUserPic(avatarUuid);
    }
  };

  // Cancel change password
  const cancelChangePhoto = (): void => {
    checkChanges(() => setOpenChangePhotoPage(() => false));
  };

  const closeSuccessModal = (): void => {
    setOpenChangePhotoPage(() => false);
    setHasChanges(() => false);
  };

  const closeSuccessRemoveAvatarModal = (): void => {
    setSuccessRemopveAvatarModal(() => false);
    setHasChanges(() => false);
  };

  // JSX
  const avatarJsx = useMemo(() => {
    if (values.preview) {
      return <img src={values.preview} alt="Avatar" />;
    }
    if (avatar) {
      return (
        <img
          src={`data:image/jpeg;charset=utf-8;base64,${avatar}`}
          alt="Avatar"
          crossOrigin="anonymous"
        />
      );
    }
    return (
      <div className={style['change-photo__avatar-container']}>
        <PersonGreyIcon />
      </div>
    );
  }, [values.preview, avatar]);

  const loaderJsx = (loadingHcpPhoto || loadingToAWS || loadingRemoveUserPic) && <Loading />;

  return (
    <>
      {loaderJsx}
      <div className={style['change-photo__description']}>{help_others}</div>
      <div className={style['change-photo__description']}>{change_photo_description}</div>
      <div className={style['change-photo__description']}>{file_size_norm}</div>

      <form onSubmit={formik.handleSubmit} className={style['change-photo__form']}>
        <div className={style['change-photo__form--fields']}>
          <div className={style['change-photo__preview']}>
            {avatarJsx}
            {avatar && !values.preview && (
              <Tooltip title={remove_avatar}>
                <div className={style['change-photo__delete']} onClick={removeAvatar} aria-hidden>
                  <DeleteIcon />
                </div>
              </Tooltip>
            )}
          </div>

          <InputTypeFileAsText
            id="upload-file"
            inputName="file"
            labelName={values.preview || avatar ? edit_photo : add_photo}
            hasErrors={false}
            onChangeMethod={onCheckIncomingFiles}
            disabled={!!loaderJsx}
            fileInputKey={fileInputKey}
            accept="image/png, image/jpeg, image/jpg"
            isTouched
          />
        </div>

        <div className={style['change-photo__btn-cont']}>
          <Button
            buttonType="button"
            buttonName={cancel}
            buttonClass={style['change-photo__btn']}
            buttonMethod={cancelChangePhoto}
            disabledButton={!!loaderJsx}
          />
          <Button
            buttonClass={style['change-photo__btn']}
            buttonType="submit"
            buttonName={save}
            disabledButton={!!loaderJsx || !dirty}
          />
        </div>
      </form>

      {/* Pop up */}
      {errorMessage.error && (
        <Popup
          content=""
          title={errorMessage.heavy ? large_file_norm : wrong_format}
          buttonName={ok}
          onClosePopup={(): void => setErrorMessage(deepCopyObj(initErr))}
        />
      )}

      {/* Success modal */}
      {successModal && (
        <Modal style={style['change-password__modal']} onClose={closeSuccessModal}>
          <CloseBtn close={closeSuccessModal} />
          <div className={style['change-password__conf-wrapper']}>
            <Confirmation
              icon="success"
              title={success}
              contant={your_photo_was_updated}
              buttonTitle={ok}
              buttonMethod={closeSuccessModal}
            />
          </div>
        </Modal>
      )}
      {successRemopveAvatarModal && (
        <Modal style={style['change-password__modal']} onClose={closeSuccessRemoveAvatarModal}>
          <CloseBtn close={closeSuccessRemoveAvatarModal} />
          <div className={style['change-password__conf-wrapper']}>
            <Confirmation
              icon="success"
              title={success}
              contant={your_photo_was_removed}
              buttonTitle={ok}
              buttonMethod={closeSuccessRemoveAvatarModal}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ChangeHcpPhoto;
