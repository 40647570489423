// Core
import React, { useEffect, useLayoutEffect, FC } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, withRouter } from 'react-router-dom';

import storage from '../storage';
import { path, constants } from './book';
import { parseJwt } from '../parseJwt';
import { PrivatRouteProps } from './types';
import PrivateCommonComponent from '../../components/auth/PrivateCommonComponent';
import { Dashboard } from '../../components';

const PrivateRoute = ({
  component: Component,
  path: routePath,
  successPath,
  routes,
  ...rest
}: PrivatRouteProps): JSX.Element => {
  const user = storage.get('user');
  const userRole = user?.role;
  const isAuthenticated = user?.token;
  const validToken = parseJwt(isAuthenticated);

  const history = useHistory();
  const location = useLocation();
  const { pathname, search } = location;

  const pathAdmin = pathname === path.admin;
  const pathHcp = pathname === path.home;

  const pathAdminSignin = pathname === path.adminSignIn || pathname === path.admin;
  const pathHcpSignin = pathname === path.signIn || pathname === path.home;

  const isAdmin = userRole === constants.admin || pathname === path.adminSignIn;
  const isHcp = userRole === constants.hcp || pathname === path.signIn;

  const isPathAdmin = pathname && !!pathname.match(/admin/);
  // const isPublicPath = publicRoute;

  console.log('***PrivateRoute_exp=', validToken.exp * 1000 < Date.now());
  console.log('***PrivateRoute_pathname=', pathname);
  console.log('***PrivateRoute_isAuthenticated=', isAuthenticated);

  useLayoutEffect(() => {
    console.log('***useLayoutEffect_PrivateRoute_userRole=', userRole);
    if (isAuthenticated) {
      if (validToken.exp * 1000 < Date.now()) {
        //   // if (!isPublicPath && validToken.exp * 1000 < Date.now()) {
        storage.save('user', '');
        if (isPathAdmin) {
          history.push(path.adminSignIn);
        } else {
          history.push(path.signIn);
        }
      }
      // if (isAuthenticated && path === path.signIn) {
      //   return <Redirect to={successPath} />
      // }
      // if (pathAdminSignin || pathHcpSignin) {
      //   history.push({
      //     pathname: pathAdminSignin ? path.adminDashboard : path.dashboard,
      //     search,
      //   });
      // }
      // if (permissions.includes(constants.admin) && isHcp && isPathAdmin) {
      //   storage.save('user', '');
      //   history.push(path.adminSignIn);
      // }
      // if (permissions.includes(constants.hcp) && isAdmin && !isPathAdmin) {
      //   storage.save('user', '');
      //   history.push(path.signIn);
      // }
    }
    if (!isAuthenticated) {
      history.push({
        pathname: pathAdmin ? path.adminSignIn : path.signIn,
      });
    }
    // if (!isAuthenticated && !isPublicPath) { TODO
    // document.title = label;
  }, [pathHcp, pathAdmin, userRole, pathAdminSignin, pathHcpSignin]);
  // const redirectPath = pathAdmin ? path.adminSignIn : path.signIn;
  useEffect(() => {
    console.log('***++PrivateRoute_INIT=');
  }, []);
  console.log('***++PrivateRoute_history=', history);

  return (
    <>
      {isAuthenticated ? (
        <>
          <PrivateCommonComponent />
          <Dashboard successPath={successPath} routes={routes} />
        </>
      ) : (
        <>Not Auth</>
      )}
    </>
    // <>
    //   <PrivateCommonComponent />
    //   <Switch>
    //     <Route
    //       {...rest}
    //       path={routePath}
    //       component={(props: any): JSX.Element =>
    //         isAuthenticated ? (
    //           <Component {...props} routes={routes} successPath={successPath} />
    //         ) : (
    //           <Redirect to={redirectPath} />
    //         )
    //       }
    //     />
    //   </Switch>
    // </>
  );
};

export default PrivateRoute;
