import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { sanitize } from 'dompurify';

import styles from './styles.module.css';
import { GetState, getCurrenLang } from '../../../../redux/selector';
import { PencilIcon } from '../../../../theme/icons';
import { EditEmail } from '../../../Form/EditEmail';
import { WarnNotifModal } from '../../../../common/NotificationModal';

const SendPdfWarnModal = ({
  patientEmail,
  setPatientEmail,
  oncloseWarning,
  uploadPdfFileToAWS,
  loading,
}: any): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const do_you_want_to_send_this =
    t?.dashboard.hcp.profile_patient.video_bank.do_you_want_to_send_this;
  const title = do_you_want_to_send_this?.replace('<email>', patientEmail);
  const cancel = t?.common.cancel;
  const send = t?.common.send;

  const [editMode, setEditMode] = useState<boolean>(false);

  // JSX
  const content = (
    <div className={styles['send-pdf-warning__title']}>
      {!editMode && (
        <div className={styles['send-pdf-warning__title']}>
          <div className={styles['send-pdf-warning__email-cont']}>
            <div
              className={styles['send-pdf-warning__email']}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: sanitize(title) }}
            />
            <div
              className={styles['send-pdf-warning__edit-cont']}
              onClick={(): void => setEditMode(true)}
              aria-hidden
            >
              <img src={PencilIcon} alt="edit" />
            </div>
          </div>
        </div>
      )}
      {editMode && (
        <EditEmail
          email={patientEmail}
          setPatientEmail={setPatientEmail}
          setEditMode={setEditMode}
        />
      )}
    </div>
  );

  return (
    <WarnNotifModal
      onClose={oncloseWarning}
      content={content}
      cancelBtnName={cancel}
      actionBtnName={send}
      actionMethod={uploadPdfFileToAWS}
      disableAction={editMode || loading}
    />
  );
};

export default SendPdfWarnModal;
