/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell,
} from 'recharts';
import cx from 'classnames';

import styles from '../styles.module.css';
import { getCurrenLang, getPatientSleepLoading } from '../../../redux/selector';
import { TBar } from '../PainChart/types';
import { TooltipSleep } from '../../../common/TooltipRecharts';
import { spinner } from '../../../common/Loader';
import { SleepChartValuesType } from '../../../redux/bodyData';
import { SleepYAxisTick } from '../../../utils/helper_charts';
import { LegendBarChart } from '../../../common/Legend';
import { PERIOD_FOR_CHART } from '../../../utils/enums';

export const SleepChart = ({ sleepData, isOverview, type }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const excellent_t = t?.dashboard.hcp.profile_patient.excellent || 'Excellent';
  const good_t = t?.dashboard.hcp.profile_patient.good || 'Good';
  const fair_t = t?.dashboard.hcp.profile_patient.fair || 'Fair';
  const poor_t = t?.dashboard.hcp.profile_patient.poor || 'Poor';
  const below_t = t?.dashboard.hcp.profile_patient.below || 'Below';
  const average_sleep_quality =
    t?.dashboard.hcp.profile_patient.average_sleep_quality || 'Average sleep quality';
  const sleep_quality =
    t?.dashboard.hcp.profile_patient.completion_and_steps.sleep_quality || 'Sleep quality';

  const no_data = t?.common.no_data;

  const [avSleepQuality, setAvSleepQuality] = useState<string | null>(null);

  enum SleepQualityColor {
    excellent = '#323159',
    good = '#306EBF',
    fair = '#89C8FF',
    poor = '#A63C94',
  }

  const loading = useSelector<any>((state) => getPatientSleepLoading(state));
  const windowClientWidth = useSelector((state: any) => state.common.screen.innerWidth);
  const chartData: any = sleepData?.values;
  const axisName = sleepData?.axis;
  const chartPeriod = sleepData?.title;
  const periodType = sleepData?.graph;
  const legendConfig = [
    { name: excellent_t, value: '90-100', color: SleepQualityColor.excellent },
    { name: good_t, value: '80-89', color: SleepQualityColor.good },
    { name: fair_t, value: ' 60-79', color: SleepQualityColor.fair },
    { name: poor_t, value: `${below_t} 60`, color: SleepQualityColor.poor },
  ];
  const isLabelAngle =
    periodType === PERIOD_FOR_CHART.W2 ||
    periodType === PERIOD_FOR_CHART.M6 ||
    periodType === PERIOD_FOR_CHART.M3;
  const isAllLabels = periodType !== PERIOD_FOR_CHART.M6 && windowClientWidth > 500;

  const getQuality = (entry: { sleepValue: number }, qaType: 'color' | 'label'): string => {
    let color = SleepQualityColor.good;
    let label = '';

    if (entry.sleepValue) {
      if (entry.sleepValue < 60) {
        color = SleepQualityColor.poor;
        label = poor_t;
      } else if (entry.sleepValue < 80) {
        color = SleepQualityColor.fair;
        label = fair_t;
      } else if (entry.sleepValue < 90) {
        color = SleepQualityColor.good;
        label = good_t;
      } else {
        color = SleepQualityColor.excellent;
        label = excellent_t;
      }
    }
    const res = qaType === 'color' ? color : label;
    return res;
  };
  // Excellent: 90-100  Good: 80-89  Fair: 60-79   Poor: Below 60

  useEffect(() => {
    if (sleepData) {
      let av = 0;
      let acc = 0;
      let counter = 0;
      if (sleepData.values.length) {
        sleepData.values.map((item: SleepChartValuesType) => {
          acc += item.sleepValue;
          if (item.sleepValue) {
            counter += 1;
          }
          return null;
        });
      }
      if (counter === 0) {
        setAvSleepQuality('-');
        return;
      }
      av = acc / counter;
      const result = getQuality({ sleepValue: av }, 'label');
      setAvSleepQuality(result);
    }
  }, [sleepData, t]);

  const sleepBarConfig = [
    {
      name: 'bar',
      label: 'max_heart_rate',
      color: '#F15B07',
      lineType: '',
    },
  ];

  // Custom Tooltip
  const renderTooltip = (payload: any): JSX.Element => {
    const pay = payload.payload;
    return <TooltipSleep payload={pay} t={t} />;
  };

  // JSX
  const noData = !loading && !chartData?.length && <span className={styles.nodata}>{no_data}</span>;

  return (
    <div
      className={cx({
        [styles['chart-wrapper']]: true,
        'chart-to-PDF': true,
      })}
    >
      {!isOverview && (
        <div>
          <div className={styles['chart-title']}>{sleep_quality}</div>
          <div className={styles['chart-desciption-wrapper']}>
            {avSleepQuality && (
              <div className={styles['chart-desciption--pain']}>
                {average_sleep_quality}
                <span className={styles['chart-desciption--qa']}>{avSleepQuality}</span>
              </div>
            )}
          </div>
          <LegendBarChart config={legendConfig} />
        </div>
      )}
      {isOverview && (
        <div className={styles.chart__flex}>
          <div className={styles['left-section']}>
            <div className={styles['chart-period']}>{chartPeriod}</div>
            <div className={styles['chart-desciption-wrapper']}>
              {avSleepQuality && (
                <div className={styles['chart-desciption--pain']}>
                  {average_sleep_quality}
                  <span className={styles['chart-desciption--qa']}>{avSleepQuality}</span>
                </div>
              )}
            </div>
          </div>
          <div className={styles['right-section']}>
            <div className={styles['chart__legend--wrapper']}>
              <LegendBarChart config={legendConfig} />
            </div>
          </div>
        </div>
      )}

      <div className={styles['chart-container']}>
        <div className={styles['chart-label']}>{axisName}</div>
        {loading && spinner}
        {noData}
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={chartData}
            margin={{
              top: 10,
              left: -20,
              right: isLabelAngle ? 7 : 0,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              // @ts-ignore
              style={{
                fontSize: isLabelAngle ? '11px' : '14px',
                fill: '#23335B',
              }}
              angle={isLabelAngle ? -45 : 0}
              interval={isAllLabels ? 0 : 'preserveEnd'} // 0 - To show all ticks
            />
            <YAxis
              domain={sleepData?.yDomen || [0, 10]}
              stroke="#9BA1AE"
              tickLine={{ stroke: '#F0F0F8' }}
              interval={0}
              tickCount={7}
              tick={<SleepYAxisTick type={type} />}
              // @ts-ignore
              style={{
                fontSize: '14px',
                fill: '#23335B',
              }}
            />
            {sleepBarConfig.map((typeChart: TBar) => (
              <Bar
                key={typeChart.label}
                strokeWidth={3}
                dataKey="bar"
                fill="1C9CE4"
                isAnimationActive
              >
                {chartData ? (
                  chartData.map((entry: SleepChartValuesType, index: number) => (
                    <Cell fill={getQuality(entry, 'color')} key={`sleepCellBar-${String(index)}`} />
                  ))
                ) : (
                  <></>
                )}
              </Bar>
            ))}
            {!noData && (
              <Tooltip
                allowEscapeViewBox={{ x: false, y: false }}
                offset={windowClientWidth > 1000 ? 10 : 500}
                content={renderTooltip}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
