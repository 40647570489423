import React, { ReactElement } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';
import { CustomCheckbox } from '../Input';
import { EnvelopIcon, UpdateIcon } from '../../theme/icons';
import { Tooltip } from 'antd';

const NotificationAction = ({
  t,
  checkAllItems,
  isCheked,
  updateList,
  disabled,
  setReadStatus,
}: any): ReactElement => {
  const select_all = t?.common.select_all || 'Select all';
  const action_t = t?.dashboard.hcp.table.action;
  const mark_as_read_t = t?.header.mark_as_read;
  const refresh_t = t?.header.refresh || 'Refresh';

  return (
    <div className={styles['notifications__action-container']}>
      <div className={styles['notifications__action-label']}>{action_t}</div>
      <div className={styles['notifications__action-icons']}>
        <div className={styles['notifications__checkbox-wrapper']}>
          <CustomCheckbox
            htmlId="SelectAllId"
            name="selectAll"
            checked={isCheked}
            onChangeMethod={(e: any): void => checkAllItems(e)}
            isTouched={false}
            hasErrors={false}
          />
          <div className={styles['notifications__checkbox-label']}>{select_all}</div>
        </div>
        <Tooltip title={mark_as_read_t}>
          <div
            className={cx({
              [styles.notifications__envelop]: true,
              [styles['notifications__envelop--disabled']]: disabled,
            })}
            onClick={disabled ? (): null => null : () => setReadStatus()}
            aria-hidden
          >
            <EnvelopIcon />
          </div>
        </Tooltip>

        <Tooltip title={refresh_t}>
          <div className={styles.notifications__envelop} onClick={updateList} aria-hidden>
            <UpdateIcon />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default NotificationAction;
