import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import styles from './styles.module.css';
import { getCurrenLang } from '../../../../../redux/selector';
import { Button } from '../../../../../common/index';
import { DeleteIcon } from '../../../../../theme/icons';
import { getOptions, getTouched } from '../../../../../utils/helper';
import { TypeValue, InitialValuesForm, SetsTypeValue } from '../../../VideoExercise/types';
import { exerciseConfig } from '../../../../../utils/variables';
import { numberOfThumbnails } from '../../../VideoExercise/initForm';
import {
  InputFloatLabel,
  RadioConverter,
  RoundCheckbox,
  TextareaFeildFloatLabel,
} from '../../../../../common/Input';
import { getPreviews } from '../../../VideoExercise/Components';
import { SelectFloatLabel } from '../../../../../common/Input/Select';
import { ShowErrorNotification } from '../../../../Form/ErrorMessage';
import { TFocuses, TFocusesProcedure } from '../../types';

const SettingsForm = ({
  formik,
  index,
  isPhotosExist,
  exercisePhotos,
  closeSettings,
  setWarningModal,
  loading,
  errorSaveExercise,
  errorSaveExerciseInFolder,
  deleteFromTeamError,
  focusType2Arr,
  exercisesCategories,
  focusType1Arr,
  exercisesProcedureFocus,
  isModal = false,
  isModalFolder = false,
  dataQa,
}: any): ReactElement => {
  // }: VideoExerciseProp): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const cancel = t?.common.cancel;
  const delete_text = t?.common.delete;
  const comments = t?.dashboard.hcp.profile_patient.video_bank.comments;
  const FocusOfTheExercise = t?.dashboard.hcp.profile_patient.video_bank.focus_of_the_exercise;
  const SelectAllThatApply = t?.dashboard.hcp.profile_patient.video_bank.select_all_that_apply;
  const exercise = t?.dashboard?.hcp.profile_patient.completion_and_steps.exercise?.toLowerCase();
  const save = t?.common.save;
  const exercise_name = t?.dashboard.hcp.profile_patient.video_bank.exercise_name;
  const warn_setting_title =
    t?.notifications.org_video_exerc_warning_setting_title ||
    'Note about Organisation Video Bank settings:';
  const warn_setting_one =
    t?.hcp.org_video_bank?.warning_setting_one ||
    'Changes to Exercise Details will only affect exercises that are currently inactive for all patients in your organisation.';
  const warn_setting_two =
    t?.hcp.org_video_bank?.warning_setting_two ||
    'Changes to Focus of the Exercise will apply to all exercises, both active and inactive, for all patients in your organisation.';
  const warn_setting_three =
    t?.hcp.org_video_bank?.warning_setting_three ||
    "We recommend changing video settings only within the patient's Prescribed Exercise Media.";
  const exercise_details = t?.hcp.org_video_bank?.exercise_details || 'Exercise Details';

  const { isValid, values, dirty } = formik;
  const hasFocusErrors = formik.errors.focus1;
  const isFocusTouched = getTouched(formik.touched);

  const onRadioChange = (dimProp: string, dimValue: string): void => {
    formik.setFieldValue(dimProp, dimValue);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* Warning */}
      {!isModal && !isModalFolder && (
        <div className={styles['settings__warning-container']}>
          <div className={styles['settings__warning--title']}>{warn_setting_title}</div>
          <div>{warn_setting_one}</div>
          <div>{warn_setting_two}</div>
          <div>{warn_setting_three}</div>
        </div>
      )}

      <div className={styles.settings__details}>
        <div className={styles['settings__left-container']}>
          <InputFloatLabel
            inputId="name"
            inputName="name"
            inputType="text"
            placeholder={exercise_name}
            hasErrors={formik.errors.name}
            inputValue={values.name}
            isTouched={formik.touched.name}
            onChangeMethod={formik.handleChange}
          />
          <div className={styles.settings__title}>{exercise_details}</div>
          <div className={styles.settings__subrow}>
            {exerciseConfig(t).repsTime.map((repsOrTime: { value: string; label: string }) => (
              <div className={styles.settings__elem} key={`repsOrTime${repsOrTime.label}`}>
                <RadioConverter
                  labelName={repsOrTime.label}
                  handleChange={(): void => onRadioChange('dim', repsOrTime.value)}
                  inputName="time"
                  value={values.dim}
                  checkedRadio={values.dim === repsOrTime.value}
                  tip=""
                  id={`${repsOrTime.value}Radio${index}`}
                />
              </div>
            ))}
          </div>

          <div
            className={cx({
              [styles.settings__subrow]: true,
              [styles['settings__space-subrow']]: true,
              [styles.invisible]: values.dim === 'reps',
            })}
          >
            {exerciseConfig(t).minSec.map((minOrSec: TypeValue) => (
              <div
                className={styles['settings__select-field-time']}
                key={`minOrSec${minOrSec.label}`}
              >
                <SelectFloatLabel
                  inputId={`${index}minOrSec${minOrSec.label}`}
                  name={minOrSec.value}
                  onChange={formik.setFieldValue}
                  inputValue={values[minOrSec.value as keyof InitialValuesForm]}
                  placeholder={minOrSec.label}
                  options={getOptions(0, 59)}
                  // disabledField={!checkPermission()}
                  type="org video bank"
                />
              </div>
            ))}
          </div>

          <div
            className={cx({
              [styles.settings__subrow]: true,
              [styles['settings__space-subrow']]: true,
            })}
          >
            {exerciseConfig(t).sets.map((set: SetsTypeValue) => (
              <div
                key={`sets${set.label}`}
                className={cx({
                  [styles['settings__reps-fields']]: true,
                  [styles.invisible]: values.dim === 'time' && set.value === 'reps',
                })}
              >
                <InputFloatLabel
                  inputId={`${index}Videobank_${set.label}`}
                  inputName={set.value}
                  placeholder={set.label}
                  inputType="text"
                  inputValue={values[set.value as keyof InitialValuesForm]}
                  hasErrors={formik.errors[set.value as keyof InitialValuesForm]}
                  isTouched
                  onChangeMethod={formik.handleChange}
                  // disabled={!checkPermission()}
                />
              </div>
            ))}
          </div>
          <TextareaFeildFloatLabel
            id={`${index}exerciseTextArea`}
            name="comments"
            value={values.comments}
            placeholder={comments}
            onChange={formik.handleChange}
            rows={4}
          />
        </div>
        <div className={styles['settings__right-container']}>
          <div className={styles.settings__title}>{FocusOfTheExercise}</div>
          <div className={styles.settings__subtitle}>{SelectAllThatApply}</div>

          <div className={styles['settings__focuses-container']}>
            {focusType2Arr?.length > 0 &&
              focusType2Arr.map((focusItem: string, i: number) => {
                const currentFocusNumber = `focus${i + 1}` as keyof TFocuses;
                const currentName = exercisesCategories[currentFocusNumber]?.name;
                const htmlId = `${currentName}${index}`;
                return (
                  <label
                    key={`focus${focusItem}`}
                    htmlFor={htmlId}
                    className={cx({
                      [styles['checkbox-container']]: true,
                      // [styles.disable]: !checkPermission(),
                    })}
                  >
                    <div
                      className={cx({
                        [styles.customCheckbox]: true,
                        [styles.error]: !isValid && isFocusTouched && hasFocusErrors,
                        // [styles.disable]: !checkPermission(),
                      })}
                    >
                      <input
                        id={htmlId}
                        name={`focus${i + 1}`}
                        type="checkbox"
                        value={currentName}
                        checked={values[currentFocusNumber]}
                        onChange={formik.handleChange}
                        data-qa={dataQa}
                        // disabled={type === VIDEO_BANK_TYPE.PREVIEW || !checkPermission()}
                      />
                      <span className={styles.checkmark} />
                    </div>
                    <div>{currentName}</div>
                  </label>
                );
              })}
          </div>
          <div className={styles['settings__focuses-container']}>
            {focusType1Arr?.length > 0 &&
              focusType1Arr.map((focusItem: string, i: number) => {
                const currentFocusNumber = `procedure${i + 1}` as keyof TFocusesProcedure;
                const currentName = exercisesProcedureFocus[currentFocusNumber].name;
                const htmlId = `${currentName}${index}`;
                return (
                  <div key={htmlId} className={styles['settings__roundchexbox-container']}>
                    <RoundCheckbox
                      htmlId={htmlId}
                      name={`procedure${i + 1}`}
                      label={currentName}
                      checked={values[currentFocusNumber]}
                      onChangeMethod={formik.handleChange}
                      isTouched={false}
                      hasErrors={false}
                    />
                  </div>
                );
              })}
          </div>

          {/* Photo previews */}
          {isPhotosExist ? (
            <div className={styles['video-exercise__preview-container']}>
              {getPreviews(exercisePhotos)}
              <div className={styles['video-folder__counter']}>
                {exercisePhotos.length > numberOfThumbnails ? (
                  <>+{exercisePhotos.length - numberOfThumbnails}</>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Errors */}
          {(errorSaveExercise || deleteFromTeamError || (hasFocusErrors && isFocusTouched)) && (
            <div className={styles.errorMessages}>
              <ShowErrorNotification errorMessage={deleteFromTeamError} />
              <ShowErrorNotification errorMessage={errorSaveExercise} />
              <ShowErrorNotification errorMessage={errorSaveExerciseInFolder} />
              <ShowErrorNotification errorMessage={hasFocusErrors} />
            </div>
          )}
        </div>
      </div>
      {!isModal && (
        <div className={styles['bottom-setting-container']}>
          {!isModalFolder && (
            <div
              className={cx({
                [styles['settings__btn-delete-wraper']]: true,
                // [styles.notAllowed]: !checkPermission(),
              })}
              role="presentation"
              onClick={(): void => setWarningModal(() => true)}
            >
              <DeleteIcon />
              <span className={styles['settings__btn-delete-text']}>
                {`${delete_text} ${exercise}`}
              </span>
            </div>
          )}

          <Button
            buttonType="button"
            buttonName={cancel}
            buttonMethod={(): void => closeSettings()}
            buttonClass={styles['settings__btn--save']}
            disabledButton={loading}
          />
          <Button
            buttonType="submit"
            // buttonMethod={(): void => formik.setFieldValue('isDraft', false)}
            buttonName={save}
            buttonClass={styles['settings__btn--save']}
            disabledButton={!dirty || loading}
          />
        </div>
      )}
    </form>
  );
};

export default SettingsForm;
