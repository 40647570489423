// Type
import {
  FILL_PATIENTS_STEPS,
  STEPS_LOADING,
  STEPS_ERROR,
  ActionTypes,
  DataType,
  StepsDataType,
  ERROR_PATIENT_COMPLETED_EXERCISES,
  ErrorHttpAction,
  FILL_PATIENT_COMPLETINS,
  FILL_EXERCISES_PER_PERIOD,
  FILL_EXERCISES_OVERVIEW_TAB,
  FILL_DATA_BY_EXERCISES,
  IsExerciseChart,
  PatientCompletionsFillAction,
  PatientErrorFetching,
  PatientExerciseName,
  PatientFillAction,
  PatientFillPeriondAction,
  PatientFillStep,
  LoadingActionType,
  ExercisesType,
  SET_EXERCISE_NAME,
  SET_IS_EXERCISE,
  FILL_EXERCISES_MOTIVATION_PAIN_TAB,
  FILL_EXERCISES_REASONS_TAB,
  REASONS_LEGEND_VALUE,
  MOTIVATIONS_LEGEND_VALUE,
  BY_EXERCISES_LEGEND_VALUE,
  COMPLETATION_STEPS_LEGEND_VALUE,
  COMPLETATION_SKIP_STOP_LEGEND_VALUE,
  ExercisesFillAction,
  FILL_EXERCISE_COMPLETION,
  EXERCISE_COMPLETION_LOADING,
  EXERCISE_COMPLETION_ERROR,
  ExercComplDataType,
  FillPatientExercCompl,
  EXERC_PER_PERIOD_LOADING,
  ExercPerPeriodType,
  SetPerPeriodDataAction,
  NOT_COMPLETION_EXERCISE_LOADING,
  NOT_COMPLETION_EXERCISE_ERROR,
  FILL_NOT_COMPLETION_EXERCISE,
  FillPatientNotComplExer,
  NotComplExercDataType,
} from './types';

export const fillSteps = (payload: ExercComplDataType[] | []): PatientFillStep => ({
  type: FILL_PATIENTS_STEPS,
  payload,
});

export const loadingSteps = (payload: boolean): LoadingActionType => ({
  type: STEPS_LOADING,
  payload,
});

export const setErrorSteps = (payload: any): PatientErrorFetching => ({
  type: STEPS_ERROR,
  payload,
});

export const fillExerciseCompletion = (payload: StepsDataType[] | []): FillPatientExercCompl => ({
  type: FILL_EXERCISE_COMPLETION,
  payload,
});

export const exerciseComplLoading = (payload: boolean): LoadingActionType => ({
  type: EXERCISE_COMPLETION_LOADING,
  payload,
});

export const setExerciseComplError = (payload: any): PatientErrorFetching => ({
  type: EXERCISE_COMPLETION_ERROR,
  payload,
});

export const fillExercisesPerPeriod = (payload: ExercPerPeriodType[]): SetPerPeriodDataAction => ({
  type: FILL_EXERCISES_PER_PERIOD,
  payload,
});

export const setExercisesPerPeriodLoading = (payload: boolean): LoadingActionType => ({
  type: EXERC_PER_PERIOD_LOADING,
  payload,
});

export const fillNotComplExerc = (payload: NotComplExercDataType[]): FillPatientNotComplExer => ({
  type: FILL_NOT_COMPLETION_EXERCISE,
  payload,
});

export const notComplExercLoading = (payload: boolean): LoadingActionType => ({
  type: NOT_COMPLETION_EXERCISE_LOADING,
  payload,
});

export const notComplExercError = (payload: any): PatientErrorFetching => ({
  type: NOT_COMPLETION_EXERCISE_ERROR,
  payload,
});

// Old

export const fillExercisesOverviewTab = (payload: [] | object[]): ExercisesFillAction => ({
  type: FILL_EXERCISES_OVERVIEW_TAB,
  payload,
});

export function fillExercMotivPainTab(payload: DataType): PatientFillAction {
  return {
    type: FILL_EXERCISES_MOTIVATION_PAIN_TAB,
    payload,
  };
}
export function fillExercisesReasonsTab(payload: DataType): PatientFillAction {
  return {
    type: FILL_EXERCISES_REASONS_TAB,
    payload,
  };
}

export const fillCompletionsExercises = (payload: DataType): PatientFillPeriondAction => ({
  type: FILL_DATA_BY_EXERCISES,
  payload,
});

export function fillCompletions(payload: DataType): PatientCompletionsFillAction {
  return {
    type: FILL_PATIENT_COMPLETINS,
    payload,
  };
}

export function setfetchingError(payload: ErrorHttpAction): PatientErrorFetching {
  return {
    type: ERROR_PATIENT_COMPLETED_EXERCISES,
    payload,
  };
}

export const setExerciseName = (payload: string | null): PatientExerciseName => ({
  type: SET_EXERCISE_NAME,
  payload,
});

export function setIsExercise(payload: boolean): IsExerciseChart {
  return {
    type: SET_IS_EXERCISE,
    payload,
  };
}
export const setReasonsLegendValue = (payload: any): PatientFillAction => ({
  type: REASONS_LEGEND_VALUE,
  payload,
});
export const setMotivationsLegendValue = (payload: any): PatientFillAction => ({
  type: MOTIVATIONS_LEGEND_VALUE,
  payload,
});
export const setComplByExercLegendValue = (payload: any): PatientFillAction => ({
  type: BY_EXERCISES_LEGEND_VALUE,
  payload,
});
export const setComplStepsLegendValue = (payload: any): PatientFillAction => ({
  type: COMPLETATION_STEPS_LEGEND_VALUE,
  payload,
});
export const setComplSkipStopLegendValue = (payload: any): PatientFillAction => ({
  type: COMPLETATION_SKIP_STOP_LEGEND_VALUE,
  payload,
});

const init: ExercisesType = {
  steps: [],
  stepsLoading: false,
  stepsError: '',

  exerciseComletion: [],
  exerciseComletionLoading: false,
  exerciseComletionError: '',

  exercisesPerPeriod: [],
  exercisesPerPeriodLoading: false,

  notCompletedExercises: [],
  notCompletedExercisesLoading: false,
  notCompletedExercisesError: '',

  // old
  byExercises: [],
  isExercise: true,
  isFetching: false,
  exerciseName: null,
  error: false,
  exercisesOverviewTab: [],
  exercMotivPainTab: [],
  exercReasonsTab: [],
  stepOverviewTab: [],
  stepByExercises: [],
  reasonsLegendValue: [],
  motivationsLegendValue: [],
  complByExercLegendValue: {
    completed: 0,
    partlyDone: 0,
    attempts: 0,
    noAttempt: 0,
  },
  complStepsLegendValue: [],
  complSkipStopLegendValue: [],
};

// Reducer
export const completedReducer = (state = init, action: ActionTypes): ExercisesType => {
  switch (action.type) {
    case FILL_PATIENTS_STEPS:
      return {
        ...state,
        steps: action.payload,
      };
    case STEPS_LOADING:
      return {
        ...state,
        stepsLoading: action.payload,
      };
    case STEPS_ERROR:
      return {
        ...state,
        stepsError: action.payload,
      };
    case FILL_EXERCISE_COMPLETION:
      return {
        ...state,
        exerciseComletion: action.payload,
      };
    case EXERCISE_COMPLETION_LOADING:
      return {
        ...state,
        exerciseComletionLoading: action.payload,
      };
    case FILL_EXERCISES_PER_PERIOD:
      return {
        ...state,
        exercisesPerPeriod: action.payload,
      };
    case EXERC_PER_PERIOD_LOADING:
      return {
        ...state,
        exercisesPerPeriodLoading: action.payload,
      };
    case FILL_NOT_COMPLETION_EXERCISE:
      return {
        ...state,
        notCompletedExercises: action.payload,
      };
    case NOT_COMPLETION_EXERCISE_LOADING:
      return {
        ...state,
        notCompletedExercisesLoading: action.payload,
      };
    case NOT_COMPLETION_EXERCISE_ERROR:
      return {
        ...state,
        notCompletedExercisesError: action.payload,
      };

    // old
    case FILL_DATA_BY_EXERCISES:
      return {
        ...state,
        byExercises: action.payload, // DATA for graphic complition
        error: false,
      };
    case FILL_EXERCISES_OVERVIEW_TAB:
      return {
        ...state,
        exercisesOverviewTab: action.payload,
      };
    case FILL_EXERCISES_MOTIVATION_PAIN_TAB:
      return {
        ...state,
        exercMotivPainTab: action.payload,
      };
    case FILL_EXERCISES_REASONS_TAB:
      return {
        ...state,
        exercReasonsTab: action.payload,
      };
    case ERROR_PATIENT_COMPLETED_EXERCISES:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case SET_EXERCISE_NAME:
      return {
        ...state,
        exerciseName: action.payload,
      };
    case SET_IS_EXERCISE:
      return {
        ...state,
        isExercise: action.payload,
      };
    case REASONS_LEGEND_VALUE:
      return {
        ...state,
        reasonsLegendValue: action.payload,
      };
    case MOTIVATIONS_LEGEND_VALUE:
      return {
        ...state,
        motivationsLegendValue: action.payload,
      };

    case BY_EXERCISES_LEGEND_VALUE:
      return {
        ...state,
        complByExercLegendValue: action.payload,
      };

    case COMPLETATION_STEPS_LEGEND_VALUE:
      return {
        ...state,
        complStepsLegendValue: action.payload,
      };

    case COMPLETATION_SKIP_STOP_LEGEND_VALUE:
      return {
        ...state,
        complSkipStopLegendValue: action.payload,
      };

    default:
      return state;
  }
};
