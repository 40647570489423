import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Pagination } from 'antd';
import cx from 'classnames';
import { toast } from 'react-toastify';

import styles from './styles.module.css';
import { Button, Loading } from '../../common';
import { getCurrenLang } from '../../redux/selector';
import { FilterByExercise } from '../Filter/ExerciseFilter';
import { getSelectOptions, initialPage } from '../../utils/helper';
import { PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE } from '../../utils/variables';
import { path } from '../../utils';
import useGetFocuses from '../../hooks/useGetFocuses';
import OrgPatientInfoTableHeader from './components/TableHeader';
import {
  useGetAllHospitalVideos,
  useGetAllHospitalVideosMatches,
  useGetHospitalNames,
} from '../../graphql/hospitals';
import { TSortdirectionProps } from '../../utils/model';
import MediaForPatient from './components/MediaForPatient';
import useCheckRole from '../../hooks/useCheckRole';

const OrganisationPatientInformation = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const upload_patient_information = t?.hcp.manage_patient_information?.upload_patient_information;
  const no_data = t?.common.no_data;

  const history = useHistory();
  const { isAdmin } = useCheckRole();

  // Queris
  // get list video exercises for Team video bank page from the database
  const {
    _getAllHospitalVideos,
    allHospitalVideos,
    loadingHospitalVideos,
    errorHospitalVideos,
    allHospitalVideosData,
  } = useGetAllHospitalVideos();

  // Get search result
  const {
    totalVideos,
    _getAllHospitalVideosMatches,
    loadingHospitalVideosMatches,
  } = useGetAllHospitalVideosMatches();
  const { hospitalNames } = useGetHospitalNames(!isAdmin);
  const hospitalNameOptions = useMemo(() => getSelectOptions(hospitalNames), [hospitalNames]);

  // Local state
  const [mediaList, setMediaList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [pageSize, setPageSize] = useState<number>(15);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [focusFilter, setFocusFilter] = useState<number[]>([]);

  const [sortBy, setSortby] = useState<string>('name');
  const [sortDirectionName, setSortDirectionName] = useState<string>('');
  const [isSearch, setIsSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [hideSearchMenu, setHideSearchMenu] = useState(false);

  const {
    focusType1Arr,
    // focusType2Arr,
    // exercisesCategories,
    exercisesProcedureFocus,
    procedureFilterConfig,
    loadingFocuses,
  } = useGetFocuses();

  // After changing the filter settings, update the list of videos
  useEffect(() => {
    setCurrentPage(initialPage);
    const listProps: TSortdirectionProps = {
      page: 1,
      itemsPerPage: pageSize,
    };
    if (sortDirectionName) {
      listProps.sortBy = sortBy;
      listProps.sortDirection = sortDirectionName;
    }
    _getAllHospitalVideos({
      variables: {
        listProps,
        filterFocus: focusFilter,
        searchProps: [
          {
            searchField: 'hospitals_videos.name',
            searchValue: searchQuery,
          },
        ],
      },
    });
  }, [focusFilter]);

  // After receiving the list of Org exercises from the database, display them on the UI
  useEffect(() => {
    if (allHospitalVideos?.list) {
      setMediaList(allHospitalVideos.list);
    } else if (!loadingHospitalVideos) {
      setMediaList([]);
    }
  }, [allHospitalVideosData]);

  // Display the number of document pages in pagination
  useEffect(() => {
    if (allHospitalVideos?.totalItems) {
      setTotalItems(allHospitalVideos.totalItems);
    } else if (!loadingHospitalVideos) {
      setTotalItems(0);
    }
  }, [allHospitalVideosData?.getAllHospitalVideos.totalItems]);

  // If error get all Hospital Videos show message
  useEffect(() => {
    if (errorHospitalVideos) {
      toast.error(errorHospitalVideos);
    }
  }, [errorHospitalVideos]);

  /* * search for matches in the database * */
  const [searchMatches, setSearchMatches] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState('');

  // Find search matches in DB
  const findMatches = (searchVal: string): void => {
    if (!searchVal) {
      setSearchMatches(null);
      setSearchInputValue('');
      return;
    }
    setSearchInputValue(searchVal);
    _getAllHospitalVideosMatches({
      variables: {
        filterFocus: focusFilter,
        listProps: {
          page: 1,
          itemsPerPage: pageSize,
        },
        searchProps: [
          {
            searchField: 'hospitals_videos.name',
            searchValue: searchVal,
          },
        ],
      },
    });
  };

  // Show the result from the database in the search menu
  useEffect(() => {
    if (!searchInputValue || !hideSearchMenu) return;
    if (!loadingHospitalVideosMatches) {
      const current = totalVideos;
      setSearchMatches(current);
    }
  }, [totalVideos]);

  // Get search exercise name
  const handleSearch = (val: string): void => {
    setSearchQuery(val);
    setSearchMatches(null);
    setHideSearchMenu(!hideSearchMenu);
    if (val) {
      setCurrentPage(1);
      const listProps: TSortdirectionProps = {
        page: 1,
        itemsPerPage: pageSize,
      };
      if (sortDirectionName) {
        listProps.sortBy = sortBy;
        listProps.sortDirection = sortDirectionName;
      }
      _getAllHospitalVideos({
        variables: {
          listProps,
          filterFocus: focusFilter,
          searchProps: [
            {
              searchField: 'hospitals_videos.name',
              searchValue: val,
            },
          ],
        },
      });
      setIsSearch(() => true);
    }
  };

  // Reset search exercise name
  const handleReset = (): void => {
    setSearchQuery('');
    setSearchMatches(null);
    setHideSearchMenu(!hideSearchMenu);
    if (searchQuery) {
      setCurrentPage(1);
      const listProps: TSortdirectionProps = {
        page: 1,
        itemsPerPage: pageSize,
      };
      if (sortDirectionName) {
        listProps.sortBy = sortBy;
        listProps.sortDirection = sortDirectionName;
      }
      _getAllHospitalVideos({
        variables: {
          listProps,
          filterFocus: focusFilter,
          searchProps: [
            {
              searchField: 'hospitals_videos.name',
              searchValue: '',
            },
          ],
        },
      });
      setIsSearch(() => false);
    }
  };
  /** */

  // Sorting
  // Sort exercise list by Column Name
  const sortByColumnName = (name: string, sortdirection: string): void => {
    setSortby(name);
    setSortDirectionName(sortdirection);
    const listProps: TSortdirectionProps = {
      page: currentPage,
      itemsPerPage: pageSize,
    };
    if (sortdirection) {
      listProps.sortBy = name;
      listProps.sortDirection = sortdirection;
    }
    _getAllHospitalVideos({
      variables: {
        listProps,
        filterFocus: focusFilter,
        searchProps: [
          {
            searchField: 'hospitals_videos.name',
            searchValue: searchQuery,
          },
        ],
      },
    });
  };

  // Exercise List Update
  const updateList = (): void => {
    const listProps: TSortdirectionProps = {
      page: currentPage,
      itemsPerPage: pageSize,
    };
    if (sortDirectionName) {
      listProps.sortBy = sortBy;
      listProps.sortDirection = sortDirectionName;
    }
    _getAllHospitalVideos({
      variables: {
        listProps,
        filterFocus: focusFilter,
        searchProps: [
          {
            searchField: 'hospitals_videos.name',
            searchValue: searchQuery,
          },
        ],
      },
    });
  };

  // Go to the next page
  const onChangePagination = (page: any, itemsPerPage: any): void => {
    setPageSize(itemsPerPage);
    setCurrentPage(page);
    const listProps: TSortdirectionProps = {
      page,
      itemsPerPage,
    };
    if (sortDirectionName) {
      listProps.sortBy = sortBy;
      listProps.sortDirection = sortDirectionName;
    }
    _getAllHospitalVideos({
      variables: {
        listProps,
        filterFocus: focusFilter,
        searchProps: [
          {
            searchField: 'hospitals_videos.name',
            searchValue: searchQuery,
          },
        ],
      },
    });
  };

  // Redirect to Upload media file page
  const openUploadFilePage = (): void => {
    const pathname = isAdmin
      ? path.upload_patient_information_admin
      : path.upload_patient_information;
    history.push({
      pathname,
    });
  };

  // JSX
  const loadingJSX = (loadingHospitalVideos || loadingFocuses || loadingHospitalVideosMatches) && (
    <Loading />
  );
  const noData = !mediaList?.length && !loadingJSX && (
    <div className={styles.noData}>{no_data}</div>
  );
  const uploadBtnJsx = (
    <Button
      buttonType="button"
      buttonName={upload_patient_information}
      buttonMethod={openUploadFilePage}
      buttonClass={styles['org-vorg-patient-info__btn']}
    />
  );

  return (
    <div className={styles['org-patient-info__container']}>
      {loadingJSX}
      {/* FILTER */}
      {t && (
        <div
          className={cx([
            styles['org-patient-info__filter-container'],
            styles['org-patient-info__wrapper--pd'],
          ])}
        >
          <FilterByExercise
            filterConfig={procedureFilterConfig}
            focusFilter={focusFilter}
            setFocusFilter={setFocusFilter}
          />
          <div className={styles['org-patient-info__upload-btn-bg']}>{uploadBtnJsx}</div>
        </div>
      )}

      {/* Header */}
      <OrgPatientInfoTableHeader
        sortByColumnName={sortByColumnName}
        sortBy={sortBy}
        handleSearch={handleSearch}
        handleReset={handleReset}
        searchMatches={searchMatches}
        findMatches={findMatches}
        hideSearchMenu={hideSearchMenu}
        isSearch={isSearch}
        setHideSearchMenu={setHideSearchMenu}
        setSearchMatches={setSearchMatches}
      />

      {/* Organisation video exercises */}
      {noData}
      {!!mediaList?.length &&
        exercisesProcedureFocus &&
        mediaList.map((videoData: any, i: number) => {
          const keyObj = `media${videoData.id}`;
          return (
            <MediaForPatient
              index={i}
              key={keyObj}
              data={videoData}
              exercisesProcedureFocus={exercisesProcedureFocus}
              focusType1Arr={focusType1Arr}
              hospitalNameOptions={hospitalNameOptions}
              updateVideoList={updateList}
            />
          );
        })}

      {!noData && mediaList?.length > 0 && (
        <div className={styles.wrapper_pagination}>
          <Pagination
            size="small"
            current={currentPage}
            pageSize={pageSize}
            total={totalItems}
            onChange={onChangePagination}
            pageSizeOptions={PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE}
            showSizeChanger
          />
        </div>
      )}
      {/* Sm screen */}
      <div className={styles['org-patient-info__upload-btn-sm']}>{uploadBtnJsx}</div>
    </div>
  );
};

export default OrganisationPatientInformation;
