/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
} from 'recharts';
import cx from 'classnames';

import styles from '../styles.module.css';
import { getCurrenLang, getPatientHeartRateLoading } from '../../../redux/selector';
import { TBar } from '../PainChart/types';
import { TooltipRecharts } from '../../../common/TooltipRecharts';
import { spinner } from '../../../common/Loader';
import { LegendLineChart } from '../../../common/Legend';
import { PERIOD_FOR_CHART } from '../../../utils/enums';

export const HeartRateChart = ({ heartRateData, isOverview, type }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const max_heart_rate =
    t?.dashboard.hcp.profile_patient.completion_and_steps.max_heart_rate ||
    'Maximum heart rate in bpm';
  const min_heart_rate =
    t?.dashboard.hcp.profile_patient.completion_and_steps.min_heart_rate ||
    'Minimum heart rate in bpm';
  const av_heart_rate =
    t?.dashboard.hcp.profile_patient.completion_and_steps.av_heart_rate ||
    'Average heart rate in bpm';
  const rest_heart_rate =
    t?.dashboard.hcp.profile_patient.completion_and_steps.rest_heart_rate ||
    'Resting heart rate in bpm';
  const heart_rate = t?.dashboard.hcp.profile_patient.tabs.heart_rate || 'Heart rate';

  const no_data = t?.common.no_data;

  const loading = useSelector<any>((state) => getPatientHeartRateLoading(state));
  const windowClientWidth = useSelector((state: any) => state.common.screen.innerWidth);
  const chartData: any = heartRateData?.values;
  const axisName = heartRateData?.axis;
  const chartPeriod = heartRateData?.title;
  const periodType = heartRateData?.graph;
  const isLabelAngle =
    periodType === PERIOD_FOR_CHART.W2 ||
    periodType === PERIOD_FOR_CHART.M6 ||
    periodType === PERIOD_FOR_CHART.M3;
  const isAllLabels = periodType !== PERIOD_FOR_CHART.M6 && windowClientWidth > 500;

  const heartRateLineConfig = [
    {
      name: 'maxHeartRate',
      label: max_heart_rate,
      color: '#F15B07',
      lineType: '',
    },
    {
      name: 'minHeartRate',
      label: min_heart_rate,
      color: '#1C9CE4',
      lineType: '',
    },
    {
      name: 'averageHeartRate',
      label: av_heart_rate,
      color: '#1CBA66',
      lineType: '',
    },
    {
      name: 'restingHeartRate',
      label: rest_heart_rate,
      color: '#23335B',
      lineType: '',
    },
  ];

  // Custom Tooltip
  const renderTooltip = (payload: any): JSX.Element => {
    const pay = payload.payload;
    return <TooltipRecharts payload={pay} type={type} t={t} />;
  };

  // JSX
  const noData = !loading && !chartData?.length && <span className={styles.nodata}>{no_data}</span>;

  return (
    <div
      className={cx({
        [styles['chart-wrapper']]: true,
        'chart-to-PDF': true,
      })}
    >
      {!isOverview && (
        <div>
          <div className={styles['chart-title']}>{heart_rate}</div>
          <div className={styles['chart__exrc-compl-legend--wrapper']}>
            <LegendLineChart config={heartRateLineConfig} />
          </div>
        </div>
      )}

      {isOverview && (
        <div className={styles.chart__flex}>
          <div className={styles['left-section']}>
            <div className={styles['chart-period']}>{chartPeriod}</div>
          </div>
          <div className={styles['right-section']}>
            <div className={styles['chart__legend--wrapper']}>
              <LegendLineChart config={heartRateLineConfig} />
            </div>
          </div>
        </div>
      )}

      <div className={styles['chart-container']}>
        <div className={styles['chart-label']}>{axisName}</div>
        {loading && spinner}
        {noData}
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={chartData}
            margin={{
              top: 10,
              left: -20,
              right: isLabelAngle ? 7 : 0,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              // @ts-ignore
              style={{
                fontSize: isLabelAngle ? '11px' : '14px',
                fill: '#23335B',
              }}
              angle={isLabelAngle ? -45 : 0}
              interval={isAllLabels ? 0 : 'preserveEnd'} // 0 - To show all ticks
            />
            <YAxis
              domain={[0, 120]}
              stroke="#9BA1AE"
              tickLine={{ stroke: '#F0F0F8' }}
              interval={0}
              tickCount={7}
              // @ts-ignore
              style={{
                fontSize: '14px',
                fill: '#23335B',
              }}
            />
            {heartRateLineConfig.map((typeChart: TBar) => (
              <Line
                key={typeChart.name}
                type="monotone"
                dataKey={typeChart.name}
                stroke={typeChart.color}
                strokeDasharray={typeChart.lineType}
                isAnimationActive
              />
            ))}
            {!noData && (
              <Tooltip
                allowEscapeViewBox={{ x: false, y: false }}
                offset={windowClientWidth > 1000 ? 10 : 500}
                content={renderTooltip}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
