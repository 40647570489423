import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { getCurrenLang, getExPerPeriodLoadin } from '../../../redux/selector';
import styles from './styles.module.css';
import { spinner } from '../../../common/Loader';
import { EXERC_SUMMARY_COLORS } from '../../../utils/enums';
import { IndividualExercisesType } from '../../../redux/types';
import IndividualExerciseItem from './IndividualExerciseItem';
import { IndividualExerciseChartProps } from './types';

const IndividualExerciseChart = ({
  exercSummaryData,
  isOverview,
}: IndividualExerciseChartProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const no_data = t?.common.no_data;
  const completed = t?.dashboard.hcp.profile_patient.completion_and_steps.completed;
  const partly_done = t?.dashboard.hcp.profile_patient.completion_and_steps.partly_done;
  const skipped_t = t?.dashboard.hcp.profile_patient.completion_and_steps.skipped || 'Skipped';
  const not_started =
    t?.dashboard.hcp.profile_patient.completion_and_steps.not_started || 'Not started';
  const exercise_summary =
    t?.dashboard.hcp.profile_patient.completion_and_steps.exercise_summary ||
    'Individual Exercise Summary';
  const average_completion =
    t?.dashboard.hcp.profile_patient.completion_and_steps.average_completion ||
    'Average completion ';

  const legendConfig = [
    {
      color: EXERC_SUMMARY_COLORS.COMPLETED,
      label: completed,
    },
    {
      color: EXERC_SUMMARY_COLORS.PARTLY_DONE,
      label: partly_done,
    },
    {
      color: EXERC_SUMMARY_COLORS.SKIPPED,
      label: skipped_t,
    },
    {
      color: EXERC_SUMMARY_COLORS.NOT_STARTED,
      label: not_started,
    },
  ];

  const [exercises, setExercises] = useState<IndividualExercisesType[]>([]);
  const [chartLabels, setChartLabels] = useState<string[]>([]);

  const loading = useSelector<any>((state) => getExPerPeriodLoadin(state));

  useEffect(() => {
    if (exercSummaryData) {
      setChartLabels(exercSummaryData.chartLabels);
      setExercises(exercSummaryData.exercises);
    }
  }, [exercSummaryData]);

  // JSX
  const noData = !exercises.length && !loading && <div className={styles.nodata}>{no_data}</div>;

  return (
    <div className={styles['individual-chart__wrapper']}>
      {loading && <div className={styles['individual-chart__loading-wrapper']}>{spinner}</div>}

      <div
        className={cx({
          [styles['individual-chart__title']]: isOverview,
          [styles['individual-chart__title--remote']]: !isOverview,
        })}
      >
        {exercise_summary}
      </div>
      {noData}
      {!noData && (
        <div className={styles['individual-chart__container']}>
          <div
            className={cx({
              [styles['individual-chart__legend-container']]: isOverview,
              [styles['individual-chart__legend-container--remote']]: !isOverview,
            })}
          >
            {legendConfig.map((legend: any, i: number) => (
              <div
                key={`${legend.label}${String(i)}`}
                className={cx({
                  [styles['individual-chart__legend-item']]: isOverview,
                  [styles['individual-chart__legend-item--remote']]: !isOverview,
                })}
              >
                <div
                  className={styles['individual-chart__marker']}
                  style={{ backgroundColor: legend.color }}
                />
                <div
                  className={cx({
                    [styles['individual-chart__legend-label']]: isOverview,
                    [styles['individual-chart__legend-label--remote']]: !isOverview,
                  })}
                >
                  {legend.label}
                </div>
              </div>
            ))}
          </div>
          {/* Exercise */}
          {!!exercises.length &&
            exercises.map((exercise: IndividualExercisesType, i: number) => (
              <div
                key={`${exercise.name}${String(i)}`}
                className={cx({
                  [styles['individual-chart__exercise-wrapper']]: true,
                  'chart-to-PDF': true,
                })}
              >
                <div className={styles['individual-chart__exercise-name']}>{exercise.name}</div>
                <div className={styles['individual-chart__exercise-descr']}>
                  {`${average_completion} ${exercise.avarage}%`}
                </div>
                <div className={styles['individual-chart__axis-name']}>100%</div>

                <div className={styles['individual-chart__chart-wrapper']}>
                  {!!chartLabels.length &&
                    chartLabels.map((label: string, inx: number) => (
                      <div key={`label${String(inx)}`} className={styles['individual-chart__row']}>
                        <div className={styles['individual-chart__ceil-period']}>{label}</div>
                        <IndividualExerciseItem
                          exercise={{
                            timesPerDay: exercise.timesPerDay,
                            completed: exercise.completed[inx],
                            skipped: exercise.skipped[inx],
                            stopped: exercise.stopped[inx],
                            name: exercise.name,
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default IndividualExerciseChart;
