import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import overviewStyles from '../styles.module.css';
import style from './style.module.css';
import { PatientRehabTeamIcon } from '../../../theme/icons';
import { NotificationCardProps } from '../types';
import {
  getCurrenLang,
  getPatientTeamList,
  getPatientTeamsError,
  getPatientTeamsLoading,
  GetState,
} from '../../../redux/selector';
import { CardTitle, OtherHCP, PatientTeam } from '../Elements';
import useCheckRole from '../../../hooks/useCheckRole';
import { spinner } from '../../../common/Loader';
import {
  useGetHcpsForPatientRehabTeam,
  useGetPatientRehabTeam,
} from '../../../graphql/hospitalTeam';
import { initialPage } from '../../../utils/helper';
import { CurrentHcpType, DataTeamType } from '../../RehabTeams/types';

const RehabTeamCard = ({ patientId, loading }: NotificationCardProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const team_t = t?.common.team;
  const patient_rehab_team = t?.menu.patient_rehab_team;
  const other_hcp = t?.dashboard.hcp.profile_patient.other_hcp;
  const no_data = t?.common.no_data;

  const { isAdmin } = useCheckRole();

  // Endpoint
  const { _getPatientRehabTeam } = useGetPatientRehabTeam();
  const {
    _getHcpsForPatient,
    data,
    hcpsForPatient,
    // hcpsForPatientError,
    hcpsForPatientLoading,
  } = useGetHcpsForPatientRehabTeam();

  // Local state
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [pageSize, setPageSize] = useState<number>(150);
  const [hcpListForPatient, setHcpListForPatient] = useState<CurrentHcpType[]>();

  // Redux
  const dataTeam: any = useSelector<GetState>((state) => getPatientTeamList(state));
  const teamsLoading: any = useSelector<GetState>((state) => getPatientTeamsLoading(state));
  const teamListError: any = useSelector<GetState>((state) => getPatientTeamsError(state));

  useEffect(() => {
    if (teamListError) {
      toast.error(teamListError);
    }
  }, [teamListError]);

  useEffect(() => {
    // get Hospital Teams list
    _getPatientRehabTeam({
      searchProps: {
        searchField: 'name',
        searchValue: '',
      },
      listProps: {
        itemsPerPage: pageSize,
        page: currentPage,
        sortBy: '',
        sortDirection: '',
      },
      patientId,
    });
    // Get Other HCP

    _getHcpsForPatient(patientId, '', 'hcp.firstName', '');
  }, []);

  // Fill data to state
  useEffect(() => {
    if (hcpsForPatient && !hcpsForPatientLoading) {
      setHcpListForPatient(hcpsForPatient);
    }
  }, [data]);

  // JSX
  const loadingJSX = (loading || teamsLoading) && spinner;

  const noTeamData =
    isAdmin ||
    (dataTeam?.length === 0 && !teamsLoading && <div className={style.nodata}>{no_data}</div>);
  const noOtherHcp =
    isAdmin || (hcpListForPatient?.length === 0 && <div className={style.nodata}>{no_data}</div>);

  return (
    <div className={overviewStyles['overview__sm-card-container']}>
      {loadingJSX}
      <CardTitle icon={<PatientRehabTeamIcon />} title={patient_rehab_team} />

      <div className={style['rehab-team__container']}>
        <div className={style['rehab-team__subtitle']}>{team_t}</div>
        {/* List */}
        {dataTeam?.map((team: DataTeamType) => (
          <PatientTeam key={`Team${team.id}`} data={team} patientId={patientId} />
        ))}

        {noTeamData}

        <div className={style['rehab-team__subtitle']}>{other_hcp}</div>
        {/* List */}

        {hcpListForPatient &&
          hcpListForPatient.map((currentHcp: CurrentHcpType) => (
            <OtherHCP key={`HCP${currentHcp.id}`} data={currentHcp} />
          ))}

        {noOtherHcp}
      </div>
    </div>
  );
};

export default RehabTeamCard;
