import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';
import {
  InputFormik,
  InputPhoneNumber,
  Button,
  DropDown,
  SelectFieldWithCutSearch,
  SelectFloatLabelWithSearch,
} from '../../common';
import { ShowErrorNotification } from './ErrorMessage';
import { GetState, getCurrenLang, getUserCountry } from '../../redux/selector';
import { InviteHcpFormProps } from './types';
import { PermissionsCheckboxesJSX } from '../invite/PermissionsCheckboxesJSX';
import useCheckRole from '../../hooks/useCheckRole';
import { InputFloatLabel } from '../../common/Input';
import { InputPhoneNumberFloatLabel } from '../../common/Input/PhoneNumber';
import { SelectFloatLabel } from '../../common/Input/Select';

export const InviteHcpForm = ({
  errorMessage,
  hasErrors,
  inputValue,
  isTouched,
  onChangeMethod,
  isValid,
  setFieldValue,
  options,
  hospitalNameOptions,
  departmentOptions,
  isDirty,
}: InviteHcpFormProps): ReactElement => {
  const userCountry: any = useSelector<GetState>((state) => getUserCountry(state));
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const first_name = t?.invite_hcp.first_name;
  const last_name = t?.invite_hcp.last_name;
  const email = t?.invite_hcp.email;
  const number_phone = t?.profile_hcp.number_phone;
  const profession = t?.invite_hcp.profession;
  const on_send_invite_hcp = t?.common.on_send_invite_hcp;
  const no_options = t?.invite_patient.please_enter_min_3_characters;
  const organisation_name = t?.common.organisation_name;
  const department_t = t?.dashboard.admin.table.hcp.department;

  const { isAdmin } = useCheckRole();

  return (
    <div className={styles.form_wrapper}>
      <div className={styles.row}>
        <div className={styles['cell-50']}>
          <InputFloatLabel
            inputId="firstName"
            inputName="firstName"
            placeholder={first_name}
            inputType="text"
            hasErrors={hasErrors.firstName}
            inputValue={inputValue.firstName}
            isTouched={isTouched.firstName}
            onChangeMethod={onChangeMethod}
            isRedStar
          />
        </div>
        <div className={styles['cell-50']}>
          <InputFloatLabel
            inputId="lastName"
            inputName="lastName"
            placeholder={last_name}
            inputType="text"
            hasErrors={hasErrors.lastName}
            inputValue={inputValue.lastName}
            isTouched={isTouched.lastName}
            onChangeMethod={onChangeMethod}
            isRedStar
          />
        </div>
      </div>
      <InputFloatLabel
        inputId="email"
        inputName="email"
        inputType="text"
        placeholder={email}
        hasErrors={hasErrors.email}
        inputValue={inputValue.email}
        isTouched={isTouched.email}
        onChangeMethod={onChangeMethod}
        isRedStar
      />
      <InputPhoneNumberFloatLabel
        inputId="phoneNumber"
        isValid={isValid}
        hasErrors={hasErrors.phone}
        value={inputValue.phone}
        isTouched={isTouched.phone}
        onChange={(date: any) => setFieldValue('phone', date)}
        placeholder={number_phone}
        userCountry={userCountry}
        isRedStar
      />
      <SelectFloatLabel
        inputId="profession"
        name="profession"
        placeholder={profession}
        onChange={setFieldValue}
        options={options}
        hasErrors={hasErrors.profession}
        isTouched={isTouched.profession}
        type="invite hcp"
        isRedStar
      />
      <SelectFloatLabelWithSearch
        inputId="organisationName"
        name="organisationName"
        placeholder={organisation_name}
        onChange={setFieldValue}
        options={hospitalNameOptions}
        hasErrors={hasErrors.organisationName}
        isTouched={isTouched.organisationName}
        selected={inputValue.organisationName}
        noOptionsMessage={no_options}
        type="invite hcp"
        isRedStar
      />
      <SelectFloatLabel
        inputId="department"
        name="department"
        placeholder={department_t}
        onChange={setFieldValue}
        options={departmentOptions}
        hasErrors={hasErrors.department}
        isTouched={isTouched.department}
        type="invite hcp"
        isRedStar
      />

      {/* Permission checkboxes */}
      <PermissionsCheckboxesJSX
        isAdmin={isAdmin}
        inputValue={inputValue}
        onChangeMethod={onChangeMethod}
      />

      {errorMessage && (
        <div className={styles.errorMessages}>
          <ShowErrorNotification errorMessage={errorMessage} />
        </div>
      )}
      <div className={styles.spacer} />

      <Button
        buttonType="submit"
        buttonName={on_send_invite_hcp}
        disabledButton={!isDirty}
        buttonClass={styles['btn__submit--right-align']}
      />
    </div>
  );
};
