import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import pageStyles from '../../styles.module.css';
import patientStyles from '../style.module.css';
import {
  GetState,
  getCurrenLang,
  getErrorPatientProfile,
  getLoadingPatientProfile,
  getPatientProfile,
} from '../../../redux/selector';
import { Loading, MainTitle } from '../../../common';
import { PatientProfileIcon, RedTickIcon } from '../../../theme/icons';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import { getQueryParams } from '../../../utils/helper';
import { path } from '../../../utils';
import useCheckRole from '../../../hooks/useCheckRole';
import { useQueryPatientProfile } from '../../../graphql/patients';
import { StatusKeyNameEnum } from '../../../utils/enums';
import { PatientProfile } from '../../../components/PatientProfile';
import PatientRehabGoals from '../../../components/PatientProfile/RehabGoals';
import { fillPatientProfile } from '../../../redux/patient';

const ProfilePatientPage = (): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const patient_list = t?.title.patient_list;
  const saved = t?.common.saved;
  const patient_profile = 'Patient Profile'; // t?.dashboard.hcp.profile_patient.patient_profile;
  const patient_rehab_goals =
    t?.dashboard.hcp.profile_patient.patient_rehab_goals || 'Patient Rehab Goals';
  const patient_not_found = t?.common.patient_not_found;

  const location = useLocation();
  const dispatch = useDispatch();
  const { userId, userStatus } = getQueryParams(location.search);
  const userid = Number(userId);
  const patientStatus = String(userStatus);
  const { isAdmin } = useCheckRole();

  // Endpoints
  // Get current patient profile from DB
  const { _getProfileById } = useQueryPatientProfile(patientStatus);
  // const { hospitalNames } = useGetHospitalNames();

  // Redux
  const profileData: any = useSelector<any>((state: GetState) => getPatientProfile(state));
  const profileLoading = useSelector<any>((state: GetState) => getLoadingPatientProfile(state));
  const profileError: any = useSelector<any>((state: GetState) => getErrorPatientProfile(state));

  // Local state
  const [isProfileUpdated, setProfileUpdated] = useState(false);

  // No data available for this user
  const notAllowed = useMemo(() => {
    if (!userStatus || !userid || profileError) {
      return true;
    }
    if (userStatus === StatusKeyNameEnum.invite_sent) {
      return true;
    }
    if (userStatus === StatusKeyNameEnum.pending) {
      return true;
    }
    return false;
  }, [userid, userStatus, profileError, profileData]);

  // Refresh data
  const refreshProfileData = (): void => {
    _getProfileById({
      variables: {
        patientId: userid,
      },
    });
  };

  // Clear state after componentWillUnmount
  useEffect(
    () => (): void => {
      dispatch(fillPatientProfile(null));
    },
    [],
  );

  // Send request to the database
  useEffect(() => {
    if (!notAllowed) {
      _getProfileById({
        variables: {
          patientId: userid,
        },
      });
      //   _getAllGoals();
      //   _getPatientsGoals({
      //     variables: {
      //       patientId: userid,
      //     },
      //   });
    }
  }, [notAllowed, userid]);

  // Show error
  useEffect(() => {
    if (profileError) {
      toast.error(profileError);
    }
  }, [profileError]);

  const loadingJSX = profileLoading && <Loading />;

  // Breadcrumb path
  const routes = useMemo(
    () => [
      {
        path: isAdmin ? path.adminDashboardList : path.dashboardList,
        breadcrumbName: patient_list,
      },
      {
        path: isAdmin
          ? `${path.patient_overview_admin}${location.search}`
          : `${path.patient_overview}${location.search}`,
        breadcrumbName: profileData ? `${profileData.firstName} ${profileData.lastName}` : '-',
      },
      {
        path: patient_profile,
        breadcrumbName: patient_profile,
      },
    ],
    [t, profileData],
  );

  return (
    <div className={pageStyles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={pageStyles['main__flex-wrapper']}>
        {/* Profile form */}
        <div className={pageStyles['left-section']}>
          <div className={pageStyles['profile-user__title-container']}>
            <MainTitle title={patient_profile} icon={<PatientProfileIcon />} />
            {isProfileUpdated && (
              <div className={pageStyles['profile-user__saved-container']}>
                <RedTickIcon />
                <div className={pageStyles['profile-user__saved']}>{saved}</div>
              </div>
            )}
          </div>
          {notAllowed ? (
            <div className={patientStyles['profile-user__notfound']}>{patient_not_found}</div>
          ) : (
            <></>
          )}
          {profileData && !notAllowed && (
            <PatientProfile
              patient={profileData}
              refreshProfileData={refreshProfileData}
              // hospitalNames={hospitalNames}
              isProfileUpdated={isProfileUpdated}
              setProfileUpdated={setProfileUpdated}
            />
          )}
        </div>

        {/* Patient Rehab Goals */}
        <div className={pageStyles['right-section']}>
          <MainTitle title={patient_rehab_goals} />
          <PatientRehabGoals notAllowed={notAllowed} userId={userid} />
        </div>
      </div>

      {/* Popups */}
    </div>
  );
};

export default ProfilePatientPage;
