/* eslint-disable indent */
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { toast } from 'react-toastify';
import cx from 'classnames';
import { useFormik } from 'formik';
import moment from 'moment';

import styles from '../styles.module.css';
import { Button, Loading } from '../../../../common';
import { GetState, getCurrenLang, getCurrentFormatDate } from '../../../../redux/selector';
import {
  EXERCISE_STATUS_COLOR,
  EXERCISE_STATUSES,
  PERMISSIONS,
  VIDEO_BANK_TYPE,
} from '../../../../utils/enums';
import { useSaveAndSandFolderToPatint, useSaveFolderSettings } from '../../../../graphql/videoBank';
import useCheckPermissions from '../../../../hooks/useCheckPermissions';
import {
  validationAddExercToNewFolder,
  validationScheduleFolder,
} from '../../../../utils/validators';
import { ClockIcon, FolderIcon } from '../../../../theme/icons';
import { getScheduleNumberOptions, getUnitOptions } from '../../../../utils/share/options';
import FolderDetails from '../../OrganisationVideoBank/components/ExerciseFolderSettings/FolderDetails';
import ScheduleFolder from '../../OrganisationVideoBank/components/ScheduleFolder';
import { ShowErrorNotification } from '../../../Form/ErrorMessage';
import { WarnNotifModal } from '../../../../common/NotificationModal';
import MediaExercise from '../MediaExercise';

const FolderExercises = ({
  data,
  exercisesCategories,
  exercisesProcedureFocus,
  focusType1Arr,
  focusType2Arr,
  updateList,
  isInvitee,
  patientId,
  index,
}: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const cancel = t?.common.cancel;
  const settings_t = t?.dashboard.hcp.profile_patient.video_bank.settings;
  const status_active = t?.common.status_active;
  const status_inactive = t?.common.status_inactive;
  const save_t = t?.common.save;
  const save_and_send = t?.dashboard.hcp.profile_patient.video_bank.save_and_send;
  const folder_success_saved = t?.dashboard.hcp.profile_patient.exercise_folder_saved;
  const save_and_send_anyway = t?.dashboard.hcp.profile_patient.video_bank.save_and_send_anyway;
  const you_dont_have_permission = t?.common.you_dont_have_permission;
  const archived_exerc_in_folder_attention = t?.notifications.archived_exerc_in_folder_attention;
  const close_settings =
    t?.dashboard.hcp.profile_patient.video_bank.close_settings ||
    'Please close the exercise settings';
  const prescribed_on =
    t?.dashboard.hcp.profile_patient.video_bank.prescribed_on || 'Prescribed on';

  // Endpoints
  //  Save folder settings
  const {
    _saveFolderSettings,
    errorSaveFolderSettings,
    loadingSaveFolderSettings,
    folderSettingsSaved,
  } = useSaveFolderSettings();

  // save and send folder with exercises to patient by HCP
  const {
    _saveAndSendFolder,
    errorSaveAndSendFolder,
    loadingSaveAndSendFolder,
    folderSavedAndSent,
  } = useSaveAndSandFolderToPatint(isInvitee);

  // Local state
  const [isSettingsOpen, setOpenSettings] = useState(false);
  const [isOpenScheduleForm, setOpenScheduleForm] = useState(false);
  const [isWarningArchiveExerc, setWarningArchiveExerc] = useState(false);
  const [openNestedExerciseIds, setOpenNestedExerciseIds] = useState([]);
  const [isThereAtLeastOneArchivedExercise, setThereAtLeastOneArchivedExercise] = useState(false);

  const formatsDate = useSelector((state: GetState) => getCurrentFormatDate(state));
  const exerciseCreatedAt = data.folderExercises[0]?.exerciseCreatedAt;
  const isPermissionEditVideoExerc = useCheckPermissions(
    PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_OTM,
  );
  const unitOptions = t ? getUnitOptions(t.dashboard.hcp.profile_patient.completion_and_steps) : [];
  const numberOptions = getScheduleNumberOptions();

  const folderId = data.currentId;
  const initialValues = useMemo(
    () => ({
      name: data.name,
      description: data.description || '',
      type: '',
      amount: data.hcpFolderTimer?.amount ? numberOptions[data.hcpFolderTimer.amount - 1] : null,
      timePeriod: data.hcpFolderTimer?.timePeriod
        ? unitOptions[data.hcpFolderTimer.timePeriod - 1]
        : null,
    }),
    [data],
  );

  const formik = useFormik({
    initialValues,
    validate: (values) => validationScheduleFolder(values, t),
    validationSchema: () => validationAddExercToNewFolder(t),
    onSubmit: async (values: any) => {
      // Only save changes (without send to patient)
      if (values.type === save_t) {
        _saveFolderSettings({
          variables: {
            hcpFolderSettings: {
              id: folderId,
              name: values.name,
              description: values.description,
              amount: values.amount?.value || null,
              timePeriod: values.timePeriod?.value || null,
            },
          },
        });
        return;
      }

      // Save and send to patient all exercises
      if (values.type === save_and_send) {
        if (isThereAtLeastOneArchivedExercise && !isWarningArchiveExerc) {
          setWarningArchiveExerc(() => true);
          return;
        }
        const response: any = {
          variables: {
            hcpFolderData: {
              id: folderId,
              name: values.name,
              description: values.description,
              amount: values.amount?.value || null,
              timePeriod: values.timePeriod?.value || null,
            },
          },
        };
        if (isInvitee) {
          response.variables.inviteePatientId = patientId;
        } else {
          response.variables.patientId = patientId;
        }
        _saveAndSendFolder(response);
      }
    },
  });
  const { values, touched, dirty } = formik;

  const _onSubmit = (requestType: string): void => {
    formik.setFieldValue('type', requestType);
    formik.handleSubmit();
  };

  // if the folder is saved successfully we show a success message
  useEffect(() => {
    if (folderSettingsSaved) {
      toast.info(folder_success_saved);
      setOpenScheduleForm(() => false);
      setOpenSettings(() => false);
      updateList();
    }
  }, [folderSettingsSaved]);

  // if the folder is saved and sent successfully we show a success message
  useEffect(() => {
    if (folderSavedAndSent) {
      toast.info(folder_success_saved);
      updateList();
      setWarningArchiveExerc(() => false);
      setThereAtLeastOneArchivedExercise(() => false);
      setOpenScheduleForm(() => false);
      setOpenSettings(() => false);
    }
  }, [folderSavedAndSent]);

  // Check archived exercises and required fields for nested exercises
  useEffect(() => {
    if (isSettingsOpen) {
      // const notCompletedExerciseIds: any = [];
      let counterArchivedExercises = 0;
      data.folderExercises.map((videoExerciseItem: any) => {
        // if (
        //   !videoExerciseItem.name ||
        //   !videoExerciseItem.exerciseTimesPerDay ||
        //   !(videoExerciseItem.exerciseCategories.length > 0)
        // ) {
        //   notCompletedExerciseIds.push(videoExerciseItem.currentId);
        // }
        if (videoExerciseItem.status === 'Archived') {
          counterArchivedExercises += 1;
        }
        return null;
      });
      if (counterArchivedExercises > 0) {
        setThereAtLeastOneArchivedExercise(() => true);
      } else {
        setThereAtLeastOneArchivedExercise(() => false);
      }
      // setNotCompletedExercises([...notCompletedExercises, ...notCompletedExerciseIds]);
      //   setNotCompletedExercises([...notCompletedExerciseIds]);
      //   if (!activeFolderIds.includes(data.currentId)) {
      //     setActiveFolderIds([...activeFolderIds, dataFolder.currentId]);
      //   }
      // } else {
      //   const newactiveFolderIds = [...activeFolderIds];
      //   const pos = newactiveFolderIds.indexOf(data.currentId);
      //   if (pos >= 0) {
      //     newactiveFolderIds.splice(pos, 1);
      //     setNotCompletedExercises(newactiveFolderIds);
      //   }
      //   setActiveFolderIds(newactiveFolderIds);
    }
  }, [isSettingsOpen, data]);

  // Open settings
  const actionBtnHandler = (): null | void => {
    return setOpenSettings(() => !isSettingsOpen);
  };

  const { title: folderStatusTitle, color: folderStatusColor } = useMemo(() => {
    const folderStatus = {
      title: status_inactive,
      color: EXERCISE_STATUS_COLOR.inactive,
    };
    const exercises = data.folderExercises;
    exercises.map((exercise: any) => {
      if (exercise.status === EXERCISE_STATUSES.active) {
        folderStatus.title = status_active;
        folderStatus.color = EXERCISE_STATUS_COLOR.active;
      }
    });
    return folderStatus;
  }, [data]);

  // Close open modal windows
  const onCloseModal = (): void => {
    setWarningArchiveExerc(() => false);
  };

  // JSX
  const loadingJSX = (loadingSaveFolderSettings || loadingSaveAndSendFolder) && <Loading />;
  const warnContant = (
    <div className={styles['video-exercise__archive-warn']}>
      {archived_exerc_in_folder_attention}
    </div>
  );
  const actionJsx = (
    <>
      <div className={styles['video-exercise__status']} style={{ color: folderStatusColor }}>
        {folderStatusTitle}
      </div>

      <Tooltip title={!isPermissionEditVideoExerc ? you_dont_have_permission : ''}>
        <div
          className={cx({
            [styles['video-exercise__btn--settings']]: true,
            [styles['video-exercise__btn--settings-open']]: isSettingsOpen,
            // [styles['video-exercise__btn--disabled']]: !isPermissionEditVideoExerc,
          })}
          aria-hidden
          onClick={actionBtnHandler}
        >
          {settings_t}
        </div>
      </Tooltip>
    </>
  );

  return (
    <div>
      <div
        className={cx({
          [styles['video-exercise__row']]: true,
        })}
      >
        {loadingJSX}

        {/* Thumbnail */}
        <div className={styles['video-exercise__block']}>
          <div className={styles.preview}>
            <FolderIcon />
            {data.hcpFolderTimer?.amount && (
              <div className={styles['video-folder__clock']}>
                <ClockIcon />
              </div>
            )}
          </div>
        </div>

        {/* Name */}
        <div className={styles['video-exercise__name-container']}>
          <div className={styles['video-exercise__name']}>{values.name}</div>
          <div className={styles['video-exercise__date']}>
            {`${prescribed_on} ${moment(exerciseCreatedAt).format(formatsDate.momentFormat)}`}
          </div>
          <div className={styles['video-exercise__action-container--sm']}>{actionJsx}</div>
        </div>

        {/* Action */}
        <div className={styles['video-exercise__action-container']}>{actionJsx}</div>
      </div>

      {/* Settings */}
      {isSettingsOpen && (
        <form onSubmit={formik.handleSubmit}>
          <div className={styles['margin-bottom']}>
            <FolderDetails
              dataFolder={data}
              unitOptions={unitOptions}
              numberOptions={numberOptions}
              formik={formik}
              loadingJSX={loadingJSX}
              isOpenScheduleForm={isOpenScheduleForm}
              setOpenScheduleForm={setOpenScheduleForm}
            />
          </div>
          {isOpenScheduleForm && (
            <div className={styles['margin-bottom']}>
              <ScheduleFolder
                t={t}
                formik={formik}
                unitOptions={unitOptions}
                numberOptions={numberOptions}
              />
            </div>
          )}

          {/* Nested Exercises */}
          <div className={styles['video-exercise__nested-exerc-container']}>
            {data.folderExercises.map((videoExerciseItem: any, i: number) => {
              const keyObj = `${videoExerciseItem.currentId}${videoExerciseItem.type}${i}${videoExerciseItem.status}`;
              return (
                <MediaExercise
                  key={`${keyObj}_${String(index)}_${String(i)}`}
                  data={videoExerciseItem}
                  exercisesCategories={exercisesCategories}
                  exercisesProcedureFocus={exercisesProcedureFocus}
                  focusType1Arr={focusType1Arr}
                  focusType2Arr={focusType2Arr}
                  updateList={updateList}
                  isInvitee={isInvitee}
                  patientId={patientId}
                  type={VIDEO_BANK_TYPE.NESTED}
                  folderId={folderId}
                  openNestedExerciseIds={openNestedExerciseIds}
                  setOpenNestedExerciseIds={setOpenNestedExerciseIds}
                />
              );
            })}
          </div>

          {/* Errors */}
          {(errorSaveFolderSettings || errorSaveAndSendFolder) && touched && (
            <div className={styles.errorMessages}>
              <ShowErrorNotification errorMessage={errorSaveFolderSettings} />
              <ShowErrorNotification errorMessage={errorSaveAndSendFolder} />
            </div>
          )}

          <div className={styles['margin-bottom']} />
          <div className={styles['add-video__btns-container']}>
            <Tooltip title={openNestedExerciseIds.length ? close_settings : ''}>
              <div>
                <Button
                  buttonClass={styles['add-video__btn']}
                  buttonType="button"
                  buttonName={save_t}
                  buttonMethod={(): void => _onSubmit(save_t)}
                  disabledButton={
                    !isPermissionEditVideoExerc ||
                    !!loadingJSX ||
                    !dirty ||
                    !!openNestedExerciseIds.length
                  }
                />
              </div>
            </Tooltip>
            <Tooltip title={openNestedExerciseIds.length ? close_settings : ''}>
              <div>
                <Button
                  buttonClass={styles['add-video__btn']}
                  buttonType="button"
                  buttonName={save_and_send}
                  buttonMethod={(): void => _onSubmit(save_and_send)}
                  disabledButton={
                    !isPermissionEditVideoExerc || !!loadingJSX || !!openNestedExerciseIds.length
                  }
                />
              </div>
            </Tooltip>
          </div>
        </form>
      )}

      {/* Popups */}
      {/* If at least one exercise has a status of "Archive" - Warning before saving */}
      {isWarningArchiveExerc && (
        <WarnNotifModal
          onClose={onCloseModal}
          content={warnContant}
          cancelBtnName={cancel}
          actionBtnName={save_and_send_anyway}
          actionMethod={formik.handleSubmit}
        />
      )}
    </div>
  );
};

export default FolderExercises;
