import React, { Fragment, ReactElement } from 'react';
import { Pagination } from 'antd';
import { useSelector } from 'react-redux';

import style from './styles.module.css';
import { DataTeamType } from '../types';
import { GetState, getCurrenLang, getTeamSettingsData } from '../../../redux/selector';
import { SortSearchItem } from '../../Video/OrganisationVideoBank/components/HeaderItems';
import { PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE } from '../../../utils/variables';
import HcpTeam from '../HcpTeam';
import TeamSettings from '../TeamSettings';

const HcpTeamTable = ({
  dataTeam,
  sortByColumnName,
  sortBy,
  handleSearch,
  handleReset,
  searchMatches,
  findMatches,
  hideSearchMenu,
  isSearch,
  setHideSearchMenu,
  setSearchMatches,
  noData,
  pageFromQuery,
  pageSize,
  itemsTotal,
  onChangePagination,
  isPermissionCreateTeam,
  withPagination = true,
  updateTeamList,
}: any): ReactElement => {
  const t: any = useSelector<GetState>((state) => getCurrenLang(state));
  const team_t = t?.common.team;
  const hcp_t = t?.common.hcp_t;
  const patient_t = t?.dashboard.hcp.profile_patient.questionnaires.patient;

  const isOpenSettings: any = useSelector<any>((state) => getTeamSettingsData(state));

  return (
    <div className={style['rehab-teams__container']}>
      {/* Header */}
      <div className={style['rehab-teams__header']}>
        <div className={style['rehab_teams__header--team']}>
          <SortSearchItem
            fieldName={team_t}
            t={t}
            sortName="name"
            sortByColumnName={sortByColumnName}
            sortBy={sortBy}
            withSearch
            handleSearch={handleSearch}
            handleReset={handleReset}
            searchMatches={searchMatches}
            findMatches={findMatches}
            hideSearchMenu={hideSearchMenu}
            isSearch={isSearch}
            setHideSearchMenu={setHideSearchMenu}
            setSearchMatches={setSearchMatches}
          />
        </div>
        <div className={style['rehab_teams__header--hcp']}>{hcp_t}</div>
        <div className={style['rehab_teams__header--patient']}>{patient_t}</div>
        {/* Sm screen */}
        <div className={style['rehab_teams__header--sm-screen']}>
          <div className={style['rehab_teams__header--hcp-sm']}>{hcp_t}</div>
          <div className={style['rehab_teams__header--patient-sm']}>{patient_t}</div>
        </div>
      </div>

      {/* Teams */}
      {!noData && (
        <div className={style['rehab_teams__table-wrapper']}>
          {dataTeam?.map((team: DataTeamType) => (
            <Fragment key={`Team${team.id}`}>
              <HcpTeam data={team} isPermissionCreateTeam={isPermissionCreateTeam} />
              {/* Settings sm screen */}
              <div className={style['rehab-teams__settings--sm']}>
                {isOpenSettings?.id === team.id && (
                  <TeamSettings
                    key={isOpenSettings.id}
                    isPermissionCreateTeam={isPermissionCreateTeam}
                    updateTeamList={updateTeamList}
                  />
                )}
              </div>
            </Fragment>
          ))}

          {withPagination && !!dataTeam?.length && (
            <div className="wrapper_pagination">
              <Pagination
                size="small"
                pageSizeOptions={PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE}
                current={pageFromQuery}
                pageSize={pageSize}
                total={itemsTotal}
                onChange={onChangePagination}
                showSizeChanger
              />
            </div>
          )}
        </div>
      )}
      {noData}
    </div>
  );
};

export default HcpTeamTable;
