import React, { ReactElement } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';

import { PublicRouteProps } from './types';

const PublicRoute = ({
  component: Component,
  path: routePath,
  exact,
  isAuthenticated,
  successPath,
  ...rest
}: PublicRouteProps): ReactElement => {
  const location = useLocation();
  const history = useHistory();
  console.log('***PublicRoute_routePath=', routePath);
  console.log('***PublicRoute_location=', location);
  console.log('***PublicRoute_isAuthenticated=', isAuthenticated);
  // if (isAuthenticated && location.pathname.includes('/signin')) {
  //   history.push(successPath);
  // }

  return (
    <Route
      {...rest}
      exact={exact}
      path={routePath}
      component={(props: any): JSX.Element => <Component {...props} />}
    />
  );
};

export default PublicRoute;
