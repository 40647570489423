import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import { Pagination } from 'antd';
import { toast } from 'react-toastify';

import pageStyles from '../../styles.module.css';
import styles from './style.module.css';
import patientStyles from '../style.module.css';
import {
  GetState,
  getCurrenLang,
  getErrorPatientProfile,
  getLoadingPatientProfile,
  getPatientProfile,
} from '../../../redux/selector';
import { Loading, MainTitle } from '../../../common';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import { getQueryParams, initialPage } from '../../../utils/helper';
import { path } from '../../../utils';
import useCheckRole from '../../../hooks/useCheckRole';
import { useQueryPatientProfile } from '../../../graphql/patients';
import { StatusKeyNameEnum } from '../../../utils/enums';
import { useQueryGetPatientDocument } from '../../../graphql/attachments';
import { TSortdirectionProps } from '../../../utils/model';
import WoundImagesTableHeader from '../../../components/WoundImages/TableHeader';
import { PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE } from '../../../utils/variables';
import WoundImagesItem from '../../../components/WoundImages/WoundImagesItem';
import { WoundImageType } from './types';
import { WoundImagesIcon } from '../../../theme/icons';
import { fillPatientProfile } from '../../../redux/patient';

const WoundImagesPage = (): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const patient_list = t?.title.patient_list;
  const no_data = t?.common.no_data;
  const wound_images = t?.menu.wound_images;
  const patient_not_found = t?.common.patient_not_found;

  const location = useLocation();
  const dispatch = useDispatch();
  const { userId, userStatus } = getQueryParams(location.search);
  const userid = Number(userId);
  const patientStatus = String(userStatus);
  const { isAdmin } = useCheckRole();

  // Endpoints
  // Get current patient profile from DB
  const { _getProfileById } = useQueryPatientProfile(patientStatus);
  // Get patients documents from DB
  const {
    _getPatientDoc,
    loadingDoc,
    errorDoc,
    patientDocuments,
    data,
  } = useQueryGetPatientDocument();

  // Local state
  const [patientsMedia, setPatientsMedia] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [pageSize, setPageSize] = useState<number>(15);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [sortBy, setSortby] = useState<string>('documentDate');
  const [sortDirectionName, setSortDirectionName] = useState<string>('');

  // Redux
  const profileData: any = useSelector<any>((state: GetState) => getPatientProfile(state));
  const profileLoading = useSelector<any>((state: GetState) => getLoadingPatientProfile(state));
  const profileError: any = useSelector<any>((state: GetState) => getErrorPatientProfile(state));

  // Local state

  // No data available for this user
  const notAllowed = useMemo(() => {
    if (!userStatus || !userid || profileError) {
      return true;
    }
    if (userStatus === StatusKeyNameEnum.invite_sent) {
      return true;
    }
    if (userStatus === StatusKeyNameEnum.pending) {
      return true;
    }
    return false;
  }, [userid, userStatus, profileError]);

  const getPatientDocuments = (
    searchValue: any,
    filterMedia: any,
    itemsPerPage: number,
    page: number,
    sortDirection: string,
  ): void => {
    _getPatientDoc({
      variables: {
        patientId: userid,
        searchProps: { searchField: 'documentDate', searchValue },
        filterMedia,
        listProps: {
          itemsPerPage,
          page,
          sortBy,
          sortDirection,
        },
      },
    });
  };

  // After receiving the list of patients info from the database, display them on the UI
  useEffect(() => {
    if (patientDocuments && !loadingDoc) {
      setPatientsMedia(patientDocuments.list);
      setTotalItems(patientDocuments.totalItems);
    } else if (!loadingDoc) {
      setPatientsMedia(null);
      setTotalItems(0);
    }
  }, [data]);

  // Show message if loading documents from patients is error
  useEffect(() => {
    if (errorDoc) {
      toast.error(errorDoc);
    }
  }, [errorDoc]);

  // Send request to the database
  useEffect(() => {
    if (!notAllowed) {
      _getProfileById({
        variables: {
          patientId: userid,
        },
      });
      const searchByDate = '';
      const mediaFilter: any = [];
      getPatientDocuments(searchByDate, mediaFilter, pageSize, currentPage, sortDirectionName);
    }
  }, [notAllowed, userid]);

  // Sort exercise list by Column Name
  const sortByColumnName = (name: string, sortdirection: string): void => {
    setSortby(name);
    setSortDirectionName(sortdirection);
    const listProps: TSortdirectionProps = {
      page: currentPage,
      itemsPerPage: pageSize,
    };
    if (sortdirection) {
      listProps.sortBy = name;
      listProps.sortDirection = sortdirection;
    }
    const searchByDate = '';
    const mediaFilter: any = [];
    getPatientDocuments(searchByDate, mediaFilter, pageSize, currentPage, sortdirection);
  };

  // Go to the corresponding page
  const onChangePagination = (page: any, itemsPerPage: any): void => {
    setPageSize(itemsPerPage);
    setCurrentPage(page);
    const searchByDate = '';
    const mediaFilter: any = [];
    getPatientDocuments(searchByDate, mediaFilter, itemsPerPage, page, sortDirectionName);
  };

  // Clear state after componentWillUnmount
  useEffect(
    () => (): void => {
      dispatch(fillPatientProfile(null));
    },
    [],
  );

  // JSX
  const loadingJSX = profileLoading && <Loading />;
  const noData = patientsMedia?.length === 0 && !loadingDoc && (
    <div className={styles.noData}>{no_data}</div>
  );

  // Breadcrumb path
  const routes = useMemo(
    () => [
      {
        path: isAdmin ? path.adminDashboardList : path.dashboardList,
        breadcrumbName: patient_list,
      },
      {
        path: isAdmin
          ? `${path.patient_overview_admin}${location.search}`
          : `${path.patient_overview}${location.search}`,
        breadcrumbName: profileData ? `${profileData.firstName} ${profileData.lastName}` : '-',
      },
      {
        path: wound_images,
        breadcrumbName: wound_images,
      },
    ],
    [t, profileData],
  );

  return (
    <div className={pageStyles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={cx([pageStyles.main__wrapper, pageStyles['main__wrapper--without-pd']])}>
        <div className={pageStyles['main__wrapper--pd']}>
          <MainTitle title={wound_images} icon={<WoundImagesIcon />} />
        </div>

        {notAllowed ? (
          <div className={patientStyles['profile-user__notfound']}>{patient_not_found}</div>
        ) : (
          <>
            {/* Table Header */}
            <WoundImagesTableHeader sortByColumnName={sortByColumnName} sortBy={sortBy} />

            {/* Table */}
            {patientsMedia?.map((fromPatientItem: WoundImageType, index: number) => (
              <WoundImagesItem
                key={`${fromPatientItem.name}${String(index)}`}
                data={fromPatientItem}
              />
            ))}
            {noData}
            {!noData && (
              <div className="wrapper_pagination">
                <Pagination
                  size="small"
                  current={currentPage}
                  pageSize={pageSize}
                  total={totalItems}
                  onChange={onChangePagination}
                  pageSizeOptions={PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE}
                  showSizeChanger
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default WoundImagesPage;
