import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import moment from 'moment';
import { Popover } from 'antd';
import cx from 'classnames';
import {
  showTheStatus,
  calcLastActivity,
  getFilterOptions,
  capitalizeFirstLetter,
  getInsurancePolicyLabel,
  hexToRgbA,
  getProcedureFilterOptions,
  getTooltipText,
} from '../../../utils/helper';
import styles from '../styles.module.css';
import { Hospital, Profession, Records, RewardsModalState, TSortState } from '../types';
import { CloseIcon, TeamIcon, LockIcon, FilterIcon } from '../../../theme/icons';
import { mobileFormat } from '../../../utils/variables';
import UserStatus from './UserStatus';
import { CommonButton } from '../../../common/Button/Button';
import { RewardsSwitch } from '../../../common/Switch/Switch';
import { LIST_TYPE, REWARDS_OPTIONS, StatusKeyNameEnum } from '../../../utils/enums';
import { CurrentFormatDates } from '../../../redux/common';
import FilterJsx from './FilterJsx';

export const getColumns = (
  t: { [a: string]: any },
  getColumnSearchProps: (a: string, b: string) => object,
  _onInviteResendInvite: (obj: Records) => void,
  _resendCode: (obj: Records) => void,
  _onAccept: (obj: Records) => void,
  _showProfile: (obj: Records, mode: LIST_TYPE) => void,
  _onDeactivate: (obj: Records) => void,
  currentTab: string,
  mode: LIST_TYPE,
  sortedInfo: TSortState,
  filteredInfo: any,
  windowclientWidth: number,
  refreshTable: () => void,
  openListHcp: (record: Records) => void,
  setTurnOnRewardsModal: Dispatch<SetStateAction<RewardsModalState>>,
  setIsWarningDeactivedRewards: Dispatch<SetStateAction<RewardsModalState>>,
  isAdmin: boolean,
  setShowPopup: React.Dispatch<
    React.SetStateAction<{
      isShow: boolean;
      content: string;
    }>
  >,
  formatsDate: CurrentFormatDates,
  userCountry: string,
  isPermissionEditProfile: boolean,
  currentRewardsOption: number,
  _redirectToMyTeam: () => void,
  _onLockPatientAccount: (obj: Records) => void,
): any => {
  const view_t = t?.dashboard.hcp.table.view;
  const details_t = t?.dashboard.hcp.table.details;
  const deactivate_t = t?.common.deactivate;
  const resend_code_t = t?.dashboard.hcp.table.resend_code;
  const resend_invite_t = t?.dashboard.hcp.table.resend_invite;
  const connect_with_patient = t?.dashboard.hcp.table.to_view_details_connect_with_patient;
  const dob_t = /* t?.dashboard.hcp.profile_patient.information.label_dob || */ 'Date of Birth';
  const phone_number = t?.dashboard.hcp.table.phone_number || 'Phone Number';
  const first_name = t?.dashboard.hcp.profile_patient.information.first_name;
  const last_name = t?.dashboard.hcp.profile_patient.information.last_name;
  const surgery_date = /* t?.dashboard.hcp.table.surgery_date */ 'Surgery Date';
  const org_name = t?.dashboard.hcp.table.org_name || 'Organisation Name';
  const last_used_app = /* t?.dashboard.hcp.table.last_activity */ 'Last Used App';

  const isView = windowclientWidth > mobileFormat ? ['xs'] : ['xs', 'sm'];
  // const firstNameSearch =
  //   windowclientWidth > mobileFormat
  //     ? { ...getColumnSearchProps(`${mode}.firstName`, `${first_name}`) }
  //     : {};
  // const lastNameSearch =
  //   windowclientWidth > mobileFormat
  //     ? { ...getColumnSearchProps(`${mode}.lastName`, `${last_name}`) }
  //     : {};
  const columnsConfig = [
    {
      tab: ['admin-patient'],
      title: (
        <span
          className={cx({
            [styles.header_table]: true,
            [styles.center]: true,
          })}
        >
          {t && 'HCP`s'}
        </span>
      ),
      width: '70px',
      minHeight: '61px',
      dataIndex: 'hcpList',
      key: 'hcpList',
      name: 'hcpList',
      render: (text: any, record: Records): ReactElement => (
        <Popover
          content={`${t?.dashboard.hcp.table.view_hcp_list_who_related} ${record.firstName}  ${record.lastName}`}
        >
          <CommonButton
            className={cx({
              [styles['btn__view-list']]: true,
              [styles.center]: true,
              [styles.disabled]: false,
            })}
            disabled={false}
            onClick={(): void => openListHcp(record)}
          >
            {view_t}
          </CommonButton>
        </Popover>
      ),
      responsive: ['lg'],
    },
    {
      tab: ['hcp', 'patient', 'admin-hcp'],
      title: <span className={styles.header_table}>{t?.dashboard.hcp.table.status}</span>,
      key: `status${mode}`,
      name: 'status',
      dataIndex: 'status',
      render: (status: string, record: any): ReactElement => {
        const statusKey = status;
        const [title, hex] = showTheStatus(statusKey, t);
        return mode === 'patient' ? (
          <UserStatus
            mode={mode}
            record={record}
            refresh={refreshTable}
            isResponsive={false}
            disabledProp={!isPermissionEditProfile}
            t={t}
          />
        ) : (
          <span style={{ color: hex }} className={styles.colorStatus}>
            {title}
          </span>
        );
      },
      width: mode === LIST_TYPE.PATIENT ? '225px' : '130px',
      minHeight: '61px',
      filters: t && getFilterOptions(t, mode),
      filterMultiple: false,
      filteredValue: (filteredInfo && filteredInfo[`status${mode}`]) || null,
      filterIcon: (filtered: any): JSX.Element => <FilterJsx filtered={filtered} />,
      sorter: true,
      sortOrder: sortedInfo && sortedInfo.columnKey === `status${mode}` && sortedInfo.order,
      responsive: ['lg'],
    },
    {
      tab: ['patient'],
      title: (
        <span className={cx({ [styles.header_table]: true, [styles.center]: true })}>
          {t?.dashboard.hcp.table.action}
        </span>
      ),
      key: 'resendCode',
      width: '150px',
      name: 'resendCode',
      minHeight: '61px',
      className: 'ant-column-resend-code',
      render: (text: any, record: Records): ReactElement => {
        const statusKey = record?.status?.toLowerCase();
        const toolTip = `${resend_code_t} ${record?.firstName} ${record?.lastName}`;
        return (
          <div className={styles.center}>
            {statusKey === StatusKeyNameEnum.connected ||
            statusKey === StatusKeyNameEnum.pending ? (
              <Popover content={toolTip}>
                <span
                  className={cx([styles.btn__resend, styles['btn__resend--blue']])}
                  onClick={(): void => _resendCode(record)}
                  aria-hidden
                >
                  {resend_code_t}
                </span>
              </Popover>
            ) : (
              <>-</>
            )}
          </div>
        );
      },
      responsive: ['lg'],
    },
    {
      tab: ['hcp', 'patient', 'admin-hcp', 'admin-patient'],
      title: (
        <span className={cx({ [styles.header_table]: true, [styles.center]: true })}>{view_t}</span>
      ),
      key: 'action',
      width: '95px',
      // width: windowclientWidth > mobileFormat ? '140px' : '124px',
      ellipsis: true,
      name: 'action',
      minHeight: '61px',
      render: (text: any, record: Records): ReactElement => {
        const statusKey = record && record.status && record.status.toLowerCase();
        return (
          <div className={styles.center}>
            {(statusKey === StatusKeyNameEnum.active ||
              statusKey === StatusKeyNameEnum.connected ||
              statusKey === StatusKeyNameEnum.inactive ||
              (statusKey === StatusKeyNameEnum.pending && !isAdmin) ||
              (statusKey === StatusKeyNameEnum.invite_sent && !isAdmin)) && (
              <span
                className={styles.link}
                onClick={(): void => _showProfile(record, mode)}
                aria-hidden
              >
                <Popover
                  content={`${t && t.dashboard.hcp.table.view_details} ${record?.firstName} ${
                    record?.lastName
                  }`}
                >
                  {details_t}
                </Popover>
              </span>
            )}
            {/* {statusKey === StatusKeyNameEnum.invited && (
              <span
                className={cx([styles.orange, styles.btn, styles['btn-resend']])}
                onClick={(): void => _onInviteResendInvite(record)}
                aria-hidden
              >
                <Popover
                  content={`${resend_invite_t} ${record?.firstName}  ${
                    record?.lastName
                  }`}
                >
                  {resend_invite_t}
                </Popover>
              </span>
            )} */}
            {/* {record &&
              record.accesses &&
              record.accesses[0] &&
              record.accesses[0].status &&
              record.accesses[0].status === StatusKeyNameEnum.disable && (
                <span
                  className={cx([styles.green, styles.btn, styles['btn-join']])}
                  onClick={(): void => _onAccept(record)}
                  aria-hidden
                >
                  {`${t && t.dashboard.hcp.table.accept_join}`}
                </span>
              )} */}
            {statusKey === StatusKeyNameEnum.expired && (
              <span
                className={cx([styles.orange, styles.btn__resend, styles['btn__resend--orange']])}
                onClick={(): void => _onInviteResendInvite(record)}
                aria-hidden
              >
                <Popover content={`${resend_invite_t} ${record?.firstName}  ${record?.lastName}`}>
                  {resend_invite_t}
                </Popover>
              </span>
            )}
            {(statusKey === StatusKeyNameEnum.disconnected ||
              statusKey === StatusKeyNameEnum.treatment_completed ||
              statusKey === StatusKeyNameEnum.declined) && (
              <span
                className={cx({
                  [styles.link]: true,
                  [styles.disable]: true,
                })}
              >
                <Popover
                  content={`${connect_with_patient} ${record?.firstName}  ${record?.lastName}`}
                >
                  {details_t}
                </Popover>
              </span>
            )}
          </div>
        );
      },
      responsive: ['lg'],
    },
    {
      tab: ['admin-hcp', 'admin-patient'],
      title: (
        <span
          className={cx({
            [styles.header_table]: true,
            [styles.header_deactivate_table]: true,
            [styles.center]: true,
          })}
        >
          {deactivate_t}
        </span>
      ),
      width: '100px',
      dataIndex: 'deactivate',
      key: 'deactivate',
      name: 'deactivate',
      minHeight: '61px',
      render: (text: any, record: Records): ReactElement => {
        const statusKey = record?.status?.toLowerCase();
        return (
          <div className={styles.center}>
            {statusKey !== StatusKeyNameEnum.invite_sent ? (
              <span
                className={styles.button_deactivate}
                onClick={(): void => _onDeactivate(record)}
                aria-hidden
              >
                <Popover content={`${deactivate_t} ${record.firstName}  ${record.lastName}`}>
                  <CloseIcon />
                </Popover>
              </span>
            ) : (
              <>-</>
            )}
          </div>
        );
      },
      responsive: ['lg'],
    },
    {
      tab: ['patient'],
      title: <span className={styles.header_table}>{t?.common.team}</span>,
      dataIndex: 'teams',
      name: 'teams',
      key: `teams${mode}`,
      width: '60px',
      className: 'ant-column-name',
      minHeight: '61px',
      responsive: ['lg'],
      render: (text: any, record: Records): ReactElement => {
        const { firstName, lastName, teams } = record;
        const item = {
          firstName,
          lastName,
          teams,
          id: 0,
          email: '',
          status: '',
          hospital: '',
          hospitalName: '',
        };
        const tooltipText = getTooltipText(item, true, t);
        return (
          <Popover content={tooltipText}>
            {tooltipText ? (
              <div className={styles.team} onClick={_redirectToMyTeam} aria-hidden>
                <TeamIcon />
              </div>
            ) : (
              <></>
            )}
          </Popover>
        );
      },
    },
    {
      tab: ['hcp', 'patient', 'admin-hcp', 'admin-patient'],
      title: <span className={styles.header_table}>{first_name}</span>,
      dataIndex: 'firstName',
      name: 'firstName',
      key: `firstName${mode}`,
      width: windowclientWidth > mobileFormat ? '200px' : '83px',
      ellipsis: true,
      className: 'ant-column-name',
      minHeight: '61px',
      sorter: true,
      // ...firstNameSearch,
      sortOrder: sortedInfo && sortedInfo.columnKey === `firstName${mode}` && sortedInfo.order,
      responsive: ['lg'],
    },
    {
      tab: ['hcp', 'patient', 'admin-hcp', 'admin-patient'],
      title: <span className={styles.header_table}>{last_name}</span>,
      dataIndex: 'lastName',
      key: `lastName${mode}`,
      name: 'lastName',
      width: windowclientWidth > mobileFormat ? '230px' : '83px',
      ellipsis: true,
      className: 'ant-column-name',
      minHeight: '61px',
      sorter: true,
      sortOrder: sortedInfo && sortedInfo.columnKey === `lastName${mode}` && sortedInfo.order,
      // ...lastNameSearch,
      responsive: ['lg'],
    },
    {
      tab: ['hcp', 'patient', 'admin-hcp', 'admin-patient'],
      title: <></>,
      dataIndex: 'firstLastNameEmail',
      name: 'firstLastNameEmail',
      key: `firstLastNameEmail${mode}`,
      ellipsis: true,
      className: 'ant-column-name',
      minHeight: '61px',
      responsive: isView,
      render: (text: any, record: Records): ReactElement => {
        const statusKey = record?.status?.toLowerCase() || '';
        const [title, color, hex] = showTheStatus(statusKey, t);
        const _onClick = (): void | null => {
          if (
            record &&
            record.accesses &&
            record.accesses[0] &&
            record.accesses[0].status &&
            record.accesses[0].status === StatusKeyNameEnum.disable
          ) {
            _onAccept(record);
            return;
          }
          switch (statusKey) {
            case StatusKeyNameEnum.active:
            case StatusKeyNameEnum.connected:
            case StatusKeyNameEnum.inactive: {
              _showProfile(record, mode);
              return;
            }
            case StatusKeyNameEnum.invite_sent:
            case StatusKeyNameEnum.pending: {
              if (!isAdmin) {
                _showProfile(record, mode);
              }
              return;
            }
            case StatusKeyNameEnum.invited:
            case StatusKeyNameEnum.expired: {
              _onInviteResendInvite(record);
              return;
            }
            case StatusKeyNameEnum.disconnected:
            case StatusKeyNameEnum.treatment_completed: {
              const content = `${connect_with_patient}
              ${' '}${record?.firstName} ${record?.lastName}`;
              setShowPopup({ isShow: true, content });
              return;
            }
            default: {
              const content = 'Profile view is not available.';
              setShowPopup({ isShow: true, content });
            }
          }
        };
        const isResendCode = !!(
          (statusKey === StatusKeyNameEnum.connected || statusKey === StatusKeyNameEnum.pending) &&
          !isAdmin
        );

        return (
          <>
            <div
              onClick={_onClick}
              className={cx({
                [styles['table__sm-row']]: !isResendCode,
                [styles['table__sm-row-resend']]: isResendCode,
              })}
              role="presentation"
            >
              <div className={styles['table__first-last-name']}>
                {`${record?.firstName} ${record?.lastName}`}
              </div>
              <div className={styles['table__sm-email']}>{record?.email}</div>
              {currentTab !== 'admin-patient' ? (
                <div
                  className={cx({
                    [styles.colorRes]: true,
                  })}
                  style={{
                    backgroundColor: hexToRgbA(hex, 0.1),
                    color: hex,
                  }}
                >
                  {capitalizeFirstLetter(title)}
                </div>
              ) : (
                <></>
              )}
            </div>
            {isResendCode && (
              <div
                className={styles.resend_code}
                onClick={(): void => _resendCode(record)}
                aria-hidden
              >
                {`${resend_code_t}`}
              </div>
            )}
          </>
        );
      },
    },
    {
      title: (
        <span className={styles.header_table}>{t && t.dashboard.admin.table.hcp.profession}</span>
      ),
      tab: ['hcp', 'admin-hcp'],
      dataIndex: 'profession',
      name: 'profession',
      key: `profession${mode}`,
      width: '192px',
      maxWidth: '192px',
      minHeight: '61px',
      className: 'ant-column-email',
      sorter: true,
      sortOrder: sortedInfo && sortedInfo.columnKey === `profession${mode}` && sortedInfo.order,
      render: (profession: Profession): ReactElement => (
        <>{(profession && profession.type) || ''}</>
      ),
      responsive: ['lg'],
    },
    {
      tab: ['patient', 'admin-patient'],
      title: <span className={styles.header_table}>{dob_t}</span>,
      dataIndex: 'dob',
      key: `dob${mode}`,
      name: 'dob',
      width: '145px',
      minHeight: '61px',
      className: 'ant-column-date-of-birth-or-surgery ',
      sorter: true,
      sortOrder: sortedInfo && sortedInfo.columnKey === `dob${mode}` && sortedInfo.order,
      ...getColumnSearchProps(`${mode}.dob`, 'dob'),
      render: (dob: string): ReactElement => (
        <>{dob ? moment(dob).format(formatsDate.momentFormat) : '-'}</>
      ),
      responsive: ['lg'],
    },
    {
      tab: ['hcp', 'patient', 'admin-hcp', 'admin-patient'],
      title: <span className={styles.header_table}>{t && t.dashboard.hcp.table.email}</span>,
      dataIndex: 'email',
      key: `email${mode}`,
      name: 'email',
      width: '295px',
      ellipsis: true,
      className: 'ant-column-email',
      minHeight: '61px',
      sorter: true,
      sortOrder: sortedInfo && sortedInfo.columnKey === `email${mode}` && sortedInfo.order,
      ...getColumnSearchProps(`${mode}.email`, 'email'),
      responsive: ['lg'],
    },
    {
      tab: ['hcp', 'patient', 'admin-hcp', 'admin-patient'],
      title: <span className={styles.header_table}>{phone_number}</span>,
      dataIndex: 'phone',
      key: `phone${mode}`,
      name: 'phone',
      width: '160px',
      className: 'ant-column-email',
      minHeight: '61px',
      sorter: true,
      sortOrder: sortedInfo && sortedInfo.columnKey === `phone${mode}` && sortedInfo.order,
      ...getColumnSearchProps(`${mode}.phone`, phone_number),
      responsive: ['lg'],
    },
    {
      tab: ['patient', 'admin-patient'],
      title: <span className={styles.header_table}>{t?.dashboard.hcp.table.procedure}</span>,
      dataIndex: 'procedure',
      key: `procedure${mode}`,
      name: 'procedure',
      width: '165px',
      minHeight: '61px',
      className: 'ant-column-procedure',
      sorter: true,
      sortOrder: sortedInfo && sortedInfo.columnKey === `procedure${mode}` && sortedInfo.order,
      render: (procedure: string): ReactElement => <>{procedure}</>,
      responsive: ['lg'],
      filters: t && getProcedureFilterOptions(t),
      filterMultiple: false,
      filteredValue: (filteredInfo && filteredInfo[`procedure${mode}`]) || null,
      filterIcon: (filtered: any): JSX.Element => (
        <div
          className={cx({
            [styles.table__filter]: true,
            [styles['table__filter--active']]: filtered,
          })}
        >
          <FilterIcon />
        </div>
      ),
    },
    {
      tab: ['patient', 'admin-patient'],
      title: <span className={styles.header_table}>{surgery_date}</span>,
      dataIndex: 'surgeryDate',
      key: `surgeryDate${mode}`,
      name: 'surgeryDate',
      width: '150px',
      minHeight: '61px',
      className: 'ant-column-date-of-birth-or-surgery ',
      sorter: true,
      sortOrder: sortedInfo && sortedInfo.columnKey === `surgeryDate${mode}` && sortedInfo.order,
      ...getColumnSearchProps(`${mode}.dos`, 'dos'),
      render: (surgeryDate: string): ReactElement => (
        <>{surgeryDate ? moment(surgeryDate).format(formatsDate.momentFormat) : '-'}</>
      ),
      responsive: ['lg'],
    },
    {
      tab: ['patient', 'admin-patient'],
      title: (
        <span className={styles.header_table}>
          {getInsurancePolicyLabel(t, userCountry)}
          {/* {t && t.dashboard.hcp.table.nhs_number} */}
        </span>
      ),
      dataIndex: 'nhsNumber',
      key: `nhsNumber${mode}`,
      name: 'nhsNumber',
      width: '150px',
      className: 'ant-column-nhs-number',
      minHeight: '61px',
      sorter: true,
      sortOrder: sortedInfo && sortedInfo.columnKey === `nhsNumber${mode}` && sortedInfo.order,
      ...getColumnSearchProps('patient.nhsNumber', `${t && t.dashboard.hcp.table.nhs_number}`),
      responsive: ['lg'],
    },
    {
      tab: ['patient', 'admin-patient'],
      title: <span className={styles.header_table}>{org_name}</span>,
      dataIndex: 'hospitalName',
      key: `hospitalName${mode}`,
      name: 'hospitalName',
      width: '250px',
      ellipsis: true,
      minHeight: '61px',
      className: 'ant-column-hospital-name',
      sorter: true,
      sortOrder: sortedInfo && sortedInfo.columnKey === `hospitalName${mode}` && sortedInfo.order,
      render: (a: Hospital): ReactElement => <>{a}</>,
      ...getColumnSearchProps('hospital.name', `${t && t.dashboard.hcp.table.hospital_name}`),
      responsive: ['lg'],
    },
    {
      tab: ['hcp', 'admin-hcp'],
      title: <span className={styles.header_table}>{org_name}</span>,
      dataIndex: 'hospital',
      key: `hospital${mode}`,
      name: 'hospital',
      width: '400px',
      minHeight: '61px',
      className: 'ant-column-hospital-name',
      sorter: true,
      sortOrder: sortedInfo && sortedInfo.columnKey === `hospital${mode}` && sortedInfo.order,
      render: (a: any): ReactElement => <>{a && a.name}</>,
      ...getColumnSearchProps('hospital.name', `${t && t.dashboard.hcp.table.hospital_name}`),
      responsive: ['lg'],
    },
    {
      tab: ['admin-patient'],
      title: <span className={styles.header_table}>{t?.dashboard.hcp.table.account_status}</span>,
      dataIndex: 'account_status',
      key: `account_status${mode}`,
      name: 'account_status',
      width: '110px',
      ellipsis: true,
      minHeight: '61px',
      render: (text: any, record: Records): ReactElement => {
        const unlock_account = t?.common.unblock_account;
        const unlock_account_user = unlock_account
          ? unlock_account.replace('<user>', `${record.firstName}  ${record.lastName}`)
          : '';
        const loginAttempts = record.login ? JSON.parse(record.login) : false;
        const isLockedAccount = loginAttempts?.attempts >= 15;

        return (
          <div className={styles.center}>
            {isLockedAccount ? (
              <span
                className={styles.button_deactivate}
                onClick={(): void => _onLockPatientAccount(record)}
                aria-hidden
              >
                <Popover content={unlock_account_user}>
                  <img src={LockIcon} alt="lock" />
                </Popover>
              </span>
            ) : (
              <></>
            )}
          </div>
        );
      },
      responsive: ['lg'],
    },
    {
      tab: ['hcp', 'admin-hcp', 'patient', 'admin-patient'],
      title: <span className={styles.header_table}>{last_used_app}</span>,
      dataIndex: 'lastVisit',
      key: `lastVisit${mode}`,
      name: 'lastVisit',
      width: '125px',
      minHeight: '61px',
      className: 'ant-column-last-activity',
      render: (a: Date): ReactElement => <>{t && calcLastActivity(a, t.dashboard.hcp.table)}</>,
      sorter: true,
      sortOrder: sortedInfo && sortedInfo.columnKey === `lastVisit${mode}` && sortedInfo.order,
      responsive: ['lg'],
    },
    {
      tab:
        currentRewardsOption === REWARDS_OPTIONS.OPTION_2 && !isAdmin
          ? ['patient']
          : ['admin-patient'],
      title: (
        <span
          className={cx({
            [styles.header_table]: true,
            [styles.header_deactivate_table]: true,
            [styles.center]: true,
          })}
        >
          {`${t && t.common.rewards}`}
        </span>
      ),
      width: '100px',
      dataIndex: 'rewards',
      key: 'rewards',
      name: 'rewards',
      minHeight: '61px',
      render: (text: any, record: Records): ReactElement => {
        const { id, firstName, lastName, isRewardsEnabled, hospitalName } = record;
        const rewardsSwitchHandler = (checked: boolean): void => {
          const candidatForRewards = {
            isOpen: true,
            candidatId: id,
            firstName,
            lastName,
            hospitalName: isAdmin ? 'the On The Mend' : hospitalName,
          };
          if (checked) {
            setTurnOnRewardsModal(() => candidatForRewards);
          }
          if (!checked) {
            setIsWarningDeactivedRewards(candidatForRewards);
          }
        };
        return (
          <Popover
            content={`${t && t.notifications.turn_on_off_rewards} ${firstName}  ${lastName}`}
          >
            <div className={styles.center}>
              <RewardsSwitch
                onChange={rewardsSwitchHandler}
                isRewardsEnabled={isRewardsEnabled}
                size="default"
              />
            </div>
          </Popover>
        );
      },
      responsive: ['lg'],
    },
  ];
  // if (windowclientWidth > mobileFormat) {
  //   return columnsConfig.filter((column) => column.tab.includes(currentTab));
  // }
  // return columnsConfig.filter(
  // (column) => ['action', 'firstName', 'lastName', 'email'].includes(column.name));
  return columnsConfig.filter((column) => column.tab.includes(currentTab));
};
