import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from 'antd';

import pagesStyles from '../../styles.module.css';
import style from './styles.module.css';
import {
  GetState,
  getCurrenLang,
  getRehabTeamList,
  getRehabTeamsError,
  getRehabTeamsLoading,
  getRehabTeamsTotalItems,
  getSearchTeamLoading,
  getTeamSettingsData,
  getTotalItemsSearchTeam,
} from '../../../redux/selector';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import { Button, Loading, MainTitle } from '../../../common';
import { RehabTeamsIcon } from '../../../theme/icons';
import useCheckPermissions from '../../../hooks/useCheckPermissions';
import { PERMISSIONS, TEAM_CATEGORY } from '../../../utils/enums';
import { executeScroll, getQueryParams, initialPage, setQueryParams } from '../../../utils/helper';
import { path } from '../../../utils';
import {
  useGetAllHospitalTeams,
  useGetAllHospitalTeamsMatch,
  useGetHospitalTeamsForHcp,
  useGetHospitalTeamsForHcpMatch,
} from '../../../graphql/hospitalTeam';
import TeamSettings from '../../../components/RehabTeams/TeamSettings';
import {
  setOpenTeamSettingData,
  setSearchTeamItems,
  setTeamList,
  setTeamListError,
  setTotalItems,
} from '../../../redux/rehabTeams';
import HcpTeamTable from '../../../components/RehabTeams/HcpTeamTable';

const RehabTeamsPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const create_team = t?.hcp.manage.create_team;
  const my_teams = t?.hcp.manage.my_teams;
  const you_dont_have_permission = t?.common.you_dont_have_permission;
  const rehab_teams = t?.menu.rehab_teams;
  const my_teams_shows = t?.hcp.rehab_teams?.my_teams_shows;
  const org_teams_shows = t?.hcp.rehab_teams?.org_teams_shows;
  const org_teams = t?.hcp.rehab_teams?.org_teams;
  const no_my_teams = t?.hcp.rehab_teams?.no_my_teams;
  const no_org_teams = t?.hcp.rehab_teams?.no_org_teams;

  // Endpoints
  // My Teams
  const { _getHospitalTeamsForHcp } = useGetHospitalTeamsForHcp();
  const { _getHospitalTeamsForHcpMatch } = useGetHospitalTeamsForHcpMatch();

  // Organisation Teams
  const { _getAllHospitalTeams } = useGetAllHospitalTeams();
  const { _getAllHospitalTeamsMatch } = useGetAllHospitalTeamsMatch();

  // Redux
  const isOpenSettings: any = useSelector<any>((state) => getTeamSettingsData(state));
  const teamsLoading: any = useSelector<GetState>((state) => getRehabTeamsLoading(state));
  const teamListError: any = useSelector<GetState>((state) => getRehabTeamsError(state));
  const itemsTotal: any = useSelector<GetState>((state) => getRehabTeamsTotalItems(state));
  const dataTeam: any = useSelector<GetState>((state) => getRehabTeamList(state));
  const teamsLoadingMatch = useSelector<GetState>((state) => getSearchTeamLoading(state));
  const searchMatches = useSelector<GetState>((state) => getTotalItemsSearchTeam(state));
  const sectionRef: any = useRef(null);

  // Local state
  const [pageSize, setPageSize] = useState(15);
  const [sortBy, setSortby] = useState<string>('name');
  const [sortDirectionName, setSortDirectionName] = useState<string>('');
  const [isSearch, setIsSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [hideSearchMenu, setHideSearchMenu] = useState(false);

  const isPermissionCreateTeam = useCheckPermissions(PERMISSIONS.CREATE_TEAM_OTM);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { category, page } = getQueryParams(location.search);
  const categoryFromQuery: any = String(category);
  const pageFromQuery = Number(page);

  const isSearchParamError = useMemo(() => {
    if (!categoryFromQuery || !pageFromQuery) {
      return true;
    }
    if (
      categoryFromQuery !== TEAM_CATEGORY.MY_TEAM &&
      categoryFromQuery !== TEAM_CATEGORY.ORG_TEAM
    ) {
      return true;
    }
    return false;
    // (pageTotal && currentPage > pageTotal) || currentPage < 1 || !Number.isInteger(currentPage);
  }, [categoryFromQuery, page]);

  // Query get team list
  const _getList = useMemo(() => {
    if (categoryFromQuery === TEAM_CATEGORY.MY_TEAM) {
      return _getHospitalTeamsForHcp;
    }
    if (categoryFromQuery === TEAM_CATEGORY.ORG_TEAM) {
      return _getAllHospitalTeams;
    }
    return (): null => null;
  }, [categoryFromQuery]);

  // Query search team
  const _searchTeam = useMemo(() => {
    if (categoryFromQuery === TEAM_CATEGORY.MY_TEAM) {
      return _getHospitalTeamsForHcpMatch;
    }
    if (categoryFromQuery === TEAM_CATEGORY.ORG_TEAM) {
      return _getAllHospitalTeamsMatch;
    }
    return (): null => null;
  }, [categoryFromQuery]);

  // If there is a Search Param error, use the default
  useEffect(() => {
    if (isSearchParamError) {
      history.push({
        pathname: path.rehabTeams,
        search: `category=${TEAM_CATEGORY.MY_TEAM}&page=${initialPage}`,
      });
    }
  }, [isSearchParamError]);

  // Switch between teams category
  const changeСategory = (arg: TEAM_CATEGORY): void => {
    if (categoryFromQuery === arg) {
      return;
    }
    history.push({
      pathname: path.rehabTeams,
      search: `category=${arg}&page=${initialPage}`,
    });
  };

  // Scroll to elem
  useEffect(() => {
    if (isOpenSettings) {
      executeScroll(sectionRef);
    }
  }, [isOpenSettings]);

  // Breadcrumb path
  const routes = [
    {
      path: rehab_teams,
      breadcrumbName: rehab_teams,
    },
  ];

  useEffect(() => {
    if (teamListError) {
      toast.error(teamListError);
    }
  }, [teamListError]);

  useEffect(() => {
    // get Hospital Teams list
    _getList({
      searchProps: {
        searchField: 'name',
        searchValue: searchQuery,
      },
      listProps: {
        itemsPerPage: pageSize,
        page: pageFromQuery,
        sortBy: '',
        sortDirection: '',
      },
    });

    // When didunmount clear open settings state
    return (): void => {
      dispatch(setOpenTeamSettingData(null));
    };
  }, []);

  // If changed tab get new list
  useEffect(() => {
    if (categoryFromQuery) {
      setSearchQuery('');
      // setCurrentPage(initialPage);
      setSortby('name');
      setSortDirectionName('');
      setIsSearch(false);
      setHideSearchMenu(false);
      _getList({
        searchProps: {
          searchField: 'name',
          searchValue: '',
        },
        listProps: {
          itemsPerPage: pageSize,
          page: initialPage,
          sortBy: '',
          sortDirection: '',
        },
      });
    }
  }, [categoryFromQuery]);

  // when reload page
  useEffect(() => {
    if (pageFromQuery) {
      _getList({
        searchProps: {
          searchField: 'name',
          searchValue: searchQuery,
        },
        listProps: {
          itemsPerPage: pageSize,
          page: pageFromQuery,
          sortBy,
          sortDirection: sortDirectionName,
        },
      });
    }
    return (): void => {
      dispatch(setTeamList(null));
      dispatch(setTotalItems(0));
      dispatch(setTeamListError(undefined));
    };
  }, []);

  // Update Hospital Teams list
  const updateTeamList = (): void => {
    _getList({
      searchProps: {
        searchField: 'name',
        searchValue: searchQuery,
      },
      listProps: {
        itemsPerPage: pageSize,
        page: pageFromQuery,
        sortBy,
        sortDirection: sortDirectionName,
      },
    });
  };

  // Go to the correspond page
  const onChangePagination = (pagePgin: number, itemsPerPage?: number | undefined): void => {
    setPageSize(itemsPerPage || 15);
    _getList({
      searchProps: {
        searchField: 'name',
        searchValue: searchQuery,
      },
      listProps: {
        itemsPerPage: itemsPerPage || 15,
        page: pagePgin,
        sortBy: '',
        sortDirection: '',
      },
    });
    history.push({
      pathname: location.pathname,
      search: setQueryParams({
        category: categoryFromQuery,
        page: pagePgin,
      }),
    });
  };

  // Search and sort
  /* * search for matches in the database * */

  // Set to null search query
  const setSearchMatches = (): void => {
    dispatch(setSearchTeamItems(null));
  };

  // Find search matches in DB
  const findMatches = (searchVal: string): void => {
    if (!searchVal) {
      dispatch(setSearchTeamItems(null));
      return;
    }

    _searchTeam({
      searchProps: {
        searchField: 'name',
        searchValue: searchVal,
      },
      listProps: {
        itemsPerPage: 15,
        page: initialPage,
        sortBy: '',
        sortDirection: '',
      },
    });
  };

  // Sort exercise list by name
  const sortByColumnName = (name: string, sortdirection: string): void => {
    setSortby(name);
    setSortDirectionName(sortdirection);
    _getList({
      searchProps: {
        searchField: name,
        searchValue: searchQuery,
      },
      listProps: {
        itemsPerPage: pageSize,
        page: pageFromQuery,
        sortBy: name,
        sortDirection: sortdirection,
      },
    });
  };

  // Get search value by team name column
  const handleSearch = (val: string): void => {
    setSearchQuery(val);
    dispatch(setSearchTeamItems(null));
    setHideSearchMenu(!hideSearchMenu);
    if (val) {
      _getList({
        searchProps: {
          searchField: 'name',
          searchValue: val,
        },
        listProps: {
          itemsPerPage: pageSize,
          page: initialPage,
          sortBy,
          sortDirection: sortDirectionName,
        },
      });
      setIsSearch(() => true);
      history.push({
        pathname: location.pathname,
        search: setQueryParams({
          category: categoryFromQuery,
          page: initialPage,
        }),
      });
    }
  };

  // Reset search exercise name
  const handleReset = (): void => {
    setSearchQuery('');
    dispatch(setSearchTeamItems(null));
    setHideSearchMenu(!hideSearchMenu);
    if (searchQuery) {
      _getList({
        searchProps: {
          searchField: 'name',
          searchValue: '',
        },
        listProps: {
          itemsPerPage: pageSize,
          page: initialPage,
          sortBy,
          sortDirection: sortDirectionName,
        },
      });
      setIsSearch(() => false);
    }
    history.push({
      pathname: location.pathname,
      search: setQueryParams({
        category: categoryFromQuery,
        page: initialPage,
      }),
    });
  };

  // Redirect to Create Team page
  const openCreateTeam = (): void => {
    history.push({
      pathname: path.createTeam,
    });
  };

  // JSX
  const loadingJSX = (teamsLoading || teamsLoadingMatch) && <Loading />;
  const noData = dataTeam?.length === 0 && (
    <div className={pagesStyles.noData}>
      {categoryFromQuery === TEAM_CATEGORY.MY_TEAM ? no_my_teams : no_org_teams}
    </div>
  );
  const createTeamBtnJsx = (
    <Button
      buttonClass={style['rehab-teams__btn']}
      buttonType="button"
      buttonName={create_team}
      disabledButton={!isPermissionCreateTeam}
      buttonMethod={isPermissionCreateTeam ? openCreateTeam : (): null => null}
    />
  );

  return (
    <div className={pagesStyles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={pagesStyles.main__wrapper}>
        <MainTitle title={rehab_teams} icon={<RehabTeamsIcon />} />

        <div className={style['rehab-teams__description-container']}>
          <div className={style['left-section--description']}>
            <div className={style['rehab-teams__description']}>{my_teams_shows}</div>
            <div className={style['rehab-teams__description']}>{org_teams_shows}</div>
          </div>

          <Tooltip title={!isPermissionCreateTeam ? you_dont_have_permission : ''}>
            <div className={style['right-section--description']}>{createTeamBtnJsx}</div>
          </Tooltip>
        </div>

        <div className={style['rehab-teams__teams-container']}>
          {/* Main page */}
          <div className={pagesStyles['left-section']}>
            {/* Tabs */}
            <div className={style['rehab-teams__tabs-container']}>
              {/* My Teams */}
              <div
                className={cx({
                  [style['rehab-teams__tab']]: true,
                  [style['rehab-teams__tab--active']]: categoryFromQuery === TEAM_CATEGORY.MY_TEAM,
                })}
                onClick={(): void => changeСategory(TEAM_CATEGORY.MY_TEAM)}
                role="tabpanel"
                aria-hidden
              >
                {my_teams}
              </div>
              {/* Org Teams */}
              <div
                className={cx({
                  [style['rehab-teams__tab']]: true,
                  [style['rehab-teams__tab--active']]: categoryFromQuery === TEAM_CATEGORY.ORG_TEAM,
                })}
                onClick={(): void => changeСategory(TEAM_CATEGORY.ORG_TEAM)}
                role="tabpanel"
                aria-hidden
              >
                {org_teams}
              </div>
            </div>

            {/* List */}
            <HcpTeamTable
              dataTeam={dataTeam}
              sortByColumnName={sortByColumnName}
              sortBy={sortBy}
              handleSearch={handleSearch}
              handleReset={handleReset}
              searchMatches={searchMatches}
              findMatches={findMatches}
              hideSearchMenu={hideSearchMenu}
              isSearch={isSearch}
              setHideSearchMenu={setHideSearchMenu}
              setSearchMatches={setSearchMatches}
              noData={noData}
              pageFromQuery={pageFromQuery}
              pageSize={pageSize}
              itemsTotal={itemsTotal}
              onChangePagination={onChangePagination}
              isPermissionCreateTeam={isPermissionCreateTeam}
              updateTeamList={updateTeamList}
            />
          </div>

          {/* Settings big screen */}
          <div className={style['rehab-teams__settings--bg']}>
            {!!isOpenSettings && (
              <div className={pagesStyles['right-section']} ref={sectionRef}>
                <TeamSettings
                  key={isOpenSettings.id}
                  isPermissionCreateTeam={isPermissionCreateTeam}
                  updateTeamList={updateTeamList}
                />
              </div>
            )}
          </div>
          {/* Create Team small screen */}
          <div className={style['rehab-teams__create-team--sm']}>{createTeamBtnJsx}</div>
        </div>
      </div>
    </div>
  );
};

export default RehabTeamsPage;
