import React, { ReactElement, useMemo, useState } from 'react';
import cx from 'classnames';
import { sanitize } from 'dompurify';

import styles from './styles.module.css';
import { CloseBtn } from '../Button/Button';
import { Modal } from '../Modal';
import { otmLink } from '../../utils/variables';
import { EXERCISE_STATUS_COLOR } from '../../utils/enums';

interface GuideTitleProps {
  text: string;
  content: JSX.Element;
}

export const GuideTitle = ({ text, content }: GuideTitleProps): ReactElement => {
  const [isGuideOpen, setGuideOpen] = useState(false);

  const patientStatusGuidJsx = useMemo(() => {
    if (text) {
      const parsString = text?.split('<link>');
      if (parsString?.length > 1) {
        const partOne = parsString[0];
        const partTwo = parsString[1];
        const partThree = parsString[2] || '';

        return (
          <div className={styles.guide__wrapper}>
            <span>{partOne}</span>
            <span
              className={styles.guide__link}
              onClick={(): void => setGuideOpen(true)}
              aria-hidden
            >
              {partTwo}
            </span>
            <span>{partThree}</span>
          </div>
        );
      }
    }
    return <></>;
  }, [text]);

  const closeModal = (): void => {
    setGuideOpen(() => false);
  };

  return (
    <>
      {patientStatusGuidJsx}
      {isGuideOpen && (
        <Modal onClose={closeModal} style={styles.modal__guide}>
          <CloseBtn close={closeModal} />
          {content}
        </Modal>
      )}
    </>
  );
};

export const RewardsGuide = ({ guideStatusesConfig, t }: any): JSX.Element => {
  const guide_setting_rewards = t?.hcp.rewards.guide_setting_rewards || 'Guide to Setting Rewards';
  const guide_description_one =
    t?.hcp.rewards.guide_description_one ||
    'Your patient can earn rewards by completing at least 80% of the weekly exercise plan you prescribe. These incentives can be cash vouchers with commercial brands or weekly options that you customise. If you choose to customise,  you can also offer something for signing up to use the app.';
  const give_access =
    t?.hcp.rewards.give_access || 'To give your patients access to your custom rewards:';
  const guide_list_one =
    t?.hcp.rewards.guide_list_one ||
    'Rewards must be provided in multiples of 4 weeks. You can omit a reward at sign-up, but you must start rewards from Week 1, then continue weekly without gaps. For example, Week 1, 2, 3, 4 or Week 1, 2, 3, 4, 5, 6, 7, 8. The system will not allow gaps, such as setting rewards for Week 2, 4, and 6 only.';
  const guide_list_two =
    t?.hcp.rewards.guide_list_two ||
    'Go to the Patient List and toggle on the “Reward” column for each patient you want to earn rewards.';
  const guide_list_three =
    t?.hcp.rewards.guide_list_three ||
    'A calendar will pop up, allowing you to choose the starting week for offering rewards. Rewards must always start on the nearest Monday at 9:00 AM and end on a Monday at 8:59 AM, either after 4 weeks or 8 weeks.';
  const guide_offer_cash =
    t?.hcp.rewards.guide_offer_cash ||
    'To offer cash vouchers from a range of commercial brands provided by On The Mend, please contact us at <email>.';
  const guide_offer_cash_email = guide_offer_cash?.replace('<email>', otmLink);

  return (
    <div className={styles.guide__container}>
      <div className={styles.guide__title}>{guide_setting_rewards}</div>
      <div className={styles.guide__description}>{guide_description_one}</div>
      <div className={styles.guide__description}>{give_access}</div>
      <ul className={styles.guide__list}>
        <li className={styles.guide__li}>{guide_list_one}</li>
        <li className={styles.guide__li}>{guide_list_two}</li>
        <li className={styles.guide__li}>{guide_list_three}</li>
      </ul>

      {/* Statuses */}
      {guideStatusesConfig.map((status: any) => (
        <div className={styles['guide__status-container']} key={status.description}>
          <div
            className={cx({
              [styles.guide__status]: true,
              [styles[status.color]]: true,
            })}
          >
            {status.status}
          </div>
          <div className={styles['guide__status-description']}>{status.description}</div>
        </div>
      ))}

      <div
        className={styles.guide__footer}
        dangerouslySetInnerHTML={{ __html: sanitize(guide_offer_cash_email) }}
      />
    </div>
  );
};

export const ExerciseStatusGuide = ({ t }: any): JSX.Element => {
  const status_active = t?.common.status_active;
  const status_inactive = t?.common.status_inactive;
  const status_archived = t?.common.status_archived;
  const set = t?.common.set || 'Set';
  const not_set = t?.common.not_set || 'Not set';
  const status_guide = t?.dashboard.hcp.profile_patient.status_guide;

  const exercise_is_prescribed =
    t?.dashboard.hcp.profile_patient.exercise_is_prescribed ||
    'Exercise is prescribed by a healthcare professional and appears in the patient’s exercise list.';
  const folders_are_active =
    t?.dashboard.hcp.profile_patient.folders_are_active ||
    'Folders are ‘Active’ when at least one exercise is currently prescribed.';
  const exercise_not_prescribed =
    t?.dashboard.hcp.profile_patient.exercise_not_prescribed ||
    'Exercise has not been prescribed and will not appear anywhere in the patient’s exercise list.';
  const inactive_folder_means =
    t?.dashboard.hcp.profile_patient.inactive_folder_means ||
    'An inactive folder means no exercises within it are currently active.';
  const exercise_settings_saved =
    t?.dashboard.hcp.profile_patient.exercise_settings_saved ||
    'Exercise settings have been entered and saved, and will appear in the patient’s exercise list once the folder is saved.';
  const exercise_settings_not_entered =
    t?.dashboard.hcp.profile_patient.exercise_settings_not_entered ||
    'Exercise settings have not been entered and will appear as Inactive on the patient’s exercise list once the folder is saved.';
  const exercise_archived =
    t?.dashboard.hcp.profile_patient.exercise_archived ||
    'Exercise was previously prescribed but is now archived by either healthcare professional or  patient. It will only appear in the "Archived Exercises" and "Exercise History" in the Calendar section of the patient’s app.';

  const config = [
    {
      status: status_active,
      color: EXERCISE_STATUS_COLOR.active,
      description: (
        <section>
          <div className={styles['guide__description-item']}>{exercise_is_prescribed}</div>
          <div className={styles['guide__description-item']}>{folders_are_active}</div>
        </section>
      ),
    },
    {
      status: status_inactive,
      color: EXERCISE_STATUS_COLOR.inactive,
      description: (
        <section>
          <div className={styles['guide__description-item']}>{exercise_not_prescribed}</div>
          <div className={styles['guide__description-item']}>{inactive_folder_means}</div>
        </section>
      ),
    },
    {
      status: set,
      color: EXERCISE_STATUS_COLOR.set,
      description: (
        <section>
          <div className={styles['guide__description-item']}>{exercise_settings_saved}</div>
        </section>
      ),
    },
    {
      status: not_set,
      color: EXERCISE_STATUS_COLOR.notSet,
      description: (
        <section>
          <div className={styles['guide__description-item']}>{exercise_settings_not_entered}</div>
        </section>
      ),
    },
    {
      status: status_archived,
      color: EXERCISE_STATUS_COLOR.archived,
      description: (
        <section>
          <div className={styles['guide__description-item']}>{exercise_archived}</div>
        </section>
      ),
    },
  ];

  return (
    <div className={styles.guide__container}>
      <div className={styles.guide__title}>{status_guide}</div>
      {/* Statuses */}
      {config.map((status: any) => (
        <div className={styles['guide__exercise-status-row']} key={status.description}>
          <div
            style={{ color: status.color }}
            className={cx({
              [styles['guide__exercise-status']]: true,
              // [styles[status.color]]: true,
            })}
          >
            {status.status}
          </div>
          {status.description}
        </div>
      ))}
    </div>
  );
};
