import React, { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';

import overviewStyles from '../styles.module.css';
import style from './style.module.css';
import { PatientRehabTeamIcon } from '../../../theme/icons';
import { PatientMessagesCardProps } from '../types';
import { getCurrenLang } from '../../../redux/selector';
import { CardTitle } from '../Elements';
import useCheckRole from '../../../hooks/useCheckRole';
import { spinner } from '../../../common/Loader';

const PatientMessagesCard = ({ patient, loading }: PatientMessagesCardProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const messages_relating = t?.menu.messages_relating || 'Messages Relating to';
  const no_messages_relating =
    t?.dashboard.hcp.profile_patient.no_messages_relating ||
    'There are currently no messages relating to this patient.';
  const patientName = useMemo(() => {
    if (patient) {
      const { firstName, lastName } = patient;
      return `${messages_relating} ${firstName} ${lastName}`;
    }
    return messages_relating || '';
  }, [patient, t]);

  const { isAdmin } = useCheckRole();

  // Endpoint

  // JSX
  const loadingJSX = loading && spinner;

  const noData =
    isAdmin || (true && <div className={overviewStyles.nodata}>{no_messages_relating}</div>);

  return (
    <div className={overviewStyles['overview__sm-card-container']}>
      {loadingJSX}
      <CardTitle icon={<PatientRehabTeamIcon />} title={patientName} />

      <div className={style.notifications__container}>{noData}</div>
    </div>
  );
};

export default PatientMessagesCard;
