import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import { DragDropContext } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';

import pageStyles from '../../styles.module.css';
import style from './style.module.css';
import patientStyles from '../style.module.css';
import {
  GetState,
  getCurrenLang,
  getDraggedList,
  getErrorPatientProfile,
  getExerciseLibrary,
  getLoadingPatientProfile,
  getPatientProfile,
} from '../../../redux/selector';
import { Loading, MainTitle, Modal } from '../../../common';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import { getPatientName, getQueryParams } from '../../../utils/helper';
import { path } from '../../../utils';
import useCheckRole from '../../../hooks/useCheckRole';
import { useQueryPatientProfile } from '../../../graphql/patients';
import { EXERCISE_TYPE, StatusKeyNameEnum } from '../../../utils/enums';
import { fillDraggedList, fillPatientProfile, updateExerciseLibrary } from '../../../redux/patient';
import PrescribedExerciseList from '../../../components/Video/PrescribedExerciseList';
import PatientExerciseLibrary from '../../../components/Video/PatientExerciseLibrary';
import { CloseBtn } from '../../../common/Button/Button';
import useGetFocuses from '../../../hooks/useGetFocuses';
import DragMediaModalContent from '../../../components/Video/PatientExerciseLibrary/DragMediaModalContent';
import DragFolderModalContent from '../../../components/Video/PatientExerciseLibrary/DragFolderModalContent';

const PatientExerciseLibreryPage = (): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const patient_list = t?.title.patient_list;
  const patient_exercise_library = t?.menu.patient_exercise_library;
  const set_exercise = t?.dashboard.hcp.profile_patient.video_bank.set_exercise || 'Set Exercise';
  const set_folder_exercise =
    t?.dashboard.hcp.profile_patient.video_bank.set_folder_exercise || 'Set Folder Contents';
  const patient_not_found = t?.common.patient_not_found;

  const location = useLocation();
  const dispatch = useDispatch();
  const { userId, userStatus } = getQueryParams(location.search);
  const patientStatus = String(userStatus);
  const patientId = Number(userId);
  const { isAdmin, isHcp } = useCheckRole();
  const {
    focusType1Arr,
    focusType2Arr,
    exercisesCategories,
    exercisesProcedureFocus,
    // procedureFilterConfig,
    // loadingFocuses,
  } = useGetFocuses();

  // Redux
  const profileData: any = useSelector<any>((state: GetState) => getPatientProfile(state));
  const profileLoading = useSelector<any>((state: GetState) => getLoadingPatientProfile(state));
  const profileError: any = useSelector<any>((state: GetState) => getErrorPatientProfile(state));
  const exersisesLib: any = useSelector<any>((state: GetState) => getExerciseLibrary(state));
  const draggedList: any = useSelector<any>((state: GetState) => getDraggedList(state));

  // Local state
  const [draggedItem, setDraggedItem] = useState<null | {
    sourceIndex: number;
    destinationIndex: number;
    item: any;
    newOrder: any;
  }>(null);

  // No data available for this user
  const notAllowed = useMemo(() => {
    if (!userStatus || !patientId || profileError) {
      return true;
    }
    return false;
  }, [patientId, userStatus, profileError]);

  const isInvitee = userStatus === StatusKeyNameEnum.invite_sent;
  // Endpoints
  // Get current patient profile from DB
  const { _getProfileById } = useQueryPatientProfile(patientStatus);

  // Send request to the database
  useEffect(() => {
    if (!notAllowed) {
      _getProfileById({
        variables: {
          patientId,
        },
      });
    }
  }, [notAllowed, patientId]);

  // Show error
  useEffect(() => {
    if (profileError) {
      toast.error(profileError);
    }
  }, [profileError]);

  // Clear state after componentWillUnmount
  useEffect(
    () => (): void => {
      dispatch(fillPatientProfile(null));
    },
    [],
  );

  // JSX
  const loadingJSX = profileLoading && <Loading />;

  // Breadcrumb path
  const routes = useMemo(
    () => [
      {
        path: isAdmin ? path.adminDashboardList : path.dashboardList,
        breadcrumbName: patient_list,
      },
      {
        path: isAdmin
          ? `${path.patient_overview_admin}${location.search}`
          : `${path.patient_overview}${location.search}`,
        breadcrumbName: getPatientName(profileData),
      },
      {
        path: patient_exercise_library,
        breadcrumbName: patient_exercise_library,
      },
    ],
    [t, profileData],
  );

  const onDragEnd = ({ destination, source, draggableId, type }: any): void => {
    if (!destination) {
      return;
    }

    const start = source.droppableId;
    const end = destination.droppableId;

    if (start === end && destination.index === source.index) {
      return;
    }
    if (start === 'PatientExerciseLibrary') {
      const newOrder = [...exersisesLib];
      newOrder.splice(source.index, 1);
      if (start === end) {
        newOrder.splice(destination.index, 0, exersisesLib[source.index]);
        dispatch(updateExerciseLibrary(newOrder));
        return;
      }
      setDraggedItem({
        item: exersisesLib[source.index],
        sourceIndex: source.index,
        destinationIndex: destination.index,
        newOrder,
      });
      /* dispatch(updateExerciseLibrary(newOrder));
      const newDraggedList = [...draggedList];
      newDraggedList.splice(destination.index, 0, exersisesLib[source.index]);
      dispatch(fillDraggedList(newDraggedList));   */
    } else {
      const newOrder = [...draggedList];
      newOrder.splice(source.index, 1);
      if (start === end) {
        newOrder.splice(destination.index, 0, draggedList[source.index]);
        dispatch(fillDraggedList(newOrder));
        return;
      }
      dispatch(fillDraggedList(newOrder));
      const newDraggedList = [...exersisesLib];
      newDraggedList.splice(destination.index, 0, draggedList[source.index]);
      dispatch(updateExerciseLibrary(newDraggedList));
    }
  };

  // Cancel drag
  const cancelDrag = (): void => {
    setDraggedItem(null);
  };

  // Save exercese item to drugged list (redux)
  const continueDrag = (mutateData: any): void => {
    if (draggedItem) {
      dispatch(updateExerciseLibrary(draggedItem.newOrder));
      const newDraggedList = [...draggedList];
      newDraggedList.splice(draggedItem.destinationIndex, 0, mutateData);
      // newDraggedList.splice(
      // draggedItem.destinationIndex, 0, exersisesLib[draggedItem.sourceIndex]);
      dispatch(fillDraggedList(newDraggedList));
      setDraggedItem(null);
    }
  };

  return (
    <div className={pageStyles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={cx([pageStyles.main__wrapper])}>
        {!notAllowed && (
          <DragDropContext onDragEnd={onDragEnd}>
            <div className={pageStyles['flex-container']}>
              {/* Patient Exercise Library */}
              <div className={pageStyles['left-section']}>
                <PatientExerciseLibrary patientId={patientId} isInvitee={isInvitee} />
              </div>

              {/* Prescribed Exercise List */}
              <div className={pageStyles['right-section']}>
                <PrescribedExerciseList
                  patientId={patientId}
                  isInvitee={isInvitee}
                  patientStatus={patientStatus}
                />
              </div>
            </div>
          </DragDropContext>
        )}

        {notAllowed && (
          <div className={patientStyles['profile-user__notfound']}>{patient_not_found}</div>
        )}
      </div>
      {draggedItem && (
        <Modal onClose={cancelDrag} style={style['exerc-lib__modal']}>
          <CloseBtn close={cancelDrag} />
          <MainTitle
            title={
              draggedItem.item.type === EXERCISE_TYPE.FOLDER ? set_folder_exercise : set_exercise
            }
          />

          {(draggedItem.item.type === EXERCISE_TYPE.PHOTO ||
            draggedItem.item.type === EXERCISE_TYPE.VIDEO) && (
            <DragMediaModalContent
              data={draggedItem.item}
              cancelDrag={cancelDrag}
              continueDrag={continueDrag}
              exercisesCategories={exercisesCategories}
              exercisesProcedureFocus={exercisesProcedureFocus}
              focusType1Arr={focusType1Arr}
              focusType2Arr={focusType2Arr}
            />
          )}
          {draggedItem.item.type === EXERCISE_TYPE.FOLDER && (
            <DragFolderModalContent
              data={draggedItem.item}
              cancelDrag={cancelDrag}
              continueDrag={continueDrag}
              exercisesCategories={exercisesCategories}
              exercisesProcedureFocus={exercisesProcedureFocus}
              focusType1Arr={focusType1Arr}
              focusType2Arr={focusType2Arr}
              isInvitee={isInvitee}
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default PatientExerciseLibreryPage;
