// @ts-nocheck
import React, { Children, ReactElement, useEffect, useState, useMemo } from 'react';
import cx from 'classnames';
import Select, { components } from 'react-select';
import { Tooltip } from 'antd';

import {
  connectPatientSelectStyles,
  dropdownIndicatorNone,
  dropdownIndicatorStyles,
  selectFloatLabelStyles,
  selectNewStyles,
  selectStyles,
} from './styles';
import styles from './styles.module.css';
import {
  DropDownProps,
  SelectProps,
  SelectStatusFieldProps,
  SelectFieldWithCutSearchProps,
  SelectFloatLabelProps,
  SelectFloatLabelWithSearchProps,
} from './types';

export const DropDown = (props: DropDownProps): ReactElement => {
  const {
    name,
    options,
    labelName,
    isValid,
    isTouched,
    hasErrors,
    onChange,
    inputValue,
    dataQa,
  } = props;
  const handleChange = (value: any): void => {
    onChange(name, value.value);
  };
  const [selectedOption, setSelectedOption] = useState(
    options ? options.map((a: any) => a.id === inputValue) : null,
  );
  const invalidStyles = cx({
    [styles.selectError]: !isValid && isTouched && hasErrors,
  });
  return (
    <label className={styles.lable_select} htmlFor={labelName}>
      <div className={styles.subtitle}>
        {labelName}
        <Select
          options={options}
          className={`${styles.selector} ${invalidStyles}`}
          // styles={{ ...selectStyles }}
          styles={{ ...connectPatientSelectStyles(false) }}
          onChange={handleChange}
          defaultValue={selectedOption}
          data-qa={dataQa}
        />
        {!inputValue && isTouched && hasErrors ? (
          <div className={styles.error}>{hasErrors}</div>
        ) : null}
      </div>
    </label>
  );
};

export const SelectField = (props: SelectProps): ReactElement => {
  const {
    name,
    options,
    labelName,
    isValid,
    isTouched,
    hasErrors,
    onChange,
    inputValue,
    selected,
    disabledField = false,
    disabledWithoutClass,
    placeholder = '',
    isClearable = false,
    type = '',
    isRedStar = false,
    isShowErrorText = true,
    tooltipText = '',
    dataQa,
  } = props;

  const [selectedOption, setSelectedOption] = useState(
    selected || (options && options[0] && options[0].value) || '',
  );

  const handleChange = (value: any): void => {
    onChange(name, value || '');
    setSelectedOption(value);
  };
  const invalidStyles = cx({
    [styles.selectError]: !isValid && isTouched && hasErrors,
  });
  let stylesForSelect = {};
  switch (type) {
    case 'connect patient':
    case 'user profile':
    case 'hcp profile':
    case 'video bank':
    case 'exercise folder details':
    case 'feedback form':
    case 'manage notification':
      stylesForSelect = { ...connectPatientSelectStyles(disabledField) };
      break;
    default:
      stylesForSelect = { ...selectStyles, ...dropdownIndicatorStyles(disabledField) };
  }

  return (
    <label className={styles.lable_select} htmlFor={labelName}>
      <div className={styles.subtitle}>
        <div className={styles.labelSel}>
          {labelName}
          {isRedStar && <span className={styles.red_star}>*</span>}
        </div>
        <Tooltip title={tooltipText || ''}>
          <div className={cx({ [styles.disabled]: disabledField })}>
            <Select
              options={options || []}
              className={`${styles.selector} ${invalidStyles}`}
              styles={{ ...stylesForSelect }}
              value={inputValue}
              defaultValue={selectedOption}
              onChange={handleChange}
              isDisabled={disabledField || disabledWithoutClass}
              placeholder={placeholder}
              isClearable={isClearable}
              data-qa={dataQa}
            />
          </div>
        </Tooltip>
        {!inputValue && isTouched && hasErrors && isShowErrorText ? (
          <div className={styles.error}>{hasErrors}</div>
        ) : null}
      </div>
    </label>
  );
};

export const SelectStatusField = (props: SelectStatusFieldProps): ReactElement => {
  const {
    options,
    onChange,
    inputValue,
    disabledField,
    customStyles,
    filterOptionsArr = [],
    dataQa,
  } = props;
  const [selectedOption, setSelectedOption] = useState(
    inputValue || options?.[0]?.value || { color: '', label: '', value: '' },
  );
  useEffect(() => {
    setSelectedOption(inputValue);
  }, [inputValue]);

  const handleChange = (value: any): void => {
    onChange(value);
    setSelectedOption(value);
  };

  const filterOptions = (candidate: { label: string; value: string; data: any }): boolean =>
    !filterOptionsArr.includes(candidate.value);

  return (
    <Select
      options={options || []}
      styles={{ ...customStyles }}
      value={selectedOption}
      onChange={handleChange}
      isSearchable={false}
      isDisabled={disabledField}
      filterOption={filterOptions}
      data-qa={dataQa}
      // menuIsOpen
    />
  );
};

export const SelectFieldWithCutSearch = (props: SelectFieldWithCutSearchProps): ReactElement => {
  const {
    name,
    options,
    labelName,
    isValid,
    isTouched,
    hasErrors,
    onChange,
    inputValue,
    selected,
    disabledField = false,
    placeholder,
    noOptionsMessage,
    type = '',
    dataQa,
  } = props;

  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    selected || (options && options[0] && options[0].value) || '',
  );
  useEffect(() => {
    setSelectedOption(inputValue);
  }, [inputValue]);

  const handleChange = (value: any): void => {
    onChange(name, value || '');
    setSelectedOption(value);
  };
  const handleInputChange = (inputVal: string): void => {
    if (inputVal && inputVal.length > 2) {
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  };
  let customStyles = {};
  switch (type) {
    case 'personal info':
      customStyles = { ...selectNewStyles, ...dropdownIndicatorStyles(disabledField) };
      break;
    case 'invite hcp':
    case 'user profile':
    case 'hcp profile':
    case 'manage proms':
      customStyles = { ...connectPatientSelectStyles(disabledField) };
      break;
    default:
      customStyles = { ...selectStyles, ...dropdownIndicatorStyles(disabledField) };
  }
  const isPatientProfile = type === 'personal info';

  return (
    <div className={isPatientProfile ? styles.label : styles.lable_select}>
      <div
        className={cx({
          [styles.subtitle]: !isPatientProfile,
          [styles.titleField]: isPatientProfile,
          [styles.disabled]: disabledField,
        })}
      >
        <div
          className={cx({
            [styles.labelSel]: !isPatientProfile,
          })}
        >
          {labelName}
        </div>
        <Select
          options={showOptions ? options : []}
          className={cx({
            [styles.selectError]: !isValid && isTouched && hasErrors,
            [styles.selector]: !isPatientProfile,
            [styles.selectorNew]: isPatientProfile,
          })}
          styles={{ ...customStyles }}
          value={inputValue}
          defaultValue={selectedOption}
          onChange={handleChange}
          isDisabled={disabledField}
          onInputChange={handleInputChange}
          placeholder={placeholder}
          noOptionsMessage={(): string => noOptionsMessage}
          data-qa={dataQa}
          // menuIsOpen
        />
        {!inputValue && isTouched && hasErrors ? (
          <div className={styles.error}>{hasErrors}</div>
        ) : null}
      </div>
    </div>
  );
};

export const SelectTableStatusField = (props: SelectStatusFieldProps): ReactElement => {
  const {
    options,
    onChange,
    inputValue,
    disabledField,
    customStyles,
    filterOptionsArr = [],
    marker,
    dataQa,
  } = props;

  const filterOptions = (candidate: { label: string; value: string; data: any }): boolean =>
    !filterOptionsArr.includes(candidate.value);

  const Control = ({ children, ...props }: any) => (
    <components.Control {...props}>
      {marker} {children}
    </components.Control>
  );

  return (
    <Select
      menuPortalTarget={document.body}
      menuPosition="fixed"
      options={options || []}
      styles={{ ...customStyles, ...dropdownIndicatorNone(disabledField) }}
      value={inputValue}
      onChange={onChange}
      isSearchable={false}
      isDisabled={disabledField}
      filterOption={filterOptions}
      components={{ Control }}
      data-qa={dataQa}
    />
  );
};
export const SelectFieldNew = (props: SelectProps): ReactElement => {
  const {
    name,
    options,
    labelName,
    isValid,
    isTouched,
    hasErrors,
    onChange,
    inputValue,
    selected,
    disabledField,
    disabledWithoutClass,
    placeholder = '',
    isClearable = false,
    dataQa,
  } = props;

  const [selectedOption, setSelectedOption] = useState(
    selected || (options && options[0] && options[0].value) || '',
  );

  const handleChange = (value: any): void => {
    onChange(name, value || '');
    setSelectedOption(value);
  };
  const invalidStyles = cx({
    [styles.selectError]: !isValid && isTouched && hasErrors,
  });
  return (
    <label className={styles.label} htmlFor="id">
      <div className={styles.titleField}>
        {labelName}
        <div className={cx({ [styles.disabled]: disabledField })}>
          <Select
            options={options || []}
            className={`${styles.selectorNew} ${invalidStyles}`}
            styles={{ ...selectNewStyles, ...dropdownIndicatorStyles(disabledField) }}
            value={inputValue}
            defaultValue={selectedOption}
            onChange={handleChange}
            isDisabled={disabledField || disabledWithoutClass}
            placeholder={placeholder}
            isClearable={isClearable}
            data-qa={dataQa}
          />
        </div>
        {!inputValue && isTouched && hasErrors ? (
          <div className={styles.error}>{hasErrors}</div>
        ) : null}
      </div>
    </label>
  );
};

export const SelectFloatLabel = (props: SelectFloatLabelProps): ReactElement => {
  const {
    name,
    options,
    inputId,
    isValid,
    isTouched,
    hasErrors,
    onChange,
    inputValue,
    selected,
    disabledField = false,
    disabledWithoutClass,
    placeholder = '',
    isClearable = false,
    type = '',
    isRedStar = false,
    isShowErrorText = true,
    tooltipText = '',
    dataQa,
  } = props;
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }: any) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {Children.map(children, (child: any) =>
          child && child.type !== Placeholder ? child : null,
        )}
      </ValueContainer>
    );
  };

  const [selectedOption, setSelectedOption] = useState(
    selected || (options && options[0] && options[0].value) || '',
  );

  const handleChange = (value: any): void => {
    onChange(name, value || '');
    setSelectedOption(value);
  };
  const invalidStyles = cx({
    [styles.selectError]: !isValid && isTouched && hasErrors,
  });
  let stylesForSelect = {};
  switch (type) {
    case 'user profile':
    case 'invite hcp':
    case 'connect patient':
    case 'org video bank':
    case 'exercise folder details':
    case 'rewards':
    case 'email-notif':
      stylesForSelect = { ...selectFloatLabelStyles(disabledField) };
      break;
    default:
      stylesForSelect = {
        ...selectFloatLabelStyles(disabledField),
        ...dropdownIndicatorStyles(disabledField),
      };
  }
  const customPlaceholder = (
    <div>
      <span>{placeholder || ''}</span>
      {isRedStar && <span className={styles.red_star}>*</span>}
    </div>
  );

  return (
    <label
      className={cx({
        [styles.label_wrapper]: true,
        [styles['select-float']]: true,
      })}
      htmlFor={inputId}
    >
      <Tooltip title={tooltipText || ''}>
        <div className={cx({ [styles.disabled]: disabledField })}>
          <Select
            options={options || []}
            className={`${styles.selector} ${invalidStyles}`}
            styles={{ ...stylesForSelect }}
            value={inputValue}
            defaultValue={selectedOption}
            onChange={handleChange}
            isDisabled={disabledField || disabledWithoutClass}
            placeholder={customPlaceholder}
            isClearable={isClearable}
            components={{
              ValueContainer: CustomValueContainer,
            }}
            data-qa={dataQa}
            // menuIsOpen
          />
        </div>
      </Tooltip>
      {!inputValue && isTouched && hasErrors && isShowErrorText ? (
        <div className={styles.error}>{hasErrors}</div>
      ) : null}
    </label>
  );
};
export const SelectFloatLabelWithSearch = (
  props: SelectFloatLabelWithSearchProps,
): ReactElement => {
  const {
    inputId,
    name,
    options,
    isValid,
    isTouched,
    hasErrors,
    onChange,
    inputValue,
    selected,
    disabledField = false,
    placeholder,
    noOptionsMessage,
    type = '',
    isRedStar = false,
    isClearable = false,
    dataQa,
  } = props;

  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    selected || (options && options[0] && options[0].value) || '',
  );

  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = useMemo(
    () => ({ children, ...props }: any): JSX.Element => (
      <ValueContainer {...props}>
        <Placeholder {...props}>{props.selectProps.placeholder}</Placeholder>
        {Children.map(children, (child: any) =>
          child && child.type !== Placeholder ? child : null,
        )}
      </ValueContainer>
    ),
    [],
  );
  useEffect(() => {
    setSelectedOption(inputValue);
  }, [inputValue]);

  const handleChange = (value: any): void => {
    onChange(name, value || '');
    setSelectedOption(value);
  };
  const handleInputChange = (inputVal: string): void => {
    if (inputVal && inputVal.length > 2) {
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  };
  let customStyles = {};
  switch (type) {
    case 'user profile':
    case 'invite hcp':
    case 'org patient info':
    case 'rewards':
      customStyles = { ...selectFloatLabelStyles(disabledField) };
      break;
    default:
      customStyles = {
        ...selectFloatLabelStyles(disabledField),
        ...dropdownIndicatorStyles(disabledField),
      };
  }
  const customPlaceholder = (
    <div>
      <span>{placeholder || ''}</span>
      {isRedStar && <span className={styles.red_star}>*</span>}
    </div>
  );

  return (
    <label className={styles.label_wrapper} htmlFor={inputId}>
      <div className={cx({ [styles.disabled]: disabledField })}>
        <Select
          options={showOptions ? options : []}
          className={cx({
            [styles.selectError]: !isValid && isTouched && hasErrors,
            [styles.selector]: true,
          })}
          styles={{ ...customStyles }}
          value={inputValue}
          defaultValue={selectedOption}
          onChange={handleChange}
          isDisabled={disabledField}
          onInputChange={handleInputChange}
          placeholder={customPlaceholder}
          isClearable={isClearable}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          noOptionsMessage={(): string => noOptionsMessage}
          data-qa={dataQa}
          // menuIsOpen
        />
        {!inputValue && isTouched && hasErrors ? (
          <div className={styles.error}>{hasErrors}</div>
        ) : null}
      </div>
    </label>
  );
};
