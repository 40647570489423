import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';
import { GetState, getCurrenLang } from '../../../redux/selector';
import {
  ActionItem,
  SortSearchItem,
} from '../../Video/OrganisationVideoBank/components/HeaderItems';

const WoundImagesTableHeader = ({ sortByColumnName, sortBy }: any): ReactElement => {
  const t: any = useSelector<GetState>((state) => getCurrenLang(state));
  const action = t?.dashboard.hcp.profile_patient.video_bank.action;
  const media_type = t?.hcp.org_video_bank.media_type;
  const date_sent = t?.dashboard.hcp.profile_patient.date_sent || 'Date sent';
  const comments_from_patient =
    t?.dashboard.hcp.profile_patient.comments_from_patient || 'Comments from patient';

  return (
    <div className={styles['wound-images__header']}>
      {/* Media type */}
      <div className={styles['wound-images__media-container']}>
        <ActionItem name={media_type} />
      </div>
      {/* Comments */}
      <div className={styles['wound-images__comments-container']}>
        <ActionItem name={comments_from_patient} />
      </div>
      {/* Date sent */}
      <div className={styles['wound-images__date-sent-container']}>
        <SortSearchItem
          t={t}
          fieldName={date_sent}
          sortName="documentDate"
          sortByColumnName={sortByColumnName}
          sortBy={sortBy}
        />
      </div>
      {/* Action */}
      {/* <div className={styles['org-patient-info__action']}>
        <ActionItem name={action} />
      </div> */}
    </div>
  );
};

export default WoundImagesTableHeader;
