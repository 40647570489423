import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import styles from './styles.module.css';
import { GetState, getCurrenLang, getPermissions } from '../../redux/selector';
import { CustomCheckbox } from '../../common/Input';
import { additionalFieldsAdmin } from './initValue';
import { PERMISSIONS } from '../../utils/enums';

export const PermissionsCheckboxesJSX = ({
  isAdmin,
  inputValue,
  onChangeMethod,
}: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const setsAccessLimits_otm = t?.common.otm_sets_access_limits;
  const setsAccessLimits_hcp = t?.common.hcp_sets_access_limits;
  const edit_patient_profile_info = t?.common.edit_patient_profile_info;
  const invite_or_add_hcp = t?.common.invite_or_add_hcp;
  const edit_or_prescribe_exercise_videos = t?.common.edit_or_prescribe_exercise_videos;
  const add_videos_to_team_video_bank = t?.common.add_videos_to_team_video_bank;
  const give_access_to = t?.common.give_access_to;
  const createTeam_otm = t?.common.create_team;

  const titles: any = {
    setsAccessLimits_otm,
    setsAccessLimits_hcp,
    editPatientProfileInfo_otm: edit_patient_profile_info,
    editPatientProfileInfo_hcp: edit_patient_profile_info,
    inviteOrAddHcp_otm: invite_or_add_hcp,
    inviteOrAddHcp_hcp: invite_or_add_hcp,
    editOrPrescribeExerciseVideos_otm: edit_or_prescribe_exercise_videos,
    editOrPrescribeExerciseVideos_hcp: edit_or_prescribe_exercise_videos,
    addVideosToTeamVideoBank_otm: add_videos_to_team_video_bank,
    addVideosToTeamVideoBank_hcp: add_videos_to_team_video_bank,
    createTeam_otm,
  };

  const permissions: any = useSelector<GetState>((state) => getPermissions(state));

  const sheckValue = (name: string, currentValue: boolean): boolean => {
    // For admin
    if (isAdmin) {
      return currentValue;
    }

    // For HCP
    switch (name) {
      case PERMISSIONS.EDIT_PATIENT_PROFILE_INFO_OTM: {
        if (permissions.includes(PERMISSIONS.EDIT_PATIENT_PROFILE_INFO_HCP)) {
          return currentValue;
        }
        return false;
      }
      case PERMISSIONS.INVITE_OR_ADD_HCP_OTM: {
        if (permissions.includes(PERMISSIONS.INVITE_OR_ADD_HCP_HCP)) {
          return currentValue;
        }
        return false;
      }
      case PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_OTM: {
        if (permissions.includes(PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_HCP)) {
          return currentValue;
        }
        return false;
      }
      case PERMISSIONS.ADD_VIDEOS_TO_TEAM_VIDEOBANK_OTM: {
        if (permissions.includes(PERMISSIONS.ADD_VIDEOS_TO_TEAM_VIDEOBANK_HCP)) {
          return currentValue;
        }
        return false;
      }
      default:
        return false;
    }
  };

  return (
    <>
      {/* Permission checkboxes */}
      {additionalFieldsAdmin().map((group: any) => {
        const jsx =
          !isAdmin && group.key === 'hcp' ? (
            <></>
          ) : (
            <div className={styles.form__group} key={group.key}>
              {isAdmin && (
                <div className={styles.form__row}>
                  {/* <CustomCheckbox
                    htmlId={`setsAccessLimits${group.key}`}
                    name={`setsAccessLimits_${group.key}`}
                    checked={inputValue[`setsAccessLimits_${group.key}`]}
                    onChangeMethod={(e: any): void => onChangeGroupMethod(e, group.key)}
                    isTouched={false}
                    hasErrors={false}
                  /> */}
                  <div>{titles[`setsAccessLimits_${group.key}`]}</div>
                </div>
              )}

              {/* {inputValue[`setsAccessLimits_${group.key}`] && ( */}
              <div>
                <div>{give_access_to}</div>
                {group[group.key].map((boxLabel: string) => {
                  const isDisable = isAdmin ? false : !sheckValue(boxLabel, true);
                  return (
                    <React.Fragment key={boxLabel}>
                      <div className={styles.form__row}>
                        <CustomCheckbox
                          htmlId={boxLabel}
                          name={boxLabel}
                          // checked={sheckValue(boxLabel, inputValue[boxLabel])}
                          checked={inputValue[boxLabel]}
                          onChangeMethod={onChangeMethod}
                          isTouched={false}
                          hasErrors={false}
                          disabled={isDisable}
                          bigSize
                        />
                        <div
                          className={cx({
                            [styles.form__checkbox_name]: true,
                            [styles.form__disable]: isDisable,
                          })}
                        >
                          {titles[boxLabel]}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
              {/* )} */}
            </div>
          );
        return jsx;
      })}
    </>
  );
};
