import React, { ReactElement, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { Tooltip } from 'antd';

import styles from './styles.module.css';
import {
  GetState,
  getCurrenLang,
  getHcpName,
  getIsNotSavedPatientInfo,
  getIsNotSavedPatientProfil,
  getHcpAvatarPic,
} from '../../redux/selector';
import { Logo, PersonGreyIcon } from '../../theme/icons';
import { path } from '../../utils/routers/book';
import { LIST_TYPE, PATH_TYPE } from '../../utils/enums';
import { getQueryParams, initialPage } from '../../utils/helper';
import { hcpArray, patientArray } from '../../utils/variables';
import { PopupWithTwoButtons } from '../../common/Popup';
import useCheckRole from '../../hooks/useCheckRole';
import BellIcon from './bellIcon';
import { toggleMenu } from '../../redux/common';

const HeaderApp = ({ collapsed }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const admin = t?.header.admin || 'Admin';
  const my_profile = t?.title.my_profile;
  const manage_organisations = t?.menu.manage_organisations;
  const patient_list = t?.title.patient_list;
  const hcp_list = t?.title.hcp_list;
  const cancel = t?.common.cancel;
  const exit = t?.common.exit;
  const warning_unsaved_changes = t?.common.warning_unsaved_changes;
  const manage_professions = t?.menu.manage_professions;
  const manage_proms = t?.menu.manage_proms;
  const manage_exercises = t?.menu.manage_exercises;
  const manage_departments = t?.menu.manage_departments;

  // Local states
  // const [anchorEl, setAnchorEl] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  // const [isModalOpen, setModal] = useState(false);
  // const [isModalShareVideo, setModalShareVideo] = useState(false);
  const [currentRedirectTabType, setCurrentRedirectTabType] = useState('');
  // const [isShareWarning, setWarning] = useState(false);
  // const [isSharedSuccess, setSharedSuccess] = useState(false);

  const isNotSavedPatientProfile = useSelector<any>((state) => getIsNotSavedPatientProfil(state));
  const isNotSavedPatientInfo = useSelector((state: GetState) => getIsNotSavedPatientInfo(state));
  const hcp = useSelector((state: GetState) => getHcpName(state));
  const avatar = useSelector<GetState>((state) => getHcpAvatarPic(state));
  const { notificationsList, countUnread }: any = useSelector<any>((state) => state.notifications);

  const location = useLocation();
  const history = useHistory();
  // const currentHcpEmail = fromLS && fromLS.email;
  const { isAdmin, isHcp } = useCheckRole();
  const homePath = isAdmin ? path.adminDashboardList : path.dashboardList;
  // const today = moment().format('DD-MM-YYYY');
  // const showModalDate = storage.get('show-modal-date');
  const { category } = getQueryParams(location.search);
  const { pathname } = location;

  const dispatch = useDispatch();
  const showMenu = (): void => {
    dispatch(toggleMenu(true));
  };

  // // const {_getUserName, loading, hcp } = useQueryUserName(!isHcp);
  // const { _getUserName, loading } = useQueryUserName(!hcp.recipients?.length);
  // useQueryAdminCountry(!isAdmin);
  // const {
  //   _getPatientInvitations,
  //   patientsInvitations,
  //   total,
  //   listLoading,
  // } = usePatientsPendingInvitations();
  // const { _getSharingNotification, dataSharingNotif } = useGetSharingNotification();
  // const {
  //   _setHcpInformedAboutSharing,
  //   setHcpInformedLoading,
  //   setHcpInformedError,
  //   isSetHcpInformed,
  // } = useSetHcpInformedAboutSharing();

  // // Share VideoBank to another HCP(Hospital)
  // const { _shareVideobank, isSharingSuccess, shareLoading, shareError
  // } = useSharingTeamVideobank();

  const categoryFromQuery = String(category);
  const isPatientCategory = patientArray.includes(categoryFromQuery);
  const isHCPCategory = hcpArray.includes(categoryFromQuery);

  // const { onLogout } = useLogout(isAdmin);
  // const open = Boolean(anchorEl);

  // const handleClick = (event: any): void => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = (): void => {
  //   setAnchorEl(null);
  // };

  // const logout = (): void => {
  //   onLogout();
  //   const redirectTo = isAdmin ? path.adminSignIn : path.signIn;

  //   history.push(redirectTo);
  //   storage.save('user', '');
  //   storage.save(currentHcpEmail, today);
  // };

  const handlePath = (type: string): void => {
    switch (type) {
      case PATH_TYPE.PROFILE:
        if (pathname !== path.profile) {
          history.push({ pathname: path.profile });
        }
        break;
      // case PATH_TYPE.MANAGE_ORGANISATION:
      //   history.push({ pathname: path.manage_organisations });
      //   break;
      // case PATH_TYPE.MANAGE_PROFESSIONS:
      //   history.push({ pathname: path.manage_professions });
      //   break;
      // case PATH_TYPE.MANAGE_PROMS:
      //   history.push({ pathname: path.manage_proms });
      //   break;
      // case PATH_TYPE.MANAGE_REWARDS:
      //   if (isAdmin) {
      //     history.push({ pathname: path.manage_rewards_admin });
      //   }
      //   if (isHcp) {
      //     history.push({ pathname: path.manage_rewards_hcp });
      //   }
      //   break;
      // case PATH_TYPE.MANAGE_EXERCISES:
      //   history.push({ pathname: path.manage_exercises });
      //   break;
      // case PATH_TYPE.MANAGE_DEPARTMENTS:
      //   history.push({ pathname: path.manage_departments });
      //   break;
      case LIST_TYPE.PATIENT:
        if (isAdmin) {
          history.push({
            pathname: path.adminDashboardList,
            search: `category=${LIST_TYPE.PATIENT_ADMIN}&page=${initialPage}`,
          });
        } else {
          history.push({
            pathname: path.dashboardList,
            search: `category=${LIST_TYPE.PATIENT}&page=${initialPage}`,
          });
        }
        break;
      case LIST_TYPE.HCP:
        if (isAdmin) {
          history.push({
            pathname: path.adminDashboardList,
            search: `category=${LIST_TYPE.HCP_ADMIN}&page=${initialPage}`,
          });
        } else {
          history.push({
            pathname: path.dashboardList,
            search: `category=${LIST_TYPE.HCP}&page=${initialPage}`,
          });
        }
        break;
      // case PATH_TYPE.MANAGE_PATIENT_INFORMATION: {
      //   const pathname = isAdmin
      //     ? path.manage_patient_information_admin
      //     : path.manage_patient_information;
      //   history.push({ pathname });
      //   break;
      // }
      default:
        history.push({ pathname: homePath });
    }
  };

  // Check unsaved Patient profile
  const checkUnsavedPatientProfile = (tabType: string): void => {
    if (isNotSavedPatientProfile || isNotSavedPatientInfo) {
      setCurrentRedirectTabType(tabType);
      return setShowPopup(() => true);
    }
    // if (tabType === 'logout') {
    //   return logout();
    // }
    return handlePath(tabType);
  };

  // Clouse popup
  const onClose = (): void => {
    setCurrentRedirectTabType('');
    setShowPopup(() => false);
  };

  // Confirm method
  const onConfirm = (): void => {
    // if (currentRedirectTabType === 'logout') {
    //   logout();
    // } else {
    handlePath(currentRedirectTabType);
    // }
    onClose();
  };

  // Open/close notification window
  const showNotifications = (): void => {
    history.push({
      pathname: path.all_notification,
    });
  };

  // const clickLogoHandler = (): void => {
  //   const key = isAdmin ? LIST_TYPE.HCP : LIST_TYPE.PATIENT;
  //   checkUnsavedPatientProfile(key);
  // };

  // useEffect(() => {
  //   // if (!isAdmin && showModalDate !== today) {
  //   if (!isAdmin) {
  //     _getUserName();
  //     _getPatientInvitations({
  //       variables: {
  //         listProps: {
  //           page: 1,
  //           itemsPerPage: 15,
  //         },
  //         pending: true,
  //         searchByDate: [],
  //       },
  //     });
  //   }
  // }, []);

  // useEffect(() => {
  //   if (patientsInvitations && patientsInvitations.length > 0) {
  //     let isNewRequest = false;

  //     if (showModalDate.patientsIds) {
  //       patientsInvitations.map((patient: { id: number }) => {
  //         if (!showModalDate.patientsIds.includes(patient.id)) {
  //           isNewRequest = true;
  //         }
  //         return null;
  //       });
  //     }

  //     if (isNewRequest) {
  //       setModal(() => true);
  //       return;
  //     }

  //     if (showModalDate.today !== today) {
  //       setModal(() => true);
  //     }
  //   }
  // }, [patientsInvitations]);

  // const onclose = (): void => {
  //   const patientsIds = patientsInvitations.map((patient: { id: number }) => patient.id);
  //   storage.save('show-modal-date', {
  //     today,
  //     patientsIds,
  //   });
  //   setModal(() => false);
  // };

  // // If share error then show message.
  // useEffect(() => {
  //   if (isSharingSuccess) {
  //     setWarning(() => false);
  //     setSharedSuccess(() => true);
  //     _setHcpInformedAboutSharing(hcp.recipients[0].id);
  //   }
  // }, [isSharingSuccess]);

  // // If share error then show message.
  // useEffect(() => {
  //   if (shareError) {
  //     toast.error(shareError);
  //     _setHcpInformedAboutSharing(hcp.recipients[0].id);
  //   }
  // }, [shareError]);

  // // If success then update User params.
  // useEffect(() => {
  //   if (isSetHcpInformed && !isAdmin) {
  //     _getUserName();
  //   }
  // }, [isSetHcpInformed]);

  // // If error show message.
  // useEffect(() => {
  //   if (setHcpInformedError && !isAdmin) {
  //     toast.error(setHcpInformedError);
  //     _getUserName();
  //   }
  // }, [setHcpInformedError]);

  // // Check if there are any unread notifications about sharing TeamVideobank
  // // from another hospital.
  // useEffect(() => {
  //   if (dataSharingNotif) {
  //     setModalShareVideo(() => true);
  //   }
  // }, [dataSharingNotif]);

  // // Check if there are any unread notifications about sharing TeamVideobank
  // // from another hospital.
  // useEffect(() => {
  //   if (hcp?.recipients.length > 0) {
  //     _getSharingNotification({
  //       variables: {
  //         hcpId: hcp.recipients[0].initiatorId,
  //       },
  //     });
  //   }
  // }, [hcp.recipients]);

  // // Clouse shareModal and send to DB cancel
  // const onCancelShare = (): void => {
  //   setModalShareVideo(() => false);
  //   setWarning(() => false);
  //   _setHcpInformedAboutSharing(hcp.recipients[0].id);
  // };

  // // Share team video bank to another organisation
  // const showWarningToShareVideo = (): void => {
  //   setModalShareVideo(() => false);
  //   setWarning(() => true);
  // };
  // // Send request to DB
  // const shareVideoBank = (): void => {
  //   _shareVideobank(hcp.recipients[0].initiatorId);
  // };

  // JSX
  // const hcpMenuItems = (
  //   <>
  //     {isHcp && (
  //       <>
  //         <div
  //           onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.PROFILE)}
  //           role="presentation"
  //         >
  //           <MenuItem onClick={handleClose}>
  //             <div className={styles.menu__icon}>
  //               <img src={ProfileIcon} alt="profile" />
  //             </div>
  //             {profile}
  //           </MenuItem>
  //         </div>
  //         <div
  //           onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.MANAGE_EXERCISES)}
  //           role="presentation"
  //         >
  //           <MenuItem onClick={handleClose}>
  //             <div className={styles.menu__icon}>
  //               <img src={ExerciseIcon} alt="manage" />
  //             </div>
  //             {manage_exercises}
  //           </MenuItem>
  //         </div>
  //         {/* <div
  //           onClick={(): void =>
  // checkUnsavedPatientProfile(PATH_TYPE.MANAGE_PATIENT_LIST_ACCESS)}
  //           role="presentation"
  //         >
  //           <MenuItem onClick={handleClose}>
  //             <div className={styles.menu__icon}>
  //               <img src={ManyPeopleIcon} alt="manage" />
  //             </div>
  //             {manage_patient_list_access}
  //           </MenuItem>
  //         </div> */}
  //       </>
  //     )}
  //   </>
  // );
  // const adminMenuItems = (
  //   <>
  //     {isAdmin && (
  //       <>
  //         <div
  //           onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.MANAGE_ORGANISATION)}
  //           role="presentation"
  //         >
  //           <MenuItem onClick={handleClose}>
  //             <div className={styles.menu__icon}>
  //               <img src={PencilIcon} alt="organisation" />
  //             </div>
  //             {manage_organisations}
  //           </MenuItem>
  //         </div>
  //         <div
  //           onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.MANAGE_PROFESSIONS)}
  //           role="presentation"
  //         >
  //           <MenuItem onClick={handleClose}>
  //             <div className={styles.menu__icon}>
  //               <img src={PencilIcon} alt="professions" />
  //             </div>
  //             {manage_professions}
  //           </MenuItem>
  //         </div>
  //         <div
  //           onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.MANAGE_PROMS)}
  //           role="presentation"
  //         >
  //           <MenuItem onClick={handleClose}>
  //             <div className={styles.menu__icon}>
  //               <img src={PencilIcon} alt="professions" />
  //             </div>
  //             {manage_proms}
  //           </MenuItem>
  //         </div>
  //         <div
  //           onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.MANAGE_DEPARTMENTS)}
  //           role="presentation"
  //         >
  //           <MenuItem onClick={handleClose}>
  //             <div className={styles.menu__icon}>
  //               <img src={TeamIcon} alt="professions" />
  //             </div>
  //             {manage_departments}
  //           </MenuItem>
  //         </div>
  //       </>
  //     )}
  //   </>
  // );

  const hcpPatientTabs = (
    <div className={styles['header__tabs-container']}>
      {/* Patients TAB */}
      <div
        className={cx({
          [styles.header__tab]: true,
          [styles['header__tab--active']]: isPatientCategory,
        })}
        onClick={(): void => checkUnsavedPatientProfile(LIST_TYPE.PATIENT)}
        role="tabpanel"
        aria-hidden
      >
        {patient_list}
      </div>
      <div
        className={cx({
          [styles.header__tab]: true,
          [styles['header__tab--active']]: isHCPCategory,
        })}
        onClick={(): void => checkUnsavedPatientProfile(LIST_TYPE.HCP)}
        role="tabpanel"
        aria-hidden
      >
        {hcp_list}
      </div>
    </div>
  );

  return (
    <div className={styles.header__wrapper}>
      <div
        className={cx({
          [styles.header__container]: true,
          [styles.collapsed]: collapsed,
        })}
      >
        {/* Logo smscreen */}
        <div className={styles['header__left-container']}>
          <div className={styles.header__logo}>
            <Logo />
          </div>
          {/* Burger menu */}
          <div className={styles.header__burger} onClick={showMenu} aria-hidden>
            <div className={styles['header__burger-item']} />
            <div className={styles['header__burger-item']} />
            <div className={styles['header__burger-item']} />
          </div>
          {/* TABS (full screen) */}
          <div className={styles.fullScreenTabs}>{hcpPatientTabs}</div>
        </div>
        <div className={styles['menu-container']}>
          {isHcp && (
            <div className={styles['notification-container']}>
              {/* ICON BELL */}
              <BellIcon
                notificationsList={notificationsList}
                countUnread={countUnread}
                showNotifications={showNotifications}
              />
            </div>
          )}

          <Tooltip title={isAdmin ? '' : my_profile}>
            <div
              className={styles['header__greeting-container']}
              onClick={
                isAdmin
                  ? (): null => null
                  : (): void => checkUnsavedPatientProfile(PATH_TYPE.PROFILE)
              }
              aria-hidden
            >
              {isHcp && hcp ? (
                <div className={styles['header__name-container']}>
                  <div className={styles.header__name}>{`${hcp.firstName} ${hcp.lastName}`}</div>
                  <div className={styles.header__profession}>{hcp.profession}</div>
                </div>
              ) : (
                <></>
              )}
              {isAdmin ? <span className={styles.greetingAdmin}>{admin}</span> : <></>}
              <div className={styles['header__greeting-icon']}>
                {avatar ? (
                  <img
                    src={`data:image/jpeg;charset=utf-8;base64,${avatar}`}
                    alt="Avatar"
                    crossOrigin="anonymous"
                  />
                ) : (
                  <PersonGreyIcon />
                )}
              </div>
            </div>
          </Tooltip>
        </div>
      </div>

      {/* Tabs sm screen */}
      <div className={styles.mobileScreenTabs}>{hcpPatientTabs}</div>

      {showPopup && (
        <PopupWithTwoButtons
          title={warning_unsaved_changes}
          content=""
          confirmButtonName={exit}
          closeButtonName={cancel}
          onConfirm={onConfirm}
          onClosePopup={onClose}
        />
      )}

      {/* <AppBar color="transparent">
          <div className={styles.container}>
            <div className={styles.row}>
              <div className={styles['header__logo-tabs-container']}>
              </div>
              <div className={styles['menu-container']}>
                <div
                  className={styles['user-container']}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  role="presentation"
                >
                  <PersonIcon className={user} />
                  <span className={styles.user}>
                    {!loading && hcp && (
                      <>
                        {hcp.firstName} {hcp.lastName}
                      </>
                    )}
                  </span>
                  <div className={styles['expand-icon']}>
                    <ExpandMoreIcon className={expand} />
                  </div>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                  }}
                  transformOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                  }}
                >
                  <div className={styles.contant_profile}>
                    {hcpMenuItems}
                    {adminMenuItems}
                    <div
                      onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.MANAGE_REWARDS)}
                      role="presentation"
                    >
                      <MenuItem onClick={handleClose}>
                        <div className={styles.menu__icon}>
                          <img src={RewardIcon} alt="rewards" />
                        </div>
                        {manage_rewards}
                      </MenuItem>
                    </div>
                    <div
                      onClick={(): void =>
                        checkUnsavedPatientProfile(PATH_TYPE.MANAGE_PATIENT_INFORMATION)
                      }
                      role="presentation"
                    >
                      <MenuItem onClick={handleClose}>
                        <div className={styles.menu__icon}>
                          <img src={PencilIcon} alt="manage video" />
                        </div>
                        {manage_patient_nformation}
                      </MenuItem>
                    </div>
                    <MenuItem onClick={(): void => checkUnsavedPatientProfile('logout')}>
                      <div className={styles.menu__icon}>
                        <img src={ExitIcon} alt="exit" />
                      </div>
                      {logout_btn}
                    </MenuItem>
                  </div>
                </Menu>
              </div>
            </div> */}

      {/* LIST TABS (full screen) */}
      {/* <div className={styles.mobileScreenTabs}>{hcpPatientTabsList}</div>
          </div>
        </AppBar> */}

      {/* Popup if share team videobank anoter HCP */}
      {/* {isModalShareVideo && !isModalOpen && !isSharedSuccess && (
        <Modal style={styles['share-modal']} onClose={onCancelShare}>
          <div className="btn-close">
            <CloseIcon onClick={onCancelShare} />
          </div>
          <div>
            <NotificationSucceess
              title={dataSharingNotif?.title || ''}
              contant={dataSharingNotif?.body || ''}
            />
            <div className={styles['share-modal__btn-wrapper']}>
              <Button
                buttonType="button"
                buttonName={dataSharingNotif.isTwoWaySharing ? ok : no}
                buttonMethod={onCancelShare}
              />
              {!dataSharingNotif.isTwoWaySharing && (
                <Button
                  buttonType="button"
                  buttonName={yes}
                  buttonMethod={showWarningToShareVideo}
                />
              )}
            </div>
          </div>
        </Modal>
      )} */}
      {/* {isShareWarning && (
        <Modal style={shareStyle.modal} onClose={onCancelShare}>
          <div className="btn-close">
            <CloseIcon onClick={onCancelShare} />
          </div>
          <WarningNotification
            title=""
            contant={
              all_video_will_be_available?.replace(
                '<organisationName>',
                hcp.recipients?.[0]?.hcpInitiator?.hospital?.name || '',
              ) || ''
            }
            buttonName={share}
            onCancel={onCancelShare}
            onSubmit={shareVideoBank}
          />
        </Modal>
      )} */}
      {/* {isSharedSuccess && (
        <Modal
          style={shareStyle.modal__success}
          onClose={(): void => setSharedSuccess(() => false)}
        >
          <ConfirmatioModal
            title={success}
            contant={video_successfully_shared}
            buttonTitle={ok}
            buttonMethod={(): void => setSharedSuccess(() => false)}
          />
        </Modal>
      )} */}
    </div>
  );
};

// export default withAuthContext(HeaderApp);
export default HeaderApp;
