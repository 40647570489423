import { loader } from 'graphql.macro';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { ExecutionResult } from 'graphql';

import { useMutationRequest } from '../../hooks/feature/useMutationRequest';
import { storage, path } from '../../utils';
import {
  setCurrentCountry,
  setHcpAvatarPic,
  setHcpName,
  setHcpNameLoading,
} from '../../redux/common';
import {
  Form,
  FormChangeHcpPassword,
  UseChangeGivingAccessToMyPatientsProps,
  UseCurrentHcpProfileForToggler,
  UseDeactiveteHcp,
  UseDeleteHcpProfile,
  UseHcpChangePassword,
  UseQueryHcpProfile,
  UseQueryUserProfile,
  UseUpdateHcpProfile,
  UseUpdateHcpProfileByAdmin,
  UseUpdateHcpProfileByHcp,
} from '../types';
import { config } from '../../utils/configs';
import { SharingInitiatorsType } from '../../components/HeaderApp/types';
import useHandleGqlError from '../../hooks/useHandleGqlError';
import {
  setNotifToMeData,
  setNotifToMeError,
  setNotifToMeLoading,
} from '../../redux/emailNotifications';

// Mutations
const gqlHcp = loader('./gql/mutationProfileHcp.graphql');
const deleteHcpAccount = loader('./gql/mutationDeleteHcpByHcp.graphql');
const changeHcpPass = loader('./gql/mutationHcpChangePassword.graphql');
const mutationDeleteHcp = loader('./gql/mutationDeleteHcp.graphql');
const updateHcpProfileByAdmin = loader('./gql/mutationHcpUpdateProfileByAdmin.graphql');
const updateHcpProfileByHcp = loader('./gql/mutationUpdateHcpProfByHcp.graphql');
const setAllowPatientUnarchiveExercise = loader(
  './gql/mutationSetAllowPatientUnarchiveExercise.graphql',
);
const changeGivingAccessToMyPatients = loader(
  './gql/mutationChangeGivingAccessToMyPatients.graphql',
);
const updateHcpPhoto = loader('./gql/mutationUpdateHcpPhoto.graphql');
const userPic = loader('./gql/getUserPic.graphql');
const removeUserPic = loader('./gql/mutationRemoveUserPic.graphql');

// Queries
const getProfileHcp = loader('./gql/queryProfile.graphql');
const getName = loader('./gql/queryUserName.graphql');
const getAdmin = loader('./gql/queryAdminProfile.graphql');
const queryGetProfileHcpById = loader('./gql/queryGetHcpById.graphql');
const gqlNotificationsSet = loader('./gql/queryManageNotifikation.graphql');
const getCurrentHcpProfileForToggler = loader('./gql/queryGetCurrentHcpProfileForToggler.graphql');

export const useUpdateHcpProfile = (): UseUpdateHcpProfile => {
  const [_update, { data, errorText, loading }] = useMutationRequest<{
    updateHcpProfile: { access_token: string; refresh_token: string };
  }>(gqlHcp, {});

  const _updateProfile = (form: any): void => {
    _update({
      variables: {
        hcpUpdateData: form,
      },
    });
  };

  // const token = data && data.updateHcpProfile.access_token;
  // const refresh_token = data && data.updateHcpProfile.refresh_token;

  // if (token) {
  //   const {
  //     email, id, role, lang,
  //   } = parseJwt(token);
  //   const setUser = {
  //     email,
  //     id,
  //     lang,
  //     role,
  //     token,
  //     refresh_token,
  //   };
  //   storage.save('user', setUser);
  //   storage.save('lang', lang);
  //   window.location.assign(path.profile);
  // }

  return {
    _updateProfile,
    updateData: data,
    updating: loading,
    errorMessage: errorText,
  };
};

// User profile (HCP)
export const useQueryUserProfile = (): UseQueryUserProfile => {
  const { loading, error, data } = useQuery(getProfileHcp, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  return {
    loading,
    error,
    hcp: data && data.getCurrentHcpProfile,
  };
};

// Get HCP (Name, country, permissions, etc...)
export const useQueryUserName = (isPolling: boolean) => {
  const [_getUserName, { loading, error, data }] = useLazyQuery(getName, {
    pollInterval: isPolling ? config.pollInterval : 0,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });
  const dispatch = useDispatch();

  const response: any = {};
  if (data) {
    const res = data.getCurrentHcpProfile;
    const allowedPromsId =
      res.hospital?.hospitalQuesionnaire?.map((item: { id: number }) => item.id) || [];
    const userPermissions = res.hcpPermission?.map((item: { name: string }) => item.name) || [];
    response.country = res.country;
    response.firstName = res.firstName;
    response.id = res.id;
    response.email = res.email;
    response.lastName = res.lastName;
    response.allowedPromsId = allowedPromsId;
    response.permissions = userPermissions;
    response.hospitalId = res.hospitalId;
    response.hospital = res.hospital.name;
    response.isAutomaticallyAccept = res.isAutomaticallyAccept;
    response.rewardsType = res.hospital?.hospitalProgramRewards.programReward.order || 1;
    response.profession = res.profession?.type || '';
    response.photo = res.photo || '';
    response.photoPic = '';
    // Take no more than one item at a time
    const filteredRecipients = res.recipients?.filter(
      (recipient: SharingInitiatorsType) => !recipient.isHcpInformedAboutSharing,
    )[0];
    response.recipients = filteredRecipients ? [filteredRecipients] : [];
  }

  useEffect(() => {
    if (data) {
      dispatch(setHcpName(response));
      dispatch(setCurrentCountry(response.country));
    }
  }, [data?.getCurrentHcpProfile]);

  useEffect(() => {
    dispatch(setHcpNameLoading(loading));
  }, [loading]);

  return {
    _getUserName,
    loading,
    error: error?.graphQLErrors?.[0]?.message || error,
    hcpData: data && response,
    hcpPhotoUuid: data && response.photo,
  };
};

// get current setting for manage HCP notifications
export const useCurrentHcpProfileForToggler = (): UseCurrentHcpProfileForToggler => {
  const [_getTogglerSetting, { loading, error, data }] = useLazyQuery(
    getCurrentHcpProfileForToggler,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );
  const dispatch = useDispatch();
  const errorText = useHandleGqlError(error);
  let response;

  useEffect(() => {
    if (data) {
      response = data.getCurrentHcpProfileForToggler;
      dispatch(setNotifToMeData(response));
    }
  }, [data]);

  useEffect(() => {
    dispatch(setNotifToMeLoading(loading));
  }, [loading]);

  useEffect(() => {
    dispatch(setNotifToMeError(errorText));
  }, [error]);

  return {
    _getTogglerSetting,
    dataTogglerSetting: data?.getCurrentHcpProfileForToggler,
    errorTogglerSettings: errorText,
    loadingTogglerSettings: loading,
    togglerData: data,
  };
};

export const useQueryAdminCountry = (skip = false) => {
  const { loading, error, data } = useQuery(getAdmin, {
    skip,
  });
  const response = data && data.getCurrentAdmin;
  const dispatch = useDispatch();

  useEffect(() => {
    if (response) {
      dispatch(setCurrentCountry(response.country));
    }
  }, [response]);

  return {
    loadingAdmin: loading,
    error: error?.graphQLErrors?.[0]?.message || error,
    adminProfile: response,
  };
};

export const useDeleteHcpProfile = (currentHcpEmail: string): UseDeleteHcpProfile => {
  const [_deleteHcpProfile, { data, errorText, loading }] = useMutationRequest<{
    deleteHcpByHcp: boolean;
  }>(deleteHcpAccount, {});
  const response = data && data.deleteHcpByHcp;
  if (response) {
    storage.save('user', '');
    storage.save('lang', '');
    storage.save('t', '');
    storage.save('show-modal-date', '');
    storage.save(currentHcpEmail, '');
    window.location.assign(path.success_delete_hcp_account);
  }

  return {
    _deleteHcpProfile,
    deleteProcess: loading,
    deleteError: errorText,
    isDeletedHcpProfile: response,
  };
};

export const useHcpChangePassword = (): UseHcpChangePassword => {
  const [_changePassword, { data, errorText, loading }] = useMutationRequest<{
    hcpChangePassword: boolean;
  }>(changeHcpPass, {});

  const _changeHcpPassword = (form: FormChangeHcpPassword): void => {
    _changePassword({
      variables: {
        hcpChangePasswordData: form,
      },
    });
  };

  return {
    _changeHcpPassword,
    loadingChangePass: loading,
    errorChangePass: errorText,
    isPasswordChanged: data && data.hcpChangePassword,
  };
};

export const useDeactiveteHcp = (): UseDeactiveteHcp => {
  const [_onDeactivete, { loading, errorText, data }] = useMutationRequest<{ deleteHcp: boolean }>(
    mutationDeleteHcp,
    {},
  );

  const _onDeactiveteHcp = (
    params: any,
  ): Promise<
    ExecutionResult<{
      deleteHcp: boolean;
    }>
  > => {
    console.log();

    return _onDeactivete({
      variables: {
        hcpId: params,
      },
    });
  };

  return {
    _onDeactiveteHcp,
    deactiveteHcpLoading: loading,
    deactiveteHcpError: errorText,
    seccessDeletedHcp: data && data.deleteHcp,
  };
};

// Save HCP profile by Admin
export const useUpdateHcpProfileByAdmin = (): UseUpdateHcpProfileByAdmin => {
  const [_update, { data, errorText, loading }] = useMutationRequest<{
    updateHcpProfileByAdmin: boolean;
  }>(updateHcpProfileByAdmin, {});

  const _updateProfile = (id: number | string, hcpUpdateData: Form, hcpPermissions: any): void => {
    _update({
      variables: {
        hcpId: id,
        hcpUpdateData,
        hcpPermissions,
      },
    });
  };

  return {
    updating: loading,
    _updateProfile,
    errorMessage: errorText,
    successUpdatedProfile: data && data.updateHcpProfileByAdmin,
  };
};

// Save HCP profile by other HCP
export const useUpdateHcpProfileByHcp = (): UseUpdateHcpProfileByHcp => {
  const [_updateByHcp, { data, errorText, loading }] = useMutationRequest<{
    updateHcpProfileByHcp: boolean;
  }>(updateHcpProfileByHcp, {});

  const _updateProfileByHcp = (id: number | string, form: string[]): void => {
    _updateByHcp({
      variables: {
        hcpId: id,
        hcpPermissions: form,
      },
    });
  };

  return {
    loadingByHcp: loading,
    _updateProfileByHcp,
    errorMessageByHcp: errorText,
    successUpdatedProfileByHcp: data && data.updateHcpProfileByHcp,
  };
};

// Get hcp profile by hcp or admin
export const useQueryHcpProfile = (): UseQueryHcpProfile => {
  const [_getHcpById, { loading, error, data }] = useLazyQuery(queryGetProfileHcpById, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });
  const user = data && data.getHcpById;
  if (data) {
    const res = user.hcpPermission?.map((item: { name: string }) => {
      user[item.name] = true;
      return null;
    });
    const invitersId = user.invitees.map((item: { inviterId: number }) => item.inviterId);
    user.invitersId = [...invitersId] || [];
  }
  return {
    _getHcpById,
    loading,
    error: error?.graphQLErrors?.[0]?.message || error,
    hcp: user,
  };
};

// Set allow patient unarchive exercise in his App (Manage exercises)
export const useSetAllowPatientUnarchiveExercise = (): any => {
  const [_onChangeAllowUnarchiveExercise, { data, errorText, loading }] = useMutationRequest<{
    setAllowPatientUnarchiveExercise: boolean;
  }>(setAllowPatientUnarchiveExercise, { fetchPolicy: 'no-cache' });
  const _changeAllowUnarchiveExercise = (checked: boolean): void => {
    _onChangeAllowUnarchiveExercise({
      variables: {
        isAllowPatientUnarchive: checked,
      },
    });
  };

  return {
    _changeAllowUnarchiveExercise,
    changedAllowUnarchiveExercise: data?.setAllowPatientUnarchiveExercise,
    allowUnarchiveExerciseError: errorText,
    allowUnarchiveExerciseLoading: loading,
  };
};

// Set "Allow any healthcare professional I invite to join On The Mend to
// view my entire patient list (Manage Patient List Access)
export const useChangeGivingAccessToMyPatients = (): UseChangeGivingAccessToMyPatientsProps => {
  const [_onChangeGivingAccessToMyPatients, { data, errorText, loading }] = useMutationRequest<{
    changeGivingAccessToMyPatients: boolean;
  }>(changeGivingAccessToMyPatients, { fetchPolicy: 'no-cache' });

  const _changeGivingAccessToMyPatients = (checked: boolean): void => {
    _onChangeGivingAccessToMyPatients({
      variables: {
        solution: checked,
      },
    });
  };

  return {
    _changeGivingAccessToMyPatients,
    changedGivingAccessToMyPatients: data?.changeGivingAccessToMyPatients,
    changeGivingAccessToMyPatientsError: errorText,
    changeGivingAccessToMyPatientsLoading: loading,
  };
};

// Update HCP photo for profile
export const useUpdateHcpPhoto = () => {
  const [_onUpdateHcpPhoto, { data, errorText, loading }] = useMutationRequest<{
    updateHcpPhoto: boolean;
  }>(updateHcpPhoto, {});

  const _updateHcpPhoto = (request: { hcpPhoto: string | null }): void => {
    _onUpdateHcpPhoto({
      variables: request,
    });
  };

  return {
    _updateHcpPhoto,
    loadingHcpPhoto: loading,
    errorHcpPhoto: errorText,
    updatedHcpPhoto: data && data.updateHcpPhoto,
  };
};

// get user avatar pic (base64)
export const useQueryUserPic = () => {
  const [_getPic, { loading, error, data }] = useLazyQuery(userPic, {});

  const dispatch = useDispatch();
  const _getUserPic = (uuid: string): void => {
    _getPic({
      variables: {
        pictureUuid: uuid,
      },
    });
  };

  useEffect(() => {
    if (data) {
      dispatch(setHcpAvatarPic(data.getUserPic));
    }
  }, [data]);

  return {
    _getUserPic,
    loadingUserPic: loading,
    errorUserPic: error,
    userPic: data && data.getUserPic,
  };
};

// get user avatar pic (base64)
export const useQueryHcpPic = () => {
  const [_onGetHcpPic, { loading, error, data }] = useLazyQuery(userPic, {});

  const _getHcpPic = (uuid: string): void => {
    _onGetHcpPic({
      variables: {
        pictureUuid: uuid,
      },
    });
  };

  return {
    _getHcpPic,
    loadingHcpPic: loading,
    errorHcpPic: error,
    hcpPic: data && data.getUserPic,
    dataHcpPic: data,
  };
};

// Remove user avatar pic
export const useRemoveUserPic = () => {
  const [_removePic, { data, errorText, loading }] = useMutationRequest<{
    removeUserPic: boolean;
  }>(removeUserPic, {});

  const _removeUserPic = (uuid: string): void => {
    _removePic({
      variables: {
        pictureUuidKey: uuid,
      },
    });
  };

  return {
    _removeUserPic,
    loadingRemoveUserPic: loading,
    errorRemovePic: errorText,
    removePicData: data && data.removeUserPic,
  };
};

// Remove old avatar pic after update avatar
export const useRemoveUserPicAfterUpdate = () => {
  const [_removePic, { data, errorText, loading }] = useMutationRequest<{
    removeUserPic: boolean;
  }>(removeUserPic, {});

  const _removeOldUserPic = (uuid: string): void => {
    _removePic({
      variables: {
        pictureUuidKey: uuid,
      },
    });
  };

  return {
    _removeOldUserPic,
    loadingRemoveOldUserPic: loading,
    errorRemoveOldPic: errorText,
    removeOldPicData: data && data.removeUserPic,
  };
};
