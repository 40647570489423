import { ChartValuesType } from '../motivations';

export const FILL_PATIENTS_STEPS = 'FILL_PATIENTS_STEPS';
export const STEPS_LOADING = 'STEPS_LOADING';
export const STEPS_ERROR = 'STEPS_ERROR';

export const FILL_EXERCISE_COMPLETION = 'FILL_EXERCISE_COMPLETION';
export const EXERCISE_COMPLETION_LOADING = 'EXERCISE_COMPLETION_LOADING';
export const EXERCISE_COMPLETION_ERROR = 'EXERCISE_COMPLETION_ERROR';
export const FILL_EXERCISES_PER_PERIOD = 'FILL_EXERCISES_PER_PERIOD';
export const EXERC_PER_PERIOD_LOADING = 'EXERC_PER_PERIOD_LOADING';
export const FILL_NOT_COMPLETION_EXERCISE = 'FILL_NOT_COMPLETION_EXERCISE';
export const NOT_COMPLETION_EXERCISE_LOADING = 'NOT_COMPLETION_EXERCISE_LOADING';
export const NOT_COMPLETION_EXERCISE_ERROR = 'NOT_COMPLETION_EXERCISE_ERROR';

// OLD
export const FILL_EXERCISES_OVERVIEW_TAB = 'FILL_EXERCISES_OVERVIEW_TAB';
export const FILL_EXERCISES_MOTIVATION_PAIN_TAB = 'FILL_EXERCISES_MOTIVATION_PAIN_TAB';
export const FILL_EXERCISES_REASONS_TAB = 'FILL_EXERCISES_REASONS_TAB';
export const FILL_DATA_BY_EXERCISES = 'FILL_DATA_BY_EXERCISES';
export const FILL_PATIENT_COMPLETINS = 'FILL_PATIENT_COMPLETINS';
export const SET_EXERCISE_NAME = 'SET_EXERCISE_NAME';
export const SET_IS_EXERCISE = 'SET_IS_EXERCISE';
export const REASONS_LEGEND_VALUE = 'REASONS_LEGEND_VALUE';
export const MOTIVATIONS_LEGEND_VALUE = 'MOTIVATIONS_LEGEND_VALUE';
export const BY_EXERCISES_LEGEND_VALUE = 'BY_EXERCISES_LEGEND_VALUE';
export const COMPLETATION_STEPS_LEGEND_VALUE = 'COMPLETATION_STEPS_LEGEND_VALUE';
export const COMPLETATION_SKIP_STOP_LEGEND_VALUE = 'COMPLETATION_SKIP_STOP_LEGEND_VALUE';

export type ErrorHttpAction = any;
export interface DataType {
  data: object[];
}
export interface ExercPerPeriodType {
  id: number;
  name: string;
  exerciseDone: any;
  timesPerDay: number;
}
export interface StepsDataType {
  graph: string;
  title: string;
  description: string;
  averagePercent: number;
  values: ChartValuesType[];
  axis: string;
}
export interface ExercComplDataType {
  graph: string;
  title: string;
  description: string;
  averagePercent: number;
  values: ChartValuesType[];
  axis: string;
}
export interface NotComplExercValuesType {
  name: string;
  numberOfSkippedExercises: number;
  numberOfStoppedExercises: number;
  averageNumberOfReps: number;
  reasons: {
    tooPainful: number;
    lackOfEquipment: number;
    noTime: number;
    didntUnderstand: number;
    fatigue: number;
    willDoLater: number;
    alreadyDone: number;
    noReason: number;
    restDay: number;
  };
}
export interface NotComplExercDataType {
  graph: string;
  title: string;
  description: string;
  axisNumber: string;
  axisReasons: string;
  values: NotComplExercValuesType[];
}

export interface PatientFillStep {
  type: typeof FILL_PATIENTS_STEPS;
  payload: StepsDataType[] | [];
}
export interface FillPatientExercCompl {
  type: typeof FILL_EXERCISE_COMPLETION;
  payload: ExercComplDataType[];
}
export interface FillPatientNotComplExer {
  type: typeof FILL_NOT_COMPLETION_EXERCISE;
  payload: NotComplExercDataType[];
}
export interface SetPerPeriodDataAction {
  type: typeof FILL_EXERCISES_PER_PERIOD;
  payload: ExercPerPeriodType[];
}

export interface LoadingActionType {
  type:
    | typeof EXERC_PER_PERIOD_LOADING
    | typeof STEPS_LOADING
    | typeof EXERCISE_COMPLETION_LOADING
    | typeof NOT_COMPLETION_EXERCISE_LOADING;
  payload: boolean;
}

export interface PatientFillAction {
  type:
    | typeof FILL_EXERCISES_OVERVIEW_TAB
    | typeof FILL_EXERCISES_MOTIVATION_PAIN_TAB
    | typeof FILL_EXERCISES_REASONS_TAB
    | typeof REASONS_LEGEND_VALUE
    | typeof MOTIVATIONS_LEGEND_VALUE
    | typeof BY_EXERCISES_LEGEND_VALUE
    | typeof COMPLETATION_STEPS_LEGEND_VALUE
    | typeof COMPLETATION_SKIP_STOP_LEGEND_VALUE;
  payload: DataType | ExercComplDataType[];
}

export interface ExercisesFillAction {
  type: typeof FILL_EXERCISES_OVERVIEW_TAB;
  payload: [] | object[];
}

export interface PatientFillPeriondAction {
  type: typeof FILL_DATA_BY_EXERCISES;
  payload: DataType;
}

export interface PatientCompletionsFillAction {
  type: typeof FILL_PATIENT_COMPLETINS;
  payload: DataType;
}

export const ERROR_PATIENT_COMPLETED_EXERCISES = 'error/patient_completed_exercises';
export interface PatientErrorFetching {
  type:
    | typeof ERROR_PATIENT_COMPLETED_EXERCISES
    | typeof STEPS_ERROR
    | typeof EXERCISE_COMPLETION_ERROR
    | typeof NOT_COMPLETION_EXERCISE_ERROR;
  payload: ErrorHttpAction;
}

export interface PatientExerciseName {
  type: typeof SET_EXERCISE_NAME;
  payload: string | null;
}

export interface IsExerciseChart {
  type: typeof SET_IS_EXERCISE;
  payload: boolean;
}

export type ActionTypes =
  | PatientFillStep
  | FillPatientExercCompl
  | LoadingActionType
  | PatientFillAction
  | PatientCompletionsFillAction
  | PatientErrorFetching
  | PatientFillPeriondAction
  | PatientExerciseName
  | IsExerciseChart
  | ExercisesFillAction
  | SetPerPeriodDataAction
  | FillPatientNotComplExer;

export interface ExercisesType {
  steps: StepsDataType[] | [];
  stepsLoading: boolean;
  stepsError: any;
  exerciseComletion: ExercComplDataType[];
  exerciseComletionLoading: boolean;
  exerciseComletionError: any;
  exercisesPerPeriod: ExercPerPeriodType[];
  exercisesPerPeriodLoading: boolean;
  notCompletedExercises: NotComplExercDataType[];
  notCompletedExercisesLoading: boolean;
  notCompletedExercisesError: any;

  byExercises: any;
  isExercise: boolean;
  error: false | ErrorHttpAction;
  isFetching: boolean;
  exerciseName: null | string;
  exercisesOverviewTab: any;
  exercMotivPainTab: any;
  exercReasonsTab: any;
  stepOverviewTab: any;
  stepByExercises: any;
  reasonsLegendValue: any;
  motivationsLegendValue: any;
  complByExercLegendValue: any;
  complStepsLegendValue: any;
  complSkipStopLegendValue: any;
}
