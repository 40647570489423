import React, { useState, ReactElement, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'formik';
import cx from 'classnames';
import { Button, Popover, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { getCurrenLang } from '../../redux/selector';
import { RegExrNumber, RegExrSpecialCharacter, RegExrLetter } from '../../utils/regx';
import { CloseIcon, CheckIcon, RemoveRedEyeIcon, VisibilityOffIcon } from '../../theme/icons';
import './styles.css';
import styles from './styles.module.css';
import {
  CheckBoxProps,
  CustomCheckboxProps,
  FilterMenuElemProps,
  InputConverterProps,
  InputFieldProps,
  InputFormikProps,
  InputFormikWithButtonProps,
  InputProps,
  InputToolkitProps,
  InputTypeFileProps,
  RadioConverterProps,
  SearchMenuElemProps,
  InputRedesignType,
  InputPasswordProps,
  InputTypeFilePropsAsTextType,
  RoundCheckboxProps,
} from './types';

export const Input = ({
  inputName,
  isValid,
  inputType,
  isTouched,
  hasErrors,
  inputValue,
  onChengeMehtod,
  labelName,
  disabledType,
  dataQa,
}: InputProps): ReactElement => {
  const [state, setState] = useState(false);
  const invalidStyles = cx({ [styles.invalidInput]: !isValid && isTouched && hasErrors });
  const toggle = (): void => setState(!state);

  return (
    <label className={styles.lable_field} htmlFor={labelName}>
      <div className={styles.subtitle}>{labelName}</div>
      <div className={styles.inputWrapper}>
        <Field
          value={inputValue}
          type={state ? 'text' : inputType}
          name={inputName}
          disabled={disabledType}
          onChange={onChengeMehtod}
          className={`${styles.input} ${invalidStyles}`}
          data-qa={dataQa}
        />

        {inputType === 'password' &&
          (state ? (
            <RemoveRedEyeIcon onClick={toggle} className={styles.img} />
          ) : (
            <VisibilityOffIcon onClick={toggle} className={styles.img} />
          ))}
        {isTouched && hasErrors ? <div className={styles.error}>{hasErrors}</div> : null}
      </div>
    </label>
  );
};

export const Checkbox = ({
  inputName,
  isTouched,
  hasErrors,
  inputValue,
  onChengeMehtod,
  checked,
  disabled,
  dataQa,
}: CheckBoxProps): ReactElement => (
  <div className={styles.inputWrapper}>
    <div className={styles.container_error}>
      {isTouched && hasErrors ? <div className={styles.error_checkbox}>{hasErrors}</div> : null}
    </div>
    <input
      value={inputValue}
      checked={checked}
      type="checkbox"
      name={inputName}
      onChange={onChengeMehtod}
      disabled={disabled}
      data-qa={dataQa}
    />
  </div>
);

export const InputToolkit = ({
  inputId,
  inputName,
  hasErrors,
  inputValue,
  isTouched,
  onChecngeMethod,
  placeholder,
  dataQa,
}: InputToolkitProps): ReactElement => {
  const t: any = useSelector<any>((state: any) => getCurrenLang(state));
  const input_tooltip_title = t && t.validation.input_tooltip_title;
  const digit = t && t.validation.digit;
  const letter = t && t.validation.letter;
  const special_charaster = t && t.validation.special_charaster;
  const characters = t && t.validation.characters;

  const [innerState, setInnerState] = useState(false);

  // isValid
  const invalidStyles = cx({ [styles.invalidInput]: isTouched && hasErrors });
  const toggle = (): void => setInnerState(!innerState);

  const onLength = inputValue && inputValue.length > 5;
  const checkingNumber = RegExrNumber.test(String(inputValue));
  const checkingSpecialCharacter = RegExrSpecialCharacter.test(String(inputValue));
  const checkingLetter = RegExrLetter.test(String(inputValue));

  const htmlTable = (
    <table>
      <tr>
        <th> {input_tooltip_title} </th>
      </tr>
      <tr>
        <td> -{digit}</td>
        <td>
          {checkingNumber ? (
            <span className={styles.seccusee}>
              <CheckIcon />
            </span>
          ) : (
            <span className={styles.error_tool}>
              <CloseIcon />
            </span>
          )}
        </td>
      </tr>
      <tr>
        <td> -{letter}</td>
        <td>
          {checkingLetter ? (
            <span className={styles.seccusee}>
              <CheckIcon />
            </span>
          ) : (
            <span className={styles.error_tool}>
              <CloseIcon />
            </span>
          )}
        </td>
      </tr>
      <tr>
        <td> -{special_charaster}</td>
        <td>
          {checkingSpecialCharacter ? (
            <span className={styles.seccusee}>
              <CheckIcon />
            </span>
          ) : (
            <span className={styles.error_tool}>
              <CloseIcon />
            </span>
          )}
        </td>
      </tr>

      <tr>
        <td> -{characters}</td>
        <td>
          {onLength ? (
            <span className={styles.seccusee}>
              <CheckIcon />
            </span>
          ) : (
            <span className={styles.error_tool}>
              <CloseIcon />
            </span>
          )}
        </td>
      </tr>
    </table>
  );

  return (
    <label className={styles.label_wrapper} htmlFor={inputId}>
      <Popover content={htmlTable} trigger="click" placement="top">
        <div>
          <input
            id={inputId}
            name={inputName}
            value={inputValue}
            type={innerState ? 'text' : 'password'}
            onChange={onChecngeMethod}
            autoComplete="off"
            className={`${styles.input_password} ${invalidStyles}`}
            placeholder=" "
            data-qa={dataQa}
          />
          <span className={styles['floating-label']}>{placeholder || ''}</span>
        </div>
      </Popover>

      {innerState ? (
        <RemoveRedEyeIcon onClick={toggle} className={styles.img} />
      ) : (
        <VisibilityOffIcon onClick={toggle} className={styles.img} />
      )}
      {hasErrors && isTouched ? <div className={styles.error}>{hasErrors}</div> : null}
    </label>
  );
};

Input.defaultProps = {
  disabledType: false,
  isValid: true,
  inputType: 'text',
  isTouched: false,
  hasErrors: false,
};

// InputToolkit.defaultProps = {
//   disabledType: false,
//   isValid: true,
//   inputType: 'text',
//   isTouched: false,
//   hasErrors: false,
// };

export const InputFormik = ({
  inputName,
  inputType,
  hasErrors,
  inputValue,
  isTouched,
  onChecngeMethod,
  labelName,
  disabled = false,
  placeholder = '',
  content = false,
  isRedStar = false,
  dataQa,
}: InputFormikProps): ReactElement => {
  const [state, setState] = useState(false);
  const invalidStyles = cx({ [styles.invalidInput]: isTouched && hasErrors });
  const toggle = (): void => setState(!state);
  return (
    <label className={styles.lable_field} htmlFor={labelName}>
      <div className={styles.subtitle}>
        {labelName}
        {isRedStar && <span className={styles.red_star}>*</span>}
      </div>
      <div className={styles.inputWrapper}>
        <input
          value={inputValue}
          type={state ? 'text' : inputType}
          name={inputName}
          onChange={onChecngeMethod}
          autoComplete={inputType === 'password' ? 'off' : undefined}
          className={`${styles.input} ${invalidStyles}`}
          disabled={disabled}
          placeholder={placeholder}
          data-qa={dataQa}
        />

        {inputType === 'password' &&
          (state ? (
            <RemoveRedEyeIcon onClick={toggle} className={styles.img} />
          ) : (
            <VisibilityOffIcon onClick={toggle} className={styles.img} />
          ))}
        {content && <div className={styles.content}>{content}</div>}
        {isTouched && hasErrors ? <div className={styles.error}>{hasErrors}</div> : null}
      </div>
    </label>
  );
};

export const InputFormikWithButton = ({
  inputName,
  hasErrors,
  inputValue,
  isTouched,
  onChecngeMethod,
  labelName,
  disabled = false,
  placeholder = '',
  isButton = true,
  buttonName,
  dataQa,
}: InputFormikWithButtonProps): ReactElement => {
  const invalidStyles = cx({ [styles.invalidInput]: isTouched && hasErrors });
  return (
    <label className={styles.lable_field} htmlFor={labelName}>
      <div className={styles.subtitle}>{labelName}</div>
      <div className={styles.inputWrapper}>
        <input
          value={inputValue}
          type="text"
          name={inputName}
          onChange={onChecngeMethod}
          className={`${styles.input} ${invalidStyles}`}
          disabled={disabled}
          placeholder={placeholder}
          data-qa={dataQa}
        />

        {isButton && (
          <div className={styles.btn}>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: '31px',
                borderRadius: '4px',
                padding: '0 5px',
                textAlign: 'center',
              }}
              data-qa={dataQa}
            >
              {buttonName}
            </Button>
          </div>
        )}
        {isTouched && hasErrors ? <div className={styles.error}>{hasErrors}</div> : null}
      </div>
    </label>
  );
};

export const InputFeild = ({
  inputName,
  inputType,
  hasErrors,
  inputValue,
  isTouched,
  onChecngeMethod,
  labelName,
  disabled = false,
  fieldId,
  isRedStar = false,
  placeholderText = '',
  isCounter,
  maxLength,
  withLabelname = true,
  dataQa,
}: InputFieldProps): ReactElement => (
  <label className={styles.lable_fields} htmlFor={fieldId}>
    {withLabelname && (
      <div className={styles.subtitle}>
        {labelName}
        {isRedStar && <span className={styles.red_star}>*</span>}
      </div>
    )}
    <div className={styles.inputWrapper}>
      <input
        id={fieldId}
        value={inputValue}
        type={inputType || 'text'}
        placeholder={placeholderText}
        name={inputName}
        onChange={onChecngeMethod}
        className={cx({
          [styles.input_feilds]: true,
          [styles.invalidInput]: isTouched && hasErrors,
        })}
        disabled={disabled}
        data-qa={dataQa}
      />
      <div className={styles.errorContainer}>
        {isTouched && hasErrors ? (
          <div className={styles.error}>{hasErrors}</div>
        ) : (
          <div className={styles.error} />
        )}
        {isCounter && <div className={styles.counter}>{`${inputValue.length} / ${maxLength}`}</div>}
      </div>
    </div>
  </label>
);

export const InputFeildNew = ({
  inputName,
  inputType,
  hasErrors,
  inputValue,
  isTouched,
  onChecngeMethod,
  labelName,
  disabled = false,
  fieldId,
  dataQa,
}: InputFieldProps): ReactElement => {
  const [state, setState] = useState(false);
  const invalidStyles = cx({ [styles.invalidInput]: isTouched && hasErrors });

  return (
    <label className={styles.label} htmlFor={fieldId}>
      <div className={styles.titleField}>{labelName}</div>
      <div>
        <input
          id={fieldId}
          value={inputValue}
          type={state ? 'text' : inputType}
          name={inputName}
          onChange={onChecngeMethod}
          className={`${styles.inputNew} ${invalidStyles}`}
          disabled={disabled}
          data-qa={dataQa}
        />
        {isTouched && hasErrors ? <div className={styles.error}>{hasErrors}</div> : null}
      </div>
    </label>
  );
};

export const FilterMenuElem = ({
  divClass,
  clickHandler,
  id,
  inputClass,
  inputName,
  checkSolution,
  labelClass,
  spanClass,
  label,
  dataQa,
}: FilterMenuElemProps): ReactElement => (
  <div className={divClass} onClick={clickHandler} role="presentation">
    <input
      type="radio"
      id={id}
      className={inputClass}
      name={inputName}
      checked={checkSolution}
      onChange={clickHandler}
      data-qa={dataQa}
    />
    <label className={labelClass} htmlFor={id}>
      <span className={spanClass} />
      {label}
    </label>
  </div>
);

export const SearchMenuElem = ({
  name,
  handleSearch,
  handleReset,
  searchMatches,
  findMatches,
  hideSearchMenu,
  dataQa,
}: SearchMenuElemProps): ReactElement => {
  const t: any = useSelector<any>((state: any) => getCurrenLang(state));
  const search = t?.common.search;
  const reset = t?.common.reset;
  const show = t?.common.show;
  const search_results = t?.common.search_results;

  const [query, setQuery] = useState('');

  useEffect(() => {
    if (!hideSearchMenu) {
      setQuery('');
    }
  }, [hideSearchMenu]);

  return (
    <div className={styles.dashbord}>
      <input
        className={styles.search_input}
        value={query}
        placeholder={`${search} ${name}`}
        onChange={(e): void => {
          setQuery(e.target.value);
          findMatches(e.target.value);
        }}
        onKeyPress={(e): boolean | void => {
          if (e.key === 'Enter') {
            handleSearch(query);
            setQuery('');
          }
        }}
        data-qa={dataQa}
      />
      {searchMatches !== null && query ? (
        <div className={styles.matches}>{`${search_results}: ${searchMatches}`}</div>
      ) : (
        <></>
      )}
      <div className={styles['btn-group-container']}>
        <Button
          type="primary"
          onClick={(): void => {
            handleSearch(query);
            setQuery('');
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, padding: '0 5px', textAlign: 'center' }}
          data-qa={dataQa}
        >
          {show}
        </Button>
        <Button
          onClick={(): void => {
            handleReset();
            setQuery('');
          }}
          size="small"
          style={{ width: 90, padding: '0 5px', textAlign: 'center' }}
          data-qa={dataQa}
        >
          {reset}
        </Button>
      </div>
    </div>
  );
};

export const InputTypeFile = ({
  id,
  inputName,
  hasErrors,
  onChecngeMethod,
  labelName,
  disabled = false,
  placeholder = '',
  fileInputKey = Date.now(),
  accept,
  isMultiple = false,
  isTouched,
  isRedStar = true,
  dataQa,
}: InputTypeFileProps): ReactElement => (
  <label htmlFor={id} className={styles.label_field}>
    <div className={styles.labelNameInputFile}>
      {labelName}
      {isRedStar && <span className={styles.red_star}>*</span>}
    </div>
    <div>
      <div
        className={cx({
          [styles['input-type-file']]: true,
          [styles['input-type-file__error']]: isTouched && hasErrors,
        })}
      >
        {placeholder}
      </div>
      <input
        key={fileInputKey}
        id={id}
        type="file"
        onChange={onChecngeMethod}
        name={inputName}
        disabled={disabled}
        accept={accept}
        style={{ display: 'none' }}
        multiple={isMultiple}
        data-qa={dataQa}
      />
      {isTouched && hasErrors ? <div className={styles.error}>{hasErrors}</div> : null}
    </div>
  </label>
);

export const CustomCheckbox = ({
  htmlId,
  name,
  checked,
  onChangeMethod,
  isValid,
  isTouched,
  hasErrors,
  disabled = false,
  bigSize = false,
  dataQa,
}: CustomCheckboxProps): ReactElement => (
  <label htmlFor={htmlId} className={styles['checkbox-container']}>
    <div
      className={cx({
        [styles.customCheckbox]: true,
        [styles['customCheckbox--big-size']]: bigSize,
        [styles.error]: !isValid && isTouched && hasErrors,
      })}
    >
      <input
        id={htmlId}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChangeMethod}
        disabled={disabled}
        data-qa={dataQa}
      />
      <span
        className={cx({
          [styles.checkmark]: true,
          [styles['checkmark--big-size']]: bigSize,
        })}
      />
    </div>
  </label>
);

export const TextareaFeild = ({
  id,
  inputName,
  hasErrors,
  inputValue,
  placeholder,
  isTouched,
  onChecngeMethod,
  rows = 4,
  labelName,
  disabled = false,
  isRedStar = false,
  isCounter,
  maxLength,
  dataQa,
  ...rest
}: any): ReactElement => {
  const invalidStyles = cx({ [styles.invalidInput]: isTouched && hasErrors });
  return (
    <label htmlFor={id} className={styles.textarea__label}>
      <div className={styles.subtitle}>
        {labelName}
        {isRedStar && <span className={styles.red_star}>*</span>}
      </div>
      <div className={styles.inputWrapper}>
        <textarea
          id={id}
          name={inputName}
          value={inputValue}
          placeholder={placeholder}
          onChange={onChecngeMethod}
          rows={rows}
          className={`${styles.textarea__field} ${invalidStyles}`}
          disabled={disabled}
          data-qa={dataQa}
          {...rest}
        />
        <div className={styles.errorContainer}>
          {isTouched && hasErrors ? (
            <div className={styles.error}>{hasErrors}</div>
          ) : (
            <div className={styles.error} />
          )}
          {isCounter && (
            <div className={styles.counter}>{`${inputValue.length} / ${maxLength}`}</div>
          )}
        </div>
      </div>
    </label>
  );
};

export const RadioConverter = ({
  labelName,
  handleChange,
  inputName,
  value,
  checkedRadio,
  tip,
  id,
  bigSize = false,
  dataQa,
}: RadioConverterProps): ReactElement => (
  <>
    <Tooltip placement="top" title={tip}>
      <span
        className={cx({
          [styles.radioConverter__span]: true,
          [styles['radioConverter__span--big']]: bigSize,
        })}
      >
        <input
          type="radio"
          id={id}
          className={styles.radio}
          name={inputName}
          checked={checkedRadio}
          value={value}
          onChange={handleChange}
          data-qa={dataQa}
        />
        <label className={styles.radio_lable} htmlFor={id}>
          {labelName}
        </label>
      </span>
    </Tooltip>
  </>
);

export const InputConverter = ({
  inputName,
  hasErrors,
  inputValue,
  isDesabled,
  onChecngeMethod,
  dataQa,
}: InputConverterProps): ReactElement => (
  <div className={styles.lable_fields}>
    <div className={styles.inputWrapper}>
      <input
        type="text"
        disabled={!isDesabled}
        name={inputName}
        onChange={onChecngeMethod}
        value={inputValue}
        className={styles.input_feilds}
        data-qa={dataQa}
      />
      {hasErrors && <div className={styles.errorMessage}>{hasErrors}</div>}
    </div>
  </div>
);

export const InputConverterNew = ({
  inputName,
  hasErrors,
  inputValue,
  isDesabled,
  onChecngeMethod,
  dataQa,
}: InputConverterProps): ReactElement => (
  <div className={styles.label}>
    <input
      type="text"
      disabled={!isDesabled}
      name={inputName}
      onChange={onChecngeMethod}
      value={inputValue}
      className={styles.inputNew}
      data-qa={dataQa}
    />
    {hasErrors && <div className={styles.errorMessage}>{hasErrors}</div>}
  </div>
);

export const InputWithDescription = ({
  inputName,
  inputType,
  hasErrors,
  inputValue,
  isTouched,
  onChecngeMethod,
  labelName,
  disabled = false,
  fieldId,
  isRedStar = false,
  placeholderText = '',
  isCounter,
  maxLength,
  description,
  dataQa,
}: InputFieldProps): ReactElement => (
  <label className={styles.lable_fields} htmlFor={fieldId}>
    <div className={styles.subtitle}>
      {labelName}
      {isRedStar && <span className={styles.red_star}>*</span>}
    </div>
    <div className={styles.inputWrapper}>
      <div
        className={cx({
          [styles.inputDescrWrapper]: true,
          [styles.invalidInputWithDescr]: isTouched && hasErrors,
        })}
      >
        <input
          id={fieldId}
          value={inputValue}
          type={inputType || 'text'}
          placeholder={placeholderText}
          name={inputName}
          onChange={onChecngeMethod}
          className={styles.inputWithDescr}
          disabled={disabled}
          data-qa={dataQa}
        />
        <div className={styles.description}>{description || ''}</div>
      </div>
      <div className={styles.errorContainer}>
        {isTouched && hasErrors ? (
          <div className={styles.error}>{hasErrors}</div>
        ) : (
          <div className={styles.error} />
        )}
        {isCounter && <div className={styles.counter}>{`${inputValue.length} / ${maxLength}`}</div>}
      </div>
    </div>
  </label>
);

export const InputRedesign = ({
  inputId,
  inputName,
  inputType,
  hasErrors,
  inputValue,
  isTouched,
  onChangeMethod,
  placeholder = '',
  disabled = false,
  dataQa,
}: InputRedesignType): ReactElement => {
  const invalidStyles = cx({ [styles.invalidInput]: isTouched && hasErrors });
  return (
    <label className={styles.label_wrapper} htmlFor={inputId}>
      <input
        id={inputId}
        name={inputName}
        type={inputType || 'text'}
        value={inputValue}
        onChange={onChangeMethod}
        className={`${styles.input_redesign} ${invalidStyles}`}
        disabled={disabled}
        placeholder={placeholder}
        data-qa={dataQa}
      />
      {isTouched && hasErrors ? <div className={styles.error}>{hasErrors}</div> : <></>}
    </label>
  );
};

export const InputLoginPassword = ({
  inputId,
  inputName,
  hasErrors,
  inputValue,
  isTouched,
  onChangeMethod,
  disabled = false,
  placeholder = '',
  dataQa,
}: InputPasswordProps): ReactElement => {
  const [state, setState] = useState(false);
  const invalidStyles = cx({ [styles.invalidInput]: isTouched && hasErrors });
  const toggle = (): void => setState(!state);
  return (
    <label className={styles.label_wrapper} htmlFor={inputId}>
      <input
        id={inputId}
        name={inputName}
        value={inputValue}
        type={state ? 'text' : 'password'}
        onChange={onChangeMethod}
        // autoComplete="off"
        className={`${styles.input_login_password} ${invalidStyles}`}
        disabled={disabled}
        placeholder={placeholder}
        data-qa={dataQa}
      />
      {/* <span className={styles['floating-label']}>{placeholder || ''}</span> */}

      {state ? (
        <RemoveRedEyeIcon onClick={toggle} className={styles.img} />
      ) : (
        <VisibilityOffIcon onClick={toggle} className={styles.img} />
      )}
      {isTouched && hasErrors ? <div className={styles.error}>{hasErrors}</div> : null}
    </label>
  );
};

export const InputFloatLabel = ({
  inputId,
  inputName,
  inputType,
  hasErrors,
  inputValue,
  isTouched,
  onChangeMethod,
  placeholder = '',
  disabled = false,
  isRedStar = false,
  dataQa,
}: InputRedesignType): ReactElement => {
  const invalidStyles = cx({ [styles.invalidInput]: isTouched && hasErrors });
  return (
    <label className={styles.label_wrapper} htmlFor={inputId}>
      <input
        id={inputId}
        name={inputName}
        type={inputType || 'text'}
        value={inputValue}
        onChange={onChangeMethod}
        className={`${styles.inputFloat} ${invalidStyles}`}
        disabled={disabled}
        placeholder=" "
        data-qa={dataQa}
      />
      <div className={styles['floating-label']}>
        <span>{placeholder || ''}</span>
        {isRedStar && <span className={styles.red_star}>*</span>}
      </div>
      {isTouched && hasErrors ? <div className={styles.error}>{hasErrors}</div> : <></>}
    </label>
  );
};

export const InputPasswordFloatLabel = ({
  inputId,
  inputName,
  hasErrors,
  inputValue,
  isTouched,
  onChangeMethod,
  disabled = false,
  placeholder = '',
  dataQa,
}: InputPasswordProps): ReactElement => {
  const [state, setState] = useState(false);
  const invalidStyles = cx({ [styles.invalidInput]: isTouched && hasErrors });
  const toggle = (): void => setState(!state);
  return (
    <label className={styles.label_wrapper} htmlFor={inputId}>
      <input
        id={inputId}
        name={inputName}
        value={inputValue}
        type={state ? 'text' : 'password'}
        onChange={onChangeMethod}
        autoComplete="off"
        className={`${styles.input_password} ${invalidStyles}`}
        disabled={disabled}
        placeholder=" "
        data-qa={dataQa}
      />
      <span className={styles['floating-label']}>{placeholder || ''}</span>

      {state ? (
        <RemoveRedEyeIcon onClick={toggle} className={styles.img} />
      ) : (
        <VisibilityOffIcon onClick={toggle} className={styles.img} />
      )}
      {isTouched && hasErrors ? <div className={styles.error}>{hasErrors}</div> : null}
    </label>
  );
};

export const TextareaFeildFloatLabel = ({
  id,
  inputName,
  hasErrors,
  inputValue,
  placeholder,
  isTouched,
  onChecngeMethod,
  rows = 4,
  disabled = false,
  isRedStar = false,
  isCounter,
  maxLength,
  dataQa,
  ...rest
}: any): ReactElement => (
  <label htmlFor={id} className={styles['label_wrapper--textarea']}>
    <div
      className={cx({
        [styles.textarea__wrapper]: true,
        [styles.invalidInput]: isTouched && hasErrors,
      })}
    >
      <textarea
        id={id}
        name={inputName}
        value={inputValue}
        placeholder=" "
        onChange={onChecngeMethod}
        rows={rows}
        className={styles['textarea__float-label']}
        disabled={disabled}
        data-qa={dataQa}
        {...rest}
      />
      <div className={styles['floating-label']}>
        <span>{placeholder || ''}</span>
        {isRedStar && <span className={styles.red_star}>*</span>}
      </div>
    </div>
    <div className={styles.errorContainer}>
      {isTouched && hasErrors ? (
        <div className={styles.error}>{hasErrors}</div>
      ) : (
        <div className={styles.error} />
      )}
      {isCounter && <div className={styles.counter}>{`${inputValue.length} / ${maxLength}`}</div>}
    </div>
  </label>
);

export const InputTypeFileAsText = ({
  id,
  inputName,
  hasErrors,
  onChangeMethod,
  labelName,
  disabled = false,
  fileInputKey = Date.now(),
  accept,
  isMultiple = false,
  isTouched,
  isRedStar = false,
  dataQa,
}: InputTypeFilePropsAsTextType): ReactElement => (
  <label htmlFor={id} className={styles.inputTypeFileAsText__label}>
    <div
      className={cx({
        [styles.inputTypeFileAsText]: true,
        [styles.inputTypeFileAsText__disabled]: disabled,
        [styles.inputTypeFileAsText__error]: isTouched && hasErrors,
      })}
    >
      <div className={styles.inputTypeFileAsText__plus}>+</div>
      {labelName}
      {isRedStar && <span className={styles.red_star}>*</span>}
    </div>
    <input
      key={fileInputKey}
      id={id}
      type="file"
      onChange={onChangeMethod}
      name={inputName}
      disabled={disabled}
      accept={accept}
      style={{ display: 'none' }}
      multiple={isMultiple}
      data-qa={dataQa}
    />
    {isTouched && hasErrors ? <div className={styles.error}>{hasErrors}</div> : null}
  </label>
);

export const RoundCheckbox = ({
  htmlId,
  name,
  checked,
  onChangeMethod,
  isValid,
  isTouched,
  hasErrors,
  disabled = false,
  label,
  dataQa,
}: RoundCheckboxProps): ReactElement => (
  <label htmlFor={htmlId} className={styles.roundCheckbox__item}>
    <div className={styles['roundCheckbox-container']}>
      <div
        className={cx({
          [styles.roundCheckbox]: true,
          [styles.error]: !isValid && isTouched && hasErrors,
        })}
      >
        <input
          id={htmlId}
          name={name}
          type="checkbox"
          checked={checked}
          onChange={onChangeMethod}
          disabled={disabled}
          data-qa={dataQa}
        />
        <div
          className={cx({
            [styles.roundCheckmark]: true,
          })}
        />
      </div>
    </div>
    <div className={styles['roundCheckbox__item-label']}>{label}</div>
  </label>
);

export const InputFloatLabelWithDescription = ({
  inputName,
  inputType,
  hasErrors,
  inputValue,
  isTouched,
  onChecngeMethod,
  labelName,
  disabled = false,
  fieldId,
  isRedStar = false,
  placeholderText = '',
  isCounter,
  maxLength,
  description,
  dataQa,
}: InputFieldProps): ReactElement => {
  const [inputWidth, setInputWidth] = useState(0);
  const refInput: any = useRef(null);
  useEffect(() => {
    if (refInput.current && refInput.current.offsetWidth) {
      setInputWidth(refInput.current.offsetWidth - 15);
    }
  }, [refInput.current]);
  return (
    <label className={styles.label_wrapper} htmlFor={fieldId}>
      <div
        className={cx({
          [styles.inputDescrWrapper_float]: true,
          [styles.invalidInputWithDescr]: isTouched && hasErrors,
        })}
      >
        <input
          ref={refInput}
          id={fieldId}
          value={inputValue}
          type={inputType || 'text'}
          placeholder=" "
          name={inputName}
          onChange={onChecngeMethod}
          className={styles.inputFloat}
          disabled={disabled}
          data-qa={dataQa}
        />
        <div className={styles.description_float}>{description || ''}</div>
        <div className={styles['floating-label']} style={{ width: `${inputWidth}px` }}>
          <span>{placeholderText}</span>
          {isRedStar && <span className={styles.red_star}>*</span>}
        </div>
      </div>
      {isTouched && hasErrors ? <div className={styles.error}>{hasErrors}</div> : <></>}
    </label>
  );
};

export const InputFloatLabelWithButton = ({
  inputId,
  inputName,
  hasErrors,
  inputValue,
  isTouched,
  onChecngeMethod,
  labelName,
  disabled = false,
  placeholder = '',
  isButton = true,
  buttonName,
  dataQa,
}: InputFormikWithButtonProps): ReactElement => {
  const invalidStyles = cx({ [styles.invalidInput]: isTouched && hasErrors });
  return (
    <label className={styles.label_wrapper} htmlFor={inputId}>
      <input
        id={inputId}
        value={inputValue}
        type="text"
        name={inputName}
        onChange={onChecngeMethod}
        className={`${styles.input_password} ${invalidStyles}`}
        disabled={disabled}
        placeholder=" "
        data-qa={dataQa}
      />
      <div className={styles['floating-label']}>
        <span>{placeholder || ''}</span>
      </div>
      {isTouched && hasErrors ? <div className={styles.error}>{hasErrors}</div> : <></>}

      {isButton && (
        <div className={styles.btn}>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: '31px',
              borderRadius: '4px',
              padding: '0 5px',
              textAlign: 'center',
            }}
            data-qa={dataQa}
          >
            {buttonName}
          </Button>
        </div>
      )}
    </label>
  );
};
