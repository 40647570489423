import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Tooltip } from 'antd';

import style from '../styles.module.css';
import { getCurrenLang, GetState } from '../../../../redux/selector';
import { Button } from '../../../../common';
import { PERMISSIONS, SORT_DIRECTION } from '../../../../utils/enums';
import useCheckPermissions from '../../../../hooks/useCheckPermissions';
import {
  validationAddExercToNewFolder,
  validationScheduleFolder,
} from '../../../../utils/validators';
import { getScheduleNumberOptions, getUnitOptions } from '../../../../utils/share/options';
import FolderDetails from '../../OrganisationVideoBank/components/ExerciseFolderSettings/FolderDetails';
import ScheduleFolder from '../../OrganisationVideoBank/components/ScheduleFolder';
import { ActionItem, SortSearchItem } from '../../OrganisationVideoBank/components/HeaderItems';
import NestedExercise from './NestedExercise';
import { ExerciseStatusGuide, GuideTitle } from '../../../../common/Giude';
import { deepCopyObj } from '../../../../utils/helper';

const DragFolderContentModal = ({
  data,
  cancelDrag,
  continueDrag,
  exercisesCategories,
  exercisesProcedureFocus,
  focusType1Arr,
  focusType2Arr,
  isInvitee,
}: any): JSX.Element => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const cancel = t?.common.cancel;
  const continue_t = t?.common.continue;
  const exercise_name = t?.dashboard.hcp.profile_patient.video_bank.exercise_name;
  const media_type = t?.hcp.org_video_bank.media_type;
  const action = t?.dashboard.hcp.profile_patient.video_bank.action;
  const status_t = t?.dashboard.hcp.table.status;
  const no_data = t?.common.no_data;
  const see_status_guide = t?.dashboard.hcp.profile_patient.see_status_guide;
  const last_status_changed =
    t?.dashboard.hcp.profile_patient.video_bank.last_status_changed || 'Last status changed by';
  const set_exercise_folders_contents =
    t?.dashboard.hcp.profile_patient.video_bank.set_exercise_folders_contents ||
    'Set the contents of the exercise folders by clicking "Settings" under the Action column.';
  const not_set = t?.common.not_set || 'Not set';
  const set_t = t?.common.set || 'Set';

  const isPermissionEditVideoExerc = useCheckPermissions(
    PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_OTM,
  );

  // Local state
  const [isOpenScheduleForm, setOpenScheduleForm] = useState(false);
  const [exerciseStatuses, setExerciseStatuses] = useState<any>({});
  const [sortBy, setSortby] = useState<string>('name');
  const [sortDirectionName, setSortDirectionName] = useState<string>('');
  const [exerciseList, setExerciseList] = useState<any>();

  const checkValidForm = (statuses: any): boolean => {
    if (!statuses) return false;
    const statusesKey = statuses && Object.values(deepCopyObj(statuses));
    if (!(statusesKey && statusesKey.length)) return false;
    if (statusesKey.includes(not_set)) {
      return false;
    }
    return true;
  };

  const unitOptions = t ? getUnitOptions(t.dashboard.hcp.profile_patient.completion_and_steps) : [];
  const numberOptions = getScheduleNumberOptions();
  const folderId = data.currentId;
  const nestedExercises = data.folderExercises;
  const initialValues = useMemo(
    () => ({
      name: data.name,
      description: data.description || '',
      videobankIds: data.videobankIds,
      amount: data.hcpFolderTimer?.amount ? numberOptions[data.hcpFolderTimer.amount - 1] : null,
      timePeriod: data.hcpFolderTimer?.timePeriod
        ? unitOptions[data.hcpFolderTimer.timePeriod - 1]
        : null,
    }),
    [data],
  );

  const formik = useFormik({
    initialValues,
    validate: (values) => validationScheduleFolder(values, t),
    validationSchema: () => validationAddExercToNewFolder(t),
    onSubmit: async (values: any) => {
      const dataCopy = { ...data };
      dataCopy.name = values.name.trim();
      dataCopy.description = values.description.trim();
      if (values.amount) {
        dataCopy.hcpFolderTimer = {
          amount: values.amount.value,
          timePeriod: values.timePeriod.value,
        };
      }
      continueDrag(dataCopy);
    },
  });
  const { values } = formik;

  const dataScheduler = {
    hcpFolderTimer: {
      amount: values.amount?.value,
      timePeriod: values.timePeriod?.value,
    },
  };

  // Update status after success save exercise
  const updateList = (unicId: string): void => {
    const newStatuses = { ...exerciseStatuses };
    const updatedList = exerciseList?.map((exercise: any, index: number) => {
      const currentExercesUnicId = `${exercise.currentId}_${index}`;
      if (unicId === currentExercesUnicId) {
        newStatuses[currentExercesUnicId] = set_t;
        return { ...exercise, calcStatus: set_t };
      }
      return { ...exercise };
    });
    setExerciseList(updatedList);
    setExerciseStatuses({ ...newStatuses });
  };

  const check = (folderExercises: any) => {
    const currentStatuses: any = {};
    const newNestedExercises: any = [];
    folderExercises.map((exerciseItem: any, i: number) => {
      const unicId = `${exerciseItem.currentId}_${i}`;
      const withoutNullUpdatedByHcp = exerciseItem.updatedByHcp || '';
      if (
        !exerciseItem.name ||
        !exerciseItem.exerciseTimesPerDay ||
        !(exerciseItem.exerciseCategories.length > 0)
      ) {
        newNestedExercises.push({
          ...exerciseItem,
          calcStatus: not_set,
          updatedByHcp: withoutNullUpdatedByHcp,
        });
        currentStatuses[unicId] = not_set;
        return;
      }
      newNestedExercises.push({
        ...exerciseItem,
        calcStatus: set_t,
        updatedByHcp: withoutNullUpdatedByHcp,
      });
      currentStatuses[unicId] = set_t;
    });
    return { currentStatuses, newNestedExercises };

    // const obj: any = {};
    // const arrResult: any = [];
    // async function* asyncGenerator(): AsyncGenerator<number, void, unknown> {
    //   let j = 0;
    //   while (j < arr.length) {
    //     yield (j += 1);
    //   }
    // }
    // // eslint-disable-next-line no-restricted-syntax
    // for await (const j of asyncGenerator()) {
    //   const i = j - 1;
    //   validationExercise(t)
    //     .isValid({
    //       reps: arr[i].exerciseReps,
    //       sets: arr[i].exerciseSets,
    //       timesDay: arr[i].exerciseTimesPerDay,
    //       restDaysWeek: arr[i].exerciseRestDays,
    //     })
    //     .then((valid: any) => {
    //       const unicId = `${arr[i].currentId}_${i}`;

    //       if (valid) {
    //         obj[unicId] = set_t;
    //       } else {
    //         obj[unicId] = not_set;
    //       }
    //     });
    // }

    // arr.map((exer: any, index: number) => {
    //   validationExercise(t)
    //     .isValid({
    //       reps: exer.exerciseReps,
    //       sets: exer.exerciseSets,
    //       timesDay: exer.exerciseTimesPerDay,
    //       restDaysWeek: exer.exerciseRestDays,
    //     })
    //     .then((valid: any) => {
    //       const unicId = `${String(exer.currentId)}_${String(index)}`;
    //       if (valid) {
    //         obj[unicId] = set;
    //       } else {
    //         obj[unicId] = not_set;
    //       }
    //     });
    //   return null;
    // });

    // return obj;
  };

  useEffect(() => {
    // (async () => {
    const { currentStatuses, newNestedExercises } = check(nestedExercises);
    setExerciseStatuses(currentStatuses);
    setExerciseList(newNestedExercises);
    // })();
  }, [nestedExercises]);

  // Sort exercise list by Column Name
  const sortByColumnName = (name: string, sortdirection: string): void => {
    setSortby(name);
    setSortDirectionName(sortdirection);
    if (exerciseList.length !== 0) {
      const newExerciseList = [...exerciseList];
      newExerciseList.sort((a: any, b: any) => {
        const nameA = a[name]?.toUpperCase(); // ignore upper and lowercase
        const nameB = b[name]?.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return sortdirection === SORT_DIRECTION.DESC ? 1 : -1;
        }
        if (nameA > nameB) {
          return sortdirection === SORT_DIRECTION.DESC ? -1 : 1;
        }
        return 0;
      });
      setExerciseList(newExerciseList);
    }
  };

  // JSX
  const noData = nestedExercises.length === 0 && (
    <div className={style.prescrebed__nodata}>{no_data}</div>
  );

  return (
    <div className={style['exerc-lib__form-wrapper']}>
      <div className={style['exerc-lib__description']}>{set_exercise_folders_contents}</div>
      <GuideTitle text={see_status_guide} content={<ExerciseStatusGuide t={t} />} />

      <form onSubmit={formik.handleSubmit} className={style['exerc-lib__container--folder']}>
        {/* Folder Details */}
        <div className={style['exerc-lib__folder-details-wrapper']}>
          <FolderDetails
            dataFolder={dataScheduler}
            unitOptions={unitOptions}
            numberOptions={numberOptions}
            formik={formik}
            loadingJSX={false}
            isOpenScheduleForm={isOpenScheduleForm}
            setOpenScheduleForm={setOpenScheduleForm}
          />
          {isOpenScheduleForm && (
            <div className={style['margin-bottom']}>
              <ScheduleFolder
                t={t}
                formik={formik}
                unitOptions={unitOptions}
                numberOptions={numberOptions}
              />
            </div>
          )}
        </div>

        {/* Header */}
        <div className={style['exerc-lib__header']}>
          <div className={style['exerc-lib__media-container']}>
            <SortSearchItem
              fieldName={media_type}
              t={t}
              sortName="type"
              sortByColumnName={sortByColumnName}
              sortBy={sortBy}
            />
          </div>
          <div className={style['exerc-lib__status-container']}>
            <SortSearchItem
              fieldName={status_t}
              t={t}
              sortName="calcStatus"
              sortByColumnName={sortByColumnName}
              sortBy={sortBy}
            />
          </div>
          <div className={style['exerc-lib__exercise-name-container']}>
            <SortSearchItem
              fieldName={exercise_name}
              t={t}
              sortName="name"
              sortByColumnName={sortByColumnName}
              sortBy={sortBy}
            />
          </div>
          <div className={style['exerc-lib__last-changed-container']}>
            <SortSearchItem
              fieldName={last_status_changed}
              t={t}
              sortName="updatedByHcp"
              sortByColumnName={sortByColumnName}
              sortBy={sortBy}
            />
          </div>
          <div className={style['exerc-lib__action-container']}>
            <ActionItem name={action} />
          </div>
        </div>

        {/* Nested Exercises */}
        {noData}
        <div className={style['exerc-lib__nested-exerc-container']}>
          {exerciseStatuses &&
            exerciseList &&
            exerciseList.map((nestedExercise: any, i: number) => {
              const keyObj = `${nestedExercise.name}${String(i)}${JSON.stringify(
                exerciseStatuses,
              )}`;
              return (
                <NestedExercise
                  key={`${keyObj}`}
                  data={nestedExercise}
                  exercisesCategories={exercisesCategories}
                  exercisesProcedureFocus={exercisesProcedureFocus}
                  focusType1Arr={focusType1Arr}
                  focusType2Arr={focusType2Arr}
                  isInvitee={isInvitee}
                  folderId={folderId}
                  exerciseStatuses={exerciseStatuses}
                  setExerciseStatuses={setExerciseStatuses}
                  index={i}
                  updateList={updateList}
                  // patientId={patientId}
                  // type={VIDEO_BANK_TYPE.NESTED}
                  // openNestedExerciseIds={openNestedExerciseIds}
                  // setOpenNestedExerciseIds={setOpenNestedExerciseIds}
                />
              );
            })}
        </div>

        <div className={style['exerc-lib__btns-container']}>
          <Button
            buttonClass={style['exerc-lib__btn']}
            buttonType="button"
            buttonName={cancel}
            buttonMethod={cancelDrag}
          />
          <Tooltip title={!checkValidForm(exerciseStatuses) ? set_exercise_folders_contents : ''}>
            <div>
              <Button
                buttonClass={style['exerc-lib__btn']}
                buttonType="submit"
                buttonName={continue_t}
                disabledButton={!isPermissionEditVideoExerc || !checkValidForm(exerciseStatuses)}
              />
            </div>
          </Tooltip>
        </div>
      </form>
    </div>
  );
};

export default DragFolderContentModal;
