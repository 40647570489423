/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip as IconTooltip } from 'antd';
import cx from 'classnames';
import moment from 'moment';

import styles from './styles.module.css';
import {
  GetState,
  getAllPatientProms,
  getAllProms,
  getAllowedPatientProms,
  getCurrenLang,
  getCurrentFormatDate,
  getLoadingAllProms,
  getLoadingAvailablePatientProms,
  getLoadingPainDetecPics,
  getPainDetectPics,
  getPatientProfile,
  getPatientPromsLoading,
} from '../../../redux/selector';
import { ChartsConfig } from '../../ChartOverviewContainer/types';
import {
  dataToGraph,
  downloadFile,
  exportToPdf,
  getDataForGraph,
  getPromsId,
} from '../../../utils/helper_charts';
import {
  useGetAllQuestionnaires,
  useGetPatientAllPainDetectPics,
  useGetPatientAllQuestionnaires,
  useGetPatientCompletedQuestionnaires,
  useGetPatientQuestionnairesByProcedureType,
} from '../../../graphql/proms';
import { PROMS_NAME, proceduresEnum, statusQuestionnaries } from '../../../utils/enums';
import { CsvIcon, DownloadIcon, PdfIcon } from '../../../theme/icons';
import PcsChart from '../PCS';
import PainDetect from '../PainDetect';
import DefaultImg from '../../../theme/image/pain-detect.png';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import PromsToPdf from './PromsToPdf';

import useCheckRole from '../../../hooks/useCheckRole';
import { Oxford } from '../Oxford';
import { QuickDash } from '../QuickDash';
import { Sf36 } from '../Sf36';
import { HealthScoresType, TQuestionary } from '../types';
import { Eq5D5L } from '../Eq5d5l';
import { Loading } from '../../../common';

const PromsLayout = ({ patientId, procedureKey }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const export_to_pdf = t?.common.export_to_pdf;
  const export_to_csv = t?.common.export_to_csv;
  const proms_summary = t?.dashboard.hcp.profile_patient.questionnaires.proms_summ;
  const pcs = t?.dashboard.hcp.profile_patient.questionnaires.pcs;
  const pain_detect = t?.dashboard.hcp.profile_patient.questionnaires.pain_detect;
  const eq_5d_5l = t?.dashboard.hcp.profile_patient.questionnaires.eq_5d_5l;
  const oxford_knee = t?.dashboard.hcp.profile_patient.questionnaires.oxford_knee;
  const oxford_hip = t?.dashboard.hcp.profile_patient.questionnaires.oxford_hip;
  const oxford_shoulder = t?.dashboard.hcp.profile_patient.questionnaires.oxford_shoulder;
  const quick_dash = t?.dashboard.hcp.profile_patient.questionnaires.quick_dash;
  const moxfq = t?.dashboard.hcp.profile_patient.questionnaires.moxfq;
  const aofas = t?.dashboard.hcp.profile_patient.questionnaires.aofas;
  const sf36 = t?.dashboard.hcp.profile_patient.questionnaires.sf;
  const odi = t?.dashboard.hcp.profile_patient.questionnaires.odi;
  const not_completed = t?.dashboard.hcp.profile_patient.questionnaires.not_completed;
  const check_raw_data = t?.dashboard.hcp.profile_patient.questionnaires.check_raw_data;
  const last_name = t?.dashboard.hcp.table.last_name;
  const first_name = t?.dashboard.hcp.table.first_name;
  const nhs_number = t?.dashboard.hcp.table.nhs_number;
  const date_completed = t?.dashboard.hcp.profile_patient.questionnaires.date_completed;
  const health_level = t?.dashboard.hcp.profile_patient.questionnaires.health_Level;
  const meps = t?.dashboard.hcp.profile_patient.questionnaires.mayo;
  const prwe = t?.dashboard.hcp.profile_patient.questionnaires.prwe;
  const koos = t?.dashboard.hcp.profile_patient.questionnaires.koos;
  const hoos = t?.dashboard.hcp.profile_patient.questionnaires.hoos;

  // Proms queries
  const { _getPatientAvailableQuestionnaires } = useGetPatientQuestionnairesByProcedureType();
  const { _getPatientAllQuestionnaires } = useGetPatientAllQuestionnaires();
  const { _getPatientAllPainDetectPics } = useGetPatientAllPainDetectPics();
  const { _getAllQuestionnaires } = useGetAllQuestionnaires();
  const {
    _getPatientCompletedQuestionnaires,
    completedQuestionnaires,
    errorQuestionnaires,
    loadingQuestionnaires,
  } = useGetPatientCompletedQuestionnaires();

  // Data
  const patient: any = useSelector<any>((state) => getPatientProfile(state));
  const loadingProms = useSelector<any>((state) => getPatientPromsLoading(state));
  const loadingAllProms = useSelector<any>((state) => getLoadingAllProms(state));
  const loadingPainDetectPics = useSelector<any>((state) => getLoadingPainDetecPics(state));
  const patientPromsData: any = useSelector<any>((state) => getAllPatientProms(state));
  const painDetectPics: any = useSelector<any>((state) => getPainDetectPics(state));
  const allProms: any = useSelector<any>((state) => getAllProms(state));
  const formatsDate = useSelector((state: GetState) => getCurrentFormatDate(state));
  const loadAvailProms = useSelector((state: GetState) => getLoadingAvailablePatientProms(state));
  const availablePatientProms = useSelector((state: GetState) => getAllowedPatientProms(state));

  // Local state
  const [fileLoading, setFileLoading] = useState(false);
  const [isOpenRawData, setOpenRawData] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const loading: any = loadingProms || loadingAllProms || loadingPainDetectPics || loadAvailProms;
  const typePCS = PROMS_NAME.PCS;
  const typePain = PROMS_NAME.PainDetect;
  const { isAdmin } = useCheckRole();
  const isMobileDevice = useDeviceDetect().isMobile;

  const {
    aofasId,
    eq5d5lId,
    moxfqId,
    odiId,
    oxfordHipId,
    oxfordKneesId,
    oxfordShoulderId,
    pcsId,
    painDetectId,
    quickDashId,
    sfId,
    mepsId, // Elbow
    prweId, // Hand and Wrist
    hoosId, // Hip (free)
    koosId, // Knee (free)
  } = getPromsId(allProms);

  const dataToPcs = useMemo(() => dataToGraph(patientPromsData, typePCS, allProms), [
    patientPromsData,
    allProms,
  ]);
  const dataToPain = useMemo(() => dataToGraph(patientPromsData, typePain, allProms), [
    patientPromsData,
    allProms,
  ]);

  const pcsScore = dataToPcs?.status === statusQuestionnaries.COMPLETED ? dataToPcs.totalScore : 0;
  const pcsSkip = dataToPcs?.status === statusQuestionnaries.SKIPPED ? not_completed : null;
  const painScore =
    dataToPain?.status === statusQuestionnaries.COMPLETED ? dataToPain.totalScore : 0;
  const painSkip = dataToPain?.status === statusQuestionnaries.SKIPPED ? not_completed : null;

  const disabledPics = !(painDetectPics && painDetectPics.length > 0);

  // Check device
  useEffect(() => {
    setIsMobile(isMobileDevice);
  }, [isMobileDevice]);

  // Open modal export completed questionnaire to PDF
  const openRawData = (): void => {
    _getPatientCompletedQuestionnaires({
      variables: {
        patientId,
      },
    });
    setOpenRawData(() => true);
    if (isMobileDevice) {
      setIsMobile(() => true);
    }
  };

  // Close modal export completed questionnaire to PDF
  const onclose = (): void => {
    setIsMobile(() => false);
    setOpenRawData(() => false);
  };

  // Get data for charts
  useEffect(() => {
    const arg = {
      variables: {
        patientId,
      },
    };
    if (patientId) {
      // proms
      _getAllQuestionnaires();
      _getPatientAllQuestionnaires(arg);
      _getPatientAllPainDetectPics(arg);
      _getPatientAvailableQuestionnaires(arg);
    }
  }, [patientId]);

  // JSX
  const loadingJSX = (fileLoading || loading) && <Loading />;
  const pcsAndPd = (
    <div className={styles['proms__pcs-pd-container']}>
      {loadingJSX}
      <div className={styles['proms__pcs-pd-charts']}>
        {/* PCS */}
        <div className={styles['proms__pcs--container']}>
          <PcsChart nameChart={pcs} pcsScore={pcsScore} pcsSkip={pcsSkip} />
        </div>

        {/* PD */}
        <div className={styles['proms__pd--container']}>
          <PainDetect painScore={painScore} painSkip={painSkip} />
        </div>
      </div>

      {/* PD image */}
      <div className={styles['proms__pd-image--container']}>
        <div
          className={cx({
            [styles['proms__pd-img']]: true,
            [styles.disabled]: disabledPics,
          })}
        >
          <img src={!disabledPics ? painDetectPics[0].link : DefaultImg} alt="Pain Detect" />
        </div>
      </div>
    </div>
  );

  const eq5d5lContent = {
    name: eq_5d_5l,
    title: eq_5d_5l,
    content: (
      <div className={styles.proms__chart}>
        <Eq5D5L loading={loading} nameGraph={eq_5d_5l} chartId={eq5d5lId} />
      </div>
    ),
  };
  const quickDashContent = {
    name: quick_dash,
    title: quick_dash,
    content: (
      <div className={styles.proms__chart}>
        <QuickDash loading={loading} nameGraph={quick_dash} chartId={quickDashId} />
      </div>
    ),
  };
  const sf36Content = {
    name: sf36,
    title: sf36,
    content: (
      <div className={styles.proms__chart}>
        <Sf36 loading={loading} nameGraph={sf36} chartId={sfId} />
      </div>
    ),
  };

  const promsConfig: {
    name: string;
    title: string;
    content: JSX.Element;
  }[] = [
    {
      name: pcs,
      title: '',
      content: pcsAndPd,
    },
  ];

  switch (procedureKey) {
    case proceduresEnum.HIP: {
      promsConfig.push(eq5d5lContent);
      if (oxfordHipId && availablePatientProms[oxfordHipId]) {
        promsConfig.push({
          name: oxford_hip,
          title: oxford_hip,
          content: (
            <div className={styles.proms__chart}>
              <Oxford
                loading={loading}
                nameGraph={oxford_hip}
                chartId={oxfordHipId}
                srcColumnName="totalScore"
              />
            </div>
          ),
        });
      }
      if (hoosId && availablePatientProms[hoosId]) {
        promsConfig.push({
          name: hoos,
          title: hoos,
          content: (
            <div className={styles.proms__chart}>
              <Oxford
                loading={loading}
                nameGraph={hoos}
                chartId={hoosId}
                srcColumnName="intervalScore"
              />
            </div>
          ),
        });
      }
      break;
    }
    case proceduresEnum.KNEE: {
      promsConfig.push(eq5d5lContent);
      if (oxfordKneesId && availablePatientProms[oxfordKneesId]) {
        promsConfig.push({
          name: oxford_knee,
          title: oxford_knee,
          content: (
            <div className={styles.proms__chart}>
              <Oxford
                loading={loading}
                nameGraph={oxford_knee}
                chartId={oxfordKneesId}
                srcColumnName="totalScore"
              />
            </div>
          ),
        });
      }
      if (koosId && availablePatientProms[koosId]) {
        promsConfig.push({
          name: koos,
          title: koos,
          content: (
            <div className={styles.proms__chart}>
              <Oxford
                loading={loading}
                nameGraph={koos}
                chartId={koosId}
                srcColumnName="intervalScore"
              />
            </div>
          ),
        });
      }
      break;
    }
    case proceduresEnum.FOOT_AND_ANKLE:
      promsConfig.push(
        eq5d5lContent,
        {
          name: aofas,
          title: aofas,
          content: (
            <div className={styles.proms__chart}>
              <Oxford
                loading={loading}
                nameGraph={aofas}
                chartId={aofasId}
                srcColumnName="totalScore"
              />
            </div>
          ),
        },
        {
          name: moxfq,
          title: moxfq,
          content: (
            <div className={styles.proms__chart}>
              <Oxford
                loading={loading}
                nameGraph={moxfq}
                chartId={moxfqId}
                srcColumnName="totalScore"
              />
            </div>
          ),
        },
      );
      break;
    case proceduresEnum.OTHER_PHYSICAL_REHAB:
      promsConfig.push(eq5d5lContent);
      break;
    case proceduresEnum.SHOULDER:
      promsConfig.push(
        eq5d5lContent,
        {
          name: oxford_shoulder,
          title: oxford_shoulder,
          content: (
            <div className={styles.proms__chart}>
              <Oxford
                nameGraph={oxford_shoulder}
                chartId={oxfordShoulderId}
                srcColumnName="totalScore"
                loading={loading}
              />
            </div>
          ),
        },
        quickDashContent,
      );
      break;
    case proceduresEnum.SPINAL:
      promsConfig.push(sf36Content, {
        name: odi,
        title: odi,
        content: (
          <div className={styles.proms__chart}>
            <Oxford loading={loading} nameGraph={odi} chartId={odiId} srcColumnName="totalScore" />
          </div>
        ),
      });
      break;
    case proceduresEnum.ELBOW:
      promsConfig.push(
        eq5d5lContent,
        {
          name: meps,
          title: meps,
          content: (
            <div className={styles.proms__chart}>
              <Oxford
                loading={loading}
                nameGraph={meps}
                chartId={mepsId}
                srcColumnName="totalScore"
              />
            </div>
          ),
        },
        quickDashContent,
      );
      break;
    case proceduresEnum.HAND_AND_WRIST:
      promsConfig.push(
        eq5d5lContent,
        {
          name: prwe,
          title: prwe,
          content: (
            <div className={styles.proms__chart}>
              <Oxford
                loading={loading}
                nameGraph={prwe}
                chartId={prweId}
                srcColumnName="totalScore"
              />
            </div>
          ),
        },
        quickDashContent,
      );
      break;
    default:
      break;
  }

  // Download CSV file
  const downloadCSVHandler = (): void => {
    if (loading) return;
    setFileLoading(true);
    const PCS = dataToGraph(patientPromsData, PROMS_NAME.PCS, allProms)?.totalScore || '';
    const PD = dataToGraph(patientPromsData, PROMS_NAME.PainDetect, allProms)?.totalScore || '';
    const EQ = getDataForGraph(patientPromsData, eq5d5lId);
    const SF = getDataForGraph(patientPromsData, sfId);
    const oxfordSet: any = {};
    switch (procedureKey) {
      case proceduresEnum.HIP:
        oxfordSet.Hip = getDataForGraph(patientPromsData, oxfordHipId);
        break;
      case proceduresEnum.KNEE:
        oxfordSet.Knee = getDataForGraph(patientPromsData, oxfordKneesId);
        break;
      case proceduresEnum.SHOULDER:
        oxfordSet.Shoulder = getDataForGraph(patientPromsData, oxfordShoulderId);
        oxfordSet.QuickDash = getDataForGraph(patientPromsData, quickDashId);
        break;
      case proceduresEnum.FOOT_AND_ANKLE:
        oxfordSet.AOFAS = getDataForGraph(patientPromsData, aofasId);
        oxfordSet.MOXFQ = getDataForGraph(patientPromsData, moxfqId);
        break;
      case proceduresEnum.SPINAL:
        oxfordSet.ODI = getDataForGraph(patientPromsData, odiId);
        break;
      case proceduresEnum.ELBOW:
        oxfordSet.Elbow = getDataForGraph(patientPromsData, mepsId);
        oxfordSet.QuickDash = getDataForGraph(patientPromsData, quickDashId);
        break;
      case proceduresEnum.HAND_AND_WRIST:
        oxfordSet.HandAndWrist = getDataForGraph(patientPromsData, prweId);
        oxfordSet.QuickDash = getDataForGraph(patientPromsData, quickDashId);
        break;
      default:
        break;
    }

    let csvPromsContent = `data:text/csv;charset=utf-8,${first_name},${
      patient.firstName
    },\r\n${last_name},${patient.lastName},\r\n${nhs_number},${
      patient.nhsNumber ? String(patient.nhsNumber) : ''
    },\r\n`;
    csvPromsContent += `${pcs},${PCS},\r\n${pain_detect},${PD},\r\n`;
    if (EQ && EQ.length) {
      let date = '';
      let eq5d = '';
      let healthLevel = '';
      EQ.map((promsItem: any) => {
        date += `${
          promsItem.completedDate
            ? moment(promsItem.completedDate).format(formatsDate.momentFormat)
            : ','
        },`;
        eq5d += `${promsItem.totalScore},`;
        healthLevel += `${promsItem.healthLevel || 0},`;
        return null;
      });
      csvPromsContent += `,${date_completed},${date},\r\n,${eq_5d_5l},${eq5d},\r\n,${health_level},${healthLevel},\r\n\r\n`;
    }
    // SF-36
    if (SF && SF.length) {
      let date = '';
      const healthScoresResult: any = {};

      // Get the config if there is at least one filled period
      const getDomainConfig = (): any => {
        let flag = true;
        const result: any = [];
        SF.map((item: any) => {
          if (flag && item.healthScores?.length > 0) {
            item.healthScores.map((healthScore: HealthScoresType) => {
              healthScoresResult[healthScore.domain] = '';
              return result.push(healthScore.domain);
            });
            flag = false;
          }
          return null;
        });
        return result.length > 0 ? result : null;
      };
      const domainConfig: any = getDomainConfig();
      if (domainConfig.length) {
        SF.map((promsItem: TQuestionary) => {
          date += `${
            promsItem.completedDate
              ? moment(promsItem.completedDate).format(formatsDate.momentFormat)
              : ','
          },`;
          if (promsItem.healthScores?.length > 0) {
            promsItem.healthScores.map((healthScore: HealthScoresType) => {
              healthScoresResult[healthScore.domain] += `${healthScore.score || 0},`;
              return null;
            });
          } else {
            domainConfig.map((item: any) => {
              healthScoresResult[item] += '0,';
              return null;
            });
          }
          return null;
        });
        csvPromsContent += `,${date_completed},${date},\r\n`;
        csvPromsContent += `,${sf36},\r\n`;
        domainConfig.map((item: any) => {
          csvPromsContent += `,${item},${healthScoresResult[item]},\r\n`;
          return null;
        });
      }
    }

    // oxfords
    const oxfordsDataArr = Object.entries(oxfordSet);
    if (oxfordsDataArr && oxfordsDataArr.length) {
      /* eslint-disable-next-line */
      for (const [oxfordSetKey, oxfordSetValues] of oxfordsDataArr) {
        let oxfordDate = '';
        let oxfordValue = '';
        // @ts-ignore
        oxfordSetValues.map((oxfordSetValue: any) => {
          oxfordDate += `${
            oxfordSetValue.completedDate
              ? moment(oxfordSetValue.completedDate).format(formatsDate.momentFormat)
              : ','
          },`;
          oxfordValue += `${oxfordSetValue.totalScore},`;
          return null;
        });
        csvPromsContent += `\r\n,${date_completed},${oxfordDate},\r\n`;
        csvPromsContent += `,${oxfordSetKey},${oxfordValue},\r\n\r\n`;
      }
    }

    downloadFile(csvPromsContent, patient, 'proms', 'csv');
    setFileLoading(false);
  };

  // Download PDF file
  const downloadHandler = async (typeClass: string): Promise<void> => {
    if (!fileLoading && !loading) {
      setFileLoading(true);
      await exportToPdf(patient, typeClass);
      setFileLoading(false);
      setIsMobile(() => false);
    }
  };

  return (
    <div className={styles['proms__charts-wrapper']}>
      <div className={styles['proms-chart-title']}>{proms_summary}</div>

      {/* Export data */}
      <div className={styles['proms__export-icons--container']}>
        <IconTooltip mouseEnterDelay={0.3} placement="top" title={export_to_pdf}>
          <div
            className={cx({
              [styles['icon-pdf']]: true,
              [styles['disable-icon']]: fileLoading || loading,
            })}
            onClick={(): Promise<void> => downloadHandler('screen-to-PDF')}
            role="presentation"
          >
            <img src={PdfIcon} alt="PDF" />
          </div>
        </IconTooltip>
        <IconTooltip mouseEnterDelay={0.3} placement="bottom" title={export_to_csv}>
          <div
            className={cx({
              [styles['icon-pdf']]: true,
              [styles['disable-icon']]: fileLoading || loading,
            })}
            onClick={downloadCSVHandler}
            role="presentation"
          >
            <img src={CsvIcon} alt="CSV" />
          </div>
        </IconTooltip>

        {/* Check raw data */}
        {!isAdmin && (
          <div className={styles['prescrebed__download-wrapper']} aria-hidden onClick={openRawData}>
            <DownloadIcon />
            <div className={styles.prescrebed__download}>{check_raw_data}</div>
          </div>
        )}
      </div>

      {/* Charts */}
      <div className={styles['proms__charts--container']}>
        {promsConfig.map((component: ChartsConfig, index: number) => (
          <div
            key={`${String(index)}${component.name}`}
            className={cx({
              [styles['proms__chart--wrapper']]: true,
              'screen-to-PDF': true,
            })}
          >
            {/* Title chart */}
            {component.title && (
              <div className={styles['proms__chart--sub-title']}>{component.title}</div>
            )}

            {/* Current chart */}
            {component.content}
          </div>
        ))}
      </div>

      {/* MODAL */}
      {/* Export PROMs */}
      <PromsToPdf
        isModalOpen={isOpenRawData}
        isMobile={isMobile}
        setIsMobile={setIsMobile}
        completedQuestionnaires={completedQuestionnaires}
        fileLoading={fileLoading}
        loadingQuestionnaires={loadingQuestionnaires}
        downloadHandler={downloadHandler}
        onclose={onclose}
        errorQuestionnaires={errorQuestionnaires}
        questionnairesList={allProms}
        patient={patient}
        procedureKey={procedureKey}
      />
    </div>
  );
};

export default PromsLayout;
