import React, { useState, useEffect, ReactElement, useMemo, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Pagination } from 'antd';

import style from './styles.module.css';
import { getCurrenLang, getHcpName, GetState } from '../../redux/selector';
import { Loading, WarningNotification, Button, Modal } from '../../common';
import { ShowErrorNotification } from './ErrorMessage';
import { validationProfileHcp } from '../../utils';
import {
  capitalizeFirstLetter,
  getOptionsFromIdType,
  getSelectOptions,
  scrollPageTo,
  showTheStatus,
} from '../../utils/helper';
import { AnotherHcpProfileProps, PropFormHcpForm, TPatient, UpdatedHcpProfile } from './types';
import { PERMISSIONS, STATUS, StatusKeyNameEnum } from '../../utils/enums';
import {
  useDeactiveteHcp,
  useUpdateHcpProfileByAdmin,
  useUpdateHcpProfileByHcp,
} from '../../graphql/hcpProfile';
import { useQueryGetPatientsListByHcpId } from '../../graphql/patients';
import { PermissionsCheckboxesJSX } from '../invite/PermissionsCheckboxesJSX';
import { InputFloatLabel } from '../../common/Input';
import { InputPhoneNumberFloatLabel } from '../../common/Input/PhoneNumber';
import { SelectFloatLabel, SelectFloatLabelWithSearch } from '../../common/Input/Select';
import useDebounce from '../../hooks/useDebounce';

const AnotherHcpProfile = ({
  hcp,
  isAdmin,
  hospitalNames,
  departments,
  professions,
  isProfileUpdated,
  setProfileUpdated,
  dataQa,
}: AnotherHcpProfileProps): ReactElement => {
  const hcpCounry = hcp?.country;
  const t: any = useSelector((state: GetState) => getCurrenLang(state));
  const deactivated_successfully = t && t.notifications.deactivated_successfully;
  // const updated_profile = t?.notifications.updated_profile;
  const deactivate_user = t?.notifications.deactivate_user;
  const are_you_sure = t?.notifications.are_you_sure;
  const deactivate_btn = t?.common.deactivate;
  const search_placeholder = t?.modal.search_placeholder;
  const not_found = t?.modal.not_found;
  const first_name = t?.invite_hcp.first_name;
  const last_name = t?.invite_hcp.last_name;
  const email = t?.invite_hcp.email;
  const number_phone = t?.profile_hcp.number_phone;
  const hcp_profession = t?.profile_hcp.profession;
  const account_status = t?.dashboard.admin.profile_hcp.account_status;
  const status_active = t?.common.status_active;
  const status_inactive = t?.common.status_inactive;
  const deactivated = t?.dashboard.admin.profile_hcp.deactivated;
  const hcp_patients = t?.dashboard.admin.profile_hcp.patients;
  const save = t?.common.save;
  const no_options = t?.invite_patient.please_enter_min_3_characters;
  const organisation_name = t?.common.organisation_name;
  const department_t = t?.dashboard.admin.table.hcp.department;

  const currentUser: any = useSelector((state: GetState) => getHcpName(state));
  const isEditPermissions = isAdmin || hcp.invitersId.includes(currentUser.id);

  // Endpoints
  const { _getPatientById, patients, loading, totalItems } = useQueryGetPatientsListByHcpId();
  const {
    _updateProfile,
    successUpdatedProfile,
    errorMessage,
    updating,
  } = useUpdateHcpProfileByAdmin();
  const {
    _updateProfileByHcp,
    loadingByHcp,
    errorMessageByHcp,
    successUpdatedProfileByHcp,
  } = useUpdateHcpProfileByHcp();
  const { _onDeactiveteHcp, seccessDeletedHcp } = useDeactiveteHcp();

  // Local state
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [userDeactivated, setUserDeactivated] = useState(false);
  const [filter, setFilter] = useState<string>('');
  const [isDeactivete, setModalDeactivete] = useState<boolean>(false);

  useEffect(() => {
    if (seccessDeletedHcp) {
      toast.info(`${hcp.firstName} ${hcp.lastName} ${deactivated_successfully} `);
      setUserDeactivated(true);
    }
  }, [seccessDeletedHcp]);

  // Success message if updated HCP profile
  useEffect(() => {
    if (successUpdatedProfile || successUpdatedProfileByHcp) {
      // toast.info(`${hcp.firstName} ${hcp.lastName} ${updated_profile}`);
      setProfileUpdated(() => true);
      scrollPageTo(0);
    }
  }, [successUpdatedProfile || successUpdatedProfileByHcp]);

  // Get patients list
  useEffect(() => {
    _getPatientById({
      variables: {
        listProps: {
          page: 1,
          itemsPerPage: 10,
        },
        hcpId: hcp.id || 0,
        statuses: [STATUS.active, STATUS.inactive, STATUS.invited, STATUS.expired],
      },
    });
  }, []);

  const profession =
    professions && professions.map((item: any) => ({ value: item.id, label: item.type }));
  const phoneNumber = hcp.phone?.split('+');
  const hospitalNameOptions = useMemo(() => getSelectOptions(hospitalNames), [hospitalNames]);
  const departmentOptions = useMemo(() => getOptionsFromIdType(departments), [departments]);

  const editProfile_otm = PERMISSIONS.EDIT_PATIENT_PROFILE_INFO_OTM;
  const inviteHcp_otm = PERMISSIONS.INVITE_OR_ADD_HCP_OTM;
  const editExercise_otm = PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_OTM;
  const addVideo_otm = PERMISSIONS.ADD_VIDEOS_TO_TEAM_VIDEOBANK_OTM;
  const createTeam_otm = PERMISSIONS.CREATE_TEAM_OTM;

  const editProfile_hcp = PERMISSIONS.EDIT_PATIENT_PROFILE_INFO_HCP;
  const inviteHcp_hcp = PERMISSIONS.INVITE_OR_ADD_HCP_HCP;
  const editExercise_hcp = PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_HCP;
  const addVideo_hcp = PERMISSIONS.ADD_VIDEOS_TO_TEAM_VIDEOBANK_HCP;

  const initialValuesProfile: any = {
    email: hcp.email || '',
    firstName: hcp.firstName || '',
    lastName: hcp.lastName || '',
    phone: phoneNumber[1] || '',
    professionId: hcp.profession ? { value: hcp.profession.id, label: hcp.profession.type } : null,
    isUpdateButton: false,
    isDeactivateButton: false,
    organisationName: hcp.hospital ? { value: hcp.hospital.id, label: hcp.hospital.name } : '',
    department: hcp.department ? { value: hcp.department.id, label: hcp.department.type } : null,

    [editProfile_otm]: hcp[editProfile_otm] || false,
    [inviteHcp_otm]: hcp[inviteHcp_otm] || false,
    [editExercise_otm]: hcp[editExercise_otm] || false,
    [addVideo_otm]: hcp[addVideo_otm] || false,
    [createTeam_otm]: hcp[createTeam_otm] || false,

    [editProfile_hcp]: hcp[editProfile_hcp] || false,
    [inviteHcp_hcp]: hcp[inviteHcp_hcp] || false,
    [editExercise_hcp]: hcp[editExercise_hcp] || false,
    [addVideo_hcp]: hcp[addVideo_hcp] || false,
  };

  const toggleDeactiveModal = (): void => {
    setModalDeactivete(() => !isDeactivete);
  };

  const _onDeactivate = (): void => {
    _onDeactiveteHcp(hcp.id);
    toggleDeactiveModal();
  };

  const onSendModalConfirmSX = (
    <WarningNotification
      title={deactivate_user}
      contant={are_you_sure}
      buttonName={deactivate_btn}
      onCancel={toggleDeactiveModal}
      onSubmit={_onDeactivate}
    />
  );

  const _send = (customer: PropFormHcpForm): void => {
    const {
      email: emailReq,
      firstName,
      lastName,
      professionId,
      department,
      phone,
      organisationName,
    } = customer;
    const hcpPermissions = [];
    // group 1 (OTM sets access limits)
    if (customer.editPatientProfileInfo_otm) {
      hcpPermissions.push(PERMISSIONS.EDIT_PATIENT_PROFILE_INFO_OTM);
    }
    if (customer.inviteOrAddHcp_otm) {
      hcpPermissions.push(PERMISSIONS.INVITE_OR_ADD_HCP_OTM);
    }
    if (customer.editOrPrescribeExerciseVideos_otm) {
      hcpPermissions.push(PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_OTM);
    }
    if (customer.addVideosToTeamVideoBank_otm) {
      hcpPermissions.push(PERMISSIONS.ADD_VIDEOS_TO_TEAM_VIDEOBANK_OTM);
    }
    if (customer.createTeam_otm) {
      hcpPermissions.push(PERMISSIONS.CREATE_TEAM_OTM);
    }

    // group 2 (HCP sets access limits)
    if (customer.editPatientProfileInfo_hcp) {
      hcpPermissions.push(PERMISSIONS.EDIT_PATIENT_PROFILE_INFO_HCP);
    }
    if (customer.inviteOrAddHcp_hcp) {
      hcpPermissions.push(PERMISSIONS.INVITE_OR_ADD_HCP_HCP);
    }
    if (customer.editOrPrescribeExerciseVideos_hcp) {
      hcpPermissions.push(PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_HCP);
    }
    if (customer.addVideosToTeamVideoBank_hcp) {
      hcpPermissions.push(PERMISSIONS.ADD_VIDEOS_TO_TEAM_VIDEOBANK_HCP);
    }

    // Admin
    if (isAdmin) {
      const hcpUpdateData: UpdatedHcpProfile = customer && {
        firstName,
        lastName,
        email: emailReq,
        phone: `+${phone}`,
        professionId: professionId?.value || null,
        hospitalId: organisationName?.value || null,
      };
      if (department) {
        hcpUpdateData.departmentId = department.value;
      }
      _updateProfile(hcp.id, hcpUpdateData, hcpPermissions);
      return;
    }

    // HCP
    _updateProfileByHcp(hcp.id, hcpPermissions);
  };

  const formik = useFormik({
    initialValues: initialValuesProfile,
    validationSchema: () => validationProfileHcp(t, hcpCounry),
    onSubmit: (values) => {
      if (values.isUpdateButton) {
        _send(values);
      }
      if (values.isDeactivateButton) {
        toggleDeactiveModal();
      }
    },
  });
  const { values, errors, touched, isValid } = formik;

  useEffect(() => {
    if (isProfileUpdated) {
      setProfileUpdated(() => false);
    }
  }, [values]);

  const _onChangePagination = (page: number): void => {
    setCurrentPage(page);
    _getPatientById({
      variables: {
        listProps: {
          page,
          itemsPerPage: pageSize,
        },
        searchProps: [
          {
            searchField: 'patient.firstName',
            searchValue: filter,
          },
          {
            searchField: 'patient.lastName',
            searchValue: filter,
          },
          {
            searchField: 'patient.email',
            searchValue: filter,
          },
        ],
        statuses: [STATUS.active, STATUS.inactive, STATUS.invited, STATUS.expired],
        hcpId: hcp.id,
      },
    });
  };

  const debounceFilter = useDebounce(filter, 300);
  useEffect(() => {
    _getPatientById({
      variables: {
        listProps: {
          page: 1,
          itemsPerPage: pageSize,
        },
        searchProps: [
          {
            searchField: 'patient.firstName',
            searchValue: filter,
          },
          {
            searchField: 'patient.lastName',
            searchValue: filter,
          },
          {
            searchField: 'patient.email',
            searchValue: filter,
          },
        ],
        statuses: [STATUS.active, STATUS.inactive, STATUS.invited, STATUS.expired],
        searchWithAllMatches: false,
        hcpId: hcp.id,
      },
    });
  }, [debounceFilter]);

  // const _openPatientProfile = (user: number): void => {
  //   if (isAdmin) {
  //     const username = `${hcp.firstName}  ${hcp.lastName}`;
  //     history.push({
  //       pathname: `/admin/profile/hcp/relevantToPatient/${user}`,
  //       search: setQueryParams({
  //         relevant: true,
  //         hcpId: hcp.id,
  //         hcpName: username,
  //       }),
  //     });
  //   }
  // };

  const _onFilter = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
    setFilter(value);
  };

  const _updateHcpProfile = (): void => {
    formik.setFieldValue('isUpdateButton', true);
  };

  const _deactivateHcp = (): void => {
    formik.setFieldValue('isDeactivateButton', true);
  };

  const loadingJsx = (loadingByHcp || updating || loading) && <Loading />;

  return (
    <form onSubmit={formik.handleSubmit}>
      {loadingJsx}
      <div className={style.form_wrapper}>
        <div className={style.row}>
          <div className={style['cell-50']}>
            <InputFloatLabel
              inputId="HcpProfile_firstName"
              inputName="firstName"
              placeholder={first_name}
              inputType="text"
              inputValue={values.firstName}
              hasErrors={errors.firstName}
              isTouched={touched.firstName}
              onChangeMethod={formik.handleChange}
              disabled={!isAdmin}
              isRedStar
            />
          </div>
          <div className={style['cell-50']}>
            <InputFloatLabel
              inputId="HcpProfile_lastName"
              inputName="lastName"
              placeholder={last_name}
              inputType="text"
              inputValue={values.lastName}
              hasErrors={errors.lastName}
              isTouched={touched.lastName}
              onChangeMethod={formik.handleChange}
              disabled={!isAdmin}
              isRedStar
            />
          </div>
        </div>
        <InputFloatLabel
          inputId="email"
          inputName="email"
          inputType="text"
          placeholder={email}
          onChangeMethod={formik.handleChange}
          inputValue={values.email}
          hasErrors={errors.email}
          isTouched={touched.email}
          disabled={!isAdmin}
          isRedStar
        />

        <InputPhoneNumberFloatLabel
          inputId="phoneNumber"
          isValid={isValid}
          value={values.phone}
          hasErrors={errors.phone}
          isTouched={touched.phone}
          onChange={(date: any) => formik.setFieldValue('phone', date)}
          placeholder={number_phone}
          userCountry={hcpCounry}
          disabled={!isAdmin}
          isRedStar
        />
        <SelectFloatLabel
          inputId="profession"
          name="professionId"
          placeholder={hcp_profession}
          onChange={formik.setFieldValue}
          options={profession}
          hasErrors={errors.professionId}
          selected={values.professionId}
          isTouched={touched.professionId}
          type="invite hcp"
          disabledField={!isAdmin}
          isRedStar
        />
        <SelectFloatLabelWithSearch
          inputId="organisationName"
          name="organisationName"
          placeholder={organisation_name}
          onChange={formik.setFieldValue}
          options={hospitalNameOptions}
          hasErrors={errors.organisationName}
          isTouched={touched.organisationName}
          selected={values.organisationName}
          noOptionsMessage={no_options}
          disabledField={!isAdmin}
          type="invite hcp"
          isRedStar
        />
        <SelectFloatLabel
          inputId="department"
          name="department"
          placeholder={department_t}
          onChange={formik.setFieldValue}
          options={departmentOptions}
          hasErrors={errors.department}
          isTouched={touched.department}
          selected={values.department}
          disabledField={!isAdmin}
          type="invite hcp"
          isRedStar
        />
        {hcp.statusKey === StatusKeyNameEnum.active && !userDeactivated && isAdmin && (
          <div className={style.deactivvate_container}>
            <div className={style.active_status_title}>
              {account_status}
              <span className={style.status}>
                {hcp.statusKey === StatusKeyNameEnum.active && (
                  <span className={style.green}>{status_active}</span>
                )}
              </span>
            </div>
            <button
              data-qa={dataQa}
              className={style.button}
              onClick={_deactivateHcp}
              type="submit"
            >
              {deactivate_btn}
            </button>
          </div>
        )}
        {hcp.statusKey.toLowerCase() === StatusKeyNameEnum.inactive && isAdmin && (
          <div className={style.deactivvate_container}>
            <div className={style.active_status_title}>
              {account_status}
              <span className={style.status}>
                {hcp.statusKey.toLowerCase() === StatusKeyNameEnum.inactive && (
                  <span className={style.gray}>{status_inactive}</span>
                )}
              </span>
            </div>
          </div>
        )}
        {!isAdmin && (
          <div className={style.deactivate_container}>
            <div className={style.active_status_title}>
              {account_status}
              <span className={style.status}>
                {hcp.statusKey.toLowerCase() === StatusKeyNameEnum.inactive && (
                  <span className={style.gray}>{status_inactive}</span>
                )}
                {hcp.statusKey.toLowerCase() === StatusKeyNameEnum.active && (
                  <span className={style.green}>{status_active}</span>
                )}
              </span>
            </div>
          </div>
        )}
        {userDeactivated && <div className={style.deactivate_status_title}>{deactivated}</div>}

        {isDeactivete && (
          <Modal style={style.modal} onClose={toggleDeactiveModal}>
            {onSendModalConfirmSX}
          </Modal>
        )}
        {/* Patients */}
        <span className={style.subtitle}>{hcp_patients}</span>
        <>
          <InputFloatLabel
            inputId="searchPAtientId"
            inputName="filter"
            inputType="text"
            hasErrors={false}
            inputValue={filter}
            isTouched={false}
            onChangeMethod={_onFilter}
            placeholder={search_placeholder}
          />

          <ul className={style.list_patients}>
            {patients && patients.length > 0 ? (
              patients.map((patient: TPatient, i: number) => {
                const [, , hex] = showTheStatus(patient.status, t);
                const patientName =
                  patient.firstName || patient.lastName
                    ? `${patient.firstName}, ${patient.lastName}`
                    : patient.email;
                return (
                  <Fragment key={`patients_${String(i)}`}>
                    {patient.status === StatusKeyNameEnum.connected ? (
                      <li key={patient.id} className={style.list}>
                        <div>
                          <span className={style.item_patient}>{patientName}</span>
                        </div>
                        <div>
                          <span style={{ color: hex }}>
                            {capitalizeFirstLetter(patient.status)}
                          </span>
                        </div>
                      </li>
                    ) : (
                      <li key={patient.id} className={style.list}>
                        <div>
                          <span className={style.item_patient_not_link}>{patientName}</span>
                        </div>
                        <div>
                          <span style={{ color: hex }}>
                            {capitalizeFirstLetter(patient.status)}
                          </span>
                        </div>
                      </li>
                    )}
                  </Fragment>
                );
              })
            ) : (
              <></>
            )}

            {patients?.length === 0 && !loadingJsx ? (
              <div className={style.not_found}>{not_found}</div>
            ) : (
              <></>
            )}
          </ul>
          <div className={style.wrapper_pagination}>
            <Pagination
              size="small"
              current={currentPage}
              pageSize={pageSize}
              total={totalItems}
              onChange={_onChangePagination}
              showSizeChanger={false}
            />
          </div>
        </>

        {/* Permission checkboxes */}
        {isEditPermissions && (
          <PermissionsCheckboxesJSX
            isAdmin={isAdmin}
            inputValue={values}
            onChangeMethod={formik.handleChange}
          />
        )}

        {errorMessage && (
          <div className={style.errorMessages}>
            <ShowErrorNotification errorMessage={errorMessage} />
          </div>
        )}
        {errorMessageByHcp && (
          <div className={style.errorMessages}>
            <ShowErrorNotification errorMessage={errorMessageByHcp} />
          </div>
        )}

        <Button
          buttonType="submit"
          buttonName={save}
          buttonMethod={_updateHcpProfile}
          disabledButton={!formik.dirty || userDeactivated || isProfileUpdated}
          buttonClass={style['btn__submit--right-align']}
        />
      </div>
    </form>
  );
};

export default AnotherHcpProfile;
