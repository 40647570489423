/* eslint-disable indent */
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { toast } from 'react-toastify';
import cx from 'classnames';
import { useFormik } from 'formik';
import moment from 'moment';

import styles from '../styles.module.css';
import { Button, Loading, SelectStatusField, VideoModal } from '../../../../common';
import { GetState, getCurrenLang, getCurrentFormatDate } from '../../../../redux/selector';
import { EXERCISE_TYPE, PERMISSIONS, VIDEO_BANK_TYPE } from '../../../../utils/enums';
import {
  collectUuids,
  getExercisesCategories,
  getSetsInfo,
  getStatusOptions,
  getTouched,
  setToolTipTitleForStatus,
} from '../../../../utils/helper';
import {
  useChangeArchivedStatusExerciseByHcp,
  useDeleteExerciseByHcp,
  useQueryPhotoPic,
  useQueryThumbnail,
  useQueryVideo,
  useSaveExerciseFromFolderToPatient,
  useSaveVideoExerciseByHcp,
} from '../../../../graphql/videoBank';
import {
  COLOR_OPTIONS_VIDEOBANK,
  EXCLUDE_FROM_VIDEOBANK_STATUS_SELECT,
} from '../../../../utils/variables';
import useCheckPermissions from '../../../../hooks/useCheckPermissions';
import { PhotoSliderModal } from '../../../../common/VideoModal';
import { InputFloatLabel } from '../../../../common/Input';
import { ShowMediaIcon, ThumbnailJsx } from '../../../../common/ShowMediaIcon';
import { validationExercise, validationFocusCheckboxes } from '../../../../utils/validators';
import { ExerciseFileType, TStatusOptions } from '../../../../utils/model';
import { initialValues, notAllowedStatuses } from '../../VideoExercise/initForm';
import { DataVideoExercises, InitialValuesForm } from '../../VideoExercise/types';
import AddMediaSettingsForm from '../../OrganisationVideoBank/components/AddMediaSettingsForm';
import { selectStatusFieldStyle } from '../../../../common/Input/styles';
import { ShowErrorNotification } from '../../../Form/ErrorMessage';
import { WarnNotifModal } from '../../../../common/NotificationModal';

const MediaExercise = ({
  data,
  exercisesCategories,
  exercisesProcedureFocus,
  focusType1Arr,
  focusType2Arr,
  updateList,
  isInvitee,
  patientId,
  type,
  folderId,
  openNestedExerciseIds,
  setOpenNestedExerciseIds,
}: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const exercise_name = t?.dashboard.hcp.profile_patient.video_bank.exercise_name;
  const cancel = t?.common.cancel;
  const uploading = t?.common.uploading;
  const settings_t = t?.dashboard.hcp.profile_patient.video_bank.settings;
  const createExercise = t?.notifications.created_exercise;
  const updateExercise = t?.notifications.updated_exercise;
  const deletedExercise = t?.notifications.deleted_exercise;
  const you_dont_have_permission = t?.common.you_dont_have_permission;
  const loadingErrorMsg = t?.notifications.loading_error;
  const prescribed_on =
    t?.dashboard.hcp.profile_patient.video_bank.prescribed_on || 'Prescribed on';
  const save_and_send = t?.dashboard.hcp.profile_patient.video_bank.save_and_send;
  const archivedExercise = t?.notifications.archived_exercise;
  const unArchivedExercise = t?.notifications.unarchived_exercise;
  const save_and_send_anyway = t?.dashboard.hcp.profile_patient.video_bank.save_and_send_anyway;
  const archived_exerc_attention = t?.notifications.archived_exerc_attention;

  // Endpoints
  // Get image from AWS by Uuid
  const { _getThumbnailByName, thumbnail } = useQueryThumbnail();

  // get photo picture from AWS by Uuid
  const { _getPhotoPic, photoPic } = useQueryPhotoPic();

  // get video from AWS by uuid
  const { _getVideoByName, videoSrc, errorVideo } = useQueryVideo();

  // save and send exercise to patient by HCP
  const {
    _saveVideoExerciseByHcp,
    savedVideoExercise,
    errorMessage,
    updating,
  } = useSaveVideoExerciseByHcp(isInvitee);

  // Save and send to patient exercise from folder by HCP
  const {
    _saveExerciseFromFolderToPatient,
    savedExerciseFromFolder,
    errorMessageFromFolder,
    loadingFromFolder,
  } = useSaveExerciseFromFolderToPatient(isInvitee);

  // Add exercise to the archive
  const {
    _archiving,
    archiveData,
    archiveError,
    archiveLoading,
  } = useChangeArchivedStatusExerciseByHcp(isInvitee);

  // Softdelete exercise by HCP
  const { _deleting, deletedData, deleteError, deleteLoading } = useDeleteExerciseByHcp(isInvitee);

  // Local state
  const [isUploadingToAWS, setIsUploadingToAWS] = useState(false);
  const [isSettingsOpen, setOpenSettings] = useState(false);
  const [isVideoModalOpen, setVideoModal] = useState(false);
  const [isPhotoModalOpen, setPhotoModal] = useState(false);
  const [videoSrcState, setVideoSrcState] = useState<any>('');
  const [isWarningArchiveExerc, setWarningArchiveExerc] = useState(false);

  const formatsDate = useSelector((state: GetState) => getCurrentFormatDate(state));
  const exerciseFileType: ExerciseFileType = data.type;
  const { exerciseCreatedAt } = data;
  const isPhotosExist = !!data.exercisePhotos?.length;
  const exercisePhotos = isPhotosExist ? data.exercisePhotos : [];
  const isPermissionEditVideoExerc = useCheckPermissions(
    PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_OTM,
  );
  const initialValuesForm: InitialValuesForm = initialValues(
    data,
    exercisesCategories,
    exercisesProcedureFocus,
    t,
    focusType1Arr,
    focusType2Arr,
  );

  const formik = useFormik({
    initialValues: initialValuesForm,
    validate: (values) => validationFocusCheckboxes(values, t),
    validationSchema: () => validationExercise(t),
    onSubmit: async (values: InitialValuesForm) => {
      if (values.status.value === 'Archived' && !isWarningArchiveExerc) {
        setWarningArchiveExerc(() => true);
        return;
      }

      const request: DataVideoExercises = {
        isVideoOwnerHcp: false,
        exerciseCategoriesId: getExercisesCategories(values, exercisesCategories, 'focus'),
        exerciseProcedureTypesId: getExercisesCategories(
          values,
          exercisesProcedureFocus,
          'procedure',
        ),
        patientId,
        photos: isPhotosExist ? collectUuids(exercisePhotos) : null,
        exerciseData: {
          id: values.exerciseId,
          name: values.name,
          sets: Number(values.sets) || null,
          reps: values.dim === 'time' ? null : Number(values.reps) || null,
          time:
            values.dim === 'time'
              ? Number(values.seconds.value) + Number(values.minutes.value) * 60
              : null,
          timesPerDay: Number(values.timesDay),
          restDays: Number(values.restDaysWeek) || null,
          comment: values.comments,

          // Если есть id (из гет) тоесть для апдейта упражнения.
          // для создания упражнения все поля раскомитить
          // thumbnailName: values.thumbnailName,
          // videoName: values.videoName,
          // videobankId: values.videobankId,
          // videoUploaded: values.exerciseVideoUploaded,
          // thumbnailUploaded: values.exerciseThumbnailUploaded,
        },
      };

      // Patient videobank exercise from folder
      if (type === VIDEO_BANK_TYPE.NESTED) {
        request.folderId = folderId;
        request.exerciseData.videobankId = values.videobankId;
        _saveExerciseFromFolderToPatient(request);
        return;
      }
      _saveVideoExerciseByHcp(request);
    },
  });

  const { values, dirty } = formik;
  const hasFocusErrors = formik.errors.focus1;
  const isFocusTouched = getTouched(formik.touched);

  /* After the exercise has been saved and sent to patient,
    corresponding message is displayed and close settigs */
  useEffect(() => {
    if (!String(savedVideoExercise).includes('true') && savedVideoExercise) {
      toast.info(`${values.name} ${createExercise}`);
      updateList();
    }
    if (String(savedVideoExercise).includes('true') && savedVideoExercise) {
      toast.info(`${values.name} ${updateExercise}`);
      updateList();
    }
    if (!errorMessage && savedVideoExercise) {
      if (
        values.status.label === 'Deleted by hcp' ||
        values.status.label === 'Deleted by patient'
      ) {
        formik.setFieldValue('status', getStatusOptions('Active', 'videobank', t));
      }
      setOpenSettings(() => false);
      setWarningArchiveExerc(() => false);
    }
  }, [savedVideoExercise]);

  /* After success archived exercise corresponding message is displayed and close settings */
  useEffect(() => {
    if (archiveData) {
      if (values.status.value !== 'Archived') {
        formik.setFieldValue('status', getStatusOptions('Archived', 'videobank', t));
        toast.info(`${values.name} ${archivedExercise}`);
      } else {
        formik.setFieldValue('status', getStatusOptions('Active', 'videobank', t));
        toast.info(`${values.name} ${unArchivedExercise}`);
      }
      updateList();
    }
    if (!archiveError && archiveData) {
      setOpenSettings(() => false);
    }
  }, [archiveData]);

  /* After success deleted exercise corresponding message is displayed and close settings
    options */
  useEffect(() => {
    if (deletedData) {
      formik.setFieldValue('status', getStatusOptions('Deleted by hcp', 'videobank', t));
      toast.info(`${values.name} ${deletedExercise}`);
      updateList();
    }
    if (!deleteError && deletedData) {
      setOpenSettings(() => false);
    }
  }, [deletedData]);

  /* After the exercise video is saved FROM FOLDER and sent to patient,
    corresponding message is displayed and close settigs options */
  useEffect(() => {
    if (!String(savedExerciseFromFolder).includes('true') && savedExerciseFromFolder) {
      toast.info(`${values.name} ${createExercise}`);
      updateList();
      setWarningArchiveExerc(() => false);
    }
    if (String(savedExerciseFromFolder).includes('true') && savedExerciseFromFolder) {
      toast.info(`${values.name} ${updateExercise}`);
      updateList();
      setWarningArchiveExerc(() => false);
    }
    if (!errorMessageFromFolder && savedExerciseFromFolder) {
      formik.setFieldValue('isDraft', true);
      if (
        values.status.label === 'Deleted by hcp' ||
        values.status.label === 'Inactive' ||
        values.status.label === 'Deleted by patient'
      ) {
        formik.setFieldValue('status', getStatusOptions('Active', 'videobank', t));
      }
      setOpenSettings(() => false);
    }
  }, [savedExerciseFromFolder]);

  // Loading Thumbnail
  useEffect(() => {
    if (data.thumbnailName) {
      _getThumbnailByName({
        variables: {
          thumbnailKey: data.thumbnailName,
        },
      });
      return;
    }
    if (isPhotosExist) {
      _getPhotoPic({
        variables: {
          pictureUuid: exercisePhotos[0].fileUuid,
        },
      });
      return;
    }
    if (!data.thumbnailName && !data.videoName && !data.exerciseVideoUploaded && !isPhotosExist) {
      setIsUploadingToAWS(() => true);
    } else {
      setIsUploadingToAWS(() => false);
    }
  }, [data]);

  // Open video modal window
  useEffect(() => {
    if (videoSrc) {
      setVideoSrcState(videoSrc);
      setVideoModal(() => true);
    }
  }, [videoSrc]);

  useEffect(() => {
    if (errorVideo) {
      toast.error(errorVideo);
    }
  }, [errorVideo]);

  // Check opened exercises settings and disable Save folder buttons
  useEffect(() => {
    if (type === VIDEO_BANK_TYPE.NESTED) {
      if (isSettingsOpen) {
        setOpenNestedExerciseIds([...openNestedExerciseIds, values.exerciseId]);
        return;
      }
      const filtredIds = openNestedExerciseIds.filter((id: number) => id !== values.exerciseId);
      setOpenNestedExerciseIds(filtredIds);
    }
  }, [isSettingsOpen]);

  // Video modal window handler
  const showMedia = (): void => {
    switch (exerciseFileType) {
      case EXERCISE_TYPE.VIDEO:
        if (data.videoName) {
          _getVideoByName({
            variables: {
              videoKey: data.videoName,
            },
          });
          // setModal(() => !isVideoModalOpen);
        }
        return;
      case EXERCISE_TYPE.PHOTO:
        if (photoPic) {
          setPhotoModal(() => !isPhotoModalOpen);
        }
        return;
      default:
        setPhotoModal(() => false);
    }
  };

  // Close open modal windows
  const onCloseModal = (): void => {
    setVideoModal(() => false);
    setPhotoModal(() => false);
    setWarningArchiveExerc(() => false);
  };

  const changeStatusHandler = (value: TStatusOptions): void => {
    if (
      (value.value === 'Archived' && values.status.value !== 'Archived') ||
      (value.value === 'Active' && values.status.value !== 'Active')
    ) {
      if (!notAllowedStatuses.includes(values.status.value) && isPermissionEditVideoExerc) {
        _archiving({
          variables: {
            patientId,
            exerciseData: {
              id: values.exerciseId,
            },
          },
        });
      }
    }
    if (value.value === 'Deleted by hcp' && values.status.value !== 'Deleted by hcp') {
      _deleting({
        variables: {
          patientId,
          exerciseData: {
            id: values.exerciseId,
          },
        },
      });
    }
  };

  // Close settings
  const closeSettings = (): void => {
    formik.resetForm();
    setOpenSettings(() => false);
  };

  // JSX
  const loadingJSX = (updating || archiveLoading || deleteLoading || loadingFromFolder) && (
    <Loading />
  );
  const isNotValidExercise = (!thumbnail || !data.videoName) && !isPhotosExist && !loadingJSX;
  const tooltipTitleForStatus = setToolTipTitleForStatus(values.status?.value, t);
  const warnContant = (
    <div className={styles['video-exercise__archive-warn']}>{archived_exerc_attention}</div>
  );
  const actionJsx = (
    <>
      <Tooltip title={tooltipTitleForStatus}>
        <div className={styles['video-exercise__status']}>
          <SelectStatusField
            options={COLOR_OPTIONS_VIDEOBANK(t)}
            onChange={changeStatusHandler}
            inputValue={values.status}
            disabledField={
              notAllowedStatuses.includes(values.status?.value) || !isPermissionEditVideoExerc
            }
            filterOptionsArr={EXCLUDE_FROM_VIDEOBANK_STATUS_SELECT}
            customStyles={selectStatusFieldStyle(values.status?.color, !isPermissionEditVideoExerc)}
          />
        </div>
      </Tooltip>
      <Tooltip title={!isPermissionEditVideoExerc ? you_dont_have_permission : ''}>
        <div
          className={cx({
            [styles['video-exercise__btn--settings']]: true,
            [styles['video-exercise__btn--settings-open']]: isSettingsOpen,
            [styles['video-exercise__btn--disabled']]: !isPermissionEditVideoExerc,
          })}
          aria-hidden
          onClick={(): void => setOpenSettings(() => !isSettingsOpen)}
        >
          {settings_t}
        </div>
      </Tooltip>
    </>
  );

  return (
    <Tooltip title={isNotValidExercise ? loadingErrorMsg : ''} color="#F95428">
      <div>
        <div
          className={cx({
            [styles['video-exercise__row']]: true,
            [styles['video-exercise__row-disable']]: isNotValidExercise,
          })}
        >
          {loadingJSX}

          {/* Thumbnail */}
          <div className={styles['video-exercise__block']}>
            {!isUploadingToAWS && (
              <div
                className={cx({
                  [styles.preview]: true,
                  [styles['preview-not-allowed']]:
                    (!thumbnail || !data.videoName) && !isPhotosExist,
                })}
                role="presentation"
                onClick={showMedia}
              >
                <ThumbnailJsx thumbLink={thumbnail} photoPic={photoPic} />
                <ShowMediaIcon thumbLink={thumbnail} photoPic={photoPic} />
              </div>
            )}
            {isUploadingToAWS && <div className={styles.isUploading}>{uploading}...</div>}
          </div>

          {/* Name */}
          <div className={styles['video-exercise__name-container']}>
            <div className={styles['video-exercise__name']}>{values.name}</div>
            <div className={styles['video-exercise__date']}>
              {`${prescribed_on} ${moment(exerciseCreatedAt).format(formatsDate.momentFormat)}`}
            </div>

            <div className={styles['video-exercise__sets']}>{getSetsInfo(values, t)}</div>
            <div className={styles['video-exercise__action-container--sm']}>{actionJsx}</div>
          </div>

          {/* Action */}
          <div className={styles['video-exercise__action-container']}>{actionJsx}</div>
        </div>

        {/* Settings */}
        {isSettingsOpen && (
          <form onSubmit={formik.handleSubmit}>
            <div className={styles['video-exercise__name--input']}>
              <InputFloatLabel
                inputId="name"
                inputName="name"
                inputType="text"
                placeholder={exercise_name}
                hasErrors={formik.errors.name}
                inputValue={values.name}
                isTouched={formik.touched.name}
                onChangeMethod={formik.handleChange}
                disabled={!isPermissionEditVideoExerc}
              />
            </div>
            <AddMediaSettingsForm
              formik={formik}
              focusType1Arr={focusType1Arr}
              focusType2Arr={focusType2Arr}
              exercisesCategories={exercisesCategories}
              exercisesProcedureFocus={exercisesProcedureFocus}
              loadingJSX={loadingJSX}
            />
            {/* Errors */}
            {(errorMessage || archiveError || deleteError || errorMessageFromFolder) &&
              hasFocusErrors &&
              isFocusTouched && (
                <div className={styles.errorMessages}>
                  <ShowErrorNotification errorMessage={errorMessage} />
                  <ShowErrorNotification errorMessage={archiveError} />
                  <ShowErrorNotification errorMessage={deleteError} />
                  <ShowErrorNotification errorMessage={errorMessageFromFolder} />
                </div>
              )}

            <div className={styles['add-video__btns-container']}>
              <Button
                buttonClass={styles['add-video__btn']}
                buttonType="button"
                buttonName={cancel}
                buttonMethod={closeSettings}
                disabledButton={!!loadingJSX}
              />
              <Button
                buttonClass={styles['add-video__btn']}
                buttonType="submit"
                buttonName={save_and_send}
                disabledButton={!isPermissionEditVideoExerc || !!loadingJSX}
              />
            </div>
          </form>
        )}

        {/* Popups */}
        {isVideoModalOpen && <VideoModal videoSrc={videoSrcState} onclose={onCloseModal} />}
        {isPhotoModalOpen && (
          <PhotoSliderModal
            exercisePhotos={isPhotosExist ? exercisePhotos : []}
            photoPreviews={data.photoPreviews ? data.photoPreviews : []}
            onclose={onCloseModal}
          />
        )}

        {/* If the current exercise has a status of "Archive" - Warning before saving */}
        {isWarningArchiveExerc && (
          <WarnNotifModal
            onClose={onCloseModal}
            content={warnContant}
            cancelBtnName={cancel}
            actionBtnName={save_and_send_anyway}
            actionMethod={formik.handleSubmit}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default MediaExercise;
