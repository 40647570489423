import { createSelector } from '@reduxjs/toolkit';

import { calculateForCount } from '../../utils/helper_charts';
import { PatientState } from '../patient';
import { QuestionnaireState } from '../questionnaires/types';
import { SelectedPeriodType } from '../dropdown';
import { PeriodDropDownOptions, ProcedureKey } from '../../utils/model';
import { ChartsDataType, ChartValuesType, MotivationState } from '../motivations';
import {
  ExercComplDataType,
  ExercisesType,
  ExercPerPeriodType,
  NotComplExercDataType,
  NotComplExercValuesType,
} from '../completions/types';
import { CommonType, HcpName } from '../common';
import { TExerciseCategories } from '../../components/Video/OrganisationVideoBank/types';
import { RehabTeamsType } from '../rehabTeams';
import { RewardsStoreType } from '../rewards';
import { EmailNotifStoreType } from '../emailNotifications';
import { PatientsInvitationStoreType } from '../patientInvitation';
import { HcpNotificationsStateType } from '../notifications';
import { BodyDataState, SleepChartDataType, SleepChartValuesType } from '../bodyData';
import { PERIOD_FOR_CHART } from '../../utils/enums';
import { chartItemsCount } from '../../utils/share/options';
import { AvailablePatientPromsType } from '../../components/Charts/types';
import { FormatDates } from '../types';

export interface GetState {
  t: { local: object };
  completed: ExercisesType;
  motivation: MotivationState;
  patient: PatientState;
  common: CommonType;
  proms: QuestionnaireState;
  selectedPeriod: SelectedPeriodType;
  rehabTeams: RehabTeamsType;
  rewards: RewardsStoreType;
  emailNotification: EmailNotifStoreType;
  patientsInvitation: PatientsInvitationStoreType;
  notifications: HcpNotificationsStateType;
  bodyData: BodyDataState;
}

// Common
export const getCurrenLang = (state: GetState): any => state.t.local;
export const getCurrentFormatDate = (state: GetState): FormatDates => state.common.formatDates;
export const getUserLang = (state: GetState): string => state.common.userLang;
export const getUserCountry = (state: GetState): string => state.common.userCountry;
export const getHcpName = (state: GetState): HcpName => state.common.hcp;
export const getHcpAvatarPic = (state: GetState): string => state.common.hcp.photoPic;
export const getHcpAvatarUuid = (state: GetState): string => state.common.hcp.photo;
export const getHcpNameLoading = (state: GetState): boolean => state.common.hcpLoading;
export const getPermissions = (state: GetState): any[] => state.common.hcp.permissions;
export const getProcedureKeys = (state: GetState) => state.common.listProcedureKeys;
export const getLoadingProcedureKeys = (state: GetState) => state.common.loadingKeysOfProcedures;
export const getProcedures = (state: GetState) => state.common.procedures;
export const getLoadingProcedures = (state: GetState): boolean => state.common.loadingProcedures;
export const getExerciseCategories = (state: GetState) => state.common.exerciseCategoriesList;
export const getLoadingExerciseCategories = (state: GetState) => {
  return state.common.loadingExerciseCategoriesList;
};
export const getMenuPosition = (state: GetState): boolean => state.common.isShowMenu;

// Patient profile
export const getPatientProfile = (state: GetState): any => state.patient.profile;
export const getLoadingPatientProfile = (state: GetState): boolean => state.patient.loading;
export const getErrorPatientProfile = (state: GetState): any => state.patient.error;
export const getIsNotSavedPatientProfil = (state: GetState) => state.patient.isNotSavedProfileData;
export const getIsNotSavedPatientInfo = (state: GetState) => state.patient.isNotSavedPatientInfo;
export const getExerciseLibrary = (state: GetState): any => state.patient.exerciseLibrary;
export const getExercLibTotalItems = (state: GetState) => state.patient.exerciseLibraryTotalItems;
export const getDraggedList = (state: GetState): any => state.patient.draggedList;
export const getStartUpd = (state: GetState): any => state.patient.isStartUpdate;

// Chart state
// --motivation
export const getMotivation = (state: GetState) => state.motivation.motivation;
export const getMotivationLoading = (state: GetState) => state.motivation.loading;
export const getMotivationError = (state: GetState) => state.motivation.error;

// --bodyData
export const getPatientSleepData = (state: GetState) => state.bodyData.sleep;
export const getPatientSleepLoading = (state: GetState) => state.bodyData.sleepLoading;
export const getPatientSleepError = (state: GetState) => state.bodyData.sleepError;
export const getPatientHeartRateData = (state: GetState) => state.bodyData.heartRate;
export const getPatientHeartRateLoading = (state: GetState) => state.bodyData.heartRateLoading;
export const getPatientHeartRateError = (state: GetState) => state.bodyData.heartRateError;

// --pain
export const getPainLevelData = (state: GetState) => state.motivation.painLevel;
export const getPainLevelLoading = (state: GetState) => state.motivation.painLevelLoading;
export const getPainLevelError = (state: GetState) => state.motivation.painLevelError;

// export const getMotivValueForLegend = (state: GetState) => state.completed.motivationsLegendValue;

// --reasons
export const getNotComplExerc = (state: GetState) => state.completed.notCompletedExercises;
export const getNotComplExercLoading = (state: GetState): any => {
  return state.completed.notCompletedExercisesLoading;
};
export const getNotComplExercError = (state: GetState): any => {
  return state.completed.notCompletedExercisesError;
};

// --exercises
export const getExercComplData = (state: GetState) => state.completed.exerciseComletion;
export const getExercComplLoading = (state: GetState) => state.completed.exerciseComletionLoading;
export const getExercComplError = (state: GetState) => state.completed.exerciseComletionError;
export const getExercPerPeriodData = (state: GetState) => state.completed.exercisesPerPeriod;
export const getExPerPeriodLoadin = (state: GetState) => state.completed.exercisesPerPeriodLoading;

// export const getExercForMotivationPainTab = (state: GetState) => state.completed.exercMotivPainTab;
export const getExercForReasonsTab = (state: GetState) => state.completed.exercReasonsTab;
// --by exercises
export const getDataByExercises = (state: GetState): any => state.completed.byExercises;
// export const getByExercValueForLegend = (state: GetState) =>
//   state.completed.complByExercLegendValue;
// --by periods
export const getPeriodsOfExercises = (state: GetState): any => state.completed.exercisesPerPeriod;

// --steps
export const getPatientsSteps = (state: GetState): any => state.completed.steps;
export const getStepsLoading = (state: GetState): boolean => state.completed.stepsLoading;
export const getStepsError = (state: GetState): boolean => state.completed.error;

export const getStepsOverviewTab = (state: GetState): any => state.completed.stepOverviewTab;
export const getStepsByExercisesTab = (state: GetState): any => state.completed.stepByExercises;
// export const getComplStepsForLegend = (state: GetState) => state.completed.complStepsLegendValue;

// --skip/stop
// export const getComplSkipStopForLegend = (state: GetState) =>
//   state.completed.complSkipStopLegendValue;

export const getIsExercise = (state: GetState) => state.completed.isExercise;
export const getExerciseName = (state: GetState): null | string => state.completed.exerciseName;
export const getExercisesLoading = (state: GetState): boolean => state.completed.isFetching;

// Selected periods
export const getSelectedPeriodOverview = (state: GetState) => state.selectedPeriod.overview;
export const getSelectedPerRemoteMonit = (state: GetState) => state.selectedPeriod.remoteMonitoring;

// PROMs state
export const getAllPatientProms = (state: GetState) => state.proms.allPatientQuestionnaires;
export const getPatientPromsLoading = (state: GetState) => state.proms.loadingQuestionnaires;
export const getAllProms = (state: GetState) => state.proms.questionnairesList.allQuestionnaires;
export const getLoadingAllProms = (state: GetState): boolean => {
  return state.proms.questionnairesList.loadingAllQuestionnaires;
};
export const getPainDetectPics = (state: GetState) => state.proms.painDetectPics.pics;
export const getLoadingPainDetecPics = (state: GetState) => state.proms.painDetectPics.loadingPics;
export const getEqValueForLegend = (state: GetState) => state.proms.eqLegendValue;
export const getAvailablePatientProms = (state: GetState) => {
  return state.proms.availablePatientQuestionnaires;
};
export const getLoadingAvailablePatientProms = (state: GetState): boolean => {
  return state.proms.loadingAvailablePatientQuestionnaires;
};

// Rehab Teams
export const getRehabTeamList = (state: GetState) => state.rehabTeams.teamList;
export const getRehabTeamsLoading = (state: GetState) => state.rehabTeams.teamListLoading;
export const getRehabTeamsError = (state: GetState): string => state.rehabTeams.teamListError;
export const getRehabTeamsTotalItems = (state: GetState) => state.rehabTeams.totalItems;
export const getSearchTeamLoading = (state: GetState) => state.rehabTeams.searchTeamLoading;
export const getTotalItemsSearchTeam = (state: GetState) => state.rehabTeams.totalItemsSearchTeam;
export const getTeamSettingsData = (state: GetState) => state.rehabTeams.openTeamSettingData;

export const getPatientTeamList = (state: GetState) => state.rehabTeams.patientTeamList;
export const getPatientTeamsLoading = (state: GetState) => state.rehabTeams.patientTeamListLoading;
export const getPatientTeamsError = (state: GetState) => state.rehabTeams.patientTeamListError;
export const getPatientTeamsTotalItems = (state: GetState) => {
  return state.rehabTeams.patientTeamTotalItems;
};
export const getSearchPatientTeamLoading = (state: GetState) => {
  return state.rehabTeams.searchPatientTeamLoading;
};
export const getTotalItemsSearchPatientTeam = (state: GetState) => {
  return state.rehabTeams.totalItemsSearchPatientTeam;
};

// Rewards
export const getRewardList = (state: GetState) => state.rewards.rewardList;
export const getRewardListLoading = (state: GetState) => state.rewards.rewardListLoading;
export const getRewardListError = (state: GetState): string => state.rewards.rewardListError;
export const getRewardSettingsData = (state: GetState) => state.rewards.openRewardSettingData;

// Email Notification
export const getNotifToMeData = (state: GetState) => state.emailNotification.notifToMeData;
export const getNotifToMeLoading = (state: GetState) => state.emailNotification.notifToMeLoading;
export const getNotifToMeError = (state: GetState): string =>
  state.emailNotification.notifToMeError;
export const getEmailNotifSettingsData = (state: GetState) =>
  state.emailNotification.openSettingData;

// Patient Invitation
export const getPatientsInvitationData = (state: GetState): any =>
  state.patientsInvitation.patientsInvitations;
export const getPatientsInvitationLoading = (state: GetState): boolean =>
  state.patientsInvitation.patientsInvitationsLoading;
export const getPatientsInvitationError = (state: GetState): any =>
  state.patientsInvitation.patientsInvitationsError;
export const getPatientsInvitationTotal = (state: GetState): any => state.patientsInvitation.total;

// compose selector
// export const getMotivationWithPainPerDay = (state: any) => {
//   const getCompleted = getDataByExercises(state);
//   return getCompleted.map(({ date, painLevel }) => ({
//     date,
//     painLevel,
//   }));
// };

export const getAllowedPatientProms = createSelector(
  getAvailablePatientProms,
  (availablePatientProms: AvailablePatientPromsType[]) => {
    const normilizeData: any = {};
    if (availablePatientProms?.length > 0) {
      availablePatientProms.map((questionnaiere: AvailablePatientPromsType) => {
        normilizeData[questionnaiere.id] = questionnaiere.status;
        return null;
      });
    }
    return normilizeData;
  },
);

// Skip Stop
// By periods
export const getPainAndMotivationDataPeriods = createSelector(
  getPeriodsOfExercises,
  getSelectedPerRemoteMonit,
  getMotivation,
  (exercises, period, motivations) => {
    // const exercises = exercisesArrayAll.filter((item: any) => item.exerciseDone !== null);

    // const { periodFrom, dateTo, periodType } = period;
    // const dates = getDatesByFormat(periodFrom, dateTo, periodType);
    // const painTypes = [
    //   'sumPainScoreCompleted',
    //   'sumPainScoreSkip',
    //   'sumPainScoreStop',
    //   'exerciseCompleted',
    //   'exerciseSkipped',
    //   'exerciseStopped',
    //   'exerciseSkippedAll',
    //   'exerciseStoppedAll',
    //   'sumDesiredNumberOfReps',
    //   // 'sumPainScoreBackCompleted',
    //   // 'sumPainScoreLegCompleted',
    //   // 'sumPainScoreBackSkip',
    //   // 'sumPainScoreLegSkip',
    // ];
    // const averagePainTyps = ['motivation'];
    // const painAndMotivationResult: TDataPerPeriod[] = [];
    // let emptyData = 0;
    // let ticks = 0;

    // if (exercises && exercises.length > 0) {
    //   let count = 0;
    //   for (const [dateKey, dateArray] of Object.entries(dates)) {
    //     const dataPerPeriod: TDataPerPeriod = {
    //       motivation: 0,
    //       sumPainScoreCompleted: 0,
    //       sumPainScoreSkip: 0,
    //       sumPainScoreStop: 0,
    //       exerciseCompleted: 0,
    //       exerciseSkipped: 0,
    //       exerciseStopped: 0,
    //       exerciseSkippedAll: 0,
    //       exerciseStoppedAll: 0,
    //       averagePainScoreCompleted: 0,
    //       averagePainScoreSkipStop: 0,
    //       dateArray: '',
    //       isArchive: false,
    //       sumDesiredNumberOfReps: 0,
    //       avDesiredNumberOfReps: 0,
    //       sumPainScoreBackCompleted: 0, // for motiv and pain chart
    //       sumPainScoreLegCompleted: 0, // for motiv and pain chart
    //       sumPainScoreBackSkip: 0, // for motiv and pain chart
    //       sumPainScoreLegSkip: 0, // for motiv and pain chart
    //       sumPainScoreBackStop: 0, // for motiv and pain chart
    //       sumPainScoreLegStop: 0, // for motiv and pain chart
    //       avPainScoreBackCompleted: 0, // for motiv and pain chart
    //       avPainScoreBackSkipStop: 0, // for motiv and pain chart
    //       avPainScoreLegCompleted: 0, // for motiv and pain chart
    //       avPainScoreLegSkipStop: 0, // for motiv and pain chart
    //     };

    //     let empty = 0;
    //     dateArray.map((date: string) => {
    //       const motivation = motivations.find((item: any) => item.createdAt.includes(date));
    //       if (motivation) {
    //         dataPerPeriod.motivation += +motivation.type;
    //       }
    //       exercises.map((exercise: any) => {
    //         exercise.exerciseDone.map((exerciseResult: any) => {
    //         // JSON.parse(exercise.exerciseDone).map((exerciseResult: any) => {
    //           if (exerciseResult[date] !== null && exerciseResult[date] !== undefined) {
    //             for (const [key, value] of Object.entries(exerciseResult[date])) {
    //               if (painTypes.includes(key)) {
    //                 dataPerPeriod[key as keyof TAverageMapKey] += exerciseResult[date][key];
    //               }
    //             }
    //           } else {
    //             emptyData += 1;
    //           }
    //           if (exerciseResult[date] === null) {
    //             empty += 1;
    //           }
    //           ticks += 1;
    //           return null;
    //         });
    //         return null;
    //       });
    //       const exercisesSkipStop = dataPerPeriod.exerciseSkipped + dataPerPeriod.exerciseStopped;
    //       const sumPainScoreSkipStop = dataPerPeriod.sumPainScoreSkip
    //         + dataPerPeriod.sumPainScoreStop;
    //       if (dataPerPeriod.exerciseStoppedAll) {
    //         dataPerPeriod.avDesiredNumberOfReps = Math.round(dataPerPeriod.sumDesiredNumberOfReps
    //         / dataPerPeriod.exerciseStoppedAll);
    //       }
    //       dataPerPeriod.averagePainScoreCompleted = dataPerPeriod.exerciseCompleted
    //         ? Math.round(dataPerPeriod.sumPainScoreCompleted / dataPerPeriod.exerciseCompleted)
    //         : 0;
    //       dataPerPeriod.averagePainScoreSkipStop = exercisesSkipStop
    //         ? Math.round(sumPainScoreSkipStop / exercisesSkipStop)
    //         : 0;
    //       return null;
    //     });

    //     if (dateArray.length) {
    //       averagePainTyps.map((key: string) => {
    //         dataPerPeriod[key as keyof TAverageMapKey] = Math.round(
    //           dataPerPeriod[key as keyof TAverageMapKey] / dateArray.length,
    //         );
    //         return null;
    //       });
    //     } else {
    //       averagePainTyps.map((key: string) => {
    //         dataPerPeriod[key as keyof TAverageMapKey] = 0;
    //         return null;
    //       });
    //     }
    //     count += 1;
    //     dataPerPeriod.dateArray = dateArray.length > 1 ? String(count) : dateArray[0];
    //     if (dateArray.length === (empty / exercises.length)) {
    //       dataPerPeriod.isArchive = true;
    //     }
    //     painAndMotivationResult.push(dataPerPeriod);
    //   }
    // }
    // return ticks !== emptyData ? painAndMotivationResult : [];
    return [];
  },
);

// Completion tab (by exercises). Get a list of exercise names.
export const getConvertedExercisesNames = createSelector(getDataByExercises, (exercisesAll) => {
  const exercises = exercisesAll.filter((item: any) => item.exerciseDone !== null);
  return exercises.map((exercise: any) => ({ value: exercise.id, label: exercise.name }));
  // return exercises.map((exercise: any) => ({ value: exercise.name, label: exercise.name }));
});

// by Exercises (full screen)
export const getExercisesChartData = createSelector(
  getDataByExercises,
  getSelectedPerRemoteMonit,
  getExerciseName,
  (exercisesArrayAll, getSelected, exerciseId) => {
    // const exercisesArray = exercisesArrayAll.filter((item: any) => item.exerciseDone !== null);
    // const exercises = exerciseId !== null
    //   ? exercisesArray.filter((item: any) => item.id === exerciseId)
    //   // ? exercisesArray.filter((item: any) => item.name === exerciseName)
    //   : exercisesArray;

    // const { periodFrom, dateTo, periodType } = getSelected;
    // const dates = getDatesByFormat(periodFrom, dateTo, periodType);

    // const exercisesResult: any = [];

    // exercises.map((exercise: any, exerciseIndex: number) => {
    //   const result: any = {
    //     id: 0,
    //     name: '',
    //     title: '',
    //     dates: {},
    //   };
    //   result.id = exercise.id;
    //   // Can be the same exercise name. For Chart delete id for XAxis
    //   result.name = `${exercise.name}<id_>${exercise.id}`;
    //   // result.name = exercise.name;
    //   result.title = String.fromCharCode(exerciseIndex + 65);

    //   for (const [key, datesArray] of Object.entries(dates)) {
    //     datesArray.map((date: any) => {
    //       const exerciseStatus: ExerciseTooltipType = {
    //         completed: 0,
    //         partlyDone: 0,
    //         attempts: 0,
    //         noAttempt: 0,
    //         isNull: 0,
    //       };

    //       const exerciseTooltipStatus: ExerciseTooltipType = {
    //         completed: 0,
    //         partlyDone: 0,
    //         attempts: 0,
    //         noAttempt: 0,
    //         isNull: 0,
    //       };
    //       JSON.parse(exercise.exerciseDone).map((exerciseResult: any) => {
    //         if (exerciseResult[date] === null) {
    //           exerciseStatus.isNull = 100;
    //           exerciseTooltipStatus.isNull = 100;
    //           result.dates[date] = exerciseStatus;
    //         } else {
    //           if (!exerciseResult[date]) {
    //             exerciseStatus.noAttempt = 100;
    //             result.dates[date] = exerciseStatus;
    //             return;
    //           }
    //           for (const [key, value] of Object.entries(exerciseResult[date].status)) {
    //             if (key !== 'noAttempt' && value !== 0) {
    //               // @ts-ignore
    //               exerciseTooltipStatus[key] = value;
    //             }
    //           }
    //           let sumValues = 0;
    //           let sumPreviousValues = 0;

    //           for (const [key, value] of Object.entries(exerciseTooltipStatus)) {
    //             sumValues += value || 0;
    //             if (sumValues > 100) {
    //               // @ts-ignore
    //               exerciseStatus[key] = 100 - sumPreviousValues;
    //               break;
    //             }
    //             sumPreviousValues += value || 0;
    //             // @ts-ignore
    //             exerciseStatus[key] = value;
    //           }
    //         }
    //       });
    //       exerciseStatus.noAttempt = 0;
    //       exerciseTooltipStatus.noAttempt = 0;
    //       exerciseStatus.noAttempt = Object.values(exerciseStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(exerciseStatus).reduce((a, b) => a + b, 0) : 0;
    //       exerciseTooltipStatus.noAttempt = Object.values(exerciseTooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(exerciseTooltipStatus).reduce((a, b) => a + b, 0) : 0;
    //       result.dates[date] = { status: { ...exerciseStatus }, tooltipStatus: { ...exerciseTooltipStatus } };
    //     });
    //   }
    //   exercisesResult.push(result);
    // });
    // return exercisesResult;
    return [];
  },
);

// Completion & steps tab (full screen)
// steps by Exercises
export const getStepsByExercises = createSelector(
  getStepsByExercisesTab,
  getSelectedPerRemoteMonit,
  (steps, period) => {
    // const { periodFrom, dateTo, periodType } = period;
    // const dates = getDatesByFormat(periodFrom, dateTo, periodType);
    // const stepsArrayResult = [];
    // const averageSteps = [];

    // let count = 0;
    // for (const [dataKey, dateArray] of Object.entries(dates)) {
    //   const stepsForPeriodResult: any = { steps: 0 };
    //   let sumValue = 0;
    //   dateArray.map((date: string) => {
    //     // @ts-ignore
    //     const step: StepType = steps.find((stepOne: StepType[]) => stepOne.updatedAt.indexOf(date) > -1);
    //     if (step) {
    //       sumValue += step.steps;
    //     }
    //     return null;
    //   });
    //   stepsForPeriodResult.steps = Math.round(sumValue / dateArray.length);
    //   averageSteps.push(stepsForPeriodResult);
    // }

    // for (const [dataKey, dateArray] of Object.entries(dates)) {
    //   const stepResult: any = {
    //     'steps': 0,
    //     'chartValue': 0,
    //     'dateSteps': '',
    //   };

    //   let sumValue = 0;
    //   dateArray.map((date: string) => {
    //     // @ts-ignore
    //     const step: StepType = steps.find((stp: StepType[]) => stp.updatedAt.indexOf(date) > -1);
    //     if (step) {
    //       sumValue += step.steps;
    //     }
    //     return null;
    //   });

    //   stepResult.steps = Math.round(sumValue / dateArray.length);
    //   stepResult.chartValue = Math.round((stepResult.steps / maxStepScale(averageSteps)) * 100);
    //   stepResult.dateSteps = dateArray.length === 1 ? dateArray[0] : dataKey;
    //   count += 1;
    //   stepResult.dateArray = dateArray.length > 1 ? String(count) : dateArray[0];
    //   stepsArrayResult.push(stepResult);
    // }
    // return stepsArrayResult;
    return [];
  },
);

// Completion & steps tab (full screen)
// Number of Exercises Skipped or Stopped by Exercises
// export const getSkipStopDataByExercises = createSelector(
//   getDataByExercises,
//   getSelectedPerRemoteMonit,
//   getMotivation,
//   getExerciseName,
//   (exercisesArrayAll, period, motivations: any, exerciseId) => {
//     // const exercisesArray = exercisesArrayAll.filter((item: any) => item.exerciseDone !== null);
//     // const exercises = exerciseId !== null
//     //   ? exercisesArray.filter((item: any) => item.id === exerciseId)
//     //   : exercisesArray;
//     // const painAndMotivationResult: TSkipStopByExercises[] = [];
//     // let emptyData = 0;
//     // let ticks = 0;

//     // if (exercises && exercises.length > 0) {
//     //   const painTypes = [
//     //     'sumPainScoreCompleted',
//     //     'sumPainScoreSkip',
//     //     'sumPainScoreStop',
//     //     'exerciseCompleted',
//     //     'exerciseSkipped',
//     //     'exerciseStopped',
//     //     'exerciseSkippedAll',
//     //     'exerciseStoppedAll',
//     //     'sumDesiredNumberOfReps',
//     //   ];
//     //   const averagePainTyps = ['motivation'];
//     //   const { periodFrom, dateTo, periodType } = period;
//     //   const dates = getDatesByFormat(periodFrom, dateTo, periodType);
//     //   let count = 0;
//     //   for (const [dateKey, dateArray] of Object.entries(dates)) {
//     //     const dataPerPeriod: TSkipStopByExercises = {
//     //       // motivation: 0,
//     //       sumPainScoreCompleted: 0,
//     //       sumPainScoreSkip: 0,
//     //       sumPainScoreStop: 0,
//     //       exerciseCompleted: 0,
//     //       exerciseSkipped: 0,
//     //       exerciseStopped: 0,
//     //       exerciseSkippedAll: 0,
//     //       exerciseStoppedAll: 0,
//     //       averagePainScoreCompleted: 0,
//     //       averagePainScoreSkipStop: 0,
//     //       dateArray: '',
//     //       isArchive: false,
//     //       sumDesiredNumberOfReps: 0,
//     //       avDesiredNumberOfReps: 0,
//     //     };

//     //     let empty = 0;
//     //     dateArray.map((date: string) => {
//     //       // const motivation = motivations.find((item: any) => item.createdAt.includes(date));
//     //       // if (motivation) {
//     //       //   dataPerPeriod.motivation += +motivation.type;
//     //       // }
//     //       exercises.map((exercise: any) => {
//     //         JSON.parse(exercise.exerciseDone).map((exerciseResult: any) => {
//     //           if (exerciseResult[date] !== null && exerciseResult[date] !== undefined) {
//     //             for (const [key, value] of Object.entries(exerciseResult[date])) {
//     //               if (painTypes.includes(key)) {
//     //                 dataPerPeriod[key as keyof TAverageSkipStopMapKey] += exerciseResult[date][key];
//     //               }
//     //             }
//     //           } else {
//     //             emptyData += 1;
//     //           }
//     //           if (exerciseResult[date] === null) {
//     //             empty += 1;
//     //           }
//     //           ticks += 1;
//     //           return null;
//     //         });
//     //         return null;
//     //       });
//     //       const exercisesSkipStop = dataPerPeriod.exerciseSkipped + dataPerPeriod.exerciseStopped;
//     //       const sumPainScoreSkipStop = dataPerPeriod.sumPainScoreSkip
//     //         + dataPerPeriod.sumPainScoreStop;
//     //       if (dataPerPeriod.exerciseStoppedAll) {
//     //         dataPerPeriod.avDesiredNumberOfReps = Math.round(dataPerPeriod.sumDesiredNumberOfReps
//     //         / dataPerPeriod.exerciseStoppedAll);
//     //       }
//     //       dataPerPeriod.averagePainScoreCompleted = dataPerPeriod.exerciseCompleted
//     //         ? Math.round(dataPerPeriod.sumPainScoreCompleted / dataPerPeriod.exerciseCompleted)
//     //         : 0;
//     //       dataPerPeriod.averagePainScoreSkipStop = exercisesSkipStop
//     //         ? Math.round(sumPainScoreSkipStop / exercisesSkipStop)
//     //         : 0;
//     //       return null;
//     //     });

//     //     if (dateArray.length) {
//     //       averagePainTyps.map((key: string) => {
//     //         dataPerPeriod[key as keyof TAverageSkipStopMapKey] = Math.round(
//     //           dataPerPeriod[key as keyof TAverageSkipStopMapKey] / dateArray.length,
//     //         );
//     //         return null;
//     //       });
//     //     } else {
//     //       averagePainTyps.map((key: string) => {
//     //         dataPerPeriod[key as keyof TAverageSkipStopMapKey] = 0;
//     //         return null;
//     //       });
//     //     }
//     //     count += 1;
//     //     dataPerPeriod.dateArray = dateArray.length > 1 ? String(count) : dateArray[0];
//     //     if (dateArray.length === (empty / exercises.length)) {
//     //       dataPerPeriod.isArchive = true;
//     //     }
//     //     painAndMotivationResult.push(dataPerPeriod);
//     //   }
//     // }
//     // return ticks !== emptyData ? painAndMotivationResult : [];
//     return [];
//   },
// );

// Completion & steps tab (full screen)
// By period
export const getPeriodsChartsData = createSelector(
  getSelectedPerRemoteMonit,
  getPeriodsOfExercises,
  (getSelectedDates, exercises) => {
    // if (exercises?.length) {
    //   // const exercises = exercisesArrayAll.filter((item: any) => item.exerciseDone !== null);
    //   const { periodFrom, dateTo, periodType, value } = getSelectedDates;
    //   const dates = getDatesByFormat(periodFrom, dateTo, periodType);

    //   const resultByDayAndExercises: any = {};
    //   const resultByAveragePeriod: any = {};
    //   let isEmptyData = 0;
    //   let ticks = 0;
    //   for (const [dataKey, dateArray] of Object.entries(dates)) {
    //     dateArray.map((date: any) => {
    //       exercises.map((exercise: any) => {
    //         const exercisesResult: any = {
    //           status: {
    //             completed: 0,
    //             partlyDone: 0,
    //             attempts: 0,
    //             noAttempt: 0,
    //             isNull: 0,
    //           },
    //           // tooltipStatus: {
    //           //   completed: 0,
    //           //   partlyDone: 0,
    //           //   attempts: 0,
    //           //   noAttempt: 0,
    //           //   isNull: 0,
    //           // },
    //           name: exercise.name,
    //         };
    //         exercise.exerciseDone.map((exerciseResult: any) => {
    //         // JSON.parse(exercise.exerciseDone).map((exerciseResult: any) => {
    //           if (exerciseResult[date] !== null && exerciseResult[date] !== undefined) {
    //             for (const [key, val] of Object.entries(exerciseResult[date])) {
    //               let sumPreviousValues: any = 0;
    //               let sumValue: any = 0;
    //               if (typeof val === 'object') {
    //                 for (const [key2, value2] of Object.entries(exerciseResult[date][key])) {
    //                   if (key === 'status') {
    //                     sumValue += value2;
    //                     if (sumValue > 100) {
    //                       // exercisesResult.tooltipStatus[key2] = 100 - sumPreviousValues;
    //                       exercisesResult.status[key2] = 100 - sumPreviousValues;
    //                       break;
    //                     }
    //                     sumPreviousValues += value2;
    //                     // exercisesResult.tooltipStatus[key2] = value2;
    //                     exercisesResult.status[key2] = value2;
    //                   }
    //                 }
    //               }
    //             }
    //           } else {
    //             isEmptyData += 1;
    //           }
    //           if (exerciseResult[date] === null) {
    //             // exercisesResult.tooltipStatus.isNull = 100;
    //             exercisesResult.status.isNull = 100;
    //           }

    //           ticks += 1;

    //           exercisesResult.status.noAttempt = 0;
    //           // exercisesResult.tooltipStatus.noAttempt = 0;
    //           // @ts-ignore
    //           // exercisesResult.tooltipStatus.noAttempt = Object.values(exercisesResult.tooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(exercisesResult.tooltipStatus).reduce((a, b) => a + b, 0) : 0;
    //           // @ts-ignore
    //           exercisesResult.status.noAttempt = Object.values(exercisesResult.status).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(exercisesResult.status).reduce((a, b) => a + b, 0) : 0;
    //         });
    //         if (!resultByDayAndExercises[date]) {
    //           resultByDayAndExercises[date] = [];
    //         }
    //         resultByDayAndExercises[date].push(exercisesResult);
    //         return null;
    //       });
    //       return null;
    //     });
    //     if (dateArray.length > 1) {
    //         let exercisesDataPeriod: any[] = [];
    //         exercises.forEach((exercise: any, index: number) => {
    //             let totalStatusesForTheEntirePeriod: any = {
    //                 'status': {
    //                     'completed': 0,
    //                     'partlyDone': 0,
    //                     'attempts': 0,
    //                     'noAttempt': 0,
    //                     'isNull':0
    //                 },
    //                 // 'tooltipStatus': {
    //                 //     'completed': 0,
    //                 //     'partlyDone': 0,
    //                 //     'attempts': 0,
    //                 //     'noAttempt': 0,
    //                 //     'isNull':0
    //                 // },
    //                 'name': '',
    //             }
    //             dateArray.map((day: any) => {
    //                 for (const [key, value] of Object.entries(totalStatusesForTheEntirePeriod.status)) {
    //                     totalStatusesForTheEntirePeriod.status[key] += resultByDayAndExercises[day][index].status[key];
    //                     // totalStatusesForTheEntirePeriod.tooltipStatus[key] += resultByDayAndExercises[day][index].tooltipStatus[key];
    //                 }
    //                 totalStatusesForTheEntirePeriod.name = resultByDayAndExercises[day][index].name;
    //             });
    //             for (const [key, value] of Object.entries(totalStatusesForTheEntirePeriod.status)) {
    //                 // @ts-ignore
    //                 totalStatusesForTheEntirePeriod.status[key] = Math.round( value / (dateArray.length));
    //                 if(key === 'isNull' && totalStatusesForTheEntirePeriod.status[key] < 100) {
    //                 totalStatusesForTheEntirePeriod.status[key] = 0;
    //                 }
    //             }
    //             // for (const [key, value] of Object.entries(totalStatusesForTheEntirePeriod.tooltipStatus)) {
    //             //     // @ts-ignore
    //             //     totalStatusesForTheEntirePeriod.tooltipStatus[key] = Math.round( value / (dateArray.length));
    //             //     if(key === 'isNull' && totalStatusesForTheEntirePeriod.tooltipStatus[key] < 100) {
    //             //       totalStatusesForTheEntirePeriod.tooltipStatus[key] = 0;
    //             //     }
    //             // }

    //             totalStatusesForTheEntirePeriod.status['noAttempt'] = 0;
    //             // totalStatusesForTheEntirePeriod.tooltipStatus['noAttempt'] = 0;
    //             let sumPreviousValuesAverage: number = 0;
    //             let sumValueAverage: number = 0;
    //             for (const [key2, value2] of Object.entries(totalStatusesForTheEntirePeriod.status)) {
    //                 // @ts-ignore
    //                 sumValueAverage += value2;
    //                 // @ts-ignore
    //                 if (sumValueAverage > 100) {
    //                     totalStatusesForTheEntirePeriod.status[key2] = 100 - sumPreviousValuesAverage;
    //                     break;
    //                 }
    //                   // @ts-ignore
    //                 sumPreviousValuesAverage += value2;
    //                 totalStatusesForTheEntirePeriod.status[key2] = value2;

    //             }

    //             // let sumPreviousValuesAverageTooltip: number = 0;
    //             // let sumValueAverageTooltip: number = 0;
    //             // for (const [key2, value2] of Object.entries(totalStatusesForTheEntirePeriod.tooltipStatus)) {
    //             //     // @ts-ignore
    //             //     sumValueAverageTooltip += value2;
    //             //     // @ts-ignore
    //             //     if (sumValueAverageTooltip > 100) {
    //             //         totalStatusesForTheEntirePeriod.tooltipStatus[key2] = 100 - sumPreviousValuesAverageTooltip;
    //             //         break;
    //             //     }
    //             //       // @ts-ignore
    //             //     sumPreviousValuesAverageTooltip += value2;
    //             //     totalStatusesForTheEntirePeriod.tooltipStatus[key2] = value2;

    //             // }
    //             // @ts-ignore
    //             totalStatusesForTheEntirePeriod.status['noAttempt'] = Object.values(totalStatusesForTheEntirePeriod.status).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(totalStatusesForTheEntirePeriod.status).reduce((a, b) => a + b, 0) : 0;
    //             // @ts-ignore
    //             // totalStatusesForTheEntirePeriod.tooltipStatus['noAttempt'] = Object.values(totalStatusesForTheEntirePeriod.tooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(totalStatusesForTheEntirePeriod.tooltipStatus).reduce((a, b) => a + b, 0) : 0;
    //             exercisesDataPeriod.push(totalStatusesForTheEntirePeriod);
    //         })

    //         if (value === LAST_3_MONTHS || value === LAST_6_MONTHS) {
    //             const averageStatusesByWeek: any = {
    //                 'status': {
    //                     'completed': 0,
    //                     'partlyDone': 0,
    //                     'attempts': 0,
    //                     'noAttempt': 0,
    //                     'isNull':0
    //                 },
    //                 // 'tooltipStatus': {
    //                 //     'completed': 0,
    //                 //     'partlyDone': 0,
    //                 //     'attempts': 0,
    //                 //     'noAttempt': 0,
    //                 //     'isNull':0
    //                 // },
    //             }
    //               let counter = 0;
    //             exercisesDataPeriod.forEach((exercise, index) => {
    //               for (const [key, value] of Object.entries(averageStatusesByWeek)) {
    //                 for (const [key2, value2] of Object.entries(averageStatusesByWeek[key])) {
    //                   if (key2 !== 'isNull'){
    //                     averageStatusesByWeek[key][key2] += exercise[key][key2];
    //                   } else{
    //                     averageStatusesByWeek[key][key2] += exercise[key][key2];
    //                     exercise[key][key2] === 100 && counter++;
    //                   }
    //                 }
    //                 for (const [key2, value2] of Object.entries(averageStatusesByWeek[key])) {
    //                   if (index === exercisesDataPeriod.length - 1) {
    //                     averageStatusesByWeek[key][key2] = Math.round( averageStatusesByWeek[key][key2] / (exercisesDataPeriod.length-counter/2));
    //                     if (key2 !== 'isNull' ){
    //                       if(isNaN(averageStatusesByWeek[key][key2])) {
    //                         averageStatusesByWeek[key][key2] = 0;
    //                       }
    //                     } else{
    //                       if(isNaN(averageStatusesByWeek[key][key2])) {
    //                         averageStatusesByWeek[key][key2] = 100;
    //                       }
    //                     }
    //                     if(key2 === 'isNull' && averageStatusesByWeek[key][key2] < 100) {
    //                       averageStatusesByWeek[key][key2] = 0
    //                     }
    //                   }
    //                 }
    //               }
    //             })

    //             let sumValue: any = 0;
    //             let previousValue: any = 0;
    //             for (const [key, value] of Object.entries(averageStatusesByWeek.status)) {
    //                 sumValue += value;
    //                 if (sumValue > 100) {
    //                     averageStatusesByWeek.status[key] = 100 - previousValue;
    //                     break;
    //                 }

    //                 previousValue += value;
    //                 averageStatusesByWeek.status[key] = value
    //             }
    //             averageStatusesByWeek.status['noAttempt'] = 0;
    //             // @ts-ignore
    //             averageStatusesByWeek.status['noAttempt'] = Object.values(averageStatusesByWeek.status).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(averageStatusesByWeek.status).reduce((a, b) => a + b, 0) : 0;
    //             // averageStatusesByWeek.tooltipStatus['noAttempt'] = 0;
    //             // @ts-ignore
    //             // averageStatusesByWeek.tooltipStatus['noAttempt'] = Object.values(averageStatusesByWeek.tooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(averageStatusesByWeek.tooltipStatus).reduce((a, b) => a + b, 0) : 0;
    //             exercisesDataPeriod = [averageStatusesByWeek];
    //         }
    //         resultByAveragePeriod[dataKey] = exercisesDataPeriod;
    //     }
    //   }

    //   if (Object.keys(resultByAveragePeriod).length !== 0) {
    //     return ticks === isEmptyData ? {} : resultByAveragePeriod;
    //   }
    //   return resultByDayAndExercises;
    // }
    return {};
  },
);

// Completion & steps tab (full screen)
// Steps by periods
export const getStepsBySelectedPeriod = createSelector(
  getPatientsSteps,
  getSelectedPerRemoteMonit,
  (steps, period) => {
    // const { periodFrom, dateTo, periodType } = period;
    // const dates = getDatesByFormat(periodFrom, dateTo, periodType);
    // const stepsArrayResult = [];
    // const averageSteps = [];

    // let count = 0;
    // for (const [dataKey, dateArray] of Object.entries(dates)) {
    //   const stepsForPeriodResult: any = { steps: 0 };
    //   let sumValue = 0;
    //   dateArray.map((date: string) => {
    //     // @ts-ignore
    //     const step: StepType = steps.find((stepOne: StepType[]) => stepOne.updatedAt.indexOf(date) > -1);
    //     if (step) {
    //       sumValue += step.steps;
    //     }
    //     return null;
    //   });
    //   stepsForPeriodResult.steps = Math.round(sumValue / dateArray.length);
    //   averageSteps.push(stepsForPeriodResult);
    // }

    // for (const [dataKey, dateArray] of Object.entries(dates)) {
    //   const stepResult: any = {
    //     'steps': 0,
    //     'chartValue': 0,
    //     'dateSteps': '',
    //   };

    //   let sumValue = 0;
    //   dateArray.map((date: string) => {
    //     // @ts-ignore
    //     const step: StepType = steps.find((stp: StepType[]) => stp.updatedAt.indexOf(date) > -1);
    //     if (step) {
    //       sumValue += step.steps;
    //     }
    //     return null;
    //   });

    //   stepResult.steps = Math.round(sumValue / dateArray.length);
    //   stepResult.chartValue = Math.round((stepResult.steps / maxStepScale(averageSteps)) * 100);
    //   stepResult.dateSteps = dateArray.length === 1 ? dateArray[0] : dataKey;
    //   count += 1;
    //   stepResult.dateArray = dateArray.length > 1 ? String(count) : dateArray[0];
    //   stepsArrayResult.push(stepResult);
    // }
    // return stepsArrayResult;
    return [];
  },
);

// Reasons for not compliting tab (Full screen)
export const getReasonsData = createSelector(
  [getExercForReasonsTab, getSelectedPerRemoteMonit],
  (exersizeAll, selectedPeriod) => {
    // moved to graph
    // const exercises = exersizeAll.filter((item: any) => item.exerciseDone !== null);

    // if (exersizeAll.length) {
    //   const dataParsed = exersizeAll.map((item: any) => JSON.parse(item.exerciseDone));
    //   const {
    //     periodFrom, dateTo, periodType,
    //   } = selectedPeriod;

    //   const calcDates = getDatesByFormat(periodFrom, dateTo, periodType);
    //   const reasonsByPeriods: any = [];

    //   Object.values(calcDates).map((datesFromPeriod: string[], index: number) => {
    //     // Create default values {key: 0}
    //     const reasons: any = State.reasonTitles
    //       .reduce((object, reasonTitle) => ({ ...object, [reasonTitle]: 0 }), {});
    //     let maxValueYaxis = 0;

    //     // Handling data within a period
    //     datesFromPeriod.map((date: string) => {
    //       // All exercises for the date
    //       dataParsed.map((exercise: any) => exercise.map((a: any) => {
    //         const keyObj = Object.keys(a)[0];
    //         if (keyObj === date) {
    //           State.reasonTitles.map((reasonName: string) => {
    //             const reasonFromData = a[keyObj]?.reasons?.[reasonName];
    //             if (reasonFromData) {
    //               const accCurrentReason = reasons[reasonName] + reasonFromData;
    //               reasons[reasonName] = accCurrentReason;
    //               maxValueYaxis += reasonFromData;
    //             }
    //             return null;
    //           });
    //         }
    //         return null;
    //       }));
    //       return null;
    //     });

    //     const id = index + 1;
    //     const resultByPeriod: any = {
    //       date: datesFromPeriod.length === 1 ? datesFromPeriod[0] : id,
    //       id,
    //       maxValueYaxis,
    //       reasons: Object.keys(reasons).map((key: string) => ({ [key]: reasons[key] })),
    //     };
    //     reasonsByPeriods.push(resultByPeriod);
    //     return null;
    //   });
    //   return reasonsByPeriods;
    // }
    return [];
  },
);

// Reasons for not compliting tab (Full screen)2
// export const getReasonsData = createSelector(
//   [getExercForReasonsTab, getSelectedReason],
//   (exercises, selectedPeriod) => {
//     if (exercises.length) {
//       const { periodFrom, dateTo, periodType } = selectedPeriod;
//       const calcDates = getDatesByFormat(periodFrom, dateTo, periodType);
//       const reasonsByPeriods: any = [];

//       Object.values(calcDates).map((datesFromPeriod: string[], index: number) => {
//         // Create default values {key: 0}
//         const reasons: any = State.reasonTitles
//           .reduce((object, reasonTitle) => ({ ...object, [reasonTitle]: 0 }), {});
//         let maxValueYaxis = 0;

//         // Handling data within a period
//         datesFromPeriod.map((date: string) => {
//           // All exercises for the date
//           exercises.map((rawExercises: any) => {
//             JSON.parse(rawExercises.exerciseDone).map((exerciseResult: any) => {
//               if (exerciseResult[date]) {
//                 State.reasonTitles.map((reasonName: string) => {
//                   const reasonFromData = exerciseResult[date].reasons?.[reasonName];
//                   if (reasonFromData) {
//                     const accCurrentReason = reasons[reasonName] + reasonFromData;
//                     reasons[reasonName] = accCurrentReason;
//                     maxValueYaxis += reasonFromData;
//                   }
//                   return null;
//                 });
//               }
//             });
//             return null;
//           });
//           return null;
//         });

//         const id = index + 1;
//         const resultByPeriod: any = {
//           date: datesFromPeriod.length === 1 ? datesFromPeriod[0] : id,
//           id,
//           maxValueYaxis,
//           reasons: Object.keys(reasons).map((key: string) => ({ [key]: reasons[key] })),
//         };
//         reasonsByPeriods.push(resultByPeriod);
//         return null;
//       });
//       return reasonsByPeriods;
//     }
//     return [];
//   },
// );

// Reasons for not compliting tab (Full screen)
// export const getReasonsData = createSelector(
//   [getExercForReasonsTab],
//   (exersizeAll) => {
//     const exersizes = exersizeAll.filter((item: any) => item.exerciseDone !== null);
//     const dataParsed = exersizes.map((item: any) => JSON.parse(item.exerciseDone));

//     // get unique date for period
//     const dates = [
//       // @ts-ignore
//       ...new Set(dataParsed.map((exercise) => exercise.map((day) => Object.keys(day)[0])).flat()),
//     ];

//     let maxValueYaxis = 0; // need for Reasons chart for YAxis scale

//     const reasonsByDays = dates.map((currentDate, index) => {
//       // All exercises for the date
//       const filteredDataByCurrentDateFromAllExercises = dataParsed
//         .map((exercise: any) => exercise.filter((a: any) => Object.keys(a)[0] === currentDate))
//         .flat();

//       const reasons = filteredDataByCurrentDateFromAllExercises.map((current: any) => {
//         const hasData = !!current[currentDate];

//         const reason = hasData
//           ? {
//             date: currentDate,
//             reasons: current[currentDate].reasons,
//           }
//           : false;
//         return reason;
//       });

//       const mergedReasons = reasons?.map((currentReasons: { date: string; reasons: any}) => ({
//         reasonsByDay:
//           currentReasons && 'reasons' in currentReasons ? currentReasons.reasons : State.defaultReasons,
//       }));

//       let totalReasonsByDay = 0;
//       const reasonValues = State.reasonTitles.map((reasonName: string) => {
//         const result = {
//           [reasonName]: mergedReasons.map((item: any) => {
//             const data = item.reasonsByDay;
//             if (reasonName in data) {
//               return data[reasonName];
//             }
//             return 0;
//           }).reduce((a: any, b: any) => a + b), // day - no matter what is the ex
//         };
//         totalReasonsByDay += result[reasonName];
//         return result;
//       });
//       if (maxValueYaxis < totalReasonsByDay) {
//         maxValueYaxis = totalReasonsByDay;
//       }

//       return {
//         date: currentDate,
//         reasons: reasonValues,
//         id: index + 1,
//         maxValueYaxis,
//       };
//     });
//     console.log('reasonsByDays=', reasonsByDays);

//     return reasonsByDays;
//   },
// );

// OVERVIEW TAB
// Reasons for not compliting chart (Overview tab)
// Old logik
// export const getReasonsDataOverviewTab = createSelector(
//   [getExercisesOverviewTab], (exercisesAll) => {
//     const exersizes = exercisesAll.filter((item: any) => item.exerciseDone !== null);
//     const data = exersizes.map((item: any) => JSON.parse(item.exerciseDone));

//     const dates = [
//       // @ts-ignore
//       ...new Set(data.map((exercise) => exercise.map((day) => Object.keys(day)[0])).flat()),
//     ];
//     let maxValueYaxis = 0; // need for Reasons chart for YAxis scale
//     return dates.map((currentDate, index) => {
//       const filteredDataByCurrentDateFromAllExercises = data
//         // @ts-ignore
//         .map((currentExercises) => currentExercises.filter((a) => Object.keys(a)[0] === currentDate))
//         .flat();

//       const reasons = filteredDataByCurrentDateFromAllExercises.map((current: any) => {
//         const hasData = !!current[currentDate];

//         const reasons = hasData
//           ? [
//             {
//               date: currentDate,
//               reasons: current[currentDate].reasons,
//               // painLevel: current[currentDate].painLevel,
//               // status: current[currentDate].status,
//               // timesPerDay: current[currentDate].timesPerDay,
//             },
//           ]
//           : false;
//         return reasons;
//       });

//       const mergedReasons = reasons
//         && reasons.map((currentReasons: any) => {
//           const response = currentReasons[0];
//           // const timesPerDay = response && response.timesPerDay ? response.timesPerDay : 0;

//           return {
//             reasonsByDay:
//               currentReasons && 'reasons' in response ? response.reasons : State.defaultReasons,
//             // statusByDay:
//             //   currentReasons && 'status' in response ? response.status : State.defaultStatuses,
//             // paintByDay:
//             //   currentReasons && 'painLevel' in response
//             //     ? response.painLevel
//             //     : State.defaultStatuses,
//             // timesPerDay,
//           };
//         });

//       // const avarageValue = mergedReasons
//       //   .map((item: any) => {
//       //     const data = item.timesPerDay;
//       //     return data || 0;
//       //   })
//       //   .reduce((a: any, b: any) => a + b);

//       // const statusValue = State.statusDefault.map((currentStatus: string) => ({
//       //   [currentStatus]: mergedReasons
//       //     .map((item: any) => {
//       //       const data = item.statusByDay;
//       //       return currentStatus in data ? data[currentStatus] : 0;
//       //     })
//       //     .reduce((a: any, b: any) => {
//       //       const awarage = a + b;
//       //       return awarage;
//       //     }),
//       // }));

//       // const painValues = State.exercisePainLevel.map((currentPainLevel: string) => ({
//       //   [currentPainLevel]: mergedReasons
//       //     .map((item: any) => {
//       //       const dataPain = item.paintByDay;

//       //       if (currentPainLevel in dataPain) {
//       //         return dataPain[currentPainLevel];
//       //       }
//       //       return 0;
//       //     })
//       //     .reduce((a: any, b: any) => a + b),
//       // }));
//       let totalReasonsByDay = 0;
//       const reasonValues = State.reasonTitles.map((currentReason) => {
//         const result = ({
//           [currentReason]: mergedReasons
//             .map((item: any) => {
//               const dataReas = item.reasonsByDay;
//               if (currentReason in dataReas) {
//                 return dataReas[currentReason];
//               }
//               return 0;
//             })
//             .reduce((a: any, b: any) => a + b), // day - no metter what is the ex
//         });
//         totalReasonsByDay += result[currentReason];
//         return result;
//       });
//       if (maxValueYaxis < totalReasonsByDay) {
//         maxValueYaxis = totalReasonsByDay;
//       }

//       // const number = Math.random().toString(36).substr(2, 9); //

//       return {
//         date: currentDate,
//         reasons: reasonValues,
//         // painLevel: painValues,
//         // status: statusValue,
//         // timesPerDay: avarageValue,
//         // step,
//         // motivations,
//         id: index + 1,
//         // uncName: { [number]: index + 1 },
//         maxValueYaxis,
//       };
//     });
//   },
// );

// Motivation & Pain (Overview tab)
export const getMotivationPainDataOverviewTab = createSelector(
  getSelectedPeriodOverview,
  // getExercisesOverviewTab,
  // getMotivationOverviewTab,
  (
    periodDates, // exercises,
    //  motivations: any
  ) => {
    // const painAndMotivationResult: TDataPerPeriod[] = [];
    // let emptyData = 0;
    // let ticks = 0;

    // if (exercises?.length || motivations?.length) {
    //   const { periodFrom, dateTo, periodType } = periodDates;
    //   const dates = getDatesByFormat(periodFrom, dateTo, periodType);
    //   const painTypes = [
    //     'sumPainScoreCompleted',
    //     'sumPainScoreSkip',
    //     'sumPainScoreStop',
    //     'exerciseCompleted',
    //     'exerciseSkipped',
    //     'exerciseStopped',
    //     'exerciseSkippedAll',
    //     'exerciseStoppedAll',
    //     'sumDesiredNumberOfReps',
    //     'sumPainScoreBackCompleted',
    //     'sumPainScoreLegCompleted',
    //     'sumPainScoreBackSkip',
    //     'sumPainScoreLegSkip',
    //     'sumPainScoreBackStop',
    //     'sumPainScoreLegStop',
    //   ];
    //   let periodCounter = 0;

    //   // Handle all periods
    //   for (const [, dateArray] of Object.entries(dates)) {
    //     const dataPerPeriod: TDataPerPeriod = {
    //       motivation: 0,
    //       sumPainScoreCompleted: 0,
    //       sumPainScoreSkip: 0,
    //       sumPainScoreStop: 0,
    //       exerciseCompleted: 0,
    //       exerciseSkipped: 0,
    //       exerciseStopped: 0,
    //       exerciseSkippedAll: 0,
    //       exerciseStoppedAll: 0,
    //       averagePainScoreCompleted: 0,
    //       averagePainScoreSkipStop: 0,
    //       dateArray: '',
    //       isArchive: false,
    //       sumDesiredNumberOfReps: 0,
    //       avDesiredNumberOfReps: 0, // not used in MotivAndPain tab

    //       // for spinal procedure
    //       sumPainScoreBackCompleted: 0,
    //       sumPainScoreLegCompleted: 0,
    //       sumPainScoreBackSkip: 0,
    //       sumPainScoreLegSkip: 0,
    //       sumPainScoreBackStop: 0,
    //       sumPainScoreLegStop: 0,

    //       avPainScoreBackCompleted: 0,
    //       avPainScoreBackSkipStop: 0,
    //       avPainScoreLegCompleted: 0,
    //       avPainScoreLegSkipStop: 0,
    //     };

    //     let emptyDataInCurrentPeriod = 0;
    //     let motivationsCounter = 0;

    //     // Handle one period ********************
    //     dateArray.map((date: string) => {
    //       // Motivation
    //       const motivation = motivations.find((item: any) => item.createdAt.includes(date));
    //       if (motivation && !motivation.skipped) {
    //         motivationsCounter += 1;
    //         dataPerPeriod.motivation += motivation.type;
    //       }
    //       // Pain
    //       exercises.map((exercise: any) => {
    //         exercise.exerciseDone.map((exerciseResult: any) => {
    //           if (exerciseResult[date] !== null && exerciseResult[date] !== undefined) {
    //             for (const [key, value] of Object.entries(exerciseResult[date])) {
    //               if (painTypes.includes(key)) {
    //                 dataPerPeriod[key as keyof TAverageMapKey] += exerciseResult[date][key];
    //               }
    //             }
    //           } else {
    //             emptyData += 1;
    //           }
    //           if (exerciseResult[date] === null) {
    //             emptyDataInCurrentPeriod += 1;
    //           }
    //           ticks += 1;
    //           return null;
    //         });
    //         return null;
    //       });
    //       return null;
    //     });
    //     // *****************************************

    //     // Count average motivation per period
    //     if (motivationsCounter) {
    //       dataPerPeriod.motivation = Math.round(dataPerPeriod.motivation / motivationsCounter);
    //     }
    //     const exercisesSkipStop = dataPerPeriod.exerciseSkipped + dataPerPeriod.exerciseStopped;
    //     const sumPainScoreSkipStop = dataPerPeriod.sumPainScoreSkip
    //       + dataPerPeriod.sumPainScoreStop;
    //     // dataPerPeriod.avDesiredNumberOfReps = dataPerPeriod.exerciseStoppedAll
    //     //   ? Math.round(dataPerPeriod.sumDesiredNumberOfReps / dataPerPeriod.exerciseStoppedAll)
    //     //   : 0;
    //     dataPerPeriod.averagePainScoreCompleted = dataPerPeriod.exerciseCompleted
    //       ? Math.round(dataPerPeriod.sumPainScoreCompleted / dataPerPeriod.exerciseCompleted)
    //       : 0;
    //     dataPerPeriod.averagePainScoreSkipStop = exercisesSkipStop
    //       ? Math.round(sumPainScoreSkipStop / exercisesSkipStop)
    //       : 0;

    //     /* for Spinal */
    //     if (dataPerPeriod.exerciseCompleted) {
    //       dataPerPeriod.avPainScoreBackCompleted = Math.round(
    //         dataPerPeriod.sumPainScoreBackCompleted / dataPerPeriod.exerciseCompleted,
    //       );
    //       dataPerPeriod.avPainScoreLegCompleted = Math.round(
    //         dataPerPeriod.sumPainScoreLegCompleted / dataPerPeriod.exerciseCompleted,
    //       );
    //     }
    //     if (exercisesSkipStop) {
    //       const sumPainScoreBackSkipStop = dataPerPeriod.sumPainScoreBackSkip
    //         + dataPerPeriod.sumPainScoreBackStop;
    //       dataPerPeriod.avPainScoreBackSkipStop = Math.round(sumPainScoreBackSkipStop
    //         / exercisesSkipStop);
    //       const sumPainScoreLegSkipStop = dataPerPeriod.sumPainScoreLegSkip
    //         + dataPerPeriod.sumPainScoreLegStop;
    //       dataPerPeriod.avPainScoreLegSkipStop = Math.round(sumPainScoreLegSkipStop
    //         / exercisesSkipStop);
    //     }

    //     periodCounter += 1;
    //     dataPerPeriod.dateArray = dateArray.length > 1 ? String(periodCounter) : dateArray[0];
    //     if (dateArray.length === (emptyDataInCurrentPeriod / exercises.length)) {
    //       dataPerPeriod.isArchive = true;
    //     }

    //     // Total for one period
    //     painAndMotivationResult.push(dataPerPeriod);
    //   }
    // }
    // return ticks !== emptyData ? painAndMotivationResult : [];
    return [];
  },
);

// Compl & steps (Overview tab - Steps)
export const getStepsDataOverviewTab = createSelector(
  getStepsOverviewTab,
  getSelectedPeriodOverview,
  (steps, period) => {
    // const stepsArrayResult = [];

    // if (steps?.length) {
    //   const { periodFrom, dateTo, periodType } = period;
    //   const dates = getDatesByFormat(periodFrom, dateTo, periodType);
    //   const averageSteps = [];
    //   let count = 0;

    //   for (const [, dateArray] of Object.entries(dates)) {
    //     const stepsForPeriodResult: any = { steps: 0 };
    //     let sumValue = 0;
    //     dateArray.map((date: string) => {
    //       const step: StepType = steps
    //         .find((stepOne: StepType) => stepOne.updatedAt.indexOf(date) > -1);
    //       if (step) {
    //         sumValue += step.steps;
    //       }
    //       return null;
    //     });
    //     stepsForPeriodResult.steps = Math.round(sumValue / dateArray.length);
    //     averageSteps.push(stepsForPeriodResult);
    //   }
    //   for (const [dataKey, dateArray] of Object.entries(dates)) {
    //     const stepResult: any = {
    //       steps: 0,
    //       chartValue: 0,
    //       dateSteps: '',
    //     };

    //     let sumValue = 0;
    //     dateArray.map((date: string) => {
    //       // @ts-ignore
    //       const step: StepType = steps.find((stp: StepType[]) => stp.updatedAt.indexOf(date) > -1);
    //       if (step) {
    //         sumValue += step.steps;
    //       }
    //       return null;
    //     });

    //     stepResult.steps = Math.round(sumValue / dateArray.length);
    //     stepResult.chartValue = Math.round((stepResult.steps / maxStepScale(averageSteps)) * 100);
    //     stepResult.dateSteps = dateArray.length === 1 ? dateArray[0] : dataKey;
    //     count += 1;
    //     stepResult.dateArray = dateArray.length > 1 ? String(count) : dateArray[0];
    //     stepsArrayResult.push(stepResult);
    //   }
    // }
    // return stepsArrayResult;
    return [];
  },
);

// Complitation by periods (Overview tab - exercises)
export const getExercDataOverviewTab = createSelector(
  getSelectedPeriodOverview,
  // getExercisesOverviewTab,
  (
    getSelectedDates, // exercises
  ) => {
    // const reasonsByPeriods: any = [];

    // if (exercises.length) {
    //   const { periodFrom, dateTo, periodType, value } = getSelectedDates;
    //   const calcDates = getDatesByFormat(periodFrom, dateTo, periodType);
    //   const resultByDayAndExercises: any = {};
    //   const resultByAveragePeriod: any = {};
    //   let isEmptyData = 0;
    //   let ticks = 0;
    //   let periodNumber = 0;

    //   for (const [dataKey, dateArray] of Object.entries(calcDates)) {
    //     // reasons logik start
    //     // Create reasons default values {key: 0}
    //     const reasons: any = State.reasonTitles
    //       .reduce((object, reasonTitle) => ({ ...object, [reasonTitle]: 0 }), {});
    //     let maxValueYaxis = 0;
    //     // reasons logik end

    //     dateArray.map((date: string) => {
    //       exercises.map((exercise: any) => {
    //         const exercisesResult: any = {
    //           status: {
    //             completed: 0,
    //             partlyDone: 0,
    //             attempts: 0,
    //             noAttempt: 0,
    //             isNull: 0,
    //           },
    //           // tooltipStatus: {
    //           //   completed: 0,
    //           //   partlyDone: 0,
    //           //   attempts: 0,
    //           //   noAttempt: 0,
    //           //   isNull: 0,
    //           // },
    //           name: exercise.name,
    //         };
    //         exercise.exerciseDone.map((exerciseResult: any) => {
    //           const { status } = exercisesResult;

    //           /* Reasons logik start */
    //           if (exerciseResult[date]) {
    //             State.reasonTitles.map((reasonName: string) => {
    //               const reasonFromData = exerciseResult[date].reasons?.[reasonName];
    //               if (reasonFromData) {
    //                 const accCurrentReason = reasons[reasonName] + reasonFromData;
    //                 reasons[reasonName] = accCurrentReason;
    //                 maxValueYaxis += reasonFromData;
    //               }
    //               return null;
    //             });
    //           }
    //           /* Reasons logik end */

    //           if (exerciseResult[date] !== null && exerciseResult[date] !== undefined) {
    //             for (const [key, val] of Object.entries(exerciseResult[date])) {
    //               let sumPreviousValues: any = 0;
    //               let sumValue: any = 0;
    //               if (typeof val === 'object') {
    //                 for (const [key2, value2] of Object.entries(exerciseResult[date][key])) {
    //                   if (key === 'status') {
    //                     sumValue += value2;
    //                     if (sumValue > 100) {
    //                       // tooltipStatus[key2] = 100 - sumPreviousValues;
    //                       status[key2] = 100 - sumPreviousValues;
    //                       break;
    //                     }
    //                     sumPreviousValues += value2;
    //                     // tooltipStatus[key2] = value2;
    //                     status[key2] = value2;
    //                   }
    //                 }
    //               }
    //             }
    //           } else {
    //             isEmptyData += 1;
    //           }
    //           if (exerciseResult[date] === null) {
    //             // tooltipStatus.isNull = 100;
    //             status.isNull = 100;
    //           }

    //           ticks += 1;

    //           status.noAttempt = 0;
    //           // tooltipStatus.noAttempt = 0;
    //           // @ts-ignore
    //           // tooltipStatus.noAttempt = Object.values(tooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(tooltipStatus).reduce((a, b) => a + b, 0) : 0;
    //           // @ts-ignore
    //           status.noAttempt = Object.values(status).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(status).reduce((a, b) => a + b, 0) : 0;
    //           return null;
    //         });

    //         if (!resultByDayAndExercises[date]) {
    //           resultByDayAndExercises[date] = [];
    //         }
    //         // exercisesResult.tooltipStatus = { ...exercisesResult.status };
    //         resultByDayAndExercises[date].push(exercisesResult);
    //         return null;
    //       });
    //       return null;
    //     });

    //     /* Reasons logik start */
    //     periodNumber += 1;
    //     const resultByPeriod: any = {
    //       date: dateArray.length === 1 ? dateArray[0] : periodNumber,
    //       id: periodNumber,
    //       maxValueYaxis,
    //       reasons: Object.keys(reasons).map((key: string) => ({ [key]: reasons[key] })),
    //     };
    //     reasonsByPeriods.push(resultByPeriod);
    //     /* Reasons logik end */

    //     // By weeks or months
    //     if (dateArray.length > 1) {
    //       let exercisesDataPeriod: any[] = [];
    //       exercises.forEach((exercise: any, index: number) => {
    //         const totalStatusesForTheEntirePeriod: any = {
    //           status: {
    //             completed: 0,
    //             partlyDone: 0,
    //             attempts: 0,
    //             noAttempt: 0,
    //             isNull: 0,
    //           },
    //           // tooltipStatus: {
    //           //   completed: 0,
    //           //   partlyDone: 0,
    //           //   attempts: 0,
    //           //   noAttempt: 0,
    //           //   isNull: 0,
    //           // },
    //           name: '',
    //         };
    //         dateArray.map((day: any) => {
    //           for (const [key, value] of Object.entries(totalStatusesForTheEntirePeriod.status)) {
    //             totalStatusesForTheEntirePeriod.status[key] += resultByDayAndExercises[day][index].status[key];
    //             // totalStatusesForTheEntirePeriod.tooltipStatus[key] += resultByDayAndExercises[day][index].tooltipStatus[key];
    //           }
    //           totalStatusesForTheEntirePeriod.name = resultByDayAndExercises[day][index].name;
    //           return null;
    //         });
    //         for (const [key, value] of Object.entries(totalStatusesForTheEntirePeriod.status)) {
    //           // @ts-ignore
    //           totalStatusesForTheEntirePeriod.status[key] = Math.round(value / (dateArray.length));
    //           if (key === 'isNull' && totalStatusesForTheEntirePeriod.status[key] < 100) {
    //             totalStatusesForTheEntirePeriod.status[key] = 0;
    //           }
    //         }
    //         // for (const [key, value] of Object.entries(totalStatusesForTheEntirePeriod.tooltipStatus)) {
    //         //   // @ts-ignore
    //         //   totalStatusesForTheEntirePeriod.tooltipStatus[key] = Math.round(value / (dateArray.length));
    //         //   if (key === 'isNull' && totalStatusesForTheEntirePeriod.tooltipStatus[key] < 100) {
    //         //     totalStatusesForTheEntirePeriod.tooltipStatus[key] = 0;
    //         //   }
    //         // }

    //         totalStatusesForTheEntirePeriod.status.noAttempt = 0;
    //         // totalStatusesForTheEntirePeriod.tooltipStatus.noAttempt = 0;
    //         let sumPreviousValuesAverage = 0;
    //         let sumValueAverage = 0;

    //         for (const [key2, value2] of Object.entries(totalStatusesForTheEntirePeriod.status)) {
    //           // @ts-ignore
    //           sumValueAverage += value2;
    //           // @ts-ignore
    //           if (sumValueAverage > 100) {
    //             totalStatusesForTheEntirePeriod.status[key2] = 100 - sumPreviousValuesAverage;
    //             break;
    //           }
    //           // @ts-ignore
    //           sumPreviousValuesAverage += value2;
    //           totalStatusesForTheEntirePeriod.status[key2] = value2;
    //         }

    //         // let sumPreviousValuesAverageTooltip = 0;
    //         // let sumValueAverageTooltip = 0;
    //         // for (const [key2, value2] of Object.entries(totalStatusesForTheEntirePeriod.tooltipStatus)) {
    //         //   // @ts-ignore
    //         //   sumValueAverageTooltip += value2;
    //         //   // @ts-ignore
    //         //   if (sumValueAverageTooltip > 100) {
    //         //     totalStatusesForTheEntirePeriod.tooltipStatus[key2] = 100 - sumPreviousValuesAverageTooltip;
    //         //     break;
    //         //   }
    //         //   // @ts-ignore
    //         //   sumPreviousValuesAverageTooltip += value2;
    //         //   totalStatusesForTheEntirePeriod.tooltipStatus[key2] = value2;
    //         // }
    //         // @ts-ignore
    //         totalStatusesForTheEntirePeriod.status.noAttempt = Object.values(totalStatusesForTheEntirePeriod.status).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(totalStatusesForTheEntirePeriod.status).reduce((a, b) => a + b, 0) : 0;
    //         // @ts-ignore
    //         // totalStatusesForTheEntirePeriod.tooltipStatus.noAttempt = Object.values(totalStatusesForTheEntirePeriod.tooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(totalStatusesForTheEntirePeriod.tooltipStatus).reduce((a, b) => a + b, 0) : 0;
    //         // totalStatusesForTheEntirePeriod.tooltipStatus = { ...totalStatusesForTheEntirePeriod.status };
    //         exercisesDataPeriod.push(totalStatusesForTheEntirePeriod);
    //       });

    //       if (value === LAST_3_MONTHS || value === LAST_6_MONTHS) {
    //         const averageStatusesByWeek: any = {
    //           status: {
    //             completed: 0,
    //             partlyDone: 0,
    //             attempts: 0,
    //             noAttempt: 0,
    //             isNull: 0,
    //           },
    //           // tooltipStatus: {
    //           //   completed: 0,
    //           //   partlyDone: 0,
    //           //   attempts: 0,
    //           //   noAttempt: 0,
    //           //   isNull: 0,
    //           // },
    //         };
    //         let counter = 0;
    //         exercisesDataPeriod.forEach((exercise, index) => {
    //           for (const [key, value] of Object.entries(averageStatusesByWeek)) {
    //             for (const [key2, value2] of Object.entries(averageStatusesByWeek[key])) {
    //               if (key2 !== 'isNull') {
    //                 averageStatusesByWeek[key][key2] += exercise[key][key2];
    //               } else {
    //                 averageStatusesByWeek[key][key2] += exercise[key][key2];
    //                 if (exercise[key][key2] === 100) {
    //                   counter += 1;
    //                 }
    //               }
    //             }
    //             for (const [key2, value2] of Object.entries(averageStatusesByWeek[key])) {
    //               if (index === exercisesDataPeriod.length - 1) {
    //                 averageStatusesByWeek[key][key2] = Math.round(
    //                   averageStatusesByWeek[key][key2] / (exercisesDataPeriod.length - counter / 2),
    //                 );
    //                 if (key2 !== 'isNull') {
    //                   if (isNaN(averageStatusesByWeek[key][key2])) {
    //                     averageStatusesByWeek[key][key2] = 0;
    //                   }
    //                 } else if (isNaN(averageStatusesByWeek[key][key2])) {
    //                   averageStatusesByWeek[key][key2] = 100;
    //                 }
    //                 if (key2 === 'isNull' && averageStatusesByWeek[key][key2] < 100) {
    //                   averageStatusesByWeek[key][key2] = 0;
    //                 }
    //               }
    //             }
    //           }
    //         });

    //         let sumValue: any = 0;
    //         let previousValue: any = 0;
    //         for (const [key, value] of Object.entries(averageStatusesByWeek.status)) {
    //           sumValue += value;
    //           if (sumValue > 100) {
    //             averageStatusesByWeek.status[key] = 100 - previousValue;
    //             break;
    //           }
    //           previousValue += value;
    //           averageStatusesByWeek.status[key] = value;
    //         }
    //         averageStatusesByWeek.status.noAttempt = 0;
    //         // @ts-ignore
    //         averageStatusesByWeek.status.noAttempt = Object.values(averageStatusesByWeek.status).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(averageStatusesByWeek.status).reduce((a, b) => a + b, 0) : 0;
    //         // averageStatusesByWeek.tooltipStatus.noAttempt = 0;
    //         // @ts-ignore
    //         // averageStatusesByWeek.tooltipStatus.noAttempt = Object.values(averageStatusesByWeek.tooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(averageStatusesByWeek.tooltipStatus).reduce((a, b) => a + b, 0) : 0;
    //         // averageStatusesByWeek.tooltipStatus = { ...averageStatusesByWeek.status };
    //         exercisesDataPeriod = [averageStatusesByWeek];
    //       }
    //       resultByAveragePeriod[dataKey] = exercisesDataPeriod;
    //     }
    //   }

    //   if (Object.keys(resultByAveragePeriod).length !== 0) {
    //     return ticks === isEmptyData
    //       ? { reasonsByPeriods, completationData: {} }
    //       : { reasonsByPeriods, completationData: resultByAveragePeriod };
    //     // return ticks === isEmptyData ? {} : resultByAveragePeriod;
    //   }
    //   return { reasonsByPeriods, completationData: resultByDayAndExercises };
    // }
    // return { reasonsByPeriods, completationData: {} };
    return { reasonsByPeriods: [], completationData: {} };
  },
);
// ** NEW selectors******************************************

// Calculate date for Exercise Completion chart
const calculateChartData = (selectedPeriod: PeriodDropDownOptions | null, data: any[]) => {
  const periodKey = selectedPeriod ? selectedPeriod.value : PERIOD_FOR_CHART.W;
  const dataPeriod = data ? data.find((el: ChartsDataType) => el.graph === periodKey) : [];
  return dataPeriod;
};
// Pain level chart
export const getPainData = createSelector(
  getSelectedPeriodOverview,
  getPainLevelData,
  (selectedPeriod, data) => {
    const calcResult = calculateChartData(selectedPeriod, data);
    return calcResult;
  },
);

// Motivation chart
export const getMotivationData = createSelector(
  getSelectedPeriodOverview,
  getMotivation,
  (selectedPeriod, data) => {
    const calcResult = calculateChartData(selectedPeriod, data);
    return calcResult;
  },
);

// Steps chart
export const getStepsData = createSelector(
  getSelectedPeriodOverview,
  getPatientsSteps,
  (selectedPeriod, data) => {
    const calcResult = calculateChartData(selectedPeriod, data);
    return calcResult;
  },
);

// Heart rate chart
export const getHeartRateData = createSelector(
  getSelectedPeriodOverview,
  getPatientHeartRateData,
  (selectedPeriod, data) => {
    const calcResult = calculateChartData(selectedPeriod, data);
    return calcResult;
  },
);

// Calculate date for Exercise Completion chart
const calculateReasonsData = (
  selectedPeriod: PeriodDropDownOptions | null,
  data: NotComplExercDataType[],
) => {
  const periodKey = selectedPeriod ? selectedPeriod.value : PERIOD_FOR_CHART.W;
  const dataPeriod: any = data
    ? data.find((el: NotComplExercDataType) => el.graph === periodKey)
    : [];
  const chartData = {
    graph: '',
    title: '',
    description: '',
    axis: '',
    values: null,
    yAxisDomain: [0, 10],
  };
  if (dataPeriod) {
    const dataValues = dataPeriod.values;
    const values: any = [];
    let maxYAxis = 10;
    if (dataValues) {
      dataPeriod.values.map((period: NotComplExercValuesType) => {
        const allReasonsValues = Object.values(period.reasons);
        const sumAllReasPerPer = allReasonsValues.reduce((accumulator: any, currentValue: any) => {
          if (typeof currentValue !== 'string') {
            return accumulator + currentValue;
          }
          return accumulator;
        }, 0);
        values.push(sumAllReasPerPer);
        return null;
      });
      maxYAxis = Math.max(...values);
      if (maxYAxis % 10 !== 0) {
        let fraction = maxYAxis / 10;
        fraction = Math.ceil(fraction);
        maxYAxis = fraction * 10;
      }
    }
    chartData.graph = dataPeriod.graph;
    chartData.title = dataPeriod.title;
    chartData.description = dataPeriod.description;
    chartData.axis = dataPeriod.axisReasons;
    chartData.values = dataPeriod.values;
    chartData.yAxisDomain = [0, maxYAxis];
  }
  return chartData;
};

// Reasons for not completed exercises chart
export const getReasonsForNotComplExercData = createSelector(
  getSelectedPeriodOverview,
  getNotComplExerc,
  (selectedPeriod, data) => {
    const calcResult = calculateReasonsData(selectedPeriod, data);
    return calcResult;
  },
);

// Calculate data for skip stop Exercises chart
const calculateSkipStopData = (
  selectedPeriod: PeriodDropDownOptions | null,
  data: NotComplExercDataType[],
) => {
  const periodKey = selectedPeriod ? selectedPeriod.value : PERIOD_FOR_CHART.W;
  const dataPeriod: any = data
    ? data.find((el: NotComplExercDataType) => el.graph === periodKey)
    : [];
  const chartData = {
    graph: '',
    title: '',
    description: '',
    axis: '',
    values: null,
    yAxisDomain: [0, 10],
  };
  if (dataPeriod) {
    const dataValues: NotComplExercValuesType[] = dataPeriod.values;
    const values: any = [];
    let maxYAxis = 10;
    if (dataValues) {
      dataValues.map((period: NotComplExercValuesType) => {
        values.push(period.averageNumberOfReps);
        values.push(period.numberOfSkippedExercises);
        values.push(period.numberOfStoppedExercises);
        return null;
      });
      maxYAxis = Math.max(...values);
      if (maxYAxis % 10 !== 0) {
        let fraction = maxYAxis / 10;
        fraction = Math.ceil(fraction);
        maxYAxis = fraction * 10;
      }
    }
    chartData.graph = dataPeriod.graph;
    chartData.title = dataPeriod.title;
    chartData.description = dataPeriod.description;
    chartData.axis = dataPeriod.axisNumber;
    chartData.values = dataPeriod.values;
    chartData.yAxisDomain = [0, maxYAxis];
  }
  return chartData;
};

// Skip Stop Exercises for not completed chart
export const getSkipStopData = createSelector(
  getSelectedPeriodOverview,
  getNotComplExerc,
  (selectedPeriod, data) => {
    const calcResult = calculateSkipStopData(selectedPeriod, data);
    return calcResult;
  },
);

// Calculate date for Exercise Completion chart
const calculateExerciseCompletionData = (
  selectedPeriod: PeriodDropDownOptions | null,
  data: ExercComplDataType[],
) => {
  const periodKey = selectedPeriod ? selectedPeriod.value : PERIOD_FOR_CHART.W;
  const init = {
    graph: '',
    title: '',
    description: '',
    values: [],
    axis: '',
    averagePercent: '',
    yDomen: [0, 100],
  };
  const dataPeriod: any = data
    ? data.find((el: ExercComplDataType) => el.graph === periodKey)
    : init;

  if (dataPeriod && dataPeriod.values.length) {
    // let maxYAxis = 100;
    const dataValues = dataPeriod.values;
    if (dataValues) {
      const values: any = [];
      dataPeriod.values.map((period: ChartValuesType) => {
        values.push(period.value);
        return null;
      });

      // maxYAxis = Math.max(...values);
      // if (maxYAxis > 100) {
      //   maxYAxis = 100;
      // }

      // if (maxYAxis % 10 !== 0) {
      //   let fraction = maxYAxis / 10;
      //   fraction = Math.ceil(fraction);
      //   maxYAxis = fraction * 10;
      // }
    }
    const newData = {
      graph: dataPeriod.graph,
      title: dataPeriod.title,
      description: dataPeriod.description,
      axis: dataPeriod.axis,
      averagePercent: dataPeriod.averagePercent,
      values: dataPeriod.values,
      // yDomen: [0, maxYAxis || 100],
      yDomen: [0, 100],
    };
    return newData;
  }
  return dataPeriod;
};

// Exercise completion chart
export const getExerciseCompletionData = createSelector(
  getSelectedPeriodOverview,
  getExercComplData,
  (selectedPeriod, data) => {
    const calcResult = calculateExerciseCompletionData(selectedPeriod, data);
    return calcResult;
  },
);

// Calculate date for Individual Exercises Summary chart
const calculateExercisesSummaryData = (
  selectedPeriod: PeriodDropDownOptions | null,
  exercisesRawData: ExercPerPeriodType[],
  exercComplData: ExercComplDataType[],
) => {
  const periodKey = selectedPeriod?.value || PERIOD_FOR_CHART.W;

  const newExercises: any = [];
  if (exercisesRawData && exercisesRawData.length > 0) {
    exercisesRawData.forEach((exercise: ExercPerPeriodType) => {
      const dataForCalculation: any = [];
      const { exerciseDone } = exercise;
      if (exerciseDone) {
        const exerciseDoneParsed = JSON.parse(exerciseDone);
        exerciseDoneParsed.forEach((item: any) => {
          const date = Object.keys(item)[0];
          const data = item[date];
          dataForCalculation.push({
            date,
            exerciseCompleted: data?.exerciseCompleted || 0,
            exerciseSkipped: data?.exerciseSkipped || 0,
            exerciseStopped: data?.exerciseStopped || 0,
          });
        });
        const completed = calculateForCount(
          dataForCalculation,
          chartItemsCount[periodKey],
          'exerciseCompleted',
        )
          .slice()
          .reverse();
        const skipped = calculateForCount(
          dataForCalculation,
          chartItemsCount[periodKey],
          'exerciseSkipped',
        )
          .slice()
          .reverse();
        const stopped = calculateForCount(
          dataForCalculation,
          chartItemsCount[periodKey],
          'exerciseStopped',
        )
          .slice()
          .reverse();

        const totalCells = exercise.timesPerDay * completed.length;
        const avarage = Math.round(
          (completed.reduce((acc: any, value: any) => acc + value, 0) / totalCells) * 100,
        );
        newExercises.push({
          id: exercise.id,
          name: exercise.name,
          timesPerDay: exercise.timesPerDay,
          completed,
          skipped,
          stopped,
          avarage,
        });
      }
    });

    // Labels
    const periodsLabel: string[] = [];
    if (exercComplData) {
      const dataComplPeriod: any = exercComplData.find(
        (el: ExercComplDataType) => el.graph === periodKey,
      );
      const valuesLength = dataComplPeriod?.values?.length;
      const currentPeriodNumber = chartItemsCount[periodKey];
      if (valuesLength && valuesLength === currentPeriodNumber) {
        dataComplPeriod.values.map((item: ChartValuesType) => {
          periodsLabel.push(item.name);
          return null;
        });
      }
    }
    const result = {
      exercises: newExercises,
      chartLabels: periodsLabel?.length ? periodsLabel.reverse() : [],
    };
    return result;
  }
  return null;
};

// Overview - Exercises Per Period (Individual Exercise summary charts)
export const getExercisesPerData = createSelector(
  getSelectedPeriodOverview,
  getExercPerPeriodData,
  getExercComplData,
  (selectedPeriod, exercisesRawData, exercComplData) => {
    const calcResult = calculateExercisesSummaryData(
      selectedPeriod,
      exercisesRawData,
      exercComplData,
    );
    return calcResult;
  },
);

// Calculate sleep date for Sleep chart
// const calculateSleepData = (
//   selectedPeriod: PeriodDropDownOptions | null,
//   sleepRawData: SleepChartDataType[],
// ) => {
//   const periodKey = selectedPeriod ? selectedPeriod.value : PERIOD_FOR_CHART.W;
//   const defaultObj = {
//     title: '',
//     description: '',
//     values: [],
//     axis: '',
//   };
//   const dataPeriod: any = sleepRawData
//     ? sleepRawData.find((el: SleepChartDataType) => el.graph === periodKey)
//     : defaultObj;
//   const startDatesInMinutes: number[] = [];
//   const endDatesInMinutes: number[] = [];

//   const convertDateToMinutes = (timeInMinutes: number, type: 'start' | 'end'): number => {
//     let hours: number | string = Math.trunc(timeInMinutes / 60);
//     const minutes: number | string = timeInMinutes - hours * 60;
//     if (hours >= 12) {
//       hours -= 24;
//     }
//     const result = minutes + hours * 60;
//     if (type === 'start') {
//       startDatesInMinutes.push(result);
//     }
//     if (type === 'end') {
//       endDatesInMinutes.push(result);
//     }
//     return result;
//   };

//   if (dataPeriod && dataPeriod.values.length > 0) {
//     const newValues: any = [];
//     dataPeriod.values.map((period: SleepChartValuesType) => {
//       const dateStart = period.startTimeInMinutes;
//       const dateEnd = period.endTimeInMinutes;

//       const dateStartConverted = dateStart ? convertDateToMinutes(dateStart, 'start') : 0;
//       const dateEndConverted = dateEnd ? convertDateToMinutes(dateEnd, 'end') : 0;

//       const res = {
//         ...period,
//         bar: [dateStartConverted, dateEndConverted],
//       };
//       newValues.push(res);
//       return null;
//     });

//     const newData = {
//       title: dataPeriod.title,
//       description: dataPeriod.description,
//       values: newValues,
//       axis: dataPeriod.axis,
//       yDomen: [0, 0],
//     };
//     if (startDatesInMinutes.length && endDatesInMinutes.length) {
//       const minYAxis = Math.min(...startDatesInMinutes);
//       const minHoursSign = Math.sign(minYAxis);
//       const minYAxisHours = Math.floor(Math.abs(minYAxis / 60));
//       const minYDomen = (minYAxisHours * minHoursSign - 1) * 60;

//       const maxYAxis = Math.max(...endDatesInMinutes);
//       const maxHoursSign = Math.sign(maxYAxis);
//       const maxYAxisHours = Math.floor(Math.abs(maxYAxis / 60));
//       const maxYDomen = (maxYAxisHours * maxHoursSign + 1) * 60;
//       newData.yDomen = [minYDomen, maxYDomen];
//     }
//     console.log('777_SEL_DATA=', newData);

//     return newData;
//   }
//   return dataPeriod;
// };

// Calculate sleep date for Sleep chart
const calculateSleepData = (
  selectedPeriod: PeriodDropDownOptions | null,
  sleepRawData: SleepChartDataType[],
) => {
  const periodKey = selectedPeriod ? selectedPeriod.value : PERIOD_FOR_CHART.W;
  const defaultObj = {
    title: '',
    description: '',
    values: [],
    axis: '',
    graph: '',
  };
  const dataPeriod: any = sleepRawData
    ? sleepRawData.find((el: SleepChartDataType) => el.graph === periodKey)
    : defaultObj;
  const startDatesInMinutes: number[] = [];
  const endDatesInMinutes: number[] = [];

  const convertDateToMinutes = (timeInMinutes: number, type: 'start' | 'end'): number => {
    let normalizeMinutes = timeInMinutes;
    if (timeInMinutes >= 720) {
      normalizeMinutes -= 1440;
    }
    if (type === 'start') {
      startDatesInMinutes.push(normalizeMinutes);
    }
    if (type === 'end') {
      endDatesInMinutes.push(normalizeMinutes);
    }
    return normalizeMinutes;
  };

  if (dataPeriod && dataPeriod.values.length > 0) {
    const newValues: any = [];
    dataPeriod.values.map((period: SleepChartValuesType) => {
      const dateStart = period.startTimeInMinutes;
      const dateEnd = period.endTimeInMinutes;

      const dateStartConverted = dateStart ? convertDateToMinutes(dateStart, 'start') : 0;
      const dateEndConverted = dateEnd ? convertDateToMinutes(dateEnd, 'end') : 0;

      const res = {
        ...period,
        bar: [dateStartConverted, dateEndConverted],
      };
      newValues.push(res);
      return null;
    });

    const newData = {
      title: dataPeriod.title,
      graph: dataPeriod.graph,
      description: dataPeriod.description,
      values: newValues,
      axis: dataPeriod.axis,
      yDomen: [0, 0],
    };
    if (startDatesInMinutes.length && endDatesInMinutes.length) {
      const minYAxis = Math.min(...startDatesInMinutes);
      const minHoursSign = Math.sign(minYAxis);
      const minYAxisHours = Math.floor(Math.abs(minYAxis / 60));
      const minYDomen = (minYAxisHours * minHoursSign - 1) * 60;

      const maxYAxis = Math.max(...endDatesInMinutes);
      const maxHoursSign = Math.sign(maxYAxis);
      const maxYAxisHours = Math.floor(Math.abs(maxYAxis / 60));
      const maxYDomen = (maxYAxisHours * maxHoursSign + 1) * 60;
      newData.yDomen = [minYDomen, maxYDomen];
    }
    return newData;
  }
  return dataPeriod;
};

// Sleep chart
export const getSleepData = createSelector(
  getSelectedPeriodOverview,
  getPatientSleepData,
  (selectedPeriod, data) => {
    const calcResult = calculateSleepData(selectedPeriod, data);
    return calcResult;
  },
);

// Remote monitoring page
export const getRemoteMonitoringData = createSelector(
  getSelectedPerRemoteMonit,
  getExercPerPeriodData,
  getExercComplData,
  getNotComplExerc,
  getPainLevelData,
  getMotivation,
  getPatientsSteps,
  getPatientSleepData,
  getPatientHeartRateData,
  (
    selectedPeriod,
    exercisesSummaryData,
    exerciseComplData,
    notComplExercData,
    painLevelRawData,
    motivationRawData,
    stepsRawData,
    sleepRawData,
    heartRateRawData,
  ) => ({
    exerciseSummary: calculateExercisesSummaryData(
      selectedPeriod,
      exercisesSummaryData,
      exerciseComplData,
    ),
    exerciseCompletionData: calculateExerciseCompletionData(selectedPeriod, exerciseComplData),
    reasonsNotComletionData: calculateReasonsData(selectedPeriod, notComplExercData),
    skipStopExercData: calculateSkipStopData(selectedPeriod, notComplExercData),
    painLevelData: calculateChartData(selectedPeriod, painLevelRawData),
    motivationData: calculateChartData(selectedPeriod, motivationRawData),
    stepsData: calculateChartData(selectedPeriod, stepsRawData),
    sleepData: calculateSleepData(selectedPeriod, sleepRawData),
    heartRateData: calculateChartData(selectedPeriod, heartRateRawData),
  }),
);
