import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { GetState, getCurrenLang, getHcpName } from '../../../../redux/selector';
import { Loading } from '../../../../common';
import { deepCopyObj, getExercisesCategories, getSelectOptions } from '../../../../utils/helper';

import { path } from '../../../../utils/routers/book';
import { Popup } from '../../../../common/Popup';
import { useCreateHospitalVideo, useGetHospitalNames } from '../../../../graphql/hospitals';
import { uploadVideoForPatientInfoByHcp } from '../../../../utils/validators';

import { config } from '../../../../utils/configs';
import useCheckRole from '../../../../hooks/useCheckRole';
import useGetFocuses from '../../../../hooks/useGetFocuses';
import MediaSettings from '../MediaSettings';
import { SuccessNotifModal, WarnNotifModalOneButton } from '../../../../common/NotificationModal';

const UploadPatientInformation = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const ok = t?.common.ok;
  const warn_message = t?.hcp.manage_patient_information?.warn_message;
  const large_file = t?.help.large_file;
  const large_file_50 = large_file?.replace('<size>', '50MB');
  const wrong_format = t?.help.wrong_format;
  const video_successfully_uploaded = t?.hcp.manage_patient_information.video_successfully_uploaded;

  const history = useHistory();
  const { isAdmin, token } = useCheckRole();
  const filesSizeLimit = 52428800; // max 50Mb
  const { uri, bucketName, videoServiceUrl } = config;
  const uriWithoutGraph = uri?.split('/api')[0];
  const {
    focusType1Arr,
    // focusType2Arr,
    // exercisesCategories,
    exercisesProcedureFocus,
    // procedureFilterConfig,
    loadingFocuses,
  } = useGetFocuses();
  const initErrState = {
    error: false,
    text: '',
  };
  const currentUser: any = useSelector((state: GetState) => getHcpName(state));

  // Endpoints
  const {
    _createHospitalVideo,
    errorCreateHospitalVideo,
    loadingCreateHospitalVideo,
    hospitalVideoCreated,
  } = useCreateHospitalVideo();
  const { hospitalNames } = useGetHospitalNames(!isAdmin);
  const hospitalNameOptions = useMemo(() => getSelectOptions(hospitalNames), [hospitalNames]);

  // Local state
  const [fileInputKey, setFileInputKey] = useState<number>(Date.now());
  const [errorMessage, setErrorMessage] = useState(deepCopyObj(initErrState));
  const [isWarningModalOpen, setWarningModal] = useState(false);
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const focusByprocedures: any = {};
  focusType1Arr.map((procedure: any, i: number) => {
    focusByprocedures[`procedure${i + 1}`] = false;
    return null;
  });

  const initialValues = useMemo(
    () => ({
      isAllowDownloadVideos: true,
      file: '',
      fileName: '',
      description: '',
      name: '',
      organisationName: '',
      ...focusByprocedures,
    }),
    [focusType1Arr.length],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: () => uploadVideoForPatientInfoByHcp(t, isAdmin, false),
    // validate: (values) => validationProcedure(values, focusType1Arr, t),
    onSubmit: (value: any): any => {
      const request = {
        videoProcedureTypesId: getExercisesCategories(value, exercisesProcedureFocus, 'procedure'),
        videoData: {
          hospitalId: isAdmin ? value.organisationName.value : currentUser.hospitalId,
          name: value.name.trim(),
          fileName: value.fileName,
          description: value.description.trim(),
          isAllowDownloadVideos: value.isAllowDownloadVideos,
        },
      };
      _createHospitalVideo({
        variables: request,
      });
    },
  });

  const { values } = formik;

  // Save video file to AWS
  const uploadVideo = async (videoId: number): Promise<void> => {
    setIsLoading(() => true);
    const formData = new FormData();
    formData.append('file', values.file);
    const urlSecondPart = `video/uploadFromHospital/${videoId}`;
    let videoServUrl = `${uriWithoutGraph}/video_api/${urlSecondPart}`;
    switch (bucketName) {
      case 'otm-stage':
      case 'onthemend-en':
      case 'onthemend-us':
        videoServUrl = `${videoServiceUrl}${urlSecondPart}`;
        break;
      default:
        break;
    }
    axios
      .post(videoServUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        // Wait at least 3 seconds (We do not wait for a response because
        // the process of downloading a video file can be long.)
        // timeout: 1000,
      })
      .then(() => {
        setSuccessModal(() => true);
      })
      .catch((e) => {
        setWarningModal(() => true);
        // toast.error(video_not_uploaded);
      })
      .finally(() => setIsLoading(() => false));
  };

  // If create video error show message
  useEffect(() => {
    if (errorCreateHospitalVideo) {
      toast.error(errorCreateHospitalVideo);
    }
  }, [errorCreateHospitalVideo]);

  // After save options to DB then save videofile to AWS
  useEffect(() => {
    if (hospitalVideoCreated) {
      uploadVideo(hospitalVideoCreated);
    }
  }, [hospitalVideoCreated]);

  // Check incoming file
  const onCheckIncomingFile = async (e: any): Promise<void> => {
    setFileInputKey(Date.now());
    formik.setErrors({});
    try {
      const f = e.target.files[0];
      if (f) {
        const fileName = f.name.toLowerCase();
        const { type } = f;
        if (!isAdmin && f.size > filesSizeLimit) {
          setErrorMessage({
            error: true,
            text: large_file_50,
          });
        } else {
          const normalizeFile = new File([f], fileName, { type });
          formik.setFieldValue('fileName', fileName);
          formik.setFieldValue('file', normalizeFile);
        }
      }
    } catch (error) {
      setErrorMessage({
        error: true,
        text: wrong_format,
      });
    }
  };

  // Cancel changes and redirect to page Organisation PI
  const onCancel = (): void => {
    formik.resetForm();
    const pathname = isAdmin
      ? path.adminOrganisationPatientInformation
      : path.organisationPatientInformation;
    history.push({ pathname });
  };

  // Clouse modal window and redirect to Organisation PI page
  const oncloseModal = (): void => {
    setWarningModal(() => false);
    setSuccessModal(() => true);
    onCancel();
  };

  // JSX
  const loadingJSX = (isLoading || loadingCreateHospitalVideo || loadingFocuses) && <Loading />;

  return (
    <div>
      {loadingJSX}
      <div>
        {exercisesProcedureFocus && focusType1Arr && (
          <MediaSettings
            index={1}
            formik={formik}
            focusType1Arr={focusType1Arr}
            exercisesProcedureFocus={exercisesProcedureFocus}
            isAdmin={isAdmin}
            hospitalNameOptions={hospitalNameOptions}
            onCheckIncomingFile={onCheckIncomingFile}
            fileInputKey={fileInputKey}
            onCancel={onCancel}
            loadingJSX={loadingJSX}
            isEdit={false}
          />
        )}
      </div>

      {/* Popups */}
      {errorMessage.error && (
        <Popup
          content=""
          title={errorMessage.text}
          buttonName={ok}
          onClosePopup={(): void => setErrorMessage(JSON.parse(JSON.stringify(initErrState)))}
        />
      )}

      {/* Warning if downloading file may take a long time */}
      {isWarningModalOpen && (
        <WarnNotifModalOneButton onClose={oncloseModal} description={warn_message} btnName={ok} />
      )}

      {/* Success upload file */}
      {isSuccessModalOpen && (
        <SuccessNotifModal
          onClose={oncloseModal}
          description={video_successfully_uploaded}
          btnName={ok}
        />
      )}
    </div>
  );
};

export default UploadPatientInformation;
