/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { ReactElement } from 'react';
import moment from 'moment';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import {
  TypesExerciseStatus,
  TStepObj,
  TStep,
  TGetStepsItem,
  TGetDataLabel,
  TGetSteps,
  CSVConfig,
  ProcedureKey,
  Block,
  TQuestionary,
} from './model';
import { dayInMilliseconds } from './variables';
import {
  CHART_TABS,
  PATIENT_PROFILE_TABS,
  PROMS_NAME,
  proceduresEnum,
  statusQuestionnaries,
} from './enums';
import {
  CompletedQuestions,
  PatientAnswers,
} from '../components/Charts/Proms/PromsToPdf/Reports/types';
import {
  ExerciseCompletionIcon,
  HeartRateIcon,
  MotivationIcon,
  PainIcon,
  PromsIcon,
  SleepIcon,
  StepIcon,
} from '../theme/icons';
import { ChartValuesType } from '../redux/motivations';

import { ReactComponent as UnableMove } from '../theme/image/emoji/selected/10_Unable_to_move.svg';
import { ReactComponent as NineSevere } from '../theme/image/emoji/selected/9_nine_Severe.svg';
import { ReactComponent as Severe } from '../theme/image/emoji/selected/8_Severe.svg';
import { ReactComponent as SevenSevere } from '../theme/image/emoji/selected/7_seven_Severe.svg';
import { ReactComponent as FocusOfAttention } from '../theme/image/emoji/selected/6_Focus_of_attention.svg';
import { ReactComponent as HardToIgnore } from '../theme/image/emoji/selected/5_Hard_to_ignore.svg';
import { ReactComponent as Moderate } from '../theme/image/emoji/selected/4_Moderate.svg';
import { ReactComponent as Unfomfortable } from '../theme/image/emoji/selected/3_Unfomfortable.svg';
import { ReactComponent as Noticeable } from '../theme/image/emoji/selected/2_Noticeable.svg';
import { ReactComponent as HardlyNoticeable } from '../theme/image/emoji/selected/1_Hardly_Noticeable.svg';
import { ReactComponent as PainFree } from '../theme/image/emoji/selected/0_Pain Free.svg';
import { QuestionnairesList } from '../components/Charts/types';

export const arrayOfDays = ['7', '14', '30'];
export const arrayOfWeeks = ['6', '13', '26'];
export const arrayOfYears = ['12', '24'];
export const LAST_3_MONTHS = '13';
export const LAST_6_MONTHS = '26';

export const MobilePortretScreenSize = 450;

export const HintValueHandler = (data: any, setHintValue: any): void => {
  const value = data.tooltipStatus;
  const tooltipValue: any = {
    completed: Number(value.completed.toFixed(0)),
    partlyDone: Number(value.partlyDone.toFixed(0)),
    attempts: Number(value.attempts.toFixed(0)),
    noAttempt: Number(value.noAttempt.toFixed(0)),
    name: data.name,
    steps: data.steps,
    date: data.date,
  };
  setHintValue(tooltipValue);
};

export const getNumberPeriod = (
  periodFrom: string,
  period: string,
  surgeryDay: string,
  isDay: boolean | undefined,
  isWeek: boolean | undefined,
  isYear: boolean | undefined,
): string => {
  const setNumberPeriod = (data: any): string => {
    let iterator: string = periodFrom;
    let numberPeriod = '';
    for (let x = 0; x < Number(period); x += 1) {
      if (
        moment(surgeryDay).isBetween(
          moment(iterator).subtract(1, 'd'),
          moment(iterator).add(1, data).format('YYYY-MM-DD'),
        )
      ) {
        numberPeriod = String(x + 1);
        break;
      }
      iterator = moment(iterator).add(1, data).format('YYYY-MM-DD');
    }
    return numberPeriod;
  };
  let value = '';

  if (isDay) {
    value = surgeryDay;
  }
  if (isWeek) {
    value = setNumberPeriod('w');
  }
  if (isYear) {
    value = setNumberPeriod('M');
  }
  return value;
};

export const setPeriodLabel = (
  selectedPeriod: string,
  day: string,
  week: string,
  month: string,
): string => {
  if (arrayOfDays.includes(selectedPeriod)) {
    return day;
  }
  if (arrayOfWeeks.includes(selectedPeriod)) {
    return week;
  }
  if (arrayOfYears.includes(selectedPeriod)) {
    return month;
  }
  return '';
};

// old function
export const maxStepScale = (steps: TStep[]): number => {
  const maxStepValue = Math.max(...steps.map((step: TStep) => step.steps));
  let maxStepScaleValue = 10;
  if (maxStepValue < 11) {
    return maxStepScaleValue;
  }
  if (maxStepValue < 101) {
    maxStepScaleValue = Math.ceil(maxStepValue / 100) * 100;
  } else {
    maxStepScaleValue = Math.ceil(maxStepValue / 1000) * 1000;
  }
  return maxStepScaleValue;
};

export const maxStepYScale = (steps: ChartValuesType[]): number => {
  const maxStepValue = Math.max(...steps.map((step: ChartValuesType) => step.value));
  let maxStepScaleValue = 10;
  if (maxStepValue < 11) {
    return maxStepScaleValue;
  }
  if (maxStepValue < 101) {
    maxStepScaleValue = Math.ceil(maxStepValue / 100) * 100;
  } else {
    maxStepScaleValue = Math.ceil(maxStepValue / 1000) * 1000;
  }
  return maxStepScaleValue;
};

export const getTick = (v: number, maxStepScal: number, maxValue: number): string => {
  const YAxisValue = (v / maxValue) * maxStepScal;
  if (v === 0) {
    return String(v);
  }
  if (YAxisValue < 1000) {
    return String(YAxisValue);
  }
  if (YAxisValue < 3000) {
    return `${YAxisValue / 1000}k`;
  }
  return `${Math.round(YAxisValue / 1000)}k`;
};

export const getXDomen = (isNoData: boolean, counterBars: number): number[] | 0 => {
  const xDomain = [0];
  if (isNoData) {
    xDomain.push(0);
  } else if (counterBars < 5) {
    xDomain.push(5);
  } else {
    xDomain.splice(0, 1);
  }
  return xDomain.length && xDomain;
};

export const setColor = (statusKey: string): number => {
  switch (statusKey) {
    case 'completed':
      return 0;
    case 'partlyDone':
      return 1;
    case 'attempts':
      return 2;
    default:
      return 3;
  }
};

export const getSteps = (
  DATA: any,
  steps: TStep[],
  isNoData: boolean,
  indentBetweenPeriods: number,
  counter: number,
  customPeriod: number,
): any => {
  const stepsObj = steps.reduce((result: TStepObj, item: TStep) => {
    const key = item.dateSteps;
    result[key] = item;
    return result;
  }, {});

  if (!isNoData) {
    const dateArr = DATA.map((item: TGetStepsItem) => item.date);
    const getdateArrUnique = (): string[] => {
      const result: any = [];
      for (const str of dateArr) {
        if (!result.includes(str)) {
          result.push(str);
        }
      }
      return result;
    };
    const dateArrUnique = getdateArrUnique();
    const xPoints = dateArr.reduce((acc: { [key: string]: number }, el: string) => {
      acc[el] = (acc[el] || 0) + 1;
      return acc;
    }, {});
    let acc = 0;
    const NumberOfStatusTypes = 5;
    const coefficientForCenterPoint = 0.025;

    return dateArrUnique.map((item: string, index: number) => {
      const startPoint = counter < customPeriod ? (customPeriod - counter) / 2 : 0;
      let shiftIndentBetweenPeriods = 0;
      if (counter > customPeriod - 1) {
        shiftIndentBetweenPeriods = 0;
      } else if (dateArrUnique.length > 1) {
        shiftIndentBetweenPeriods = (indentBetweenPeriods / 2) * (dateArrUnique.length - 1);
      }
      const dataSteps = {
        x:
          startPoint +
          xPoints[item] / NumberOfStatusTypes / 2 -
          shiftIndentBetweenPeriods +
          indentBetweenPeriods * index -
          coefficientForCenterPoint +
          acc,
        y: stepsObj && stepsObj[item] && stepsObj[item].chartValue,
        date: item,
        steps: stepsObj && stepsObj[item] && stepsObj[item].steps,
      };
      acc += xPoints[item] / NumberOfStatusTypes;
      return dataSteps;
    });
  }
  return [];
};

export const getTitle = (
  day: string,
  period: string,
  isDay = true,
  t: any,
  windowClientWidth: number,
): string => {
  const today_t = t?.dashboard.hcp.table.today;
  const monthAgo = t?.dashboard.hcp.profile_patient.completion_and_steps.one_month_ago;
  const weekAgo = t?.dashboard.hcp.profile_patient.completion_and_steps.one_week_ago;
  const now: Date = new Date(moment().format('YYYY-MM-DD'));
  const v = isDay ? String((+now - +new Date(day)) / dayInMilliseconds) : day;
  let title = '';
  const setLabel = (): string => {
    if (arrayOfYears.includes(period)) {
      return monthAgo;
    }
    return weekAgo;
  };
  if (arrayOfDays.includes(period)) {
    title = v === '0' ? today_t : String(+v);
  } else if (arrayOfWeeks.includes(period) || arrayOfYears.includes(period)) {
    if (windowClientWidth <= 1000) {
      title = String(+period - +v + 1);
    } else {
      // title = v === period ? setLabel() : String(+period - (+v) + 1);
      title = String(+period - +v + 1);
    }
  }
  return title;
};

export const calcSurgeryDays = (date: Date): number => {
  if (date) {
    const dayNow = moment().startOf('day');
    const dayFrom = moment(date).startOf('day');
    return dayNow.diff(dayFrom, 'days');
  }
  return 0;
};

export const COLOR_BARS = [
  {
    method: (obj: TypesExerciseStatus): number => obj.completed,
    color: '#00B248',
  },
  {
    method: (obj: TypesExerciseStatus): number => obj.partlyDone,
    color: '#66FFA6',
  },
  {
    method: (obj: TypesExerciseStatus): number => obj.attempts,
    color: '#EEFF41',
  },
  {
    method: (obj: TypesExerciseStatus): number => obj.noAttempt,
    color: '#EEF1F8',
  },
  {
    method: (obj: TypesExerciseStatus): number => obj.isNull,
    color: '#D6EAF8',
  },
];

export const COLOR_BARS_FOR_LEGEND_MOB = [
  {
    method: (obj: TypesExerciseStatus): number => obj.completed,
    color: '#00B248',
  },
  {
    method: (obj: TypesExerciseStatus): number => obj.partlyDone,
    color: '#66FFA6',
  },
  {
    method: (obj: TypesExerciseStatus): number => obj.attempts,
    color: '#EEFF41',
  },
  {
    method: (obj: TypesExerciseStatus): number => obj.noAttempt,
    color: '#EEF1F8',
  },
];

export const setBarsData = (day: any, method: any, surgeryDay: any) => {
  return Object.keys(day).map((key) => {
    return day[key] && day[key].date === surgeryDay
      ? { x: key, y: method(day[key].status), stroke: 1 }
      : { x: key, y: method(day[key].status), stroke: 0 };
  });
};

// Get X axis labels for completion chart by exercises
const MyLabel = (props: any): JSX.Element => {
  const { containerWidth, tickCount, children, isallowed, windowClientWidth, today_t } = props;
  const notPortretScreen = windowClientWidth > MobilePortretScreenSize;
  const childrenToNumber = Number(children);
  const isToday = children === '30';
  const todayOffsetX = notPortretScreen ? '24px' : '18px';
  const todayOffsetY = notPortretScreen ? '5px' : '2px';
  if (isallowed) {
    return (
      <>
        {notPortretScreen ? (
          <foreignObject
            xmlns="http://www.w3.org/2000/svg"
            x={0}
            y={0}
            style={{
              width: `${containerWidth / tickCount}px`,
              height: '80px',
              transform: `translate(${-containerWidth / tickCount / 2}px, 1%)`,
            }}
          >
            <div
              style={{
                width: containerWidth / tickCount,
                height: '80px',
                paddingTop: '0',
                overflow: 'hidden',
                textAlign: 'center',
                wordWrap: 'break-word',
                textOverflow: 'ellipsis',
                lineHeight: '12px',
                fontSize: '12px',
                position: 'fixed',
              }}
            >
              {children}
            </div>
          </foreignObject>
        ) : (
          <text
            xmlns="http://www.w3.org/2000/svg"
            textAnchor="start"
            y={todayOffsetY}
            x="3px"
            style={{
              fill: '#9BA1AE',
              transform: 'rotate(90deg)',
              lineHeight: '10px',
              fontSize: '8px',
              fontWeight: 'normal',
              position: 'fixed',
            }}
          >
            {children}
          </text>
        )}
      </>
    );
  }
  // For 30 days period
  const getLabel = (): any => {
    const label = 30 - childrenToNumber;
    if (windowClientWidth <= 599 && label % 2 === 0) {
      return '';
    }
    return label;
  };

  return (
    <text
      textAnchor="middle"
      x={isToday ? todayOffsetX : '0'}
      y={isToday ? todayOffsetY : '18px'}
      style={{
        transform: isToday ? 'rotate(90deg)' : '0',
        fill: '#9BA1AE',
        fontWeight: notPortretScreen ? 'bold' : 'normal',
        fontSize: '12px',
      }}
    >
      {!isToday ? getLabel() : today_t}
    </text>
  );
};

export const myFormatter = (
  value: string,
  isallowed: boolean,
  windowClientWidth: number,
  today_t: any,
): ReactElement => {
  const normilizeValue = isallowed ? `${value.split('<id_>')[0]}.` : value;
  return (
    <MyLabel isallowed={isallowed} windowClientWidth={windowClientWidth} today_t={today_t}>
      {normilizeValue}
    </MyLabel>
  );
};

// Get xAxis Label for chart by periods
export const getDataLabel = (
  arr: any,
  isDay: boolean,
  isNoData: boolean,
  period: string,
  t: any,
  type: string,
  windowClientWidth: number,
): any => {
  const today_t = t?.dashboard.hcp.table.today;
  const monthAgo = t?.dashboard.hcp.profile_patient.completion_and_steps.one_month_ago;
  const weekAgo = t?.dashboard.hcp.profile_patient.completion_and_steps.one_week_ago;
  let dataLabel: TGetDataLabel[] = [];
  if (!isNoData) {
    dataLabel = arr.map((item: TGetSteps) => {
      let label: any = item.date && getTitle(item.date, period, isDay, t, windowClientWidth);
      const dataPosition = {
        y: type === PATIENT_PROFILE_TABS.PATIENT_OVERVIEW ? -6 : -4,
        rotation: 0,
      };
      // @ts-ignore
      if (isNaN(label)) {
        dataPosition.rotation = 90;
        switch (label) {
          case today_t:
            dataPosition.y = type === PATIENT_PROFILE_TABS.PATIENT_OVERVIEW ? -8 : -6;
            break;
          case weekAgo:
            dataPosition.y = -10;
            break;
          case monthAgo:
            dataPosition.y = -10;
            break;
          default:
            dataPosition.y = -3;
        }
      } else if (
        windowClientWidth <= 599 &&
        (period === '30' || period === '26' || period === '24')
      ) {
        label = label % 2 === 0 ? '' : label;
      }
      return {
        x: item.x,
        y: windowClientWidth <= 1000 ? dataPosition.y - 5 : dataPosition.y, // dataPosition.y,
        label,
        rotation: dataPosition.rotation,
      };
    });
  }
  return dataLabel;
};

// Get ticks interval for XAxis dor barchart
// export const getBarchartIntervalType = (
//   windowClientWidth: number, periodnumber: string,
// ): number => {
//   // Show all ticks
//   if (windowClientWidth > 599) {
//     return 0;
//   }
//   // Show half ticks
//   if (periodnumber === '30' || periodnumber === '26') {
//     return 1;
//   }
//   return 0;
// };

export const initialData = (type: string): any => {
  const dayCollection: any = [];
  const _initData: any = {};

  if (type === 'exercises chart') {
    _initData[''] = {
      status: {
        partlyDone: 0,
        completed: 0,
        noAttempt: 0,
        attempts: 0,
        isNull: 0,
      },
      tooltipStatus: {
        partlyDone: 0,
        completed: 0,
        noAttempt: 0,
        attempts: 0,
        isNull: 0,
      },
    };
  }
  if (type === 'periods chart') {
    _initData[''] = {
      x0: 0,
      x: 0,
      y: 0,
      color: 0,
      date: null,
      stroke: 0,
    };
  }

  dayCollection.push(_initData);
  return dayCollection;
};

export const fillCompletionAndStepsExercisesLegend = (t: any, type: string): any => {
  const completed = t?.dashboard.hcp.profile_patient.completion_and_steps.completed;
  const partly_done = t?.dashboard.hcp.profile_patient.completion_and_steps.partly_done;
  const attempts = t?.dashboard.hcp.profile_patient.completion_and_steps.attempts;
  const no_attempt = t?.dashboard.hcp.profile_patient.completion_and_steps.no_attempt;
  const surgery_day = t?.dashboard.hcp.profile_patient.completion_and_steps.surgery_day;
  const inactive = t?.dashboard.hcp.profile_patient.completion_and_steps.inactive;
  const step_count = t?.dashboard.hcp.profile_patient.completion_and_steps.step_count;

  const general = [
    { value: completed, color: '#00B248' },
    { value: partly_done, color: '#66FFA6' },
    { value: attempts, color: '#EEFF41' },
    { value: no_attempt, color: '#EEF1F8' },
  ];
  const exerciseChartLegend = [
    { value: inactive, color: '#D6EAF8' },
    { value: surgery_day, color: '#E2007A' },
  ];
  const periodChartLegend = [
    // { value: '', color: '' },
    { value: surgery_day, color: '#E2007A' },
    { value: step_count, color: '#F15B07' },
  ];

  if (type === PATIENT_PROFILE_TABS.BY_EXERCISES) {
    return [...general, ...exerciseChartLegend];
  }
  if (type === PATIENT_PROFILE_TABS.BY_PERIODS) {
    return [...general, ...periodChartLegend];
  }
  return [];
};

/* Questionnaires */

export const heightContainer = 455;
export const maxPcsValue = 52;
export const minPcsValue = 0;
export const oneValuePcsInPixel = 8.75;

export const maxPainDetectValue = 38;
export const minPainDetectValue = 0;

export const minEq5d5l = 0;
export const maxEq5d5l = 25;

export const minVasHealth = 0;
export const maxVasHealth = 100;

export const minOxford = 0;
export const maxOxford = 48;

export const minQuickDash = 0;
export const maxQuickDash = 100;

export const minQuickDash2 = 100;
export const maxQuickDash2 = 0;

export const minValue = 0;
export const minUnclear = 13;
export const minPositive = 19;

export const minMoxfq = 0;
export const maxMoxfq = 100;
export const minAofas = 0;
export const maxAofas = 100;
export const minOdi = 0;
export const maxOdi = 50;
export const minSf = 0;
export const maxSf = 100;

export const createBlocks = (itemsValue: number): Block[] => {
  const blocks: Block[] = [];
  let i = itemsValue;
  while (i > minValue) {
    const item = { color: '' };
    if (i < minUnclear) {
      item.color = '#D2EBFA';
    }
    if (i >= minUnclear && i < minPositive) {
      item.color = '#EEF1F8';
    }
    if (i >= minPositive && i <= itemsValue) {
      item.color = '#FACCE6';
    }
    blocks.push(item);
    i -= 1;
  }
  return blocks;
};

export const getCompletedText = (
  visibility: string,
  dateCompleted: string,
  notCompleted: string,
): string => {
  switch (visibility) {
    case statusQuestionnaries.COMPLETED:
      return dateCompleted;
    case statusQuestionnaries.SKIPPED:
      return `${notCompleted}:`;
    default:
      return '';
  }
};

export const chartTitleKeyQuestionnaries = {
  surgeryDate: 'SURGERY_DATE',
};

export const dataToGraph = (
  data: any,
  typeGraph: string,
  questionnairesList: QuestionnairesList[],
): any => {
  if (data?.length > 0 && questionnairesList?.length > 0) {
    const currentQuestionnaire = questionnairesList.filter(
      (questionnaire: QuestionnairesList) => questionnaire.name === typeGraph,
    )[0];
    for (const item of data) {
      if (item.questionnaireId === currentQuestionnaire.id) {
        return item;
      }
    }
  }
  return null;
};

export const getDataForGraph = (
  // t: any,
  data: any,
  // currentTabTitle: string,
  // questionnairesList: QuestionnairesList[],
  chartId: number | undefined,
): any => {
  const filterDataToGraphs = (array: TQuestionary[], criterion: number): any =>
    array.reduce((acc: TQuestionary[], item: any) => {
      if (item.questionnaireId === criterion) acc.push(item);
      return acc;
    }, []);

  if (data && chartId) {
    return filterDataToGraphs(data, chartId);
  }
  return null;
  // const eq_5d_5l = t?.dashboard.hcp.profile_patient.questionnaires.eq_5d_5l;
  // const oxford_shoulder = t?.dashboard.hcp.profile_patient.questionnaires.oxford_shoulder;
  // const oxford_hip = t?.dashboard.hcp.profile_patient.questionnaires.oxford_hip;
  // const oxford_knee = t?.dashboard.hcp.profile_patient.questionnaires.oxford_knee;
  // const quickDash = t?.dashboard.hcp.profile_patient.questionnaires.quick_dash;
  // const moxfq = t?.dashboard.hcp.profile_patient.questionnaires.moxfq;
  // const aofas = t?.dashboard.hcp.profile_patient.questionnaires.aofas;
  // const sf36 = t?.dashboard.hcp.profile_patient.questionnaires.sf;
  // const odi = t?.dashboard.hcp.profile_patient.questionnaires.odi;
  // const meps = t?.dashboard.hcp.profile_patient.questionnaires.mayo;
  // const prwe = t?.dashboard.hcp.profile_patient.questionnaires.prwe;

  // const getCurrentChartData = (questionnaireName: string): any => {
  //   // const currentQuestionnaire = questionnairesList.filter((
  //   //   questionnaire: QuestionnairesList,
  //   // ) => questionnaire.name === questionnaireName)[0];
  //   // if (currentQuestionnaire) {
  //   //   return filterDataToGraphs(data, currentQuestionnaire.id);
  //   // }
  //   if (chartId) {
  //     return filterDataToGraphs(data, chartId);
  //   }
  //   return [];
  // };

  // if (data && questionnairesList) {
  //   switch (currentTabTitle) {
  //     case eq_5d_5l:
  //       return getCurrentChartData(PROMS_NAME.EQ5D5L);
  //     case oxford_hip:
  //       return getCurrentChartData(PROMS_NAME.OxfordHipScore);
  //     case oxford_knee:
  //       return getCurrentChartData(PROMS_NAME.OxfordKneeScore);
  //     case oxford_shoulder:
  //       return getCurrentChartData(PROMS_NAME.OxfordShoulderScore);
  //     case moxfq:
  //       return getCurrentChartData(PROMS_NAME.MOXFQ);
  //     case quickDash:
  //       return getCurrentChartData(PROMS_NAME.QuickDash);
  //     case aofas:
  //       return getCurrentChartData(PROMS_NAME.AOFAS);
  //     case sf36:
  //       return getCurrentChartData(PROMS_NAME.SF36);
  //     case odi:
  //       return getCurrentChartData(PROMS_NAME.ODI);
  //     case meps:
  //       return getCurrentChartData(PROMS_NAME.MEPS);
  //     case prwe:
  //       return getCurrentChartData(PROMS_NAME.PRWE);
  //     default:
  //       return null;
  //   }
  // }
  // return null;
};

export const setCurrentTabTitle = (t: any, procedureKey: string, tab: number): string => {
  const eq_5d_5l = t && t.dashboard.hcp.profile_patient.questionnaires.eq_5d_5l;
  const oxford_knee =
    t && t.dashboard.hcp.profile_patient.questionnaires.oxford_knee?.toUpperCase();
  const oxford_hip = t && t.dashboard.hcp.profile_patient.questionnaires.oxford_hip?.toUpperCase();
  const oxford_shoulder =
    t && t.dashboard.hcp.profile_patient.questionnaires.oxford_shoulder?.toUpperCase();
  const quickDash = t && t.dashboard.hcp.profile_patient.questionnaires.quick_dash?.toUpperCase();
  const moxfq = t && t.dashboard.hcp.profile_patient.questionnaires.moxfq?.toUpperCase();
  const aofas = t && t.dashboard.hcp.profile_patient.questionnaires.aofas?.toUpperCase();
  const sf36 = t && t.dashboard.hcp.profile_patient.questionnaires.sf?.toUpperCase();
  const odi = t && t.dashboard.hcp.profile_patient.questionnaires.odi?.toUpperCase();

  if (tab === 0) {
    if (procedureKey === proceduresEnum.SPINAL) {
      return sf36;
    }
    return eq_5d_5l;
  }

  switch (procedureKey) {
    case proceduresEnum.KNEE:
      if (tab === 1) {
        return oxford_knee;
      }
      return '';
    case proceduresEnum.HIP:
      if (tab === 1) {
        return oxford_hip;
      }
      return '';
    case proceduresEnum.SHOULDER:
      if (tab === 1) {
        return oxford_shoulder;
      }
      if (tab === 2) {
        return quickDash;
      }
      return '';
    case proceduresEnum.FOOT_AND_ANKLE:
      if (tab === 1) {
        return aofas;
      }
      if (tab === 2) {
        return moxfq;
      }
      return '';
    case proceduresEnum.SPINAL:
      if (tab === 1) {
        return odi;
      }
      return '';
    default:
      return '';
  }
};

export const setOxfordTabTitle = (t: any, procedureKey: string): string => {
  const oxford_knee =
    t && t.dashboard.hcp.profile_patient.questionnaires.oxford_knee?.toUpperCase();
  const oxford_hip = t && t.dashboard.hcp.profile_patient.questionnaires.oxford_hip?.toUpperCase();
  const oxford_shoulder =
    t && t.dashboard.hcp.profile_patient.questionnaires.oxford_shoulder?.toUpperCase();
  const odi = t && t.dashboard.hcp.profile_patient.questionnaires.odi?.toUpperCase();

  switch (procedureKey) {
    case proceduresEnum.HIP:
      return oxford_hip;
    case proceduresEnum.KNEE:
      return oxford_knee;
    case proceduresEnum.SHOULDER:
      return oxford_shoulder;
    case proceduresEnum.SPINAL:
      return odi;
    default:
      return '';
  }
};

export const setDomain = (t: any, currentTabTitle: string): [number, number | string] => {
  const oxford_shoulder = t?.dashboard.hcp.profile_patient.questionnaires.oxford_shoulder;
  const oxford_hip = t?.dashboard.hcp.profile_patient.questionnaires.oxford_hip;
  const oxford_knee = t?.dashboard.hcp.profile_patient.questionnaires.oxford_knee;
  const moxfq = t?.dashboard.hcp.profile_patient.questionnaires.moxfq;
  const aofas = t?.dashboard.hcp.profile_patient.questionnaires.aofas;
  const odi = t?.dashboard.hcp.profile_patient.questionnaires.odi;
  const koos = t?.dashboard.hcp.profile_patient.questionnaires.koos;
  const hoos = t?.dashboard.hcp.profile_patient.questionnaires.hoos;

  switch (currentTabTitle) {
    case oxford_hip:
    case oxford_knee:
    case oxford_shoulder:
      return [minOxford, maxOxford];
    case moxfq:
    case koos:
    case hoos:
      return [minMoxfq, maxMoxfq];
    case aofas:
      return [minAofas, maxAofas];
    case odi:
      return [minOdi, maxOdi];
    default:
      return [0, 'auto'];
  }
};

export const getCurrentKeyOfProcedure = (
  currentProcedureId: number,
  keyProceduresList: ProcedureKey[],
): string => {
  const result = keyProceduresList.filter(
    (keyOfProcedure: ProcedureKey) => keyOfProcedure.id === currentProcedureId,
  )[0];
  if (result) {
    return result.key;
  }
  return '';
};

const createPdf = async ({
  doc,
  elements,
}: {
  doc: jsPDF;
  elements: HTMLCollectionOf<Element>;
}): Promise<void> => {
  const padding = 10;
  const marginTop = 20;
  let top = marginTop;

  async function* asyncGenerator() {
    let j = 0;
    while (j < elements.length) {
      yield (j += 1);
    }
  }
  // eslint-disable-next-line no-restricted-syntax
  for await (const j of asyncGenerator()) {
    const i = j - 1;
    const el = elements.item(i) as HTMLElement;
    try {
      const dataUrl = await htmlToImage.toPng(el, { skipAutoScale: true, quality: 0.95 });
      let elHeight = el.offsetHeight;
      let elWidth = el.offsetWidth;
      const pageWidth = doc.internal.pageSize.getWidth();
      if (elWidth > pageWidth) {
        const ratio = pageWidth / elWidth;
        elHeight = elHeight * ratio - padding * 2;
        elWidth = elWidth * ratio - padding * 2;
      }
      const pageHeight = doc.internal.pageSize.getHeight();

      if (top + elHeight > pageHeight && i !== 0) {
        doc.addPage();
        top = marginTop;
      }
      doc.addImage(dataUrl, 'PNG', padding, top, elWidth, elHeight, `image${i}`, 'SLOW');
      top += elHeight + marginTop;
    } catch (e) {
      console.error('error=', e);
    }
  }
};

export const exportToPdf = async (patient: any, classNameFromArea: string): Promise<void> => {
  // eslint-disable-next-line new-cap
  const doc = await new jsPDF('p', 'px');
  const elements = await document.getElementsByClassName(classNameFromArea);
  await createPdf({ doc, elements });
  // const el = document.getElementById('imgToPdf') as HTMLElement;
  // await doc.html(el, {
  //   callback: (doc: any) => {
  //     doc.save();
  //   },
  //   x: 10,
  //   y: 10,
  // });
  await doc.save(
    `OnTheMend_${patient.firstName[0]}_${patient.lastName}_${moment().format('DD.MM.YYYY')}.pdf`,
    { returnPromise: true },
  );
};

// EXPORT TABLE TO PDF
const createPdfTable = async ({
  doc,
  element,
  isMobile,
}: {
  doc: jsPDF;
  element: HTMLElement;
  isMobile: boolean;
}): Promise<void> => {
  const leftPadding = 10;
  const topPadding = 20;

  try {
    const dataUrl = await htmlToImage.toCanvas(element, { quality: 0.95 });
    const elWidth = element.offsetWidth;
    const elHeight = element.offsetHeight;
    let normalizeElWidth = elWidth;
    let normalizeElHeight = elHeight;
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();

    let ratio = 1;
    if (elWidth > pageWidth) {
      ratio = pageWidth / elWidth;
      normalizeElWidth = elWidth * ratio - leftPadding * 2;
      normalizeElHeight = elHeight * ratio - topPadding;
    }
    if (isMobile) {
      ratio = pageHeight / elHeight;
      normalizeElWidth = elWidth * ratio - leftPadding;
      normalizeElHeight = elHeight * ratio - topPadding;
    }

    const pages = Math.ceil(normalizeElHeight / pageHeight);
    const trHeight = 42;

    if (pages > 1) {
      for (let i = 0; i < pages; i += 1) {
        const srcImg = dataUrl;
        const sX = 0;
        const sY = (pageHeight / ratio - topPadding - trHeight) * i; // offset next page
        const sWidth = elWidth;
        const sHeight = pageHeight / ratio - topPadding - trHeight;
        const dX = 0;
        const dY = 0;
        const dWidth = elWidth;
        const dHeight = pageHeight / ratio - topPadding - trHeight;

        const onePageCanvas = document.createElement('canvas');
        onePageCanvas.setAttribute('width', `${elWidth}px`);
        onePageCanvas.setAttribute('height', `${elHeight}px`);
        const ctx = onePageCanvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);
        }
        const canvasDataURL = onePageCanvas.toDataURL('image/png', 1.0);

        // add another page
        if (i > 0) {
          doc.addPage();
        }
        //! now we declare that we're working on that page
        doc.setPage(i + 1);
        //! now we add content to that page!
        doc.addImage(
          canvasDataURL,
          'PNG',
          leftPadding,
          topPadding,
          normalizeElWidth,
          normalizeElHeight,
          `image${i}`,
          'SLOW',
        );
      }
    } else {
      doc.addImage(
        dataUrl,
        'PNG',
        leftPadding,
        topPadding,
        normalizeElWidth,
        normalizeElHeight,
        'image_1',
        'SLOW',
      );
    }
  } catch (e) {
    console.error('error=', e);
  }
};

export const exportToPdfTable = async (
  patient: any,
  id: string,
  orientation: 'l' | 'p',
  isMobile: boolean,
): Promise<void> => {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF({
    orientation,
    unit: 'px',
    format: 'a4',
  });
  const element: HTMLElement | null = await document.getElementById(id);
  if (element) {
    const tableHtml: HTMLElement | null = await element.querySelector('table');
    if (tableHtml) {
      await createPdfTable({ doc, element: tableHtml, isMobile });
    }
  }
  await doc.save(
    `OnTheMend_${patient.firstName[0]}_${patient.lastName}_${moment().format('DD.MM.YYYY')}.pdf`,
    { returnPromise: true },
  );
};

export const statusConfig = (t: any): CSVConfig[] => {
  const completed_t = t?.dashboard.hcp.profile_patient.completion_and_steps.completed;
  const partly_done_t = t?.dashboard.hcp.profile_patient.completion_and_steps.partly_done;
  const attempts_t = t?.dashboard.hcp.profile_patient.completion_and_steps.attempts;
  const no_attempt_t = t?.dashboard.hcp.profile_patient.completion_and_steps.no_attempt;
  const inactive_t = t?.dashboard.hcp.profile_patient.completion_and_steps.inactive;

  return [
    { id: 'completed', value: completed_t },
    { id: 'partlyDone', value: partly_done_t },
    { id: 'attempts', value: attempts_t },
    { id: 'noAttempt', value: no_attempt_t },
    { id: 'isNull', value: inactive_t },
  ];
};

// export const painConfig = (t: any, procedureKey: string): {id: string; value: string}[] => {
//   const day = t?.dashboard.hcp.profile_patient.completion_and_steps.day;
//   const motivation_level = t?.dashboard.hcp.profile_patient.completion_and_steps
//     .daily_motivation_level;
//   const pain_score_completed = t?.dashboard.hcp.profile_patient.completion_and_steps.average_pain;
//   const pain_score_skip_top = t?.dashboard.hcp.profile_patient.completion_and_steps.skip_stop_value;
//   const av_back_pain_score_completed = t?.dashboard.hcp.profile_patient.completion_and_steps.av_back_pain_score_completed;
//   const av_back_pain_score_skip_stop = t?.dashboard.hcp.profile_patient.completion_and_steps.av_back_pain_score_skip_stop;
//   const av_leg_pain_score_completed = t?.dashboard.hcp.profile_patient.completion_and_steps.av_leg_pain_score_completed;
//   const av_leg_pain_score_skip_stop = t?.dashboard.hcp.profile_patient.completion_and_steps.av_leg_pain_score_skip_stop;

//   const config = [
//     { id: 'name', value: day },
//     { id: 'motivation', value: motivation_level },
//   ];

//   switch (procedureKey) {
//     case proceduresEnum.SPINAL: {
//       const spinalConf = [
//         { id: 'painScoreBackCompleted', value: av_back_pain_score_completed },
//         { id: 'painScoreBackSkipStop', value: av_back_pain_score_skip_stop },
//         { id: 'painScoreLegCompleted', value: av_leg_pain_score_completed },
//         { id: 'painScoreLegSkipStop', value: av_leg_pain_score_skip_stop },

//       ];
//       return [...config, ...spinalConf];
//     }
//     default: {
//       const defaultConfig = [
//         { id: 'painScoreCompleted', value: pain_score_completed },
//         { id: 'painScoreSkipStop', value: pain_score_skip_top },
//       ];
//       return [...config, ...defaultConfig];
//     }
//   }
// };

export const skipStopConfig = (t: any): CSVConfig[] => {
  const number_of_exercises_skipped =
    t?.dashboard.hcp.profile_patient.completion_and_steps.number_of_exercises_skipped;
  const number_of_exercises_stopped =
    t?.dashboard.hcp.profile_patient.completion_and_steps.number_of_exercises_stopped;
  const number_of_reps = t?.dashboard.hcp.profile_patient.completion_and_steps.number_of_reps;
  const day = t?.dashboard.hcp.profile_patient.completion_and_steps.day;

  return [
    { id: 'name', value: day },
    { id: 'numberOfExercisesSkipped', value: number_of_exercises_skipped },
    { id: 'numberOfExercisesStopped', value: number_of_exercises_stopped },
    { id: 'AvNumberOfRepsForStoppedExercises', value: number_of_reps },
  ];
};

export const handlingChartData = (
  config: CSVConfig[],
  currentPatient: any,
  dataArray: any,
  periodLabel: string,
  t: any,
): string => {
  const last_name = t?.dashboard.hcp.table.last_name;
  const first_name = t?.dashboard.hcp.table.first_name;
  const nhs_number = t?.dashboard.hcp.table.nhs_number;

  let csvContent = `data:text/csv;charset=utf-8,${first_name},${
    currentPatient.firstName
  },\r\n${last_name},${currentPatient.lastName},\r\n${nhs_number},${
    currentPatient.nhsNumber ? String(currentPatient.nhsNumber) : ''
  },\r\n`;
  config.map((item: CSVConfig): null => {
    if (item.id === 'name') {
      csvContent += `,${periodLabel}`;
    } else {
      csvContent += `,${item.value}`;
    }
    dataArray.forEach((rowArray: any) => {
      csvContent += `,${rowArray[item.id]}`;
    });
    csvContent += '\r\n';
    return null;
  });
  return csvContent;
};

export const handlingStepsChartData = (
  currentPatient: any,
  steps: any,
  periodLabel: string,
  t: any,
): { csvExercisesContent: any; csvStepContent: any } => {
  const today_t = t?.dashboard.hcp.table.today;
  const day = t?.dashboard.hcp.profile_patient.completion_and_steps.day;
  const week = t && t.dashboard.hcp.profile_patient.completion_and_steps.week;
  const month = t && t.dashboard.hcp.profile_patient.completion_and_steps.month;
  const step_count = t?.dashboard.hcp.profile_patient.completion_and_steps.step_count;
  const last_name = t?.dashboard.hcp.table.last_name;
  const first_name = t?.dashboard.hcp.table.first_name;
  const nhs_number = t?.dashboard.hcp.table.nhs_number;

  let csvStepContent = `data:text/csv;charset=utf-8,${first_name},${
    currentPatient.firstName
  },\r\n${last_name},${currentPatient.lastName},\r\n${nhs_number},${
    currentPatient.nhsNumber ? String(currentPatient.nhsNumber) : ''
  },\r\nSurgery Date,${
    currentPatient.procedure && currentPatient.procedure.date
      ? moment(currentPatient.procedure.date).format('D/M/YYYY')
      : ''
  },\r\n`;
  csvStepContent += `,${periodLabel},`;
  let lengthStepsArr = steps?.length > 0 ? steps.length - 1 : 0;
  if (steps && steps.length) {
    steps.map(() => {
      if (lengthStepsArr === 0) {
        csvStepContent += periodLabel === day ? `${today_t},` : `1 ${periodLabel} ago,`;
      } else {
        csvStepContent += `${lengthStepsArr},`;
      }
      lengthStepsArr -= 1;
      return null;
    });
  }
  const csvExercisesContent = csvStepContent;
  csvStepContent += `\r\n,${step_count},`;
  steps.map((stepToCSV: any) => {
    csvStepContent += `${stepToCSV.steps},`;
    return null;
  });
  return { csvExercisesContent, csvStepContent };
};

export const getTableHeader = (period: string): string => {
  let temp = ', Day,';
  if (period === '7') {
    for (let i = +period + 1; i > 1; i -= 1) {
      temp += `${i},`;
    }
  }
  if (period === '14' || period === '30') {
    for (let i = +period; i > 1; i -= 1) {
      temp += `${i},`;
    }
  }
  temp += 'TODAY, \r\n';
  return temp;
};

export const downloadFile = (
  content: string,
  patient: { firstName: string; lastName: string },
  fileName: string,
  typeFile: string,
): void => {
  const encodedUri = encodeURI(content);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute(
    'download',
    `OnTheMend_${patient.firstName[0]}_${patient.lastName}_${fileName}.${typeFile}`,
  );
  document.body.appendChild(link);
  link.click();
};

export const setSizesFromwindowClientWidth = (
  windowClientWidth: number,
  selectedPeriod?: string,
) => {
  const chartStyle = {
    XYPlotLeftMargin: 85,
    XYPlotRightMargin: 10,
    XYPlotRightMargin_byPeriods: 45,
    AxisFontSize: '14px',
    YAxisTickPadding: 2,
  };

  if (windowClientWidth > 1190) {
    return chartStyle;
  }

  chartStyle.YAxisTickPadding = 0;

  if (windowClientWidth > 950) {
    chartStyle.XYPlotLeftMargin = 45;
    return chartStyle;
  }

  chartStyle.XYPlotRightMargin_byPeriods = 35;
  chartStyle.XYPlotLeftMargin = 40;
  chartStyle.AxisFontSize = '12px';

  if (windowClientWidth > 920) {
    return chartStyle;
  }

  chartStyle.AxisFontSize = selectedPeriod === '30' ? '8px' : '10px';

  if (windowClientWidth > 450) {
    return chartStyle;
  }

  chartStyle.XYPlotRightMargin_byPeriods = 10;

  return chartStyle;
};

export const setBarchartStyle = (windowClientWidth: number, selectedPeriod?: string) => {
  const chartStyleSet = {
    marginTop: 50,
    marginRight: 30,
    marginLeft: 15,
    marginBottom: 20,
    AxisFontSize: '16px',
  };
  if (windowClientWidth > 1050) {
    return chartStyleSet;
  }
  chartStyleSet.marginLeft = 10;

  if (windowClientWidth > 950) {
    chartStyleSet.AxisFontSize = '14px';
    return chartStyleSet;
  }
  if (windowClientWidth > 450) {
    chartStyleSet.marginLeft = 0;
    chartStyleSet.AxisFontSize = '12px';
    return chartStyleSet;
  }
  chartStyleSet.AxisFontSize = selectedPeriod === '30' ? '8px' : '10px';
  chartStyleSet.marginTop = 20;
  chartStyleSet.marginLeft = -20;
  chartStyleSet.marginRight = 10;
  chartStyleSet.marginBottom = 5;

  return chartStyleSet;
};

export const getPatientAnswers = (completedQuestions: CompletedQuestions[]): PatientAnswers => {
  const answerResult: any = {};
  completedQuestions.map((answ: CompletedQuestions) => {
    answerResult[answ.questionId] = JSON.parse(answ.answer);
    return null;
  });
  return answerResult;
};
export const checkIf = (
  thumbnail: string,
  video: string,
  isUploadProcess: boolean,
  isUploadingToAWS: boolean,
): boolean => {
  if (isUploadProcess) {
    return true;
  }
  if (thumbnail && video) {
    return true;
  }
  if (!isUploadingToAWS) {
    return true;
  }
  return false;
};
export const getTooltip = (
  thumbnail: string,
  video: string,
  isUploadProcess: boolean,
  isUploadingToAWS: boolean,
  t: any,
  photos: boolean,
): string => {
  const loadingErrorMsg = t?.notifications.loading_error;
  const uploading = t?.common.uploading;

  if (isUploadProcess) {
    return '';
  }
  if (thumbnail && video) {
    return '';
  }
  if (photos) {
    return '';
  }
  if (isUploadingToAWS) {
    return `${uploading}...`;
  }
  return loadingErrorMsg;
};

export const setDescriptionForCompletionChart = (
  selectedPeriod: string,
  typeChart: string,
  t: any,
): string => {
  const about_columns_info_7_days =
    t?.dashboard.hcp.profile_patient.completion_and_steps.about_columns_info_7_days;
  const about_columns_info_14_days =
    t?.dashboard.hcp.profile_patient.completion_and_steps.about_columns_info_14_days;
  const about_columns_info_exercise =
    t?.dashboard.hcp.profile_patient.completion_and_steps.about_columns_info_exercise;
  const about_columns_info_completion_7_days =
    t?.dashboard.hcp.profile_patient.completion_and_steps.about_columns_info_completion_7_days;
  const about_columns_info_completion_14_days =
    t?.dashboard.hcp.profile_patient.completion_and_steps.about_columns_info_completion_14_days;
  const about_columns_info_completion_30_days =
    t?.dashboard.hcp.profile_patient.completion_and_steps.about_columns_info_completion_30_days;
  const about_columns_info_completion_6_weeks =
    t?.dashboard.hcp.profile_patient.completion_and_steps.about_columns_info_completion_6_weeks;
  const about_columns_info_completion_13_weeks =
    t?.dashboard.hcp.profile_patient.completion_and_steps.about_columns_info_completion_13_weeks;
  const about_columns_info_completion_26_weeks =
    t?.dashboard.hcp.profile_patient.completion_and_steps.about_columns_info_completion_26_weeks;
  const about_columns_info_completion_12_months =
    t?.dashboard.hcp.profile_patient.completion_and_steps.about_columns_info_completion_12_months;
  const about_columns_info_completion_24_months =
    t?.dashboard.hcp.profile_patient.completion_and_steps.about_columns_info_completion_24_months;

  if (typeChart === PATIENT_PROFILE_TABS.BY_EXERCISES) {
    switch (selectedPeriod) {
      case '7':
        return about_columns_info_7_days;
      case '14':
        return about_columns_info_14_days;
      case '30':
        return about_columns_info_exercise;
      default:
        return '';
    }
  }
  if (typeChart === PATIENT_PROFILE_TABS.BY_PERIODS) {
    switch (selectedPeriod) {
      case '7':
        return about_columns_info_completion_7_days;
      case '14':
        return about_columns_info_completion_14_days;
      case '30':
        return about_columns_info_completion_30_days;
      case '6':
        return about_columns_info_completion_6_weeks;
      case '13':
        return about_columns_info_completion_13_weeks;
      case '26':
        return about_columns_info_completion_26_weeks;
      case '12':
        return about_columns_info_completion_12_months;
      case '24':
        return about_columns_info_completion_24_months;
      default:
        return '';
    }
  }
  return '';
};

export const getPromsId = (allProms: QuestionnairesList[]) => {
  const obj: any = {};
  allProms.map((prom: QuestionnairesList) => {
    obj[prom.name] = prom.id;
    return null;
  });
  const aofasId = obj[PROMS_NAME.AOFAS];
  const eq5d5lId = obj[PROMS_NAME.EQ5D5L];
  const moxfqId = obj[PROMS_NAME.MOXFQ];
  const odiId = obj[PROMS_NAME.ODI];
  const oxfordHipId = obj[PROMS_NAME.OxfordHipScore];
  const oxfordKneesId = obj[PROMS_NAME.OxfordKneeScore];
  const oxfordShoulderId = obj[PROMS_NAME.OxfordShoulderScore];
  const pcsId = obj[PROMS_NAME.PCS];
  const painDetectId = obj[PROMS_NAME.PainDetect];
  const quickDashId = obj[PROMS_NAME.QuickDash];
  const sfId = obj[PROMS_NAME.SF36];
  const mepsId = obj[PROMS_NAME.MEPS];
  const prweId = obj[PROMS_NAME.PRWE];
  const hoosId = obj[PROMS_NAME.HOOS];
  const koosId = obj[PROMS_NAME.KOOS];

  return {
    aofasId,
    eq5d5lId,
    moxfqId,
    odiId,
    oxfordHipId,
    oxfordKneesId,
    oxfordShoulderId,
    pcsId,
    painDetectId,
    quickDashId,
    sfId,
    mepsId,
    prweId,
    hoosId,
    koosId,
  };
};

// Redesign

export const tabsConfig = (t: any) => [
  {
    icon: <ExerciseCompletionIcon />,
    label: t?.hcp.manage.exercise_completion,
    key: CHART_TABS.exercise_completion,
  },
  {
    icon: <PainIcon />,
    label: t?.dashboard.hcp.profile_patient.questionnaires.pain,
    key: CHART_TABS.pain,
  },
  {
    icon: <MotivationIcon />,
    label: t?.dashboard.hcp.profile_patient.tabs.motivation,
    key: CHART_TABS.motivation,
  },
  {
    icon: <StepIcon />,
    label: t?.dashboard.hcp.profile_patient.completion_and_steps.step_count,
    key: CHART_TABS.step_count,
  },
  {
    icon: <HeartRateIcon />,
    label: t?.dashboard.hcp.profile_patient.tabs.heart_rate || 'Heart rate',
    key: CHART_TABS.heart_rate,
  },
  {
    icon: <SleepIcon />,
    label: t?.dashboard.hcp.profile_patient.tabs.sleep || 'Sleep',
    key: CHART_TABS.sleep,
  },
  {
    icon: <PromsIcon />,
    label: t?.dashboard.hcp.profile_patient.tabs.details_questionnaires,
    key: CHART_TABS.proms,
  },
];

export const EmojiYAxisTick = ({ x, y, payload, type }: any) => {
  const calcVal = type === CHART_TABS.pain ? 10 - payload.value : payload.value;

  switch (calcVal) {
    case 0:
      return (
        <g transform={`translate(${x - 10},${y - 8})`}>
          <UnableMove width={16} height={16} />
        </g>
      );
    case 1:
      return (
        <g transform={`translate(${x - 10},${y - 8})`}>
          <NineSevere width={16} height={16} />
        </g>
      );
    case 2:
      return (
        <g transform={`translate(${x - 10},${y - 8})`}>
          <Severe width={16} height={16} />
        </g>
      );
    case 3:
      return (
        <g transform={`translate(${x - 10},${y - 8})`}>
          <SevenSevere width={16} height={16} />
        </g>
      );
    case 4:
      return (
        <g transform={`translate(${x - 10},${y - 8})`}>
          <FocusOfAttention width={16} height={16} />
        </g>
      );
    case 5:
      return (
        <g transform={`translate(${x - 10},${y - 8})`}>
          <HardToIgnore width={16} height={16} />
        </g>
      );
    case 6:
      return (
        <g transform={`translate(${x - 10},${y - 8})`}>
          <Moderate width={16} height={16} />
        </g>
      );
    case 7:
      return (
        <g transform={`translate(${x - 10},${y - 8})`}>
          <Unfomfortable width={16} height={16} />
        </g>
      );
    case 8:
      return (
        <g transform={`translate(${x - 10},${y - 8})`}>
          <Noticeable width={16} height={16} />
        </g>
      );
    case 9:
      return (
        <g transform={`translate(${x - 10},${y - 8})`}>
          <HardlyNoticeable width={16} height={16} />
        </g>
      );
    case 10:
      return (
        <g transform={`translate(${x - 10},${y - 8})`}>
          <PainFree width={16} height={16} />
        </g>
      );
    default:
      return <></>;
  }
};

export const SleepYAxisTick = ({ x, y, payload }: any): JSX.Element => {
  const val = payload.value;
  const timeInHoursAndMinutes = (min: number): string => {
    const absMin = Math.abs(min);
    let hours: string | number = Math.floor(absMin / 60);
    let minutes: string | number = Math.floor(absMin - hours * 60);
    if (val < 0) {
      hours = 24 - hours;
      if (minutes) {
        hours -= 1;
        minutes = 60 - minutes;
      }
    }
    if (hours < 10 || hours === 0) {
      hours = `0${hours}`;
    }
    if (hours === 24) {
      hours = '00';
    }
    if (minutes < 10 || minutes === 0) {
      minutes = `0${minutes}`;
    }
    return `${hours}:${minutes}`;
  };

  return (
    <text fontSize="12px" transform={`translate(${x - 25},${y + 6})`}>
      {timeInHoursAndMinutes(val)}
    </text>
  );
};

export const calculateForCount = (data: any, count: any, selector: string) => {
  if (!data?.length) return [];
  const splitterCount = Math.ceil(data.length / count);
  let currentCount = 0;
  const res: any = [[]];
  for (let i = data.length - 1; i >= 0; i -= 1) {
    if (currentCount >= splitterCount) {
      res.unshift([]);
      currentCount = 0;
    }
    if (data[i] || data[i] === 0) res[0].push(data[i][selector]);
    currentCount += 1;
  }
  const div = count - res.length;
  if (div > 0) {
    for (let i = 0; i < div; i += 1) {
      res.unshift([0]);
    }
  }
  return res.map((item: any) => {
    const n = item.reduce((acc: any, value: any) => acc + value, 0) / item.length;
    return Math.ceil(n);
  });
};
