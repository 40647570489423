import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Pagination } from 'antd';

import { Button, Loading } from '../../common';
import styles from './styles.module.css';
import '../../theme/pagination.css';
import { getCurrenLang } from '../../redux/selector';
import PatientInvitation from './components/patientInvitation';
import { PAGE_SIZE_OPTIONS_FOR_PENDING_PATIENT_INVITATIONS } from '../../utils/variables';
import { PandingPatientInvitationsProp } from './types';
import { TSortdirectionProps } from '../../utils/model';
import { storage } from '../../utils';
import { useAcceptPendingPatientInvitations } from '../../graphql/invite';
import SearchClient from '../Search/SearchClient';
import TableHeader from './components/TableHeader';
import { initialPage } from '../../utils/helper';
import { WarnNotifModal } from '../../common/NotificationModal';

const PandingPatientInvitations = ({
  isModal = false,
  patientsInvitations,
  _getPatientInvitations,
  total,
  listLoading,
}: PandingPatientInvitationsProp): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const ok = t?.common.ok;
  const cancel = t?.common.cancel;
  const pending_patient_invitations = t?.menu.pending_patient_invitations;
  const please_review = t?.notifications.please_review;
  const accept_all = t?.notifications.accept_all;
  const decline_all = t?.notifications.decline_all;
  const no_invitations = t?.notifications.no_invitations;
  const search_patient = t?.invite_patient.search_patient;
  const decline_warn_one =
    t?.notifications.decline_warn_one || 'Are you sure you want to decline this invitation?';
  const decline_warn_two =
    t?.notifications.decline_warn_two ||
    'The patient will be notified in the Notification in the app.';

  const showModalDate = storage.get('show-modal-date');
  const { patientsIds, today } = showModalDate;

  // Local state
  const [filter, setFilter] = useState<string>('');
  const [filterQuery, setFilterQuery] = useState<any>(null);
  const [sortBy, setSortby] = useState<string>('');
  const [sortDirectionName, setSortDirectionName] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(isModal ? 5 : 15);
  const [patientIdsAction, setPatientIdsAction] = useState<any>([]);
  const [isWarningModal, setWarningModal] = useState<any>();

  const {
    _acceptPendingPatientInvitations,
    solutionLoading,
    solutionSaved,
  } = useAcceptPendingPatientInvitations();

  useEffect(() => {
    if (!isModal) {
      const listProps: TSortdirectionProps = {
        page: initialPage,
        itemsPerPage: pageSize,
      };
      if (sortDirectionName) {
        listProps.sortBy = sortBy;
        listProps.sortDirection = sortDirectionName;
      }
      _getPatientInvitations({
        variables: {
          listProps,
          searchProps: filterQuery || [
            {
              searchField: '',
              searchValue: '',
            },
          ],
          pending: true,
          searchByDate: [],
        },
      });
    }
  }, []);

  useEffect(() => {
    if (solutionSaved) {
      // Delete id from local storage
      if (patientsIds?.length > 0) {
        const newPatientsIds = [...patientsIds];
        patientIdsAction.map((id: number) => {
          const position = newPatientsIds.indexOf(id);
          if (position >= 0) {
            newPatientsIds.splice(position, 1);
          }
        });
        storage.save('show-modal-date', {
          today,
          patientsIds: newPatientsIds,
        });
      }
      const listProps: TSortdirectionProps = {
        page: initialPage,
        itemsPerPage: pageSize,
      };
      if (sortDirectionName) {
        listProps.sortBy = sortBy;
        listProps.sortDirection = sortDirectionName;
      }

      // Update List
      _getPatientInvitations({
        variables: {
          listProps,
          searchProps: filterQuery || [
            {
              searchField: '',
              searchValue: '',
            },
          ],
          pending: true,
          searchByDate: [],
        },
      });
      setWarningModal(() => undefined);
    }
  }, [solutionSaved]);

  const loadingJSX = (listLoading || solutionLoading) && <Loading />;

  const acceptHandler = (type: string, patientId?: number): void => {
    let solution = type;
    const patientIds: any = [];
    if (solution === 'acceptAll' || solution === 'declineAll') {
      patientsInvitations.map((patient: { id: number }) => {
        patientIds.push(patient.id);
        return null;
      });
      solution = solution === 'acceptAll' ? 'accept' : 'decline';
    } else {
      patientIds.push(patientId);
    }
    if (solution === 'decline') {
      const payload = { solution, patientIds };
      setWarningModal(payload);
      return;
    }
    setPatientIdsAction(patientIds);
    _acceptPendingPatientInvitations(solution, patientIds);
  };

  // confirm Action - send data to data base
  const confirmAction = (): void => {
    const { solution, patientIds } = isWarningModal;
    setPatientIdsAction(patientIds);
    _acceptPendingPatientInvitations(solution, patientIds);
  };

  // Close modal window
  const closeWarning = (): void => {
    setWarningModal(() => undefined);
  };

  // Sorting
  const sortByColumnName = (name: string, sortdirection: string): void => {
    setSortby(name);
    setSortDirectionName(sortdirection);
    const listProps: TSortdirectionProps = {
      page: currentPage,
      itemsPerPage: pageSize,
      sortBy: name,
      sortDirection: sortdirection,
    };
    _getPatientInvitations({
      variables: {
        listProps: {
          ...listProps,
        },
        searchProps: filterQuery || [
          {
            searchField: '',
            searchValue: '',
          },
        ],
        pending: true,
        searchByDate: [],
      },
    });
  };

  const onChangePagination = (page: any, itemsPerPage: any): void => {
    setPageSize(itemsPerPage);
    setCurrentPage(page);
    const listProps: TSortdirectionProps = {
      page,
      itemsPerPage,
    };
    if (sortDirectionName) {
      listProps.sortBy = sortBy;
      listProps.sortDirection = sortDirectionName;
    }
    _getPatientInvitations({
      variables: {
        listProps: {
          ...listProps,
        },
        searchProps: filterQuery || [
          {
            searchField: '',
            searchValue: '',
          },
        ],
        pending: true,
        searchByDate: [],
      },
    });
  };

  // Search value
  useEffect(() => {
    setCurrentPage(initialPage);
    const listProps: TSortdirectionProps = {
      page: initialPage,
      itemsPerPage: pageSize,
    };
    if (sortDirectionName) {
      listProps.sortBy = sortBy;
      listProps.sortDirection = sortDirectionName;
    }
    _getPatientInvitations({
      variables: {
        listProps: {
          ...listProps,
        },
        searchProps: filterQuery || [
          {
            searchField: '',
            searchValue: '',
          },
        ],
        pending: true,
        searchByDate: [],
      },
    });
  }, [filterQuery]);

  // Search patient or HCP
  const changeFilter = ({ target: { value } }: any): void => {
    setFilter(value);
    if (value.length >= 2) {
      setFilterQuery([
        { searchField: 'patient.firstName', searchValue: value },
        { searchField: 'patient.lastName', searchValue: value },
      ]);
    } else {
      setFilterQuery(null);
    }
  };

  const actionBtns = (
    <div
      className={cx({
        [styles['btns-container']]: true,
        [styles['btns-container--full-screen']]: !isModal,
        [styles['btns-container--modal']]: isModal,
      })}
    >
      <Button
        buttonName={decline_all}
        buttonType="button"
        buttonClass={styles.btn__all}
        buttonMethod={(): void => acceptHandler('declineAll')}
        disabledButton={!!loadingJSX || !patientsInvitations?.length}
        colorStyle="orange"
      />
      <Button
        buttonName={accept_all}
        buttonType="button"
        buttonClass={styles.btn__all}
        buttonMethod={(): void => acceptHandler('acceptAll')}
        disabledButton={!!loadingJSX || !patientsInvitations?.length}
        colorStyle="green"
      />
    </div>
  );
  const noData = !patientsInvitations?.length && !listLoading && (
    <div
      className={cx({
        [styles.noData]: true,
        [styles['noData--modal']]: isModal,
      })}
    >
      {no_invitations}
    </div>
  );

  const warnContent = (
    <>
      <div className={styles['modal-description']}>{decline_warn_one}</div>
      <div className={styles['modal-description']}>{decline_warn_two}</div>
    </>
  );

  return (
    <div className={styles['patients-invitations__container']}>
      {loadingJSX}
      {isModal && (
        <>
          <div className={styles.title}>{pending_patient_invitations}</div>
          <div className={styles['sub-title']}>{please_review}</div>
        </>
      )}
      {!isModal && (
        <div className={styles['search-container']}>
          <SearchClient
            inputId="patientsSearch"
            name="filter"
            placeholder={search_patient}
            inputValue={filter}
            changeFilter={changeFilter}
          />
          <div className={styles['patients-invitations__btns-wrapper--bg']}>{actionBtns}</div>
        </div>
      )}

      {!isModal && <TableHeader sortByColumnName={sortByColumnName} sortBy={sortBy} />}

      <div className={styles.table}>
        {noData}
        {patientsInvitations &&
          patientsInvitations.map((invitation: any, i: number) => (
            <PatientInvitation
              key={`PatientInvitation${String(i)}`}
              invitation={invitation}
              isModal={isModal}
              acceptHandler={acceptHandler}
              loading={!!loadingJSX}
            />
          ))}
      </div>
      {isModal && actionBtns}
      {!isModal && (
        <div className={styles['patients-invitations__btns-wrapper--sm']}>{actionBtns}</div>
      )}
      {!noData && (
        <div className="wrapper_pagination">
          <Pagination
            size="small"
            current={currentPage}
            pageSize={pageSize}
            total={total}
            onChange={onChangePagination}
            pageSizeOptions={PAGE_SIZE_OPTIONS_FOR_PENDING_PATIENT_INVITATIONS}
            showSizeChanger
          />
        </div>
      )}

      {/* Popups */}
      {isWarningModal && (
        <WarnNotifModal
          onClose={closeWarning}
          content={warnContent}
          cancelBtnName={cancel}
          actionBtnName={ok}
          actionMethod={confirmAction}
        />
      )}
    </div>
  );
};

export default PandingPatientInvitations;
