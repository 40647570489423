import React from 'react';
import moment from 'moment';
// @ts-ignore
import gbLocale from 'moment/locale/en-gb'; // (United Kingdom)
// @ts-ignore
import usLocale from 'moment/locale/es-us'; // (United States)
// @ts-ignore
import deLocale from 'moment/locale/de'; // German
// @ts-ignore
import esLocale from 'moment/locale/es'; // Spanish
import queryString from 'query-string';
import { toast } from 'react-toastify';
import axios from 'axios';

import {
  COLOR_OPTIONS_VIDEOBANK,
  dayInMilliseconds,
  getColorOptionsTable,
  numberYTicksForPainChart,
  dashFormatDate,
  dotFormatDate,
  formatDateEU,
  formatDateUS,
  slashFormatDate,
} from './variables';
import {
  ProcedureFilterOptions,
  Options,
  TStatusOptions,
  StatusFilterOptions,
  ExerciseFileType,
  ProcedureKey,
  TTickProps,
  TDataArray,
} from './model';
import { THospitalName, Procedure, Profession } from '../components/invite/types';
import { TabPosition, TabsItem } from '../common/Tabs/types';
import {
  NestedTableData,
  NestedTableRecord,
  Pagination,
  RewardsDateConfig,
  SearchByDate,
} from '../components/Table/types';
import {
  EMAIL_NOTIF_TYPE,
  EXERCISE_TYPE,
  LANG,
  LIST_TYPE,
  proceduresEnum,
  ROLE,
  SCHEDULE_UNIT,
  SORT_DIRECTION,
  STATUS,
  StatusKeyNameEnum,
  UserCountryEnum,
  userStatusColor,
  VIDEO_BANK_TYPE,
} from './enums';
import { CurrentFormatDates } from '../redux/common';
import { cellPhoneNumberDE, cellPhoneNumberGB, cellPhoneNumberUS } from './regx';
import { PatientRewards } from '../components/PersonalInformation/types';
import { calcSurgeryDays } from './helper_charts';
import { config } from './configs';
import { ExercisePhotos } from '../components/Video/VideoExercise/types';
import { getMailingFrequencyOptions } from './share/options';
import { HcpList } from '../components/HcpList/types';
import { PatientTeams } from '../pages/Hcp/RehabTeams/types';
import {
  TExerciseCategories,
  TFocusesProcedure,
} from '../components/Video/OrganisationVideoBank/types';
import { SectionTexts, HcpAllEmailNotifType } from '../components/EmailNotifications/types';

// Deep copy obj
export const deepCopyObj = (obj: any): any => JSON.parse(JSON.stringify(obj));

// Portal
export const loaderElement: any = document.getElementById('spinner');
export const modalElement: any = document.querySelector('#modal');

export const filtered = (array: any, filter: string) =>
  array.filter(
    (a: any) =>
      a.name.toLowerCase().includes(filter.toLowerCase()) ||
      a.email.toLowerCase().includes(filter.toLowerCase()),
  );

export const splitString = (stringToSplit: any, separator: any) => {
  const arrayOfStrings = stringToSplit.split(separator);
  const num = arrayOfStrings[0];
  const sub = arrayOfStrings[1];
  return { num, sub };
};

export const delay = (timeout: number) =>
  new Promise((res, rej) => {
    setTimeout(() => {
      res({ message: false });
    }, timeout);
  });

// for the validation of surgery
export const minDate = moment().subtract(1, 'y').valueOf();
export const maxDate = moment().add(1, 'y').valueOf();

// for validation of birthday
export const minYear = moment().subtract(120, 'y').valueOf();

// Month list for date picker
export const listOfMonths = (lang: string) =>
  Array.apply(0, Array(12)).map((_, i) => {
    switch (lang) {
      case LANG.EN_US:
        moment.locale('en-us', [usLocale]);
        break;
      case LANG.DE:
        moment.locale('de', [deLocale]);
        break;
      default:
        moment.locale('en-gb', [gbLocale]);
    }
    return moment().month(i).format('MMMM');
  });

// Get format for date picker
export const getFormat = (lang: string): string[] => {
  switch (lang) {
    case LANG.EN_US:
      return [slashFormatDate, dotFormatDate, dashFormatDate];
    default:
      return [dotFormatDate, slashFormatDate, dashFormatDate];
  }
};

export const formatDateToCurrentDate = 'DD/MM/YYYY';
export const currentDate = (value: string): number =>
  moment(value, formatDateToCurrentDate).valueOf();

export const getQueryParams = (qs: string): queryString.ParsedQuery<string> =>
  queryString.parse(qs);
export const setQueryParams = (qs: any): string => queryString.stringify(qs);

export const initialPage = 1;
export const initialSizePage = 10;

export const isValid = (num: string): boolean => {
  const arr = num.split(',');
  const [first_part, second_part] = arr;
  const max_length_split_array = 2;
  return !!(
    arr.length <= max_length_split_array &&
    first_part.match(/\D/g) === null &&
    (second_part ? second_part.match(/\D/g) === null : true) &&
    first_part.length
  );
};

export const ACCESS_STATUS: any = {
  enable: 'enable',
  disable: 'disable',
  pending: 'pending',
};

export const getFilterOptions = (t: any, mode: LIST_TYPE): StatusFilterOptions[] => {
  const text = t.common;
  const {
    status_invited,
    status_expired,
    status_active,
    status_inactive,
    status_connected,
    status_disconnected,
    status_treatment_completed,
    status_pending,
    status_invite_sent,
  } = text;
  const status_declined = t?.common.status_declined;
  const filterOptions: any = [];
  switch (mode) {
    case LIST_TYPE.HCP:
      filterOptions.push(
        {
          text: status_active,
          value: STATUS.active,
        },
        {
          text: status_inactive,
          value: STATUS.inactive,
        },
        {
          text: status_invited,
          value: STATUS.invited,
        },
        {
          text: status_expired,
          value: STATUS.expired,
        },
      );
      return filterOptions;
    case LIST_TYPE.PATIENT:
      filterOptions.push(
        {
          text: status_connected,
          value: STATUS.connected,
        },
        {
          text: status_disconnected,
          value: STATUS.disconnected,
        },
        {
          text: status_treatment_completed,
          value: STATUS.treatment_completed,
        },
        {
          text: status_pending,
          value: STATUS.pending,
        },
        {
          text: status_invite_sent,
          value: STATUS.invite_sent,
        },
        {
          text: status_declined,
          value: STATUS.declined,
        },
      );
      return filterOptions;
    default:
      return filterOptions;
  }
};
export const getProcedureFilterOptions = (t: any): ProcedureFilterOptions[] => {
  const text = t.common;
  const {
    focus_hip,
    focus_knee,
    focus_shoulder,
    focus_other_physical_rehab,
    focus_foot_ankle,
    focus_spinal,
    focus_elbow,
    focus_hand_wrist,
  } = text;

  const filterOptions = [
    {
      text: focus_hip,
      value: proceduresEnum.HIP,
    },
    {
      text: focus_knee,
      value: proceduresEnum.KNEE,
    },
    {
      text: focus_shoulder,
      value: proceduresEnum.SHOULDER,
    },
    {
      text: focus_other_physical_rehab,
      value: proceduresEnum.OTHER_PHYSICAL_REHAB,
    },
    {
      text: focus_foot_ankle,
      value: proceduresEnum.FOOT_AND_ANKLE,
    },
    {
      text: focus_spinal,
      value: proceduresEnum.SPINAL,
    },
    {
      text: focus_elbow,
      value: proceduresEnum.ELBOW,
    },
    {
      text: focus_hand_wrist,
      value: proceduresEnum.HAND_AND_WRIST,
    },
  ];
  return filterOptions;
};

export const showTheStatus = (status: string, t: any): string[] => {
  const active = t?.common.status_active;
  const inactive = t?.common.status_inactive;
  const invited = t?.common.status_invited;
  const expired = t?.common.status_expired;
  const connected = t?.common.status_connected;
  const disconnected = t?.common.status_disconnected;
  const treatment_completed = t?.common.status_treatment_completed;
  const pending = t?.common.status_pending;
  const invite_sent = t?.common.status_invite_sent;
  const declined = t?.common.status_declined;
  const statusKey = status && status.toLowerCase();

  switch (statusKey) {
    case StatusKeyNameEnum.connected: {
      const hex = userStatusColor.connected;
      return [connected, 'green', hex];
    }
    case StatusKeyNameEnum.disconnected: {
      const hex = userStatusColor.disconnected;
      return [disconnected, 'gray', hex];
    }
    case StatusKeyNameEnum.invited: {
      const hex = userStatusColor.invited;
      return [invited, 'orange', hex];
    }
    case StatusKeyNameEnum.inactive: {
      const hex = userStatusColor.inactive;
      return [inactive, 'gray', hex];
    }
    case StatusKeyNameEnum.active: {
      const hex = userStatusColor.active;
      return [active, 'green', hex];
    }
    case StatusKeyNameEnum.expired: {
      const hex = userStatusColor.expired;
      return [expired, 'black', hex];
    }
    case StatusKeyNameEnum.treatment_completed: {
      const hex = userStatusColor.treatment_completed;
      return [treatment_completed, 'darkBlue', hex];
    }
    case StatusKeyNameEnum.invite_sent: {
      const hex = userStatusColor.invite_sent;
      return [invite_sent, 'orange', hex];
    }
    case StatusKeyNameEnum.pending: {
      const hex = userStatusColor.pending;
      return [pending, 'black', hex];
    }
    case StatusKeyNameEnum.declined: {
      const hex = userStatusColor.declined;
      return [declined, 'brown', hex];
    }
    default: {
      const hex = userStatusColor.expired;
      return [statusKey, 'black', hex];
    }
  }
};

export const calcLastActivity = (date: Date, t: any) => {
  if (date) {
    const dayNow = moment().startOf('day');
    const dayFrom = moment(Number(date)).startOf('day');
    const days = dayNow.diff(dayFrom, 'days');

    if (days.toString()[days.toString().length - 1].includes('1')) {
      return `${days} ${t.day_ago}`;
    }
    if (days === 0) {
      return t.today;
    }
    return `${days} ${t.days_ago}`;
  }
  return '-';
};

export const defaultValue = (reps: string): Options => {
  const obj: any = {};
  for (let x = 0; x < 60; x += 1) {
    obj[x] = { value: String(x), label: String(x) };
  }
  return obj[reps];
};

export const getStatusOptions = (defaultStatus: string, type: string, t: any): TStatusOptions => {
  let options: TStatusOptions[] = [];
  switch (type) {
    case 'videobank':
      options = COLOR_OPTIONS_VIDEOBANK(t);
      break;
    case 'table':
      options = getColorOptionsTable(t);
      break;
    default:
      options = [];
  }
  const status = options.filter((itemStatus) => itemStatus.value === defaultStatus);
  return status?.length > 0 ? status[0] : { value: '', label: '', color: '#000000' };
};

export const getOptions = (min: number, max: number): Options[] => {
  const arr = [];
  for (let x = min; x <= max; x += 1) {
    arr.push({ value: String(x), label: String(x) });
  }
  return arr;
};

export const getMinutes = (duration: number): number => {
  const milliSec = duration * 1000;
  const min = Math.floor(moment.duration(milliSec).asMinutes());
  return min;
};

export const getSeconds = (duration: number): number => {
  const milliSec = duration * 1000;
  let sec = 0;
  const min = getMinutes(duration);
  if (min > 0) {
    sec = duration - min * 60;
  } else {
    sec = moment.duration(milliSec).asSeconds();
  }
  return sec;
};

export const getChecked = (name: string, exercisesCategory: Array<TExerciseCategories>): boolean =>
  exercisesCategory.map((exerciseCategory) => exerciseCategory.name).includes(name);

export const getExercisesCategories = (
  values: any,
  exercisesCategories: any,
  type: string,
): number[] => {
  const result = [];
  const lengthArr = Object.keys(exercisesCategories)?.length;
  for (let i = 1; i <= lengthArr; i += 1) {
    if (values[`${type}${i}`]) {
      result.push(exercisesCategories[`${type}${i}`].id);
    }
  }
  return result;
};

export const getTouched = (touch: any): boolean => {
  const touched: any = {};
  let error = false;
  for (let i = 1; i <= 4; i += 1) {
    if (touch[`focus${i}`]) touched[`focus${i}`] = touch[`focus${i}`];
  }
  if (Object.values(touched).length) error = true;
  return error;
};

export const normalisePatients = (patients: any) => {
  if (patients) {
    const copyObj: any = deepCopyObj(patients); // deep copy obj
    patients.map((patient: any, idx: number) => {
      if (patient.nhsNumber) {
        const nhsValue = patient.nhsNumber
          // .replace(/\D/g, '')
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        copyObj[idx].nhsNumber = `${nhsValue[1]} ${nhsValue[2]} ${nhsValue[3]}`;
      }
      return null;
    });
    return copyObj;
  }
  return [];
};

export const nhsValueHandler = (valueInput: string, userCountry: string): string | null => {
  const getValue = (nhsValue: any): string | null => {
    let result = null;
    if (!nhsValue[2]) {
      result = `${nhsValue[1]}`;
    } else if (!nhsValue[3]) {
      result = `${nhsValue[1]} ${nhsValue[2]}`;
    } else if (!nhsValue[4]) {
      result = `${nhsValue[1]} ${nhsValue[2]} ${nhsValue[3]}`;
    }
    return result;
  };

  if (valueInput) {
    if (userCountry === UserCountryEnum.US) {
      const nhsValue = valueInput.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
      const newValue = getValue(nhsValue);
      return newValue;
    }
    const nhsValue = valueInput.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const newValue = getValue(nhsValue);
    return newValue;
  }
  return null;
};

export const otherClaimIdHandler = (valueInput: string | null | undefined): string | null => {
  const getValue = (claimVal: any): string | null => {
    let result = null;
    if (!claimVal[2]) {
      result = `${claimVal[1]}`;
    } else {
      result = `${claimVal[1]}┆ ${claimVal[2]}`;
    }
    return result;
  };

  if (valueInput) {
    const claimValue = valueInput
      .replace(/┆/g, '')
      .replace(/\s/g, '')
      .match(/(\S{0,2})(\S{0,28})/);
    const newValue = getValue(claimValue);
    return newValue;
  }
  return null;
};

export const getMaxValueForYAxis = (dataArray: TDataArray[], typeAxis: string, t: any): number => {
  const number_of_exercises_skipped =
    t?.dashboard.hcp.profile_patient.completion_and_steps.number_of_exercises_skipped;
  const number_of_exercises_stopped =
    t?.dashboard.hcp.profile_patient.completion_and_steps.number_of_exercises_stopped;
  const number_of_reps = t?.dashboard.hcp.profile_patient.completion_and_steps.number_of_reps;
  let maxValue = 10;
  const arr: any = [];
  if (typeAxis === 'SkipStopNumber') {
    dataArray.map((day: any) => {
      arr.push(day[number_of_exercises_skipped]);
      arr.push(day[number_of_exercises_stopped]);
      arr.push(day[number_of_reps]);
      return null;
    });
  }
  if (typeAxis === 'Steps') {
    dataArray.map((day: TDataArray) => {
      arr.push(day.steps);
      return null;
    });
  }
  if (arr.length > 0) {
    maxValue = Math.max(...arr);
    if (maxValue < 11) {
      maxValue = 10;
    } else if (maxValue < 101) {
      maxValue = Math.ceil(maxValue / 10) * 10;
    } else if (maxValue < 1001) {
      maxValue = Math.ceil(maxValue / 100) * 100;
    } else if (maxValue < 10001) {
      maxValue = Math.ceil(maxValue / 1000) * 1000;
    }
  }
  return maxValue;
};

export const getTicks = (dataArray: TDataArray[], maxValueYAxis: number): number[] => {
  const tick = maxValueYAxis / numberYTicksForPainChart;
  const tickDomen = [];
  for (let i = 0; i <= maxValueYAxis; i += tick) {
    tickDomen.push(Math.round(i));
  }
  return tickDomen;
};

// xAxis Rechart style
export const renderTick = (
  tickProps: TTickProps,
  fontSize: string,
  windowClientWidth: number,
  isOverview: boolean,
  period: string,
  t: any,
): JSX.Element => {
  const today_t = t?.dashboard.hcp.table.today;
  const weekAgo =
    t?.dashboard.hcp.profile_patient.completion_and_steps.one_month_ago || '1 Week ago';
  const monthAgo =
    t?.dashboard.hcp.profile_patient.completion_and_steps.one_week_ago || '1 Month ago';
  const { x, y, payload, tickWidth } = tickProps;
  const dataText = {
    dx: '',
    dy: '',
    transform: '',
  };

  // @ts-ignore
  if (isNaN(payload.value)) {
    switch (payload.value) {
      case today_t:
        if (isOverview) {
          dataText.dx = windowClientWidth > 450 ? '9px' : '9px';
        } else {
          dataText.dx = windowClientWidth > 450 ? '12px' : '12px';
        }
        dataText.dy = windowClientWidth > 450 ? '4px' : '4px';
        break;
      case weekAgo:
        dataText.dx = windowClientWidth > 450 ? '25px' : '25px';
        dataText.dy = windowClientWidth > 450 ? '4px' : '4px';
        break;
      case monthAgo:
        dataText.dx = windowClientWidth > 450 ? '28px' : '28px';
        dataText.dy = windowClientWidth > 450 ? '4px' : '4px';
        break;
      default:
        dataText.dx = '0';
        dataText.dy = '6px';
    }
    dataText.transform = `rotate(90, ${x}, ${y})`;
  } else if (isOverview) {
    dataText.dx = '0';
    dataText.dy = '6px';
    dataText.transform = `rotate(0, ${x}, ${y})`;
  } else {
    dataText.dx = '0';
    dataText.dy = '14px';
    dataText.transform = `rotate(0, ${x}, ${y})`;
  }
  let label: any = payload.value;
  if (windowClientWidth <= 599 && (period === '30' || period === '26' || period === '24')) {
    label = label % 2 === 0 ? '' : label;
  }
  return (
    <text
      x={x}
      y={y}
      stroke="none"
      fill="#9BA1AE"
      dx={dataText.dx}
      dy={dataText.dy}
      textAnchor="middle"
      width={tickWidth}
      transform={dataText.transform}
      className="recharts-text recharts-cartesian-axis-tick-value"
      fontSize={fontSize}
    >
      {label}
    </text>
  );
};

export const getSelectOptions = (value: Procedure[]): THospitalName[] | [] => {
  if (value) {
    return value.map((item: Procedure) => ({
      value: item.id,
      label: item.name,
    }));
  }
  return [];
};

export const getOptionsFromIdType = (value: Profession[]): Options[] | [] => {
  if (value) {
    return value.map((item: Profession) => ({
      value: item.id,
      label: item.type,
    }));
  }
  return [];
};

export const setSortDirectionTitle = (direction: string, t: any): string => {
  const sort_by_ascend = t?.common.sort_by_ascend;
  const sort_by_descend = t?.common.sort_by_descend;
  const cancel_sort = t?.common.cancel_sort;

  switch (direction) {
    case '':
      return sort_by_ascend;
    case SORT_DIRECTION.ASC:
      return sort_by_descend;
    case SORT_DIRECTION.DESC:
      return cancel_sort;
    default:
      return '';
  }
};

export const setToolTipTitleForStatus = (selectStatus: string, t: any): string => {
  const openSettingsMsg = t && t.notifications.open_settings;
  const DeletedByPatientMsg = t && t.notifications.deleted_by_patient_message;
  switch (selectStatus) {
    case 'Inactive':
      return openSettingsMsg;
    case 'Deleted by patient':
    case 'Deleted by hcp':
      return DeletedByPatientMsg;
    default:
      return '';
  }
};

export const getSrc = (category: LIST_TYPE, hcpList: any, patientList: any): any => {
  let src: any = [];
  const getList = (list: any) => {
    src =
      list &&
      list.length &&
      list.length > 0 &&
      list.map((item: any, inx: number) => {
        const result: any = { ...item };
        result.key = inx;
        return result;
      });
    return src;
  };

  switch (category) {
    case LIST_TYPE.HCP:
      getList(hcpList);
      // normalisePatients(src);
      return src;
    case LIST_TYPE.PATIENT:
      getList(patientList);
      // normalisePatients(src);
      return src;
    default:
      return src;
  }
};

export const getSorterKey = (category: LIST_TYPE, sortField: string): string => {
  switch (sortField) {
    case 'procedure':
      return 'type.name';
    case 'profession':
      return 'profession.type';
    case 'surgeryDate':
      return 'procedure.date';
    case 'hospital':
    case 'hospitalName':
      return 'hospital.name';
    case 'hcpFirstName':
      return 'hcp.firstName';
    case 'hcpLastName':
      return 'hcp.lastName';
    case 'status':
    case 'firstName':
    case 'lastName':
    case 'email':
    case 'phone':
    case 'dob':
    case 'nhsNumber':
    case 'lastVisit':
      return `${category}.${sortField}`;
    default:
      if (category === LIST_TYPE.PATIENT) {
        return 'patient.status';
      }
      return '';
  }
};

// Remove unused foo
export const getNavTabs = (
  isAdmin: boolean,
  healthcareProfessionals: string,
  patientsLabel: string,
): TabsItem[] => [
  { tab: healthcareProfessionals, key: !isAdmin ? 'hcp' : 'admin-hcp' },
  { tab: patientsLabel, key: !isAdmin ? 'patient' : 'admin-patient' },
];

// For patient
export const filterDefault = [
  STATUS.active,
  // STATUS.expired,
  STATUS.invited,
  STATUS.pending,
  STATUS.invite_sent,
  // STATUS.declined,
];
// for HCP table
export const filterDefaultHcp = [
  STATUS.active,
  STATUS.expired,
  STATUS.invited,
  STATUS.pending,
  STATUS.invite_sent,
];

export const fetchTableData = (params: Pagination): void => {
  const {
    sortField,
    sortOrder,
    pagination,
    filters,
    mode,
    getCurrentList,
    searchedColumn,
    searchText,
    filterQuery,
    searchByDate,
    isPatientCategory,
  } = params;

  const filterDefaultTable = mode === LIST_TYPE.PATIENT ? filterDefault : filterDefaultHcp;
  const obj = {
    focuses: filters[`procedure${mode}`] || [],
    searchByDate,
  };
  const patientSearchParams = isPatientCategory ? obj : {};
  const variables = {
    listProps: {
      page: pagination.current,
      itemsPerPage: pagination.pageSize,
      sortBy: sortField,
      sortDirection: sortOrder,
    },
    searchProps: filterQuery || [
      {
        searchField: searchedColumn,
        searchValue: searchText || '',
      },
    ],
    statuses: filters[`status${mode}`] || filterDefaultTable,
    ...patientSearchParams,
  };
  getCurrentList({ variables });
};

export const getSortDirection = (
  sorterFieldState: string,
  sortOrderState: string,
  isPatientCategory: boolean,
  isHCPCategory: boolean,
): {} => {
  if (sorterFieldState && sortOrderState) {
    return {
      sortBy: sorterFieldState,
      sortDirection: sortOrderState,
    };
  }
  if (isPatientCategory) {
    return {
      sortBy: 'patient.status',
      sortDirection: 'ASC',
    };
  }
  if (isHCPCategory) {
    return {
      sortBy: 'hcp.lastVisit',
      sortDirection: 'ASC',
    };
  }
  return {
    sortBy: '',
  };
};

export const concatData = (
  title: string,
  invitedByHcpData: NestedTableRecord[],
  invitedByPatientData: NestedTableRecord[],
  index: number,
): NestedTableData[] => {
  const data: NestedTableData[] = [];
  const fillData = (records: NestedTableRecord[]): void => {
    records.map((rec: NestedTableRecord) => {
      let profession = 'Patient';
      if (rec.professionHcp) {
        profession = rec.professionHcp.type;
      } else if (rec.hcpProfession) {
        profession = rec.hcpProfession.type;
      }
      data.push({
        key: index,
        title,
        firstName: rec.firstName,
        lastName: rec.lastName,
        email: rec.email,
        phone: rec.phone,
        profession,
      });
      return null;
    });
  };

  if (invitedByHcpData && invitedByHcpData.length) {
    fillData(invitedByHcpData);
  }
  if (invitedByPatientData && invitedByPatientData.length) {
    fillData(invitedByPatientData);
  }
  return data;
};

export const setPlaceholder = (name: string, t: any): string => {
  const first_name = t && t.dashboard.hcp.table.first_name;
  const last_name = t && t.dashboard.hcp.table.last_name;
  const email_t = t && t.dashboard.hcp.table.email;

  switch (name) {
    case 'firstName':
      return first_name;
    case 'lastName':
      return last_name;
    case 'email':
      return email_t;
    default:
      return '';
  }
};

export const hexToRgbA = (hex: string, alfa: number): string => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alfa})`;
  }
  throw new Error('Bad Hex');
};

export const getSuccessfullText = (action: string, t: any): string => {
  const successfully_connected = t && t.notifications.successfully_connected;
  const successfully_disconnected = t && t.notifications.successfully_disconnected;
  const successfully_treatment_completed = t && t.notifications.successfully_treatment_completed;

  switch (action) {
    case 'connected':
      return successfully_connected;
    case 'disconnected':
      return successfully_disconnected;
    case 'treatment_completed':
      return successfully_treatment_completed;
    default:
      return '';
  }
};

export const getWarningNotificationTitle = (action: string, t: any): string => {
  const do_you_want_to_connect_this_patient =
    t && t.notifications.do_you_want_to_connect_this_patient;
  const do_you_want_to_disconnect_this_patient =
    t && t.notifications.do_you_want_to_disconnect_this_patient;
  const do_you_want_to_treatment_completed =
    t && t.notifications.do_you_want_to_treatment_completed;

  switch (action) {
    case 'connected':
      return do_you_want_to_connect_this_patient;
    case 'disconnected':
      return do_you_want_to_disconnect_this_patient;
    case 'treatment_completed':
      return do_you_want_to_treatment_completed;
    default:
      return '';
  }
};

export const getWarningNotificationContant = (action: string, t: any) => {
  const the_user_will_be_connected = t && t.notifications.the_user_will_be_connected;
  const the_user_will_be_disconnected = t && t.notifications.the_user_will_be_disconnected;
  const are_you_sure_disconnect = t && t.notifications.are_you_sure_disconnect;

  switch (action) {
    case 'connected':
      return (
        <>
          <span>{the_user_will_be_connected}</span>
          <span>{are_you_sure_disconnect}</span>
        </>
      );
    case 'disconnected':
      return (
        <>
          <span>{the_user_will_be_disconnected}</span>
        </>
      );
    case 'treatment_completed':
      return (
        <>
          <span>{are_you_sure_disconnect}</span>
        </>
      );
    default:
      return '';
  }
};

export const capitalizeFirstLetter = (string: string): string => {
  if (string) {
    const toLowerCase = string.toLowerCase();
    const toUpperCase = toLowerCase.charAt(0).toUpperCase() + toLowerCase.slice(1);
    return toUpperCase;
  }
  return '';
};

export const durationCounter = (nearestMon: Date, endDate: Date | null): RewardsDateConfig => {
  const newStartDate = new Date(moment(nearestMon).format('YYYY-MM-DD'));
  const newEndDate = new Date(moment(endDate).format('YYYY-MM-DD'));
  const duration = (+newEndDate - +newStartDate + dayInMilliseconds) / dayInMilliseconds / 7;
  return {
    newStartDate,
    newEndDate,
    duration: endDate ? duration : 0,
  };
};

export const getPatientRewards = (rewards: PatientRewards[]): PatientRewards | null => {
  if (rewards && rewards.length && rewards.length > 0) {
    const result = rewards.filter((item: PatientRewards) => !item.shutdownDate);
    return result[0];
  }
  return null;
};

export const getCamAndMics = async (
  setLists: (list: any) => void,
  setLoader: any,
): Promise<void> => {
  setLoader(true);
  // List cameras and microphones.
  navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => {
      const list: any = [];
      let i = 0;
      devices.forEach((device) => {
        // console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
        if (device.kind === 'videoinput') {
          list.push({ ind: i, cameraId: device.deviceId });
          i += 1;
        }
      });
      setLists(list);
      // setCamList(list);
      setLoader(() => false);
    })
    .catch((err) => {
      setLoader(() => false);
      console.error(`${err.name}: ${err.message}`);
    });
};

export const getAmountOfDaysUntilMonday = (dateNowLocal: any, dayOfWeek: number): any => {
  if (dayOfWeek === 0) {
    return 1;
  }
  if (dayOfWeek === 1) {
    const currentUTCHour = dateNowLocal.getUTCHours();
    const currentUTCMinutes = dateNowLocal.getUTCMinutes();
    // At 09:00 on Monday, the server will send a notification to the patient.
    // 5 min left for asynchronous
    if (currentUTCHour <= 7) {
      return 0;
    }
    if (currentUTCHour <= 8 && currentUTCMinutes < 56) {
      //
      return 0;
    }
    return 8 - dayOfWeek;
  }
  return 8 - dayOfWeek;
};

// get name for videobank save button
export const getSubmitExerciseButtonName = (
  isUploadProcess: boolean,
  type: string,
  t: any,
  exerciseFileType: ExerciseFileType,
): string => {
  const save = t?.common.save;
  const upload_my_video = t?.dashboard.hcp.profile_patient.upload_my_video;
  const save_and_sand = t?.dashboard.hcp.profile_patient.video_bank.save_and_send_to_patient;
  const upload_my_photo = t?.dashboard.hcp.profile_patient.upload_my_photo;

  if (isUploadProcess) {
    if (type === VIDEO_BANK_TYPE.PATIENT_VIDEO_BANK) {
      return save_and_sand;
    }
    if (exerciseFileType === EXERCISE_TYPE.PHOTO) {
      return upload_my_photo;
    }
    return upload_my_video;
  }
  switch (type) {
    case VIDEO_BANK_TYPE.TEAM_VIDEO_BANK:
    case VIDEO_BANK_TYPE.TEAM_VIDEO_BANK_FOLDER:
    case VIDEO_BANK_TYPE.INVITEE_PATIENT:
    case VIDEO_BANK_TYPE.NESTED_INVITEE:
      return save;
    default:
      return save_and_sand;
  }
};

// check if the body focus has been changed
export const checkFocusBodyChanges = (
  request: any,
  exerciseProcedureTypes: { id: number }[],
): boolean => {
  const sorting = (arr: number[]): void => {
    arr.sort((a: number, b: number) => {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  };
  const newFocus = request.exerciseProcedureTypesId;
  const oldFocus = exerciseProcedureTypes.map((item: { id: number }) => item.id);
  sorting(newFocus);
  sorting(oldFocus);
  if (JSON.stringify(oldFocus) === JSON.stringify(newFocus)) {
    return true;
  }
  return false;
};

export const detectBrowser = (): string => {
  let sBrowser = '';
  const sUsrAg = navigator.userAgent;

  // The order matters here, and this may report false positives for unlisted browsers.

  if (sUsrAg.indexOf('Firefox') > -1) {
    sBrowser = 'Mozilla Firefox';
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
    sBrowser = 'Samsung Internet';
    /* "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36
    (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36 */
  } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
    sBrowser = 'Opera';
    /* "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko)
    Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106" */
  } else if (sUsrAg.indexOf('Trident') > -1) {
    sBrowser = 'Microsoft Internet Explorer';
    /* "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E;
    Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko" */
  } else if (sUsrAg.indexOf('Edge') > -1) {
    sBrowser = 'Microsoft Edge';
    /* "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko)
    Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299" */
  } else if (sUsrAg.indexOf('Chrome') > -1 || sUsrAg.indexOf('Chromium') > -1) {
    sBrowser = 'Google Chrome or Chromium';
    /* "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko)
    Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36" */
  } else if (sUsrAg.indexOf('Safari') > -1) {
    sBrowser = 'Apple Safari';
    /* "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15
    (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306" */
  } else {
    sBrowser = 'unknown';
  }
  return sBrowser;
};

export const normalazePhoneNumber = (phoneNumber: string): { display: string; link: string } => {
  const obj = {
    display: '',
    link: '',
  };
  if (phoneNumber) {
    const arr = phoneNumber.split('');
    arr.splice(12, 0, ' ');
    arr.splice(9, 0, ' ');
    arr.splice(6, 0, ' ');
    arr.splice(3, 0, ' ');
    const normalize = arr.join('');
    obj.display = normalize;
    const link = `${phoneNumber.slice(0, 3)}${phoneNumber.slice(6)}`;
    obj.link = link;
  }
  return obj;
};

export const getFormats = (lang: string): CurrentFormatDates => {
  switch (lang) {
    case LANG.EN_GB:
      return {
        momentFormat: formatDateEU,
        reactDatePickerFormat: dotFormatDate,
      };
    case LANG.EN_US:
      return {
        momentFormat: formatDateUS,
        reactDatePickerFormat: slashFormatDate,
      };
    default:
      return {
        momentFormat: formatDateEU,
        reactDatePickerFormat: dotFormatDate,
      };
  }
};

export const getInsurancePolicyLabel = (t: any, country: string): string => {
  const nhs_number = /* t?.dashboard.admin.profile_patient.nhs_number; */ 'NHS Number';
  const ipn_number = t?.dashboard.admin.profile_patient.ipn_number;

  switch (country) {
    case UserCountryEnum.GB:
      return nhs_number;
    case UserCountryEnum.US:
      return ipn_number;
    case UserCountryEnum.DE:
      return ipn_number;
    default:
      return nhs_number;
  }
};

export const getPhoneRegex = (country: string): RegExp => {
  switch (country) {
    case UserCountryEnum.GB:
      return cellPhoneNumberGB;
    case UserCountryEnum.US:
      return cellPhoneNumberUS;
    case UserCountryEnum.DE:
      return cellPhoneNumberDE;
    default:
      return cellPhoneNumberGB;
  }
};

// export const getCurrentPatientName = (
//   patient: TPatient | null, status: string | string[] | null,
// ): JSX.Element => {
//   if (!patient) return <></>;
//   if (status === StatusKeyNameEnum.invite_sent) {
//     return (
//       <>
//         {patient.email}
//       </>
//     );
//   }
//   return (
//     <>
//       {patient.firstName}
//       {' '}
//       {patient.lastName}
//     </>
//   );
// };

export const displaySurgeryInfo = (procedure: { date: Date }, t: any): string => {
  const days = t?.dashboard.hcp.profile_patient.completion_and_steps.days?.toLowerCase();
  const surgery = t?.dashboard.hcp.profile_patient.completion_and_steps.surgery?.toLowerCase();
  const post = t?.dashboard.hcp.profile_patient.completion_and_steps.post;
  const pre = t?.dashboard.hcp.profile_patient.completion_and_steps.pre;
  const now = moment();
  const surgeryDate = moment(procedure.date);
  const daysFromSurgey = calcSurgeryDays(procedure.date);
  const surgery_is_today = t?.dashboard.hcp.profile_patient.surgery_is_today || 'Surgery is today';

  switch (true) {
    case moment(surgeryDate).isBefore(now, 'day'):
      return `${Math.abs(daysFromSurgey)} ${days} ${post} ${surgery}`;
    case moment(surgeryDate).isSame(now, 'day'):
      return surgery_is_today;
    case moment(surgeryDate).isAfter(now, 'day'):
      return `${Math.abs(daysFromSurgey)} ${days} ${pre} ${surgery}`;
    default:
      return 'N/A';
  }
};

export const getGender = (patientGender: number, genderOptions: Options[]): Options | null => {
  const currentGender = genderOptions.filter((item: Options) => item.value === patientGender);
  if (currentGender && currentGender.length > 0) {
    return currentGender[0];
  }
  return null;
};

export const getProfilePatientTabMode = (screenWidth: number): TabPosition => {
  if (screenWidth < 1299 && screenWidth > 649) {
    return 'left';
  }
  return 'top';
};

export const dataPickerOfBirthday = (
  { target: { value } }: React.ChangeEvent<HTMLInputElement>,
  t: any,
  formik: any,
  formatDate: { momentFormat: string },
): void => {
  const dob_more_than_today = t && t.notifications.dob_more_than_today;
  const dob_less_than = t && t.notifications.dob_less_than;
  const curDate = new Date(currentDate(value)).valueOf();

  if (curDate > new Date().valueOf()) {
    formik.setFieldValue('dob', new Date());
    toast.info(dob_more_than_today);
  }
  if (curDate < new Date(new Date(minYear).getFullYear(), 0, 1).valueOf()) {
    formik.setFieldValue('dob', new Date());
    toast.info(
      `${dob_less_than} ${moment(new Date(new Date(minYear).getFullYear(), 0, 1)).format(
        formatDate.momentFormat,
      )}`,
    );
  }
};

export const dataPickerSurgery = (
  { target: { value } }: React.ChangeEvent<HTMLInputElement>,
  t: any,
  formik: any,
  formatDate: { momentFormat: string },
): void => {
  const dos_more_than = t?.notifications.dos_more_than;
  const dos_less_than = t?.notifications.dos_less_than;
  const curDate = new Date(currentDate(value)).valueOf();

  if (curDate > new Date(maxDate).valueOf()) {
    formik.setFieldValue('surgeryDate', new Date());
    toast.info(`${dos_less_than} ${moment(maxDate).format(formatDate.momentFormat)}`);
  }
  if (curDate < new Date(minDate).valueOf()) {
    formik.setFieldValue('surgeryDate', new Date());
    toast.info(`${dos_more_than} ${moment(minDate).format(formatDate.momentFormat)}`);
  }
};

// Upload files to AWS
export const uploadFiles = async (
  selectedFiles: any,
  urlSecondPartPic: string,
  urlSecondPartVideo: string,
  token: string,
  allowedPicTypes: any,
): Promise<{
  attachmentPic: any[];
  attachmentVideo: any[];
  isLoadingErr: boolean;
}> => {
  async function* asyncGenerator(): AsyncGenerator<number, void, unknown> {
    let j = 0;
    while (j < selectedFiles.length) {
      yield (j += 1);
    }
  }
  const attachmentVideo: any = [];
  const attachmentPic: any = [];
  let isLoadingErr = false;
  if (selectedFiles.length !== 0) {
    const { uri, bucketName, videoServiceUrl } = config;
    const uriWithoutGraph = uri?.split('/api')[0];

    // const urlSecondPartPic = 'pictures/upload-hcp-help-pic'; // TODO arg
    // const urlSecondPartVideo = 'video/uploadHcpVideoHelp'; // TODO arg

    let videoServUrlImg = `${uriWithoutGraph}/video_api/${urlSecondPartPic}`;
    let videoServUrlVideo = `${uriWithoutGraph}/video_api/${urlSecondPartVideo}`;
    switch (bucketName) {
      case 'otm-stage':
      case 'onthemend-en':
        videoServUrlImg = `${videoServiceUrl}${urlSecondPartPic}`;
        videoServUrlVideo = `${videoServiceUrl}${urlSecondPartVideo}`;
        break;
      default:
        break;
    }
    // eslint-disable-next-line no-restricted-syntax
    for await (const j of asyncGenerator()) {
      const i = j - 1;
      const isImage = allowedPicTypes
        ? allowedPicTypes.includes(selectedFiles[i].type)
        : selectedFiles[i].type.includes('image');
      const backendUrl = isImage ? videoServUrlImg : videoServUrlVideo;
      const formData = new FormData();
      formData.append('file', selectedFiles[i]);
      const axiosConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      };
      await axios
        .post(backendUrl, formData, axiosConfig)
        .then(async (response) => {
          if (isImage) {
            attachmentPic.push({
              picUuid: response.data,
            });
          } else attachmentVideo.push(response.data);
        })
        // eslint-disable-next-line no-loop-func
        .catch((error) => {
          isLoadingErr = true;
          console.log('errorFromAXIOSUploadFiles=, ', error);
        });
    }
  }
  return {
    attachmentPic,
    attachmentVideo,
    isLoadingErr,
  };
};

// Area field handler (controlled textarea)
export const onChangeArea = (
  e: any,
  ind: number,
  arr: any,
  callBack: any,
  limit?: number,
): void => {
  let { value } = e.target;
  if (limit && value.length > limit) {
    value = value.slice(0, limit);
  }
  const newArr = [...arr];
  newArr[ind].description = value;
  if (!value) {
    newArr[ind].isError = false;
  }
  callBack(newArr);
};

// Input field handler (controlled input)
export const onChangeInput = (e: any, ind: number, arr: any, callBack: any): void => {
  const { value } = e.target;
  const newArr = [...arr];
  newArr[ind].url = value;
  newArr[ind].isError = false;
  callBack(newArr);
};

// Delete element from array
export const deleteElemFromArr = (
  arr: any[],
  element: string | number,
): string[] | number[] | null => {
  const newArr = [...arr];
  const position = newArr.indexOf(element);
  if (position >= 0) {
    newArr.splice(position, 1);
    return newArr;
  }
  return newArr;
};

// Check and set search by date params
export const getSearchByDateParam = (
  searchByDOB: SearchByDate,
  searchByDOS: SearchByDate,
): SearchByDate[] => {
  const searchByDate: any = [];
  if (searchByDOB.isSearch) {
    searchByDate.push({
      searchField: 'patient.dob',
      dateFrom: searchByDOB.start,
      dateTo: searchByDOB.end || searchByDOB.start,
    });
  }
  if (searchByDOS.isSearch) {
    searchByDate.push({
      searchField: 'patient.surgeryDate',
      dateFrom: searchByDOS.start,
      dateTo: searchByDOS.end || searchByDOS.start,
    });
  }
  return searchByDate;
};

export const getSectionTexts = (
  order: number,
  t: any,
  data: HcpAllEmailNotifType,
  subSection: HcpAllEmailNotifType,
): SectionTexts => {
  const exerc_compl_switch_one = t?.hcp.manage.exercise_completion_switch_one;
  const exerc_compl_switch_two = t?.hcp.manage.exercise_completion_switch_two;
  const exerc_compl_switch_three = t?.hcp.manage.exercise_completion_switch_three;
  const exerc_compl_switch_four = t?.hcp.manage.exercise_completion_switch_four;
  const exerc_compl_switch_five = t?.hcp.manage.exercise_completion_switch_five;
  const exerc_compl_switch_six = t?.hcp.manage.exercise_completion_switch_six;
  const inactive_t = t?.dashboard.hcp.profile_patient.completion_and_steps.inactive;

  switch (order) {
    case 1:
      return {
        title: t?.hcp.manage.exercise_completion,
        description: t?.hcp.manage.email_me_exercise_completion,
        smSwitchName: t?.hcp.manage.any_exercise,
        smSwitchName_subType: t?.hcp.manage.all_exercise,
        smSwitchDescr: t?.hcp.manage.individual_exercise_completion,
        smSwitchDescr_subType: t?.hcp.manage.total_exercise_completion,
        percentFieldName: t?.hcp.manage.any_individual_exercise,
        percentFieldName_subType: t?.hcp.manage.total_exercise,
        dayFieldName: t?.hcp.manage.for_less_than,
        dayFieldName_subType: t?.hcp.manage.for_less_than,
        switchDescription:
          data.frequency && exerc_compl_switch_one
            ? exerc_compl_switch_one
                .replace(
                  '<frequency>',
                  getMailingFrequencyOptions(t)[data.frequency - 1].label?.toLowerCase(),
                )
                .replace('<percent>', data.percent)
                .replace('<days>', data.days)
            : inactive_t,
        switchDescription_sub:
          subSection.frequency && exerc_compl_switch_two
            ? exerc_compl_switch_two
                .replace(
                  '<frequency>',
                  getMailingFrequencyOptions(t)[subSection.frequency - 1].label?.toLowerCase(),
                )
                .replace('<percent>', subSection.percent)
                .replace('<days>', subSection.days)
            : inactive_t,
        sectionType: EMAIL_NOTIF_TYPE.EXERCISE_COMPLETION,
      };
    case 3:
      return {
        title: t?.dashboard.hcp.profile_patient.completion_and_steps.step_count,
        description: t?.hcp.manage.email_me_step_count,
        smSwitchName: t?.hcp.manage.percentage_decline,
        smSwitchName_subType: t?.hcp.manage.below_certain_level,
        smSwitchDescr: '',
        smSwitchDescr_subType: '',
        percentFieldName: t?.hcp.manage.when_daily_step_count_falls_by,
        percentFieldName_subType: t?.hcp.manage.when_daily_step_count_falls_below,
        dayFieldName: t?.hcp.manage.on_a,
        dayFieldName_subType: t?.hcp.manage.on_a,
        switchDescription:
          data.frequency && exerc_compl_switch_three
            ? exerc_compl_switch_three
                .replace(
                  '<frequency>',
                  getMailingFrequencyOptions(t)[data.frequency - 1].label?.toLowerCase(),
                )
                .replace('<percent>', data.percent)
                .replace('<days>', data.days)
            : inactive_t,
        switchDescription_sub:
          subSection.frequency && exerc_compl_switch_four
            ? exerc_compl_switch_four
                .replace(
                  '<frequency>',
                  getMailingFrequencyOptions(t)[subSection.frequency - 1].label?.toLowerCase(),
                )
                .replace('<value>', subSection.value)
                .replace('<days>', subSection.days)
            : inactive_t,
        sectionType: EMAIL_NOTIF_TYPE.STEP_COUNT,
      };
    case 5:
      return {
        title: t?.dashboard.hcp.profile_patient.questionnaires.pain,
        description: t?.hcp.manage.email_me_pain_level,
        smSwitchName: t?.hcp.manage.completed_exercises,
        smSwitchName_subType: t?.hcp.manage.skip_stop_exercises,
        smSwitchDescr: '',
        smSwitchDescr_subType: '',
        percentFieldName: t?.hcp.manage.when_pain_score_for_completed,
        percentFieldName_subType: t?.hcp.manage.when_pain_score_for_skip_stop,
        dayFieldName: t?.hcp.manage.for_more_than,
        dayFieldName_subType: t?.hcp.manage.for_more_than,
        switchDescription:
          data.frequency && exerc_compl_switch_five
            ? exerc_compl_switch_five
                .replace(
                  '<frequency>',
                  getMailingFrequencyOptions(t)[data.frequency - 1].label?.toLowerCase(),
                )
                .replace('<value>', data.value)
                .replace('<days>', data.days)
            : inactive_t,
        switchDescription_sub:
          subSection.frequency && exerc_compl_switch_six
            ? exerc_compl_switch_six
                .replace(
                  '<frequency>',
                  getMailingFrequencyOptions(t)[subSection.frequency - 1].label?.toLowerCase(),
                )
                .replace('<value>', subSection.value)
                .replace('<days>', subSection.days)
            : inactive_t,
        sectionType: EMAIL_NOTIF_TYPE.PAIN,
      };
    default:
      return {
        title: '',
        description: '',
        smSwitchName: '',
        smSwitchName_subType: '',
        smSwitchDescr: '',
        smSwitchDescr_subType: '',
        percentFieldName: '',
        percentFieldName_subType: '',
        dayFieldName: '',
        dayFieldName_subType: '',
        switchDescription: '',
        switchDescription_sub: '',
        sectionType: EMAIL_NOTIF_TYPE.EXERCISE_COMPLETION,
      };
  }
};
// Upload files to AWS
export const uploadBase64Files = async (
  selectedFiles: any,
  urlSecondPartPic: string,
  token: string,
): Promise<{
  attachmentPic: any[];
  isLoadingErr: boolean;
}> => {
  async function* asyncGenerator(): AsyncGenerator<number, void, unknown> {
    let j = 0;
    while (j < selectedFiles.length) {
      yield (j += 1);
    }
  }
  const attachmentPic: any = [];
  let isLoadingErr = false;
  if (selectedFiles.length !== 0) {
    const { uri, bucketName, videoServiceUrl } = config;
    const uriWithoutGraph = uri?.split('/api')[0];

    let videoServUrlImg = `${uriWithoutGraph}/video_api/${urlSecondPartPic}`;
    switch (bucketName) {
      case 'otm-stage':
      case 'onthemend-en':
        videoServUrlImg = `${videoServiceUrl}${urlSecondPartPic}`;
        break;
      default:
        break;
    }
    // eslint-disable-next-line no-restricted-syntax
    for await (const j of asyncGenerator()) {
      const i = j - 1;
      const data = JSON.stringify({
        base64: selectedFiles[i],
      });
      const axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      await axios
        .post(videoServUrlImg, data, axiosConfig)
        .then(async (response) => {
          attachmentPic.push(response.data);
        })
        // eslint-disable-next-line no-loop-func
        .catch((error) => {
          isLoadingErr = true;
          console.log('errorFromAXIOSUploadPhotos=, ', error);
        });
    }
  }
  return {
    attachmentPic,
    isLoadingErr,
  };
};

// Collect uuids from object to string array
export const collectUuids = (exercPhotos: ExercisePhotos[]): string[] => {
  const uuids = exercPhotos.map((photo: ExercisePhotos) => photo.fileUuid);
  return uuids;
};

export const normilizeProcedureList = (
  procedures: TExerciseCategories[],
  keyProceduresList: ProcedureKey[],
  withOtherPhysicalRehab: boolean,
): TFocusesProcedure => {
  const accProcedureFocus: any = {};
  const result = keyProceduresList.find(
    (item: ProcedureKey) => item.key === proceduresEnum.OTHER_PHYSICAL_REHAB,
  );
  const otherPhisicalRehabId: number | null = result?.id || null;
  let procedureIndx = 1;
  procedures.map((focusProcedure: TExerciseCategories) => {
    if (withOtherPhysicalRehab || focusProcedure.id !== otherPhisicalRehabId) {
      accProcedureFocus[`procedure${procedureIndx}`] = focusProcedure;
      procedureIndx += 1;
    }
    return null;
  });
  return accProcedureFocus;
};

export const handleError = (error: any, error_occurred: string | undefined): string => {
  if (typeof error === 'string') {
    return error;
  }

  if (
    error?.graphQLErrors?.[0]?.extensions?.code === 'VALIDATION_ERROR' ||
    error?.graphQLErrors?.[0]?.extensions?.code === 'GRAPHQL_VALIDATION_FAILED'
  ) {
    return error.graphQLErrors[0].extensions.message || error_occurred;
  }

  if (error?.graphQLErrors?.[0]?.message) {
    return error.graphQLErrors[0].message;
  }
  return error_occurred || '';
};

export const getUnitScheduleText = (
  t: any,
  folder_is_scheduled_t: string | undefined,
  values: any, // {number: Options; unit: Options},
): string => {
  const days_t = t?.dashboard.hcp.profile_patient.completion_and_steps.days;
  const day_t = t?.dashboard.hcp.profile_patient.completion_and_steps.day;
  const week = t?.dashboard.hcp.profile_patient.completion_and_steps.week;
  const weeks = t?.dashboard.hcp.profile_patient.completion_and_steps.weeks;
  const month = t?.dashboard.hcp.profile_patient.completion_and_steps.month;
  const months = t?.dashboard.hcp.profile_patient.completion_and_steps.months;
  const amount = values.amount.value;
  const timePeriod = values.timePeriod.value;

  let unitText = '';
  switch (timePeriod) {
    case SCHEDULE_UNIT.DAYS:
      unitText = amount === 1 ? day_t : days_t;
      break;
    case SCHEDULE_UNIT.WEEKS:
      unitText = amount === 1 ? week : weeks;
      break;
    case SCHEDULE_UNIT.MONTHS:
      unitText = amount === 1 ? month : months;
      break;
    default:
      unitText = '';
  }
  unitText = unitText ? unitText.toLowerCase() : unitText;
  const numberText = amount || '';
  const text = folder_is_scheduled_t?.replace('<period>', `${numberText} ${unitText}`) || '';

  return text;
};

export const getTooltipText = (item: HcpList, isTooltip: boolean | undefined, t: any): string => {
  const has_already_been_added = t?.hcp.manage.has_already_been_added;
  if (!isTooltip) {
    return '';
  }
  if (item.teams?.length) {
    const teamNames = item.teams.map((teams: PatientTeams) => teams.name);
    const joinedNames = teamNames?.join(', ');
    const tip = has_already_been_added
      ? has_already_been_added
          .replace('<firstName>', item.firstName)
          .replace('<lastName>', item.lastName)
          .replace('<teamName>', joinedNames)
      : '';
    return tip;
  }
  return '';
};

export const scrollPageTo = (top: number) => {
  window.scrollTo({
    top,
    left: 0,
    behavior: 'smooth',
  });
};

export const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? 'lightblue' : 'inherit',
  width: '100%',
});

export const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // change background colour if dragging
  background: isDragging ? '#F0F0F8' : 'inherit',

  // styles we need to apply on draggables
  ...draggableStyle,
});

export const getPatientName = (profileData: {
  firstName: string;
  lastName: string;
  email: string;
}): string => {
  if (profileData) {
    const fn = profileData.firstName;
    const ln = profileData.lastName;
    const patientEmail = profileData.email;

    if (!fn && !ln && patientEmail) {
      return patientEmail;
    }
    if (!patientEmail) {
      return '-';
    }

    return `${fn} ${ln}`;
  }
  return '-';
};

export const getSetsInfo = (
  values: {
    reps: string;
    sets: string;
    timesDay: string;
    restDaysWeek: string;
    minutes: Options;
    seconds: Options;
    dim: string;
  },
  t: any,
): string => {
  const reps = t?.dashboard.hcp.profile_patient.video_bank.reps;
  const sets = t?.dashboard.hcp.profile_patient.video_bank.sets;
  const timesDay = t?.dashboard.hcp.profile_patient.video_bank.times_per_day;
  const restDaysWeek = t?.dashboard.hcp.profile_patient.video_bank.rest_days_week;
  const minutes = t?.dashboard.hcp.profile_patient.video_bank.minutes;
  const seconds = t?.dashboard.hcp.profile_patient.video_bank.seconds;

  let string = '';
  const configReps = ['repsVal', 'setsVal', 'timesDayVal', 'restDayVal'];
  const configTime = ['minVal', 'secVal', 'setsVal', 'timesDayVal', 'restDayVal'];

  const initVal: any = {
    repsVal: values.reps ? `${values.reps} ${reps}` : '',
    setsVal: values.sets ? `${values.sets} ${sets}` : '',
    timesDayVal: values.timesDay ? `${values.timesDay} ${timesDay}` : '',
    restDayVal: values.restDaysWeek ? `${values.restDaysWeek} ${restDaysWeek}` : '',
    minVal: values.minutes.value ? `${values.minutes.value} ${minutes}` : '',
    secVal: values.seconds.value ? `${values.seconds.value} ${seconds}` : '',
  };

  let isExistFirstElem = false;
  if (values.dim === 'time') {
    configTime.map((item: string) => {
      const itemVal = initVal[item];
      if (itemVal) {
        string += !isExistFirstElem ? itemVal : ` | ${itemVal}`;
        isExistFirstElem = true;
      }
      return null;
    });
    return string;
  }
  configReps.map((item: string) => {
    const itemVal = initVal[item];
    if (itemVal) {
      string += !isExistFirstElem ? itemVal : ` | ${itemVal}`;
      isExistFirstElem = true;
    }
    return null;
  });
  return string;
};
export const getOwnerAction = (
  owner: string,
  change_by_hcp: string,
  change_by_patient: string,
): string => {
  switch (owner) {
    case ROLE.HCP:
      return change_by_hcp;
    case ROLE.PATIENT:
      return change_by_patient;
    default:
      return '';
  }
};
export const executeScroll = (ref: any): void => {
  if (ref?.current) {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }
};
