import React, { ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';

import pageStyles from '../../styles.module.css';
import patientStyles from '../style.module.css';
import style from './style.module.css';
import {
  getCurrenLang,
  getErrorPatientProfile,
  getLoadingPatientProfile,
  getPatientProfile,
  getProcedureKeys,
  GetState,
} from '../../../redux/selector';
import { Loading } from '../../../common';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import { fillPatientProfile } from '../../../redux/patient';
import { StatusKeyNameEnum } from '../../../utils/enums';
import { useQueryPatientProfile } from '../../../graphql/patients';
import useCheckRole from '../../../hooks/useCheckRole';
import { path } from '../../../utils';
import { getQueryParams } from '../../../utils/helper';
import ProfilePatientCard from '../../../components/OverviewCards/ProfilePatientCard';
import PrescribedExerciseMediaCard from '../../../components/OverviewCards/PrescribedExerciseMediaCard ';
import NotificationCard from '../../../components/OverviewCards/NotificationsCard';
import RehabTeamCard from '../../../components/OverviewCards/RehabTeamCard';
import InformationCard from '../../../components/OverviewCards/InformationCard';
import WoundImagesCard from '../../../components/OverviewCards/WoundImagesCard';
import PatientMessagesCard from '../../../components/OverviewCards/PatientMessagesCard';
import ChartCard from '../../../components/OverviewCards/ChartCard';
import { getCurrentKeyOfProcedure } from '../../../utils/helper_charts';
import PromsLayout from '../../../components/Charts/Proms/PromsLayout';

const OverviewPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const patient_list = t?.title.patient_list;
  const patient_not_found = t?.common.patient_not_found;

  const location = useLocation();
  const dispatch = useDispatch();
  const { userId, userStatus } = getQueryParams(location.search);
  const patientId = Number(userId);
  const patientStatus = String(userStatus);
  const { isAdmin } = useCheckRole();

  // Endpoints
  const { _getProfileById } = useQueryPatientProfile(patientStatus);

  // Redux
  const profileData: any = useSelector<any>((state: GetState) => getPatientProfile(state));
  const profileLoading = useSelector<any>((state: GetState) => getLoadingPatientProfile(state));
  const profileError: any = useSelector<any>((state: GetState) => getErrorPatientProfile(state));
  const keyProceduresList = useSelector((state: GetState) => getProcedureKeys(state));

  const procedureId = profileData?.procedure?.type.id || 0;
  const procedureKey = useMemo(() => {
    if (keyProceduresList) {
      return getCurrentKeyOfProcedure(procedureId, keyProceduresList);
    }
    return '';
  }, [keyProceduresList, procedureId]);

  // No data available for this user
  const notAllowed = useMemo(() => {
    if (!userStatus || !patientId || profileError) {
      return true;
    }
    if (userStatus === StatusKeyNameEnum.invite_sent) {
      return true;
    }
    if (userStatus === StatusKeyNameEnum.pending) {
      return true;
    }
    return false;
  }, [patientId, userStatus, profileError]);

  // Send request to the database
  useEffect(() => {
    if (!notAllowed) {
      _getProfileById({
        variables: {
          patientId,
        },
      });
    }
  }, [notAllowed, patientId]);

  // Update prifile data
  const updateProfileData = () => {
    _getProfileById({
      variables: {
        patientId,
      },
    });
  };

  // Clear state after componentWillUnmount
  useEffect(
    () => (): void => {
      dispatch(fillPatientProfile(null));
    },
    [],
  );

  const loadingJSX = profileLoading && <Loading />;

  // Breadcrumb path
  const routes = [
    {
      path: isAdmin ? path.adminDashboardList : path.dashboardList,
      breadcrumbName: patient_list,
    },
    {
      path: isAdmin
        ? `${path.patient_overview_admin}${location.search}`
        : `${path.patient_overview}${location.search}`,
      breadcrumbName: profileData ? `${profileData.firstName} ${profileData.lastName}` : '-',
    },
  ];

  return (
    <div className={pageStyles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />

      {notAllowed && !loadingJSX ? (
        <div
          className={cx({
            [patientStyles['profile-user__notfound']]: true,
            [pageStyles.main__wrapper]: true,
          })}
        >
          {patient_not_found}
        </div>
      ) : (
        // Cards
        <>
          {/* Bg screen */}
          <div className={style.overview__container}>
            <div
              className={cx({
                [style.overview__card]: true,
                [style['overview__card--profile']]: true,
              })}
            >
              <ProfilePatientCard
                patient={profileData}
                notAllowed={notAllowed}
                patientId={patientId}
              />
            </div>
            <div
              id="scrollableVideobank"
              className={cx({
                [style.overview__card]: true,
                [style['overview__card--prescribed-exercise-media']]: true,
              })}
            >
              <PrescribedExerciseMediaCard
                patient={profileData}
                patientId={patientId}
                updateProfileData={updateProfileData}
                loading={!!loadingJSX}
              />
            </div>
            <div
              id="scrollablePatientNotifDiv"
              className={cx({
                [style.overview__card]: true,
                [style['overview__card--notifiction']]: true,
              })}
            >
              <NotificationCard
                patientId={patientId}
                patientStatus={patientStatus}
                loading={!!loadingJSX}
              />
            </div>
            <div
              className={cx({
                [style.overview__card]: true,
                [style['overview__card--d']]: true,
              })}
            >
              {/* Rehab Team */}
              <div
                className={cx({
                  [style['overview__sm-card']]: true,
                })}
              >
                <RehabTeamCard
                  patientId={patientId}
                  patientStatus={patientStatus}
                  loading={!!loadingJSX}
                />
              </div>

              {/* Patient Information */}
              <div
                id="scrollablePIDiv"
                className={cx({
                  [style['overview__sm-card']]: true,
                })}
              >
                <InformationCard patientId={patientId} loading={!!loadingJSX} />
              </div>

              {/* Wound Images */}
              <div
                id="scrollableWoundImagesDiv"
                className={cx({
                  [style['overview__sm-card']]: true,
                })}
              >
                <WoundImagesCard patientId={patientId} loading={!!loadingJSX} />
              </div>

              {/* Msg */}
              <div
                className={cx({
                  [style['overview__sm-card']]: true,
                  // [style['overview__card--d']]: true,
                })}
              >
                <PatientMessagesCard patient={profileData} loading={!!loadingJSX} />
              </div>
            </div>
            <div
              className={cx({
                [style.overview__card]: true,
                [style['overview__card--f']]: true,
              })}
            >
              <ChartCard patientId={patientId} loading={!!loadingJSX} procedureKey={procedureKey} />
            </div>
          </div>
          {/* Sm screen */}
          <div className={style['overview__container--sm']}>
            <ProfilePatientCard
              patient={profileData}
              notAllowed={notAllowed}
              patientId={patientId}
            />
            <PromsLayout patientId={patientId} procedureKey={procedureKey} />
          </div>
        </>
      )}

      {/* Popups */}
    </div>
  );
};

export default OverviewPage;
