import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ObjectSchema } from 'yup';

import { getCurrenLang, getHcpNameLoading, GetState, getUserCountry } from '../../redux/selector';
// Hooks
import { useInviteHcp } from '../../graphql/invite';
// Cpmponents
import { Loading, Confirmation, SectionTitle, Button, FormFormik } from '../../common';
// Instruments
import { InviteHcpForm } from '..';
import { validationInviteHcp } from '../../utils/validators';
import styles from './styles.module.css';
import { useGetProfessions } from '../../graphql/professions';
import { InvitedHcp, InviteHCPCustomer, Profession } from './types';
import { useGetHospitalNames } from '../../graphql/hospitals';
import { getOptionsFromIdType, getSelectOptions } from '../../utils/helper';
import { PERMISSIONS } from '../../utils/enums';
import { storage } from '../../utils';
import { constants } from '../../utils/routers/book';
import useCheckPermissions from '../../hooks/useCheckPermissions';
import { Options } from '../../utils/model';
import { useGetDepartments } from '../../graphql/departments';
import useCheckRole from '../../hooks/useCheckRole';

export const InviteHcp = ({ inModal, methodModal }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const invite_send = t?.notifications.invite_send;
  const hcp_get_invite_email = t?.notifications.hcp_get_invite_email;
  const invite_another_hcp = t?.title.invite_another_hcp || 'Invite another HCP';
  const ok = t?.notifications.ok;
  const you_dont_have_permission = t?.common.you_dont_have_permission;

  // Endpoints
  const { _onInvite, hcp, errorHcp, loading } = useInviteHcp();
  const { professions } = useGetProfessions();
  const { hospitalNames } = useGetHospitalNames();
  const { departments } = useGetDepartments();

  // Local state
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [hcpIsInvited, setHcpInvited] = useState(false);

  // Data
  const { isAdmin } = useCheckRole();
  const isPermissionAddAnotherHcp = useCheckPermissions(PERMISSIONS.INVITE_OR_ADD_HCP_OTM);
  const loadingPermission = useSelector<GetState>((state) => getHcpNameLoading(state));
  const userCountry: any = useSelector<GetState>((state) => getUserCountry(state));
  const hospitalNameOptions = useMemo(() => getSelectOptions(hospitalNames), [hospitalNames]);
  const options: Options[] | [] = useMemo(() => getOptionsFromIdType(professions), [professions]);
  const departmentOptions = useMemo(() => getOptionsFromIdType(departments), [departments]);

  const editProfile_otm = PERMISSIONS.EDIT_PATIENT_PROFILE_INFO_OTM;
  const inviteHcp_otm = PERMISSIONS.INVITE_OR_ADD_HCP_OTM;
  const editExercise_otm = PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_OTM;
  const addVideo_otm = PERMISSIONS.ADD_VIDEOS_TO_TEAM_VIDEOBANK_OTM;
  const createTeam_otm = PERMISSIONS.CREATE_TEAM_OTM;

  const editProfile_hcp = PERMISSIONS.EDIT_PATIENT_PROFILE_INFO_HCP;
  const inviteHcp_hcp = PERMISSIONS.INVITE_OR_ADD_HCP_HCP;
  const editExercise_hcp = PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_HCP;
  const addVideo_hcp = PERMISSIONS.ADD_VIDEOS_TO_TEAM_VIDEOBANK_HCP;

  // HCP
  const editProfile_otm_val = useCheckPermissions(editProfile_hcp);
  const inviteHcp_otm_val = useCheckPermissions(inviteHcp_hcp);
  const editExercise_otm_val = useCheckPermissions(editExercise_hcp);
  const addVideo_otm_val = useCheckPermissions(addVideo_hcp);

  const initialValuesHcp = {
    firstName: '',
    lastName: '',
    profession: null,
    phone: '+',
    email: '',
    organisationName: '',
    department: null,

    [editProfile_otm]: isAdmin ? true : editProfile_otm_val,
    [inviteHcp_otm]: isAdmin ? true : inviteHcp_otm_val,
    [editExercise_otm]: isAdmin ? true : editExercise_otm_val,
    [addVideo_otm]: isAdmin ? true : addVideo_otm_val,
    [createTeam_otm]: false,

    [editProfile_hcp]: useCheckPermissions(editProfile_hcp),
    [inviteHcp_hcp]: useCheckPermissions(inviteHcp_hcp),
    [editExercise_hcp]: useCheckPermissions(editExercise_hcp),
    [addVideo_hcp]: useCheckPermissions(addVideo_hcp),
  };

  const send = (customer: InviteHCPCustomer): void => {
    const {
      email,
      firstName,
      lastName,
      profession,
      department,
      phone,
      organisationName,
    } = customer;

    const hcpInviteData: InvitedHcp = {
      email,
      firstName,
      lastName,
      phone: `+${phone}`,
      professionId: +profession.value,
      hospitalId: organisationName.value,
      departmentId: +department.value,
    };

    // Add permissions
    const inviteHcp: any = {
      hcpInviteData,
      hcpPermissions: [],
    };
    const { hcpPermissions } = inviteHcp;

    // 1 block
    if (customer.editPatientProfileInfo_otm) {
      hcpPermissions.push(PERMISSIONS.EDIT_PATIENT_PROFILE_INFO_OTM);
    }
    if (customer.inviteOrAddHcp_otm) {
      hcpPermissions.push(PERMISSIONS.INVITE_OR_ADD_HCP_OTM);
    }
    if (customer.editOrPrescribeExerciseVideos_otm) {
      hcpPermissions.push(PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_OTM);
    }
    if (customer.addVideosToTeamVideoBank_otm) {
      hcpPermissions.push(PERMISSIONS.ADD_VIDEOS_TO_TEAM_VIDEOBANK_OTM);
    }
    if (customer.createTeam_otm) {
      hcpPermissions.push(PERMISSIONS.CREATE_TEAM_OTM);
    }

    // 2 block
    if (customer.editPatientProfileInfo_hcp) {
      hcpPermissions.push(PERMISSIONS.EDIT_PATIENT_PROFILE_INFO_HCP);
    }
    if (customer.inviteOrAddHcp_hcp) {
      hcpPermissions.push(PERMISSIONS.INVITE_OR_ADD_HCP_HCP);
    }
    if (customer.editOrPrescribeExerciseVideos_hcp) {
      hcpPermissions.push(PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_HCP);
    }
    if (customer.addVideosToTeamVideoBank_hcp) {
      hcpPermissions.push(PERMISSIONS.ADD_VIDEOS_TO_TEAM_VIDEOBANK_HCP);
    }
    _onInvite(inviteHcp);
    setConfirmationMessage(
      `${hcpInviteData.firstName} ${hcpInviteData.lastName} ${hcp_get_invite_email}`,
    );
  };

  const loaderJSX = loading && <Loading />;
  const FormJSX = !hcpIsInvited && (
    <FormFormik
      component={InviteHcpForm}
      initialValues={initialValuesHcp}
      onSend={send}
      errorMessage={errorHcp}
      schema={(): ObjectSchema<any | undefined> => validationInviteHcp(t, userCountry)}
      options={options}
      hospitalNameOptions={hospitalNameOptions}
      departmentOptions={departmentOptions}
    />
  );

  // If the invitation is sent to HCP successfully hide form and show success page
  useEffect(() => {
    if (hcp) {
      setHcpInvited(() => true);
    }
  }, [hcp]);

  const clearForm = (): void => {
    setHcpInvited(() => false);
  };

  const onSuccess = hcpIsInvited && (
    <div className={styles['form__success-msg-container']}>
      <div className={styles['form__success-msg']}>{confirmationMessage}</div>

      {!inModal && (
        <Button
          buttonType="button"
          buttonName={invite_another_hcp}
          buttonClass={styles['form__btn--connect']}
          buttonMethod={clearForm}
        />
      )}
    </div>
  );

  return (
    <>
      {loaderJSX}
      {isPermissionAddAnotherHcp ? FormJSX : <></>}
      {!isPermissionAddAnotherHcp && !loadingPermission ? (
        <div>{you_dont_have_permission}</div>
      ) : (
        <></>
      )}
      {!loading && onSuccess}
      {hcp && !loading && inModal && (
        <Button
          buttonMethod={methodModal}
          buttonName={ok}
          buttonType="button"
          buttonClass={styles.btn_ok}
        />
      )}
    </>
  );
};
