import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Pagination, Switch } from 'antd';
import cx from 'classnames';
import { toast } from 'react-toastify';

import styles from './styles.module.css';
import { Button, Loading } from '../../../common';
import { getCurrenLang } from '../../../redux/selector';
import { EXERCISE_TYPE, VIDEO_BANK_TYPE } from '../../../utils/enums';
import { FilterByExercise } from '../../Filter/ExerciseFilter';
import { initialPage } from '../../../utils/helper';
import {
  useQueryOrgVideoExercises,
  useQueryTeamVideoExercisesMatch,
} from '../../../graphql/videoBank';
import { PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE } from '../../../utils/variables';
import VideoExercise from './components/VideoExercise';
import FolderRow from './components/FolderRow';
import CreateFolder from './components/CreateFolder';
import TableHeader from './components/TableHeader';
import { path } from '../../../utils';
import useGetFocuses from '../../../hooks/useGetFocuses';
import {
  useCurrentHcpProfileForToggler,
  useSetAllowPatientUnarchiveExercise,
} from '../../../graphql/hcpProfile';
import { WarnNotifModal } from '../../../common/NotificationModal';
import { SelectElem } from './components/HeaderItems';

const OrganisationVideoBank = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const no_data = t?.common.no_data;
  const on = t?.common.on;
  const off = t?.common.off;
  const ok = t?.common.ok;
  const cancel = t?.common.cancel;
  const add_to_folder = t?.dashboard.hcp.profile_patient.add_to_folder;
  const add_media = t?.hcp.org_video_bank.add_media;
  const allow_patients = t?.dashboard.hcp.profile_patient.allow_patients_to_archive_unarchive;
  const change_will_apply_all_patients =
    t?.hcp.org_video_bank.change_will_apply_all_patients ||
    'This change will apply to all patients in the organisation.';
  const happy_to_proceed = t?.hcp.rehab_teams.happy_to_proceed;
  const you_can_still_turn =
    t?.hcp.org_video_bank.you_can_still_turn ||
    'You can still turn the archive function on or off for individual patients directly from their dashboard.';

  const history = useHistory();

  // Queris
  // get list video exercises for Team video bank page from the database
  const {
    _getOrgVideoExercises,
    orgVideoExersises,
    loading,
    orgVideoExercisesData,
  } = useQueryOrgVideoExercises();

  // Get hospital custom settings for Manage Archive / unarchive
  // exercises for patient in his/her App
  const {
    _getTogglerSetting,
    dataTogglerSetting,
    loadingTogglerSettings,
    errorTogglerSettings,
    togglerData,
  } = useCurrentHcpProfileForToggler();
  const {
    _changeAllowUnarchiveExercise,
    changedAllowUnarchiveExercise,
    allowUnarchiveExerciseError,
    allowUnarchiveExerciseLoading,
  } = useSetAllowPatientUnarchiveExercise();

  // Local state
  const [videoExersisesList, setVideoExersisesList] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [pageSize, setPageSize] = useState<number>(15);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [focusFilter, setFocusFilter] = useState<number[]>([]);
  const [folderFilter, setFolderFilter] = useState(false);
  const [selectExercise, setSelectExercise] = useState<number[]>([]);
  const [isExerciseFolderDetails, setExerciseFolderDetails] = useState(false);
  const [isArchiveWarning, setArchiveWarning] = useState<any>({ isShow: false, solution: null });

  const [sortBy, setSortby] = useState<string>('name');
  const [sortDirectionName, setSortDirectionName] = useState<string>('');

  const [isSearch, setIsSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [hideSearchMenu, setHideSearchMenu] = useState(false);

  const {
    focusType1Arr,
    focusType2Arr,
    exercisesCategories,
    exercisesProcedureFocus,
    procedureFilterConfig,
    loadingFocuses,
  } = useGetFocuses();

  useEffect(() => {
    _getTogglerSetting();
  }, []);

  useEffect(() => {
    if (changedAllowUnarchiveExercise && !allowUnarchiveExerciseError) {
      _getTogglerSetting();
      setArchiveWarning({ isShow: false, solution: null });
    }
  }, [changedAllowUnarchiveExercise]);

  useEffect(() => {
    if (allowUnarchiveExerciseError) {
      toast.error(allowUnarchiveExerciseError);
    }
  }, [allowUnarchiveExerciseError]);

  useEffect(() => {
    if (errorTogglerSettings) {
      toast.error(errorTogglerSettings);
    }
  }, [errorTogglerSettings]);

  // After changing the filter settings, updating the list of exercises
  useEffect(() => {
    _getOrgVideoExercises(
      initialPage,
      pageSize,
      sortBy,
      sortDirectionName,
      searchQuery,
      folderFilter,
      focusFilter,
    );
    setCurrentPage(initialPage);
  }, [focusFilter, folderFilter]);

  // After receiving the list of Org exercises from the database, display them on the UI
  useEffect(() => {
    if (orgVideoExersises) {
      setVideoExersisesList(orgVideoExersises.list);
    } else if (!loading) {
      setVideoExersisesList(null);
    }
  }, [orgVideoExercisesData]);

  // Display the number of document pages in pagination
  useEffect(() => {
    if (orgVideoExersises?.totalItems) {
      setTotalItems(orgVideoExersises.totalItems);
    } else if (!loading) {
      setTotalItems(0);
    }
  }, [orgVideoExersises?.totalItems]);

  /* * search for matches in the database * */
  const [searchMatches, setSearchMatches] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState('');

  // get match results for video exercises for Team video bank page
  const {
    _getTeamVideoExercisesMatch,
    teamVideoExercisesMatch,
    loadingMatch,
  } = useQueryTeamVideoExercisesMatch();

  // Find search matches in DB
  const findMatches = (searchVal: string): void => {
    if (!searchVal) {
      setSearchMatches(null);
      setSearchInputValue('');
      return;
    }
    setSearchInputValue(searchVal);
    _getTeamVideoExercisesMatch({
      variables: {
        listProps: {
          page: initialPage,
          itemsPerPage: pageSize,
        },
        filterFocus: focusFilter,
        isFolder: folderFilter,
        searchProps: [
          {
            searchField: 'exercise.name',
            searchValue: searchVal,
          },
        ],
      },
    });
  };

  // Show the result from the database in the search menu
  useEffect(() => {
    if (!searchInputValue || !hideSearchMenu) return;
    if (!loadingMatch) {
      const current = teamVideoExercisesMatch;
      setSearchMatches(current);
    }
  }, [teamVideoExercisesMatch]);

  // Get search exercise name
  const handleSearch = (val: string): void => {
    setSearchQuery(val);
    setSearchMatches(null);
    setHideSearchMenu(!hideSearchMenu);
    if (val) {
      setCurrentPage(initialPage);
      _getOrgVideoExercises(
        initialPage,
        pageSize,
        sortBy,
        sortDirectionName,
        val,
        folderFilter,
        focusFilter,
      );
      setIsSearch(() => true);
    }
  };

  // Reset search exercise name
  const handleReset = (): void => {
    setSearchQuery('');
    setSearchMatches(null);
    setHideSearchMenu(!hideSearchMenu);
    if (searchQuery) {
      setCurrentPage(initialPage);
      _getOrgVideoExercises(
        initialPage,
        pageSize,
        sortBy,
        sortDirectionName,
        '',
        folderFilter,
        focusFilter,
      );
      setIsSearch(() => false);
    }
  };
  /** */

  // Sorting
  // const tooltipTitleTextByName = setSortDirectionTitle(sortDirectionName, t);

  // Sort exercise list by Column Name
  const sortByColumnName = (name: string, sortdirection: string): void => {
    setSortby(name);
    setSortDirectionName(sortdirection);
    _getOrgVideoExercises(
      currentPage,
      pageSize,
      name,
      sortdirection,
      searchQuery,
      folderFilter,
      focusFilter,
    );
  };

  // Exercise List Update
  const updateList = (): void => {
    _getOrgVideoExercises(
      currentPage,
      pageSize,
      sortBy,
      sortDirectionName,
      searchQuery,
      folderFilter,
      focusFilter,
    );
  };

  // Go to the next page
  const onChangePagination = (page: any, itemsPerPage: any): void => {
    setPageSize(itemsPerPage);
    setCurrentPage(page);
    _getOrgVideoExercises(
      page,
      itemsPerPage,
      sortBy,
      sortDirectionName,
      searchQuery,
      folderFilter,
      focusFilter,
    );
  };

  // Redirect to Add Media page
  const openAddMediaPage = (): void => {
    history.push({
      pathname: path.addMedia,
    });
  };

  // Add exercise to folder
  const openExerciseFolderDetails = (): void => {
    setExerciseFolderDetails(() => true);
  };

  const isChecked = useMemo(
    () => dataTogglerSetting?.hospital?.hospitalExercise.isAllowPatientUnarchive,
    [togglerData],
  );
  const switchHandler = (checked: boolean): void => {
    setArchiveWarning({ isShow: true, solution: checked });
  };
  const onConfirm = (): void => {
    if (isArchiveWarning.solution !== null) {
      _changeAllowUnarchiveExercise(isArchiveWarning.solution);
    }
  };
  const onCancel = (): void => {
    setArchiveWarning({ isShow: false, solution: null });
  };

  // JSX
  const loadingJSX = (loading || loadingFocuses || allowUnarchiveExerciseLoading) && <Loading />;
  const noData = !videoExersisesList?.length && !loading && (
    <div className={styles.noData}>{no_data}</div>
  );
  const contentJsx = (
    <div>
      <div className={styles['org-video-bank__warning-content']}>
        {change_will_apply_all_patients}
      </div>
      <div className={styles['org-video-bank__warning-content']}>{you_can_still_turn}</div>
      <div className={styles['org-video-bank__warning-content']}>{happy_to_proceed}</div>
    </div>
  );
  const switchContainerJsx = (
    <div
      className={cx([
        styles['org-video-bank__switch-container'],
        styles['org-video-bank__wrapper--pd'],
      ])}
    >
      {/* Allow patients to archive or unarchive exercise videos */}
      <div className={styles['org-video-bank__switch']}>
        <Switch
          checkedChildren={<span>{on}</span>}
          unCheckedChildren={<span>{off}</span>}
          loading={allowUnarchiveExerciseLoading || loadingTogglerSettings}
          checked={isChecked || false}
          onChange={switchHandler}
        />
        <span className={styles['org-video-bank__switch-name']}>{allow_patients}</span>
      </div>

      <Button
        buttonType="button"
        buttonName={add_media}
        buttonMethod={openAddMediaPage}
        buttonClass={styles['org-video-bank__btn--add-media']}
      />
    </div>
  );
  return (
    <div className={styles['org-video-bank__container']}>
      {loadingJSX}

      {/* Switch / button - big screen */}
      <div className={styles.bg__wrapper}>{switchContainerJsx}</div>

      {/* FILTER */}
      {t && (
        <div
          className={cx([
            styles['org-video-bank__filter-container'],
            styles['org-video-bank__wrapper--pd'],
          ])}
        >
          <FilterByExercise
            filterConfig={procedureFilterConfig}
            focusFilter={focusFilter}
            setFocusFilter={setFocusFilter}
            folderFilter={folderFilter}
            setFolderFilter={setFolderFilter}
            isFolder
          />
        </div>
      )}

      {/* Select sm screen */}
      <div className={styles['org-video-bank__select-container']}>
        <SelectElem
          selectElem={selectExercise}
          setSelectElem={setSelectExercise}
          openMethod={openExerciseFolderDetails}
          disabled={false}
          submitBtnName={add_to_folder}
        />
      </div>

      {/* Header */}
      <TableHeader
        selectExercise={selectExercise}
        setSelectExercise={setSelectExercise}
        selectAction={openExerciseFolderDetails}
        sortByColumnName={sortByColumnName}
        sortBy={sortBy}
        handleSearch={handleSearch}
        handleReset={handleReset}
        searchMatches={searchMatches}
        findMatches={findMatches}
        hideSearchMenu={hideSearchMenu}
        isSearch={isSearch}
        setHideSearchMenu={setHideSearchMenu}
        setSearchMatches={setSearchMatches}
        actionBtnName={add_to_folder}
      />

      {/* Organisation video exercises */}
      {noData}
      {videoExersisesList &&
        exercisesCategories &&
        Object.keys(exercisesCategories).length > 0 &&
        exercisesProcedureFocus &&
        videoExersisesList.map((videoExerciseItem: any, index: number) => {
          // const keyObj = JSON.stringify(videoExerciseItem);
          const keyObj = `${videoExerciseItem.currentId}${videoExerciseItem.type}${index}`;
          return (
            <React.Fragment key={`fragment${keyObj}`}>
              {(videoExerciseItem.type === EXERCISE_TYPE.PHOTO ||
                videoExerciseItem.type === EXERCISE_TYPE.VIDEO) && (
                <VideoExercise
                  data={videoExerciseItem}
                  index={String(index)}
                  exercisesCategories={exercisesCategories}
                  exercisesProcedureFocus={exercisesProcedureFocus}
                  focusType1Arr={focusType1Arr}
                  focusType2Arr={focusType2Arr}
                  key={keyObj}
                  updateList={updateList}
                  selectExercise={selectExercise}
                  setSelectExercise={setSelectExercise}
                  type={VIDEO_BANK_TYPE.TEAM_VIDEO_BANK}
                />
              )}
              {videoExerciseItem.type === EXERCISE_TYPE.FOLDER && (
                <FolderRow data={videoExerciseItem} />
              )}
            </React.Fragment>
          );
        })}

      {!noData && videoExersisesList?.length > 0 && (
        <div className={styles.wrapper_pagination}>
          <Pagination
            size="small"
            current={currentPage}
            pageSize={pageSize}
            total={totalItems}
            onChange={onChangePagination}
            pageSizeOptions={PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE}
            showSizeChanger
          />
        </div>
      )}

      {/* Switch / button - sm screen */}
      <div className={styles.sm__wrapper}>{switchContainerJsx}</div>

      {/* Popups */}
      {/* Exercise folder details */}
      {isExerciseFolderDetails && (
        <CreateFolder
          selectExercise={selectExercise}
          setSelectExercise={setSelectExercise}
          setExerciseFolderDetails={setExerciseFolderDetails}
          updateList={updateList}
        />
      )}

      {/* Warning for archive(unarchive) */}
      {isArchiveWarning.isShow && (
        <WarnNotifModal
          onClose={onCancel}
          content={contentJsx}
          cancelBtnName={cancel}
          actionBtnName={ok}
          actionMethod={onConfirm}
        />
      )}
    </div>
  );
};

export default OrganisationVideoBank;
