import React, { useState, useEffect, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';

import styles from './styles.module.css';
import {
  GetState,
  getCurrenLang,
  getHcpName,
  getPatientsInvitationData,
  getPatientsInvitationLoading,
  getPatientsInvitationTotal,
} from '../../redux/selector';
import { Button, ConfirmatioModal, Loading, Modal, WarningNotification } from '../../common';
import {
  useGetSharingNotification,
  useSetHcpInformedAboutSharing,
  useSharingTeamVideobank,
} from '../../graphql/hcp';
import { useQueryUserName, useQueryAdminCountry, useQueryUserPic } from '../../graphql/hcpProfile';
import { usePatientsPendingInvitations } from '../../graphql/patients';
import useCheckRole from '../../hooks/useCheckRole';
import { storage } from '../../utils';
import { CloseIcon } from '../../theme/icons';
import { NotificationSucceess } from '../../common/NotificationModal';
import PandingPatientInvitations from '../patientInvitations/patientInvitations';
import Feedback from '../Feedback';
import { useGetKeysOfProcedures, useGetProcedures } from '../../graphql/procedures';
import { useQueryExerciseCategories } from '../../graphql/videoBank';
import { CloseBtn } from '../../common/Button/Button';
import useCheckLocale from '../../hooks/useCheckLocale';
import { useGetHcpNotificationList } from '../../graphql/notifications';

const PrivateCommonComponent = (): ReactElement => {
  // Redux state
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const hcp = useSelector((state: GetState) => getHcpName(state));
  const patientsInvitations = useSelector((state: GetState) => getPatientsInvitationData(state));
  const total = useSelector((state: GetState) => getPatientsInvitationTotal(state));
  const listLoading = useSelector((state: GetState) => getPatientsInvitationLoading(state));

  // Local state
  const [isModalOpen, setModal] = useState(false);
  const [isShareWarning, setWarning] = useState(false);
  const [isSharedSuccess, setSharedSuccess] = useState(false);
  const [isModalShareVideo, setModalShareVideo] = useState(false);

  // Variables
  const { isAdmin, isHcp } = useCheckRole();
  const showModalDate = storage.get('show-modal-date');
  const today = moment().format('DD-MM-YYYY');
  const yes = t?.common.yes;
  const no = t?.common.no;
  const ok = t?.common.ok;
  const success = t?.profile_hcp.success;
  const video_successfully_shared = t?.hcp.manage.video_successfully_shared;
  const share = t?.common.share;
  const all_video_will_be_available = t?.hcp.manage.all_video_will_be_available;

  // Endpoints
  // const {_getUserName, loading, hcp } = useQueryUserName(!isHcp);
  const { _getUserName, loading, hcpPhotoUuid } = useQueryUserName(!hcp.recipients?.length);
  useQueryAdminCountry(!isAdmin);
  const {
    _getPatientInvitations,
    // patientsInvitations,
    // total,
    // listLoading,
  } = usePatientsPendingInvitations();
  const { _getSharingNotification, dataSharingNotif } = useGetSharingNotification();
  const {
    _setHcpInformedAboutSharing,
    setHcpInformedLoading,
    setHcpInformedError,
    isSetHcpInformed,
  } = useSetHcpInformedAboutSharing();

  // Get and save to redux
  useGetKeysOfProcedures();
  useGetProcedures();
  const { _getExerciseCategoriesList } = useQueryExerciseCategories();

  // Share VideoBank to another HCP(Hospital)
  const { _shareVideobank, isSharingSuccess, shareLoading, shareError } = useSharingTeamVideobank();
  const { _getUserPic } = useQueryUserPic();

  // Get Hcp notifications
  const { _getHcpNotifications } = useGetHcpNotificationList();
  console.log('***dashboard_PrivateCommonComponent');

  const locale = useCheckLocale();
  const { lng, lacaleArr } = locale;
  moment.locale(lng, lacaleArr);

  // Redux
  // const notificationsStatus: any = useSelector<any>(
  //   (state) => state.notifications.notificationsStatus,
  // );
  // const itemsPerPage: any = useSelector<any>((state) => state.notifications.itemsPerPage);
  // const currentPage: any = useSelector<any>((state) => state.notifications.currentPage);

  const { currentPage, searchQuery, itemsPerPage, notificationsStatus }: any = useSelector<any>(
    (state) => state.notifications,
  );

  useEffect(() => {
    _getExerciseCategoriesList();
    if (!isAdmin) {
      _getUserName();
      _getPatientInvitations({
        variables: {
          listProps: {
            page: 1,
            itemsPerPage: 5,
          },
          pending: true,
          searchByDate: [],
        },
      });
      _getHcpNotifications(notificationsStatus, currentPage, itemsPerPage, searchQuery);
    }
  }, []);

  useEffect(() => {
    if (!isAdmin) {
      _getHcpNotifications(notificationsStatus, currentPage, itemsPerPage, searchQuery);
    }
  }, [notificationsStatus]);

  // Download and save to redux HCP (user) avatar
  useEffect(() => {
    if (!isAdmin && hcpPhotoUuid) {
      _getUserPic(hcpPhotoUuid);
    }
  }, [hcpPhotoUuid]);

  useEffect(() => {
    if (patientsInvitations && patientsInvitations.length > 0) {
      let isNewRequest = false;

      if (showModalDate.patientsIds) {
        patientsInvitations.map((patient: { id: number }) => {
          if (!showModalDate.patientsIds.includes(patient.id)) {
            isNewRequest = true;
          }
          return null;
        });
      }

      if (isNewRequest) {
        setModal(() => true);
        return;
      }

      if (showModalDate.today !== today) {
        setModal(() => true);
      }
    }
  }, [patientsInvitations]);

  const onclose = (): void => {
    const patientsIds = patientsInvitations.map((patient: { id: number }) => patient.id);
    storage.save('show-modal-date', {
      today,
      patientsIds,
    });
    setModal(() => false);
  };

  // If share error then show message.
  useEffect(() => {
    if (isSharingSuccess) {
      setWarning(() => false);
      setSharedSuccess(() => true);
      _setHcpInformedAboutSharing(hcp.recipients[0].id);
    }
  }, [isSharingSuccess]);

  // If share error then show message.
  useEffect(() => {
    if (shareError) {
      toast.error(shareError);
      _setHcpInformedAboutSharing(hcp.recipients[0].id);
    }
  }, [shareError]);

  // If success then update User params.
  useEffect(() => {
    if (isSetHcpInformed && !isAdmin) {
      _getUserName();
    }
  }, [isSetHcpInformed]);

  // If error show message.
  useEffect(() => {
    if (setHcpInformedError && !isAdmin) {
      toast.error(setHcpInformedError);
      _getUserName();
    }
  }, [setHcpInformedError]);

  // Check if there are any unread notifications about sharing TeamVideobank from another hospital.
  useEffect(() => {
    if (dataSharingNotif) {
      setModalShareVideo(() => true);
    }
  }, [dataSharingNotif]);

  // Check if there are any unread notifications about sharing TeamVideobank from another hospital.
  useEffect(() => {
    if (hcp?.recipients.length > 0) {
      _getSharingNotification({
        variables: {
          hcpId: hcp.recipients[0].initiatorId,
        },
      });
    }
  }, [hcp.recipients]);

  // Clouse shareModal and send to DB cancel
  const onCancelShare = (): void => {
    setModalShareVideo(() => false);
    setWarning(() => false);
    _setHcpInformedAboutSharing(hcp.recipients[0].id);
  };

  // Share team video bank to another organisation
  const showWarningToShareVideo = (): void => {
    setModalShareVideo(() => false);
    setWarning(() => true);
  };
  // Send request to DB
  const shareVideoBank = (): void => {
    _shareVideobank(hcp.recipients[0].initiatorId);
  };
  // JSX
  // const loadingJSX = setHcpInformedLoading || shareLoading || loading ? <Loading /> : <></>;

  return (
    <>
      {/* Popup for pending patients */}
      {isModalOpen && !isModalShareVideo && !isShareWarning && !isSharedSuccess && (
        <Modal onClose={onclose} style={styles['pending-modal']}>
          <CloseBtn close={onclose} />
          <PandingPatientInvitations
            isModal
            _getPatientInvitations={_getPatientInvitations}
            patientsInvitations={patientsInvitations}
            total={total}
            listLoading={listLoading}
          />
        </Modal>
      )}

      {/* Popup if share team videobank anoter HCP */}
      {isModalShareVideo && !isModalOpen && !isSharedSuccess && (
        <Modal style={styles['share-modal']} onClose={onCancelShare}>
          <div className="btn-close">
            <CloseIcon onClick={onCancelShare} />
          </div>
          <div>
            <NotificationSucceess
              title={dataSharingNotif?.title || ''}
              contant={dataSharingNotif?.body || ''}
            />
            <div className={styles['share-modal__btn-wrapper']}>
              <Button
                buttonType="button"
                buttonName={dataSharingNotif.isTwoWaySharing ? ok : no}
                buttonMethod={onCancelShare}
              />
              {!dataSharingNotif.isTwoWaySharing && (
                <Button
                  buttonType="button"
                  buttonName={yes}
                  buttonMethod={showWarningToShareVideo}
                />
              )}
            </div>
          </div>
        </Modal>
      )}
      {isShareWarning && (
        <Modal style={styles.modal} onClose={onCancelShare}>
          <div className="btn-close">
            <CloseIcon onClick={onCancelShare} />
          </div>
          <WarningNotification
            title=""
            contant={
              all_video_will_be_available?.replace(
                '<organisationName>',
                hcp.recipients?.[0]?.hcpInitiator?.hospital?.name || '',
              ) || ''
            }
            buttonName={share}
            onCancel={onCancelShare}
            onSubmit={shareVideoBank}
          />
        </Modal>
      )}
      {isSharedSuccess && (
        <Modal style={styles.modal__success} onClose={(): void => setSharedSuccess(() => false)}>
          <ConfirmatioModal
            title={success}
            contant={video_successfully_shared}
            buttonTitle={ok}
            buttonMethod={(): void => setSharedSuccess(() => false)}
          />
        </Modal>
      )}
      {isHcp ? <Feedback /> : <></>}
    </>
  );
};

export default PrivateCommonComponent;
