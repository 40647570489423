import React, { ReactElement, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from 'antd';

import style from './style.module.css';
import pageStyle from '../../../pages/styles.module.css';
import { Loading, MainTitle } from '../../../common';
import { InputFloatLabel, TextareaFeildFloatLabel } from '../../../common/Input';
import { Button } from '../../../common/Button/Button';
import { useHcpListForSearchHcp } from '../../../graphql/hcp';
import { MEMBER_TYPE, PERMISSIONS, TEAM_CATEGORY } from '../../../utils/enums';
import {
  useGetHospitalPatientsForSearch,
  useSaveHospitalTeam,
} from '../../../graphql/hospitalTeam';
import { saveHospitalTeamValidation } from '../../../utils/validators';
import { ShowErrorNotification } from '../../Form/ErrorMessage';
import { path } from '../../../utils';
import useCheckPermissions from '../../../hooks/useCheckPermissions';
import MemberList from '../MemberList';
import { initialPage } from '../../../utils/helper';
import { SuccessNotifModal } from '../../../common/NotificationModal';

interface CreateTeamProps {
  t: any;
  isConnectPatientPage?: boolean;
  closePage: any;
  setCreatedNewTeam: any;
}

const CreateTeam = ({
  t,
  closePage,
  setCreatedNewTeam,
  isConnectPatientPage = false,
}: CreateTeamProps): ReactElement => {
  const save_t = t?.common.save;
  const cancel = t?.common.cancel;
  const ok = t?.common.ok;
  const create_team = t?.hcp.manage.create_team;
  const team_name = t?.hcp.manage.team_name;
  const add_hcp = t?.common.add_hcp;
  const add_patients = t?.common.add_patients;
  const hcp_list = t?.title.hcp_list;
  const patient_list = t?.title.patient_list;
  const descr_team_will_not_appear = t?.hcp.manage.descr_team_will_not_appear;
  const was_successfully_created = t?.hcp.manage.was_successfully_created;
  const you_dont_have_permission = t?.common.you_dont_have_permission;
  const create_team_descr =
    t?.hcp.rehab_teams?.create_team_descr ||
    'Create a team of healthcare professionals for your patients here. This allows team members who are not directly connected to a patient to still have access to their information.';

  // Local state
  const [pageSize, setPageSize] = useState(10);
  const [addedHcps, setAddedHcps] = useState<any>([]);
  const [addedHcpIds, setAddedHcpIds] = useState<any>([]);
  const [addedPatients, setAddedPatients] = useState<any>([]);
  const [addedPatientIds, setAddedPatientIds] = useState<any>([]);
  const [isAddPatientsAutomatically, setAddPatientsAutomatically] = useState(!isConnectPatientPage);
  const [isSuccessCreateTeam, setSuccessCreateTeam] = useState(false);

  const history = useHistory();
  const isPermissionCreateTeam = useCheckPermissions(PERMISSIONS.CREATE_TEAM_OTM);

  // Endpoints
  const {
    _getHcpListForSearchHcp,
    hcpListLoading,
    hcpList,
    totalLength,
  } = useHcpListForSearchHcp();

  const {
    _saveHospitalTeam,
    hospitalTeamSaved,
    saveHospitalTeamLoading,
    saveHospitalTeamError,
  } = useSaveHospitalTeam();

  const {
    _getPatientListForSearchHcp,
    patientList,
    total,
    patientListLoading,
  } = useGetHospitalPatientsForSearch();

  const initialValues = {
    name: '',
    description: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: () => saveHospitalTeamValidation(t),
    onSubmit: async (requestData: any) => {
      const { name, description } = requestData;
      const request = {
        patientIds: isAddPatientsAutomatically ? [] : addedPatientIds,
        isAutomaticallyAddPatients: isAddPatientsAutomatically,
        hcpIds: addedHcpIds,
        hospitalTeamData: {
          name: name.trim(),
          description: description.trim(),
        },
      };
      _saveHospitalTeam(request);
    },
  });

  const { values, errors, touched } = formik;

  // Query get hcp list for search
  const getHcpListForSearch = (page: number, searchValue: string): void =>
    _getHcpListForSearchHcp({
      variables: {
        listProps: {
          page,
          itemsPerPage: pageSize,
        },
        searchProps: [
          {
            searchField: 'hcp.firstName',
            searchValue,
          },
          {
            searchField: 'hcp.lastName',
            searchValue,
          },
          {
            searchField: 'hcp.email',
            searchValue,
          },
        ],
      },
    });

  // Query get hcp list for search
  const getPatientListForSearch = (page: number, searchValue: string): void =>
    _getPatientListForSearchHcp({
      variables: {
        listProps: {
          page,
          itemsPerPage: pageSize,
        },
        searchProps: [
          {
            searchField: 'patient.firstName',
            searchValue,
          },
          {
            searchField: 'patient.lastName',
            searchValue,
          },
          {
            searchField: 'patient.email',
            searchValue,
          },
        ],
      },
    });

  // Redirect to correspond page
  const redirectTo = (): void => {
    history.push({
      pathname: path.rehabTeams,
      search: `category=${TEAM_CATEGORY.MY_TEAM}&page=${initialPage}`,
    });
  };

  // If team success created redirect to Manage Team page
  useEffect(() => {
    if (hospitalTeamSaved) {
      if (isConnectPatientPage) {
        toast.info(`${values.name} ${was_successfully_created}`);
        setCreatedNewTeam({ value: hospitalTeamSaved, label: values.name });
        closePage(() => false);
        return;
      }
      setSuccessCreateTeam(() => true);
    }
  }, [hospitalTeamSaved]);

  // JSX
  const loadingJSX = saveHospitalTeamLoading && <Loading />;
  const successCreateTeamContent = (
    <div className={style['team-settings__modal-description']}>
      {`${values.name} ${was_successfully_created}`}
    </div>
  );

  return (
    <>
      {loadingJSX}
      <MainTitle title={create_team} />
      <div className={pageStyle['section-description']}>{create_team_descr}</div>
      <form onSubmit={formik.handleSubmit} className={style.create_team__container}>
        <InputFloatLabel
          inputId="CreateTeamName"
          inputName="name"
          inputType="text"
          placeholder={team_name}
          hasErrors={errors.name}
          isTouched={touched.name}
          inputValue={values.name}
          onChangeMethod={formik.handleChange}
          isRedStar
          disabled={!isPermissionCreateTeam}
        />
        <TextareaFeildFloatLabel
          id="TeamDescription"
          inputName="description"
          placeholder={descr_team_will_not_appear}
          inputValue={values.description}
          hasErrors={errors.description}
          isTouched={touched.description}
          onChecngeMethod={formik.handleChange}
          rows={3}
          isRedStar
          disabled={!isPermissionCreateTeam}
        />

        {/* HCP List */}
        <div className={style.create_team__dash} />
        <MemberList
          t={t}
          title_t={hcp_list}
          add_member_t={add_hcp}
          listLoading={hcpListLoading}
          memberList={hcpList}
          total={totalLength}
          typeMember={MEMBER_TYPE.HCPS}
          isPermissionCreateTeam={isPermissionCreateTeam}
          getMemberListForSearch={getHcpListForSearch}
          addedMembers={addedHcps}
          setAddedMembers={setAddedHcps}
          setAddedMemberIds={setAddedHcpIds}
          selectedMembers={[]}
          isAddPatientsAutomatically={false}
          setAddPatientsAutomatically={(): null => null}
          isTooltip={false}
          isCreateTeam
        />

        {/* Patient List */}
        <MemberList
          t={t}
          title_t={patient_list}
          add_member_t={add_patients}
          listLoading={patientListLoading}
          memberList={patientList}
          total={total}
          typeMember={MEMBER_TYPE.PATIENTS}
          isPermissionCreateTeam={isPermissionCreateTeam}
          getMemberListForSearch={getPatientListForSearch}
          addedMembers={addedPatients}
          setAddedMembers={setAddedPatients}
          setAddedMemberIds={setAddedPatientIds}
          selectedMembers={[]}
          isAddPatientsAutomatically={isAddPatientsAutomatically}
          setAddPatientsAutomatically={setAddPatientsAutomatically}
          isTooltip
          isCreateTeam
        />

        {/* Errors */}
        {saveHospitalTeamError && (
          <div className={style.errorMessages}>
            <ShowErrorNotification errorMessage={saveHospitalTeamError} />
          </div>
        )}
        <div className={style.create_team__dash} />

        <Tooltip title={!isPermissionCreateTeam ? you_dont_have_permission : ''}>
          <div className={style.btns_container}>
            <Button
              buttonType="button"
              buttonName={cancel}
              buttonClass={style.create_team__btn}
              buttonMethod={(): void => closePage(() => false)}
            />
            <Button
              buttonType="submit"
              buttonName={save_t}
              buttonClass={style.create_team__btn}
              disabledButton={!!loadingJSX || !isPermissionCreateTeam || !formik.dirty}
            />
          </div>
        </Tooltip>
      </form>

      {/* Success create team */}
      {isSuccessCreateTeam && (
        <SuccessNotifModal
          content={successCreateTeamContent}
          onClose={(): void => redirectTo()}
          btnName={ok}
        />
      )}
    </>
  );
};

export default CreateTeam;
