import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { GetState, getCurrenLang } from '../../redux/selector';
import { BreadcrumbAnt } from '../../utils/routers/Breadcrumb';
import { InviteHcp } from '../../components/invite/InviteHcp';
import styles from '../styles.module.css';
import useCheckRole from '../../hooks/useCheckRole';
import { path } from '../../utils';
import { MainTitle } from '../../common';

const InviteHCPPage = (): ReactElement => {
  const t: any = useSelector<GetState>((state) => getCurrenLang(state));
  const invite_hcp = t?.title.invite_hcp;
  const patient_list = t?.title.patient_list;

  const { isAdmin } = useCheckRole();

  // const userPermissions: any = useSelector<GetState>((state) => getPermissions(state));

  // Breadcrumb path
  const routes = [
    {
      path: isAdmin ? path.adminDashboardList : path.dashboardList,
      breadcrumbName: patient_list,
    },
    {
      path: invite_hcp,
      breadcrumbName: invite_hcp,
    },
  ];

  return (
    <div className={styles.main__container}>
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={styles['main__flex-wrapper']}>
        {/* Connect Patient */}
        <div className={styles['left-section']}>
          <MainTitle title={invite_hcp} />
          <InviteHcp />
          {/* {(userPermissions.length > 0 || isAdmin) && <InviteHcp />} */}
        </div>
      </div>
    </div>
  );
};

export default InviteHCPPage;
