import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useQuery } from '@apollo/react-hooks';
import { sanitize } from 'dompurify';

import pageStyles from '../../styles.module.css';
import style from './style.module.css';
import { GetState, getCurrenLang, getHcpAvatarPic, getUserLang } from '../../../redux/selector';
import { Button, Loading, Modal, MainTitle, WarningNotification } from '../../../common';
import { useGetProfessions } from '../../../graphql/professions';
import { useGetHospitalNames } from '../../../graphql/hospitals';
import { GET_LANG_OPTIONS } from '../../../graphql/lang';
import {
  useDeleteHcpProfile,
  useQueryUserName,
  useQueryUserPic,
  useQueryUserProfile,
  useUpdateHcpProfile,
} from '../../../graphql/hcpProfile';
import { parseJwt, storage } from '../../../utils';
import { executeScroll, getOptionsFromIdType, getSelectOptions } from '../../../utils/helper';
import { ProfileForm } from '../../../components/Form/Profile';
import { DataProfile, Item } from './types';
import {
  CloseIcon,
  DeleteIcon,
  PersonGreyIcon,
  SuccessIcon,
  RedTickIcon,
} from '../../../theme/icons';
import { PopupWithTwoButtons } from '../../../common/Popup';
import { useGetDepartments } from '../../../graphql/departments';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import ChangePassword from './ChangePassword';
import { useTranslate } from '../../../graphql/translation';
import { config } from '../../../utils/configs';
import useCheckRole from '../../../hooks/useCheckRole';
import { CloseBtn } from '../../../common/Button/Button';
import { ProfileUserSecondaryPages } from '../../../utils/enums';
import ChangeHcpPhoto from './ChangeHcpPhoto';
import { otmLink } from '../../../utils/variables';

const ProfileUserPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const my_profile = t?.title.my_profile;
  const yes = t?.common.yes;
  const no = t?.common.no;
  const ok = t?.common.ok;
  const saved = t?.common.saved;
  const attention = t?.common.attention;
  const are_you_sure = t?.notifications.are_you_sure_disconnect;
  const delete_account = t?.profile_hcp.delete_account;
  const delete_account_warn_title = t?.notifications.del_hcp_account_warn_title;
  const delete_account_warn_cont = t?.notifications.del_hcp_account_warn_cont;
  const req_sent_to_admin = t?.notifications.req_sent_to_admin;
  const change_password = t?.common.change_password;
  const warning_unsaved_changes = t?.common.warning_unsaved_changes;
  const cancel = t?.common.cancel;
  const exit = t?.common.exit;
  const add_photo = t?.common.add_photo_title;
  const edit_photo = t?.common.edit_photo || 'Edit Photo';
  const change_photo = t?.common.change_photo || 'Change Photo';

  const req_sent_with_email = req_sent_to_admin?.replace('<email>', otmLink);
  const userLang: any = useSelector<GetState>((state) => getUserLang(state));
  const avatar = useSelector<GetState>((state) => getHcpAvatarPic(state));
  const sectionRef: any = useRef(null);

  // Common queris
  const { professions, loadingProfessions } = useGetProfessions();
  const { hospitalNames, loadingHospitalNames } = useGetHospitalNames();
  const { departments } = useGetDepartments();
  const { data: newData } = useQuery(GET_LANG_OPTIONS, {
    variables: {
      langCode: userLang,
    },
  });
  const langs = newData?.getLanguagesWithValue;

  // Profile queries
  const { loading, hcp } = useQueryUserProfile();
  const { updating, errorMessage, _updateProfile, updateData } = useUpdateHcpProfile();
  const { _deleteHcpProfile, deleteProcess, deleteError } = useDeleteHcpProfile(hcp?.email || '');
  const { _getTranslation } = useTranslate();
  const { _getUserName, loading: loadingName, hcpPhotoUuid } = useQueryUserName(false);
  const { _getUserPic } = useQueryUserPic();

  const [isDeleteAccountModal, setDeleteAccountModal] = useState(false);
  const [doubleCheckPopup, setDoubleCheckPopup] = useState(false);
  const [isChangeOrganisation, setChangeOrganisation] = useState(false);
  const [isRequstSentPopup, setRequstSentPopup] = useState(false);
  const [isOpenChangePassPage, setOpenChangePassPage] = useState(false);
  const [isProfileUpdated, setProfileUpdated] = useState(false);
  const [isOpenChangePhotoPage, setOpenChangePhotoPage] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [unsaveWarning, setUnsaveWarning] = useState<any>(false);

  const { isHcp } = useCheckRole();

  const updateApp = (): void => {
    // window.location.assign(path.profile);
    const lang = storage.get('lang');
    const langLS = lang || config.language;
    _getTranslation({
      variables: {
        translationData: {
          langCode: langLS,
        },
      },
    });
    _getUserName();
  };

  // Download and save to redux HCP (user) avatar
  useEffect(() => {
    if (hcpPhotoUuid) {
      _getUserPic(hcpPhotoUuid);
    }
  }, [hcpPhotoUuid]);

  // Scroll to elem
  useEffect(() => {
    if (isOpenChangePhotoPage || isOpenChangePassPage) {
      executeScroll(sectionRef);
    }
  }, [isOpenChangePhotoPage, isOpenChangePassPage]);

  // Update user credentials after save profile
  const updateUserCredentials = (newProfileData: any): void => {
    const { refresh_token, access_token: token } = newProfileData.updateHcpProfile;
    if (token) {
      const { email, id, role, lang } = parseJwt(token);

      const userCredentials = {
        email,
        id,
        lang,
        role,
        token,
        refresh_token,
      };
      storage.save('user', userCredentials);
      storage.save('lang', lang);
    }
  };

  // If update profile success, update data in local storage
  useEffect(() => {
    if (updateData) {
      setProfileUpdated(() => true);
      updateUserCredentials(updateData);
      if (isChangeOrganisation) {
        setRequstSentPopup(() => true);
      } else {
        updateApp();
      }
    }
  }, [updateData]);

  const closeReqSentPopup = (): void => {
    setRequstSentPopup(() => false);
    updateApp();
  };

  useEffect(() => {
    if (deleteError) {
      toast.error(deleteError);
    }
  }, [deleteError]);

  const _send = (req: DataProfile): void => {
    const {
      firstName,
      lastName,
      professionId,
      email,
      phone,
      organisationName,
      langId,
      department,
    } = req;
    const user: any = {
      firstName,
      lastName,
      professionId: professionId?.value,
      email,
      phone,
      langId: langId?.value,
      hospitalId: organisationName.value,
    };
    if (department) {
      user.departmentId = department.value;
    }
    if (organisationName.value !== hcp.hospital?.id) {
      setChangeOrganisation(true);
    }
    _updateProfile(user);
  };

  const options =
    langs &&
    langs.map((item: Item) => ({ value: item.id, label: item.description, code: item.code }));
  const hospitalNameOptions = useMemo(() => getSelectOptions(hospitalNames), [hospitalNames]);
  const professionOptions = useMemo(() => getOptionsFromIdType(professions), [professions]);
  const departmentOptions = useMemo(() => getOptionsFromIdType(departments), [departments]);

  const deleteHcpAccount = (): void => {
    _deleteHcpProfile();
  };

  // If the user closes a form with unsaved data
  const checkChanges = (callback: any): void => {
    if (hasChanges) {
      setUnsaveWarning(() => 'close');
      return;
    }
    callback();
  };

  // Clouse popup
  const onClosePopup = (): void => {
    setUnsaveWarning(() => false);
  };

  // Confirm method
  const onConfirm = (type: string): void => {
    setHasChanges(() => false);
    setUnsaveWarning(() => false);
    switch (type) {
      case 'close':
        setOpenChangePassPage(() => false);
        setOpenChangePhotoPage(() => false);
        return;
      case ProfileUserSecondaryPages.CHANGE_PASSWORD:
        setOpenChangePassPage(() => true);
        setOpenChangePhotoPage(() => false);
        return;
      case ProfileUserSecondaryPages.CHANGE_PHOTO:
        setOpenChangePassPage(() => false);
        setOpenChangePhotoPage(() => true);
        return;
      default:
        setOpenChangePassPage(() => false);
        setOpenChangePhotoPage(() => false);
    }
  };

  // Open secondary page
  const openPage = (type: ProfileUserSecondaryPages): void => {
    if (type === ProfileUserSecondaryPages.CHANGE_PASSWORD) {
      if (isOpenChangePassPage) {
        return;
      }
      if (hasChanges && isOpenChangePhotoPage) {
        setUnsaveWarning(() => type);
        return;
      }
      setOpenChangePhotoPage(() => false);
      setOpenChangePassPage(() => true);
    }
    if (type === ProfileUserSecondaryPages.CHANGE_PHOTO) {
      if (isOpenChangePhotoPage) {
        return;
      }
      if (hasChanges && isOpenChangePassPage) {
        setUnsaveWarning(() => type);
        return;
      }
      setOpenChangePhotoPage(() => true);
      setOpenChangePassPage(() => false);
    }
  };

  // JSX
  const profileFormJSX = !loading && hcp && professions && langs && (
    <>
      <ProfileForm
        hcp={hcp}
        options={options}
        hospitalNameOptions={hospitalNameOptions}
        professionOptions={professionOptions}
        departmentOptions={departmentOptions}
        openPage={openPage}
        errorMessage={errorMessage}
        onSend={_send}
        isProfileUpdated={isProfileUpdated}
        setProfileUpdated={setProfileUpdated}
        disabledField={loadingHospitalNames || loading || loadingProfessions || updating}
        isHcp={isHcp}
      />

      {/* Delete HCP account */}
      {isHcp && (
        <div
          className={style['hcp-profile__delete-account--container']}
          onClick={(): void => setDeleteAccountModal(() => true)}
          role="presentation"
        >
          <DeleteIcon />
          <span className={style['hcp-profile__delete-account--name']}>{delete_account}</span>
        </div>
      )}
    </>
  );

  const loadingJSX = (loadingHospitalNames ||
    loading ||
    loadingProfessions ||
    updating ||
    deleteProcess ||
    loadingName) && <Loading />;

  const avatarJsx = (
    <div className={style['profile-user__avatar-container']}>
      {avatar ? (
        <img
          src={`data:image/jpeg;charset=utf-8;base64,${avatar}`}
          alt="Avatar"
          crossOrigin="anonymous"
        />
      ) : (
        <PersonGreyIcon />
      )}
      <div
        onClick={(): void => openPage(ProfileUserSecondaryPages.CHANGE_PHOTO)}
        aria-hidden
        className={style['profile-user__avatar-backdrop']}
      >
        {hcp?.photo ? edit_photo : add_photo}
      </div>
    </div>
  );

  // Breadcrumb path
  const routes = [
    {
      path: my_profile,
      breadcrumbName: my_profile,
    },
  ];

  return (
    <div className={pageStyles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={pageStyles['main__flex-wrapper']}>
        {/* Profile form */}
        <div className={pageStyles['left-section']}>
          <div className={pageStyles['profile-user__title-container']}>
            <MainTitle title={my_profile} icon={avatarJsx} />
            {isProfileUpdated && (
              <div className={pageStyles['profile-user__saved-container']}>
                <RedTickIcon />
                <div className={pageStyles['profile-user__saved']}>{saved}</div>
              </div>
            )}
          </div>
          {profileFormJSX}
        </div>

        {/* Change password */}
        {isOpenChangePassPage ? (
          <div className={pageStyles['right-section']} ref={sectionRef}>
            <MainTitle title={change_password} />
            <ChangePassword
              setOpenChangePassPage={setOpenChangePassPage}
              setHasChanges={setHasChanges}
              checkChanges={checkChanges}
            />
          </div>
        ) : (
          <></>
        )}

        {/* Change Hcp photo */}
        {isOpenChangePhotoPage ? (
          <div className={pageStyles['right-section']} ref={sectionRef}>
            <MainTitle title={change_photo} />
            <ChangeHcpPhoto
              setOpenChangePhotoPage={setOpenChangePhotoPage}
              setHasChanges={setHasChanges}
              checkChanges={checkChanges}
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* Popups */}
      {/* Delete account warning */}
      {isDeleteAccountModal && (
        <Modal
          style={style['hcp-profile__delete-account--modal']}
          onClose={(): void => setDeleteAccountModal(() => false)}
        >
          <CloseBtn close={(): void => setDeleteAccountModal(() => false)} />
          <div className={style['hcp-profile__delete-account--warn-wrapper']}>
            <WarningNotification
              title={delete_account_warn_title}
              contant={delete_account_warn_cont}
              buttonName={yes}
              cancelButtonName={no}
              onCancel={(): void => setDeleteAccountModal(() => false)}
              onSubmit={(): void => setDoubleCheckPopup(() => true)}
              foreignStyle={style['hcp-profile__delete-account--warn-cont']}
              foreignBtnStyle={style['hcp-profile__delete-account--warn-btn']}
            />
          </div>
        </Modal>
      )}

      {/* Double check delete account */}
      {doubleCheckPopup && (
        <PopupWithTwoButtons
          title={attention}
          content={are_you_sure}
          confirmButtonName={yes}
          closeButtonName={no}
          onConfirm={deleteHcpAccount}
          onClosePopup={(): void => setDoubleCheckPopup(() => false)}
        />
      )}

      {/* Request to admin sent popup */}
      {isRequstSentPopup && (
        <Modal style={style['hcp-profile__delete-account--modal']} onClose={closeReqSentPopup}>
          <div className="btn-close" onClick={closeReqSentPopup} role="presentation">
            <CloseIcon />
          </div>
          <div className={style['hcp-profile__delete-account--warn-wrapper']}>
            <div className={style.success__icon}>
              <SuccessIcon style={{ width: '54px', height: '54px' }} />
            </div>
            <div
              className={style.success_content}
              dangerouslySetInnerHTML={{ __html: sanitize(req_sent_with_email) }}
            />
          </div>
          <Button
            buttonName={ok}
            buttonType="button"
            buttonMethod={closeReqSentPopup}
            buttonClass={style['hcp-profile__delete-account--btn']}
          />
        </Modal>
      )}

      {unsaveWarning && (
        <PopupWithTwoButtons
          title={warning_unsaved_changes}
          content=""
          confirmButtonName={exit}
          closeButtonName={cancel}
          onConfirm={(): void => onConfirm(unsaveWarning)}
          onClosePopup={onClosePopup}
        />
      )}
    </div>
  );
};

export default ProfileUserPage;
