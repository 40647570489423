import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import style from '../styles.module.css';
import { getCurrenLang, GetState } from '../../../redux/selector';
import { Button, MainTitle } from '../../../common';
import { Loading } from '../../../common/Loader';
import { deepCopyObj, onChangeArea, uploadFiles } from '../../../utils/helper';
import { initUploadFile } from '../../../pages/Patient/AddPatientInformation/InitValues';
import { TextareaFeildFloatLabel } from '../../../common/Input';
import { PopupWithTwoButtons } from '../../../common/Popup';
import useCheckRole from '../../../hooks/useCheckRole';
import { RecordVideoPops } from '../types';
import VideoRecorder from '../../../common/VideRecorder';

const RecordVideo = ({
  recVideo,
  setRecVideo,
  setOpenForm,
  recVideoRaw,
  setRecVideoRaw,
}: RecordVideoPops): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const ok = t?.notifications.ok;
  const cancel = t?.common.cancel;
  const uploading_file_error = t?.dashboard.hcp.profile_patient.uploading_file_error;
  const warning_unsaved_changes = t?.common.warning_unsaved_changes;
  const exit = t?.common.exit;
  const rec_video_label = t?.dashboard.hcp.profile_patient.rec_video_label;
  const record_video_upper = t?.dashboard.hcp.profile_patient.record_video_upper || 'Record Video';

  const { token } = useCheckRole();

  // Local state
  const [isExitWarn, setExitWarn] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Move selected and uploaded to AWS files to local state "Patient information component"
  const onSubmit = async (): Promise<void> => {
    const { file } = recVideoRaw[0];
    if (token && file) {
      const urlVideo = 'video/uploadHcpVideoDocument';
      try {
        setLoading(() => true);
        const response: {
          attachmentPic: any[];
          attachmentVideo: any[];
          isLoadingErr: boolean;
        } = await uploadFiles([file], '', urlVideo, token, false);
        if (!response.isLoadingErr) {
          const newRecordToUpload = [...recVideoRaw];
          newRecordToUpload[0].uuid = response.attachmentVideo[0].videoName;
          newRecordToUpload[0].thumbnail = response.attachmentVideo[0].thumbnailName;
          const result = [...recVideo, ...newRecordToUpload];
          setRecVideo(result);
          setRecVideoRaw([...deepCopyObj(initUploadFile)]);
          setOpenForm('');
        } else {
          toast.error(uploading_file_error);
          setLoading(() => false);
          return;
        }
        setLoading(() => false);
      } catch (err) {
        setLoading(() => false);
        toast.error(uploading_file_error);
      }
    }
  };

  // Cancel add files
  const onCancel = (): void => {
    const { file } = recVideoRaw[0];
    if (file || recVideoRaw[0].description) {
      setExitWarn(() => true);
      return;
    }
    setOpenForm('');
  };

  // Confirm exit from upload files
  const onConfirmExit = async (): Promise<void> => {
    setExitWarn(() => false);
    setOpenForm('');
    setRecVideoRaw([...deepCopyObj(initUploadFile)]);
  };

  // Clouse Exit warning popup
  const onCloseExitWarn = (): void => {
    setExitWarn(() => false);
  };

  // JSX
  const loadingJSX = loading && <Loading />;

  return (
    <>
      {loadingJSX}
      <MainTitle title={record_video_upper} />

      {/* Video recorder */}
      <VideoRecorder
        recVideoRaw={recVideoRaw}
        setRecVideoRaw={setRecVideoRaw}
        setError={setError}
      />

      <TextareaFeildFloatLabel
        id="RecordVideo"
        inputName="RecordVideo description"
        placeholder={rec_video_label}
        inputValue={recVideoRaw[0].description}
        hasErrors={false}
        isTouched={false}
        onChecngeMethod={(e: any): void => {
          onChangeArea(e, 0, recVideoRaw, setRecVideoRaw);
        }}
        rows={3}
      />

      {error && <div className={style['form__recorder-error-cont']}>{error}</div>}

      {/* Buttons */}
      <div className={style['add-patient-info__btn-cont']}>
        <Button
          buttonClass={style['add-patient-info__btn']}
          buttonType="button"
          buttonName={cancel}
          buttonMethod={onCancel}
          disabledButton={!!loadingJSX}
        />
        <Button
          buttonClass={style['add-patient-info__btn']}
          buttonType="button"
          buttonName={ok}
          buttonMethod={onSubmit}
          colorStyle="blue"
          disabledButton={!recVideoRaw[0].file || !!loadingJSX || !!error}
        />
      </div>

      {/* Warning popup if has not saved changes */}
      {isExitWarn && (
        <PopupWithTwoButtons
          title={warning_unsaved_changes}
          content=""
          confirmButtonName={exit}
          closeButtonName={cancel}
          onConfirm={onConfirmExit}
          onClosePopup={onCloseExitWarn}
        />
      )}
    </>
  );
};

export default RecordVideo;
