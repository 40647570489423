import React from 'react';
import { Route, Switch } from 'react-router-dom';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';
import { DashboardProps } from './types';
import SideBar from '../SideBar';
import HeaderApp from '../HeaderApp';
import NotFound from '../../pages/NotFound';
import { GetState } from '../../redux/selector';

const Dashboard = ({ successPath, routes }: DashboardProps): JSX.Element => {
  const collapsed = useSelector((state: GetState) => state.common.collapsed);
  return (
    <div className={cx({ [styles.dashboard__container]: true, [styles.collapsed]: collapsed })}>
      <SideBar
        successPath={successPath}
        // mainMenu={sideBarMenu}
      />
      <main className={styles.main}>
        <HeaderApp collapsed={collapsed} />
        <Switch>
          {routes?.map((route: any, index: number) => (
            <Route
              key={`privateRoute${String(index)}`}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
          {routes.length > 0 && <Route key="routNotFound" component={NotFound} />}
        </Switch>
      </main>
    </div>
  );
};

export default Dashboard;
