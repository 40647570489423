import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { toast } from 'react-toastify';

import styles from './styles.module.css';
import { storage } from '../../utils';
import { sideBarKey } from '../../utils/variables';
import { ArrowLeft, Logo, LogoOtm, CrossIcon } from '../../theme/icons';
import { getCurrenLang, getMenuPosition, GetState } from '../../redux/selector';
import useCheckRole from '../../hooks/useCheckRole';
import {
  adminSideBar,
  hcpSideBar,
  logoutSideBar,
  patientSideBar,
  patientSideBarAdmin,
} from './SideBar.config';
import { useLogout } from '../../graphql/auth';
import { SideBarConfigType } from './types';
import { SIDEBAR_LABELS } from '../../utils/enums';
import { getQueryParams } from '../../utils/helper';
import { setCollapsed, toggleMenu } from '../../redux/common';

const SideBar = ({ successPath }: any): JSX.Element => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const collapsed = useSelector((state: GetState) => state.common.collapsed);
  const isShowMenu: any = useSelector<any>((state) => getMenuPosition(state));
  const { isAdmin, isHcp } = useCheckRole();
  const location = useLocation();

  const { onLogout, error } = useLogout(isAdmin);
  const patient = location.pathname.includes('/dashboard/patient/');
  const { userStatus } = getQueryParams(location.search);
  const dispatch = useDispatch();

  useEffect(() => {
    const collapsedFromLS = !!Number(storage.get(sideBarKey));
    dispatch(setCollapsed(collapsedFromLS));

    return (): void => {
      dispatch(toggleMenu(false));
    };
  }, []);

  // If logout error then show message
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  // Close menu
  const closeMenu = (): void => {
    dispatch(toggleMenu(false));
  };

  const sideBarConfig = useMemo(() => {
    const sideBarMenu: SideBarConfigType[] = [];
    if (isAdmin && !patient) {
      sideBarMenu.push(...adminSideBar(t));
    }
    if (isHcp && !patient) {
      sideBarMenu.push(...hcpSideBar(t));
    }
    if (isHcp && patient) {
      sideBarMenu.push(...patientSideBar(t, userStatus));
    }
    if (isAdmin && patient) {
      sideBarMenu.push(...patientSideBarAdmin(t, userStatus));
    }
    sideBarMenu.push(logoutSideBar(t));
    return sideBarMenu;
  }, [t, patient, isAdmin, isHcp]);

  // Collapse or show sidebar
  const toggleCollapsed = (): void => {
    dispatch(setCollapsed(!collapsed));
    storage.save(sideBarKey, collapsed ? '0' : '1');
  };

  // JSX
  // Menu
  const getMenuJsx = (isResponsive: boolean): JSX.Element[] => {
    return sideBarConfig.map((item: SideBarConfigType, index: number) => {
      let isShowLabel = true;
      if (!isResponsive && collapsed) {
        isShowLabel = false;
      }
      if (item.label === SIDEBAR_LABELS.SECTION_LABEL) {
        return (
          <div key={`sideBar${String(index)}`} className={styles['side-bar__section-title']}>
            {isShowLabel ? item.title : ''}
          </div>
        );
      }
      if (item.label === SIDEBAR_LABELS.SECTION_END) {
        return <div key={`sideBar${String(index)}`} className={styles['side-bar__section-dash']} />;
      }
      if (item.label === SIDEBAR_LABELS.LOGOUT) {
        return (
          <Tooltip
            key={`sideBar${String(index)}`}
            placement="right"
            title={!isShowLabel ? item.title : ''}
          >
            <li
              key={`sideBarElement${String(index)}`}
              className={cx({
                [styles.side_bar_li]: true,
                [styles.side_bar__logout]: true,
              })}
              onClick={onLogout}
              aria-hidden
            >
              <div className={styles.side_bar_div}>
                <div className={styles.side_bar_icon}>{item.icon}</div>
                {isShowLabel && <div className={styles.side_bar_title}>{item.title}</div>}
              </div>
            </li>
          </Tooltip>
        );
      }

      return (
        <Tooltip
          key={`sideBar${String(index)}`}
          placement="right"
          title={!isShowLabel ? item.title : ''}
        >
          <li className={styles.side_bar_li}>
            <NavLink
              to={`${item.path}${patient ? location.search : ''}`}
              exact={item.exact}
              className={styles.side_bar_div}
              activeClassName={styles.active}
            >
              <div className={styles.side_bar_icon}>{item.icon}</div>
              {isShowLabel && <div className={styles.side_bar_title}>{item.title}</div>}
            </NavLink>
          </li>
        </Tooltip>
      );
    });
  };

  return (
    <>
      {/* Full screen */}
      <div className={cx({ [styles['side-bar__container']]: true, [styles.collapsed]: collapsed })}>
        {/* Arrow */}
        <div onClick={toggleCollapsed} className={styles.collapse} aria-hidden>
          <ArrowLeft />
        </div>

        {/* Logo */}
        <NavLink
          to={successPath}
          exact
          activeStyle={{ pointerEvents: 'none' }}
          isActive={(_, location) => location.pathname === successPath}
        >
          <div className={styles.logo}>{collapsed ? <Logo /> : <LogoOtm />}</div>
        </NavLink>

        {/* Menu */}
        <ul className={styles.menu}>{getMenuJsx(false)}</ul>
      </div>

      {/* Adaptive screen */}
      <div
        className={cx({
          [styles.menu__container]: true,
          [styles['menu__container--open']]: isShowMenu,
        })}
      >
        {/* Menu header */}
        <div className={styles.menu__header}>
          <div className={styles.menu__logo}>
            <Logo />
          </div>

          <div className="btn-close" onClick={closeMenu} aria-hidden>
            <CrossIcon />
          </div>
        </div>

        {/* Items */}
        <ul className={styles.menu}>{getMenuJsx(true)}</ul>
      </div>
    </>
  );
};

export default SideBar;
