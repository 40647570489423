import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';

import styles from './styles.module.css';
import { useQueryPhotoPic, useQueryThumbnailPic } from '../../../../graphql/videoBank';
import { ShowMediaIcon, ThumbnailJsx } from '../../../../common/ShowMediaIcon';
import { defaultValue, getMinutes, getSeconds, getSetsInfo } from '../../../../utils/helper';
import { ExerciseViewProps } from '../types';

const ExerciseView = ({
  t,
  data,
  counting,
  isCreateDPF,
  formatDate,
}: ExerciseViewProps): JSX.Element => {
  const prescribed_on =
    t?.dashboard.hcp.profile_patient.video_bank.prescribed_on || 'Prescribed on';
  const loading = t?.header.loading;
  // Endpoints
  // get photo picture from AWS by Uuid
  const {
    _getThumbnailPic,
    loadingThumbnailPic,
    thumbnailPic,
    errorThumbnailPic,
  } = useQueryThumbnailPic();

  // get photo picture from AWS by Uuid
  const { _getPhotoPic, photoPic, loadingPhotoPic, errorPhotoPic } = useQueryPhotoPic();

  const [isUploadingToAWS, setIsUploadingToAWS] = useState(false);

  const isPhotosExist = data.exercisePhotos?.length;
  const exercisePhotos = isPhotosExist ? data.exercisePhotos : [];

  // Get thumbnails
  useEffect(() => {
    if (data.thumbnailName) {
      _getThumbnailPic({
        variables: {
          thumbnailKey: data.thumbnailName,
        },
      });
      return;
    }

    if (isPhotosExist) {
      _getPhotoPic({
        variables: {
          pictureUuid: exercisePhotos[0].fileUuid,
        },
      });
      return;
    }
    if (!data.thumbnailName && !data.videoName && !data.exerciseVideoUploaded && !isPhotosExist) {
      setIsUploadingToAWS(() => true);
    } else {
      setIsUploadingToAWS(() => false);
    }
  }, [data]);

  // Get thumbnail image from AWS turn off loader
  useEffect(() => {
    if (thumbnailPic || photoPic) {
      counting();
    }
  }, [thumbnailPic, photoPic]);

  // If Get thumbnail image from AWS error turn off loader
  useEffect(() => {
    if (errorThumbnailPic || errorPhotoPic) {
      counting();
    }
  }, [errorThumbnailPic, errorPhotoPic]);

  const values = {
    reps: data.exerciseReps,
    sets: data.exerciseSets,
    timesDay: data.exerciseTimesPerDay,
    restDaysWeek: data.exerciseRestDays,
    minutes: data.exerciseTime
      ? defaultValue(String(getMinutes(data.exerciseTime)))
      : defaultValue('0'),
    seconds: data.exerciseTime
      ? defaultValue(String(getSeconds(data.exerciseTime)))
      : defaultValue('0'),
    dim: data.exerciseTime ? 'time' : 'reps',
  };

  const loadingJSX = !!loadingPhotoPic || loadingThumbnailPic;

  return (
    <section className={styles['exercise-view__row']}>
      {/* Thumbnail */}
      <div>
        {!(isUploadingToAWS || loadingJSX) && (
          <div
            className={cx({
              [styles.preview]: true,
              [styles['preview-not-allowed']]: (!thumbnailPic || !data.videoName) && !isPhotosExist,
            })}
            // role="presentation"
            // onClick={showMedia}
          >
            {!loadingJSX && <ThumbnailJsx thumbLink="" photoPic={photoPic || thumbnailPic} />}
            <ShowMediaIcon
              thumbLink={thumbnailPic}
              photoPic={photoPic}
              size={{ playVideo: { width: 15, height: 20 }, showImg: { width: 25, height: 21 } }}
            />
          </div>
        )}
        {(isUploadingToAWS || loadingJSX) && <div className={styles.isUploading}>{loading}...</div>}
      </div>
      <div
        className={cx({
          [styles['exercise-view__description-container']]: true,
          [styles['preview__create-pdf']]: isCreateDPF,
        })}
      >
        <div className={styles['exercise-view__name']}>{data.name}</div>
        <div className={styles['exercise-view__date']}>
          {`${prescribed_on} ${moment(data.exerciseCreatedAt).format(formatDate) || '-'}`}
        </div>
        <div className={styles['exercise-view__comment']}>{getSetsInfo(values, t)}</div>
        <div className={styles['exercise-view__comment']}>{data.exerciseComment || ''}</div>
      </div>
    </section>
  );
};

export default ExerciseView;
