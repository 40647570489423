import React, { ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';

import pageStyles from '../../styles.module.css';
import patientStyles from '../style.module.css';
import {
  GetState,
  getCurrenLang,
  getErrorPatientProfile,
  getLoadingPatientProfile,
  getPatientProfile,
} from '../../../redux/selector';
import { Loading, MainTitle } from '../../../common';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import { getQueryParams } from '../../../utils/helper';
import { path } from '../../../utils';
import useCheckRole from '../../../hooks/useCheckRole';
import { useQueryPatientProfile } from '../../../graphql/patients';
import { StatusKeyNameEnum } from '../../../utils/enums';
import { PatientInformationIcon } from '../../../theme/icons';
import { fillPatientProfile } from '../../../redux/patient';
import PatientInformation from '../../../components/PatientInformation';

const PatientInformationPage = (): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const patient_list = t?.title.patient_list;
  const patient_information = t?.dashboard.hcp.profile_patient.patient_information;
  const patient_not_found = t?.common.patient_not_found;

  const location = useLocation();
  const dispatch = useDispatch();
  const { userId, userStatus } = getQueryParams(location.search);
  const userid = Number(userId);
  const patientStatus = String(userStatus);
  const { isAdmin } = useCheckRole();
  const isInvitee = userStatus === StatusKeyNameEnum.invite_sent;

  // Endpoints
  // Get current patient profile from DB
  const { _getProfileById } = useQueryPatientProfile(patientStatus);

  // Redux
  const profileData: any = useSelector<any>((state: GetState) => getPatientProfile(state));
  const profileLoading = useSelector<any>((state: GetState) => getLoadingPatientProfile(state));
  const profileError: any = useSelector<any>((state: GetState) => getErrorPatientProfile(state));

  // Local state

  // No data available for this user
  const notAllowed = useMemo(() => {
    if (!userStatus || !userid || profileError) {
      return true;
    }
    if (userStatus === StatusKeyNameEnum.invite_sent) {
      return true;
    }
    if (userStatus === StatusKeyNameEnum.pending) {
      return true;
    }
    return false;
  }, [userid, userStatus, profileError]);

  // Send request to the database
  useEffect(() => {
    if (!notAllowed) {
      _getProfileById({
        variables: {
          patientId: userid,
        },
      });
    }
  }, [notAllowed, userid]);

  // Clear state after componentWillUnmount
  useEffect(
    () => (): void => {
      dispatch(fillPatientProfile(null));
    },
    [],
  );

  // JSX
  const loadingJSX = profileLoading && <Loading />;

  // Breadcrumb path
  const routes = useMemo(
    () => [
      {
        path: isAdmin ? path.adminDashboardList : path.dashboardList,
        breadcrumbName: patient_list,
      },
      {
        path: isAdmin
          ? `${path.patient_overview_admin}${location.search}`
          : `${path.patient_overview}${location.search}`,
        breadcrumbName: profileData ? `${profileData.firstName} ${profileData.lastName}` : '-',
      },
      {
        path: patient_information,
        breadcrumbName: patient_information,
      },
    ],
    [t, profileData],
  );

  return (
    <div className={pageStyles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={cx([pageStyles.main__wrapper, pageStyles['main__wrapper--without-pd']])}>
        <div className={pageStyles['main__wrapper--pd']}>
          <MainTitle title={patient_information} icon={<PatientInformationIcon />} />
        </div>

        {notAllowed ? (
          <div className={patientStyles['profile-user__notfound']}>{patient_not_found}</div>
        ) : (
          <>
            <PatientInformation patientId={userid} />
          </>
        )}
      </div>
    </div>
  );
};

export default PatientInformationPage;
