import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import styles from '../../styles.module.css';
import { path } from '../../../utils';
import { getCurrenLang } from '../../../redux/selector';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import CreateTeam from '../../../components/RehabTeams/CreateTeam';
import { initialPage } from '../../../utils/helper';
import { TEAM_CATEGORY } from '../../../utils/enums';

const CreateTeamPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const create_team = t?.hcp.manage.create_team;
  const rehab_teams = t?.menu.rehab_teams;

  // Breadcrumb path
  const routes = [
    {
      path: `${path.rehabTeams}?category=${TEAM_CATEGORY.MY_TEAM}&page=${initialPage}`,
      breadcrumbName: rehab_teams,
    },
    {
      path: create_team,
      breadcrumbName: create_team,
    },
  ];

  return (
    <div className={styles.main__container}>
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={styles.main__wrapper}>
        <div className={styles['left-section']}>
          <CreateTeam t={t} closePage={undefined} setCreatedNewTeam={undefined} />
        </div>
      </div>
    </div>
  );
};

export default CreateTeamPage;
