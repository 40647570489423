/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import cx from 'classnames';
import { Switch, Tooltip } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { SwitchSize } from 'antd/lib/switch';

import styles from './styles.module.css';
import { Button, Loading, MainTitle } from '../../../common';
import { CustomCheckbox, InputFloatLabel, TextareaFeildFloatLabel } from '../../../common/Input';
import { DeleteIcon } from '../../../theme/icons';
import { TeamMember, TeamSettingsProps } from '../types';
import {
  useChangeAdminRightsForHcp,
  useDeleteHospitalTeam,
  useGetHospitalPatientsForSearch,
  useSaveHospitalTeam,
} from '../../../graphql/hospitalTeam';
import { WarnNotifModal } from '../../../common/NotificationModal';
import { deepCopyObj } from '../../../utils/helper';
import { TListHCP } from '../../../utils/model';
import { saveHospitalTeamValidation } from '../../../utils/validators';
import { useHcpListForSearchHcp } from '../../../graphql/hcp';
import { MEMBER_TYPE } from '../../../utils/enums';
import MemberList from '../MemberList';
import { GetState, getCurrenLang, getHcpName, getTeamSettingsData } from '../../../redux/selector';
import { setOpenTeamSettingData } from '../../../redux/rehabTeams';
import { useQueryUserName } from '../../../graphql/hcpProfile';
import HcpAvatar from './HcpAvatar';

const TeamSettings = ({
  updateTeamList,
  isPermissionCreateTeam,
}: TeamSettingsProps): ReactElement => {
  const t: any = useSelector<GetState>((state) => getCurrenLang(state));
  const ok = t?.common.ok;
  const on = t?.common.on;
  const off = t?.common.off;
  const description_t = t?.common.description;
  const cancel_t = t?.common.cancel;
  const save_t = t?.common.save;
  const add_hcp = t?.common.add_hcp;
  const no_data = t?.common.no_data;
  const add_patients = t?.common.add_patients;
  const hcp_list = t?.title.hcp_list;
  const patient_list = t?.title.patient_list;
  const select_t = t?.common.select;
  const team_name = t?.hcp.manage.team_name;
  const admin_rights = t?.hcp.manage.admin_rights;
  const delete_team = t?.hcp.manage.delete_team;
  const delete_member = t?.hcp.manage.delete_member;
  const warn_turn_on_admin_rights = t?.hcp.manage.warn_turn_on_admin_rights;
  const warn_turn_off_admin_rights =
    '<firstName> <lastName> will not be able to сreate and update this team.'; // t?.hcp.manage.warn_turn_off_admin_rights;
  const was_successfully_updated = t?.hcp.manage.was_successfully_updated;
  const you_dont_have_permission = t?.common.you_dont_have_permission;
  const add_all_my_patients = t?.hcp.manage.add_all_my_patients;
  const patient_request =
    '<firstName> <lastName> asked to be removed from the team on <date>. If you agree, please select this patient and click "Delete member."'; // t?.hcp.manage.patient_request;
  const team_settings = t?.hcp.rehab_teams?.team_settings || 'Team Settings';
  const hcp_will_lose =
    t?.hcp.rehab_teams?.hcp_will_lose ||
    'Other healthcare professionals will lose access to the patients who were on the team and are not connected to them.';
  const happy_to_proceed = t?.hcp.rehab_teams.happy_to_proceed;
  const sure_delete_member = t?.hcp.rehab_teams?.sure_delete_member;
  const hcp_will_notif =
    t?.hcp.rehab_teams?.this_hcp_will_notif ||
    'This healthcare professional will be notified in the notification.';

  const initWarnState: any = {
    isShow: false,
    message: '',
    checked: false,
    hcp: null,
  };

  // Redux
  const dispatch = useDispatch();
  const data: any = useSelector<GetState>((state) => getTeamSettingsData(state));
  const currentHcp: any = useSelector<GetState>((state) => getHcpName(state));

  // Local state
  const [selectedHcpForDelete, setSelectedHcpForDelete] = useState<number[]>([]);
  const [selectedPatientForDelete, setSelectedPatientForDelete] = useState<number[]>([]);
  const [isWarnDeleteMemberModal, setWarnDeleteMemberModal] = useState('');
  const [isToggleSwitchWarnModal, setToggleSwitchWarnModal] = useState(initWarnState);
  const [isDeleteTeamWarnModal, setDeleteTeamWarnModal] = useState(false);
  const [addedHcps, setAddedHcps] = useState<any>([]);
  const [addedHcpIds, setAddedHcpIds] = useState<any>([]);
  const [addedPatients, setAddedPatients] = useState<any>([]);
  const [addedPatientIds, setAddedPatientIds] = useState<any>([]);
  const [pageSize, setPageSize] = useState(10);
  const [isAddPatientsAutomatically, setAddPatientsAutomatically] = useState(false);
  // const [isPatientReqIcon, setPatientReqIcon] = useState<string[]>([]);

  // Endpoints
  const {
    _changeAdminRightsForHcp,
    changeAdminRightsForHcpError,
    changeAdminRightsForHcpLoading,
    adminRightsForHcpChanged,
  } = useChangeAdminRightsForHcp();

  const {
    _deleteHospitalTeam,
    deleteHospitalTeamError,
    deleteHospitalTeamLoading,
    hospitalTeamdeleted,
  } = useDeleteHospitalTeam();

  const {
    _saveHospitalTeam,
    hospitalTeamSaved,
    saveHospitalTeamLoading,
    saveHospitalTeamError,
  } = useSaveHospitalTeam();

  const {
    _getHcpListForSearchHcp,
    hcpListLoading,
    hcpList,
    totalLength,
  } = useHcpListForSearchHcp();

  const {
    _getPatientListForSearchHcp,
    patientList,
    total,
    patientListLoading,
  } = useGetHospitalPatientsForSearch();

  const { _getUserName } = useQueryUserName(true);

  // const locale = useCheckLocale();
  // const { lng, lacaleArr } = locale;
  // moment.locale(lng, lacaleArr);

  const { id: hospitalTeamId } = data;

  const initialValues = useMemo(
    () => ({
      name: data.name || '',
      description: data.description || '',
      teamHcps: data.teamHcps || [],
      teamPatients: data.teamPatients || [],
    }),
    [data],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: () => saveHospitalTeamValidation(t),
    onSubmit: async (requestData: any) => {
      const { name, description, teamHcps, teamPatients } = requestData;
      const patientIds = teamPatients.map((a: TListHCP) => a.id);
      const hcpIds = teamHcps.map((a: TListHCP) => a.id);
      const request = {
        patientIds: isAddPatientsAutomatically
          ? [...patientIds]
          : [...patientIds, ...addedPatientIds],
        isAutomaticallyAddPatients: isAddPatientsAutomatically,
        hcpIds: [...hcpIds, ...addedHcpIds],
        hospitalTeamData: {
          id: hospitalTeamId,
          name: name.trim(),
          description: description.trim(),
        },
      };
      _saveHospitalTeam(request);
    },
  });

  const { values, errors, touched } = formik;

  // Close form and clear state
  const closeSettings = (): void => {
    dispatch(setOpenTeamSettingData(null));
    formik.resetForm();
    setAddedHcps([]);
    setAddedHcpIds([]);
    setAddedPatients([]);
    setAddedPatientIds([]);
  };

  // If change Hcp rights error then show message
  useEffect(() => {
    if (changeAdminRightsForHcpError) {
      toast.error(changeAdminRightsForHcpError);
    }
  }, [changeAdminRightsForHcpError]);

  // If delete Team occured error show message
  useEffect(() => {
    if (deleteHospitalTeamError) {
      toast.error(deleteHospitalTeamError);
    }
  }, [deleteHospitalTeamError]);

  // If delete Team success close settings and update team list
  useEffect(() => {
    if (hospitalTeamdeleted) {
      updateTeamList();
      closeSettings();
    }
  }, [hospitalTeamdeleted]);

  // If action success update team list
  useEffect(() => {
    if (adminRightsForHcpChanged) {
      updateTeamList();
      setToggleSwitchWarnModal(deepCopyObj(initWarnState));
      if (currentHcp.id === isToggleSwitchWarnModal.hcp.id) {
        _getUserName();
      }
    }
  }, [adminRightsForHcpChanged]);

  // If hospitalTeamSaved success update team list and clean local state
  useEffect(() => {
    if (hospitalTeamSaved) {
      updateTeamList();
      setAddedHcps([]);
      setAddedHcpIds([]);
      setAddedPatients([]);
      setAddedPatientIds([]);
      toast.info(`${data.name} ${was_successfully_updated}`);
      dispatch(setOpenTeamSettingData(null));
    }
  }, [hospitalTeamSaved]);

  // If update(save) error occured then show message
  useEffect(() => {
    if (saveHospitalTeamError) {
      toast.error(saveHospitalTeamError);
    }
  }, [saveHospitalTeamError]);

  // Handle hcp team members
  const hcpMemberHandler = (membersId: number): void => {
    if (!isPermissionCreateTeam) return;
    const newSelected = [...selectedHcpForDelete];
    const pos = newSelected.indexOf(membersId);

    if (pos >= 0) {
      newSelected.splice(pos, 1);
    } else {
      newSelected.push(membersId);
    }

    setSelectedHcpForDelete([...newSelected]);
  };

  // Handle team members
  const checkboxHandler = (e: any, membersId: number): void => {
    const value = e.target.checked;
    const newSelected = [...selectedPatientForDelete];

    if (value) {
      newSelected.push(membersId);
    } else {
      const pos = newSelected.indexOf(membersId);
      if (pos >= 0) {
        newSelected.splice(pos, 1);
      }
    }

    setSelectedPatientForDelete([...newSelected]);
  };
  // Close popup and cleare local state for selected members
  const onCloseWarnModal = (): void => {
    const isHcp = isWarnDeleteMemberModal === MEMBER_TYPE.HCPS;
    if (isHcp) {
      setSelectedHcpForDelete([]);
    } else {
      setSelectedPatientForDelete([]);
    }
    setWarnDeleteMemberModal(() => '');
  };

  // Close popup and delete from local state selected members
  const onDeleteMembersFromState = (): void => {
    const isHcp = isWarnDeleteMemberModal === MEMBER_TYPE.HCPS;
    if (isHcp) {
      const filtred = values.teamHcps.filter(
        (a: { id: number }) => !selectedHcpForDelete.includes(a.id),
      );
      formik.setFieldValue('teamHcps', filtred);
      setSelectedHcpForDelete([]);
    } else {
      const filtred = values.teamPatients.filter(
        (a: { id: number }) => !selectedPatientForDelete.includes(a.id),
      );
      formik.setFieldValue('teamPatients', filtred);
      setSelectedPatientForDelete([]);
    }
    setWarnDeleteMemberModal(() => '');
  };

  // Change Admin Rights for current team member
  const toggleAdminRightsForHcp = (): void => {
    const { checked, hcp } = isToggleSwitchWarnModal;
    _changeAdminRightsForHcp({ hcpId: hcp.id, solution: checked });
  };

  // Open warning modal window befor change admin rights
  const openWarningModal = (checked: boolean, hcp: TeamMember): void => {
    let message = '';
    if (checked) {
      message = warn_turn_on_admin_rights
        ? warn_turn_on_admin_rights
            .replace('<firstName>', hcp.firstName)
            .replace('<lastName>', hcp.lastName)
        : '';
    } else {
      message = warn_turn_off_admin_rights
        ? warn_turn_off_admin_rights
            .replace('<firstName>', hcp.firstName)
            .replace('<lastName>', hcp.lastName)
        : '';
    }
    setToggleSwitchWarnModal({
      isShow: true,
      message: `${message}`,
      checked,
      hcp,
    });
  };

  // Check already selected HCPs
  const selectedHcp = useMemo((): number[] => {
    if (data?.teamHcps) {
      return data.teamHcps.reduce((accum: number[], item: any) => [...accum, item.id], []);
    }
    return [];
  }, [data]);

  // Check already selected Patients
  const selectedPatients = useMemo((): number[] => {
    // const newPatientReq: string[] = [];
    if (data?.teamPatients) {
      const patients = data.teamPatients.map((patient: TeamMember) => {
        // if (patient.removeRequest) {
        //   newPatientReq.push(`${patient.firstName} ${patient.lastName}`);
        // }
        return patient.id;
      });
      // setPatientReqIcon(newPatientReq);
      return patients;
    }
    return [];
  }, [data]);

  // Query get hcp list for search
  const getHcpListForSearch = (page: number, searchValue: string): void =>
    _getHcpListForSearchHcp({
      variables: {
        listProps: {
          page,
          itemsPerPage: pageSize,
        },
        searchProps: [
          {
            searchField: 'hcp.firstName',
            searchValue,
          },
          {
            searchField: 'hcp.lastName',
            searchValue,
          },
          {
            searchField: 'hcp.email',
            searchValue,
          },
        ],
        // statuses: [STATUS.active, STATUS.inactive, STATUS.invited],
      },
    });

  // Query get hcp list for search
  const getPatientListForSearch = (page: number, searchValue: string): void =>
    _getPatientListForSearchHcp({
      variables: {
        listProps: {
          page,
          itemsPerPage: pageSize,
        },
        searchProps: [
          {
            searchField: 'patient.firstName',
            searchValue,
          },
          {
            searchField: 'patient.lastName',
            searchValue,
          },
          {
            searchField: 'patient.email',
            searchValue,
          },
        ],
        // statuses: filterDefault,
      },
    });

  // JSX
  const loadingJSX = (deleteHospitalTeamLoading || saveHospitalTeamLoading) && <Loading />;
  const noData = <div className={styles.noData}>{no_data}</div>;
  const deleteTeamContent = (
    <>
      <div className={styles['team-settings__modal-description']}>{hcp_will_lose}</div>
      <div className={styles['team-settings__modal-description']}>{happy_to_proceed}</div>
    </>
  );
  const deleteMemberContent = (
    <>
      <div className={styles['team-settings__modal-description']}>{sure_delete_member}</div>
      {isWarnDeleteMemberModal === MEMBER_TYPE.HCPS ? (
        <div className={styles['team-settings__modal-description']}>{hcp_will_notif}</div>
      ) : (
        <></>
      )}
    </>
  );
  const getSwitchAdminRightContent = (text: string): JSX.Element => (
    <>
      <div className={styles['team-settings__modal-description']}>{text}</div>
      <div className={styles['team-settings__modal-description']}>{happy_to_proceed}</div>
    </>
  );
  const getSwitchJsx = (size: SwitchSize | undefined, teamMember: TeamMember): JSX.Element => (
    <Switch
      checkedChildren={<span>{on}</span>}
      unCheckedChildren={<span>{off}</span>}
      checked={teamMember.isAdminRights}
      onChange={(checked: boolean): void => openWarningModal(checked, teamMember)}
      loading={changeAdminRightsForHcpLoading}
      disabled={!isPermissionCreateTeam || !!loadingJSX}
      size={size}
    />
  );

  return (
    <div>
      {loadingJSX}
      <div className={styles['team-settings__title--bg']}>
        <MainTitle title={team_settings} />
      </div>

      <form onSubmit={formik.handleSubmit} id={data?.id} className={styles['team-settings__form']}>
        <InputFloatLabel
          inputId="TeamName"
          inputName="name"
          inputType="text"
          placeholder={team_name}
          hasErrors={errors.name}
          inputValue={values.name}
          isTouched={touched.name}
          onChangeMethod={formik.handleChange}
          disabled={!isPermissionCreateTeam}
        />
        <TextareaFeildFloatLabel
          id="TeamDescription"
          inputName="description"
          placeholder={description_t}
          inputValue={values.description}
          hasErrors={errors.description}
          isTouched={touched.description}
          onChecngeMethod={formik.handleChange}
          rows={3}
          disabled={!isPermissionCreateTeam}
        />

        {/* Team members */}
        {/* HCP List */}
        <div className={styles['team-settings__subtitle']}>{hcp_list}</div>
        {values.teamHcps.length === 0 ? noData : <></>}
        {!!values.teamHcps.length && (
          <div className={styles['team-settings__members-container']}>
            {/* Header */}
            <div className={styles['team-settings__members-header']}>
              <div className={styles['team-settings__members-select-wrapper']}>
                <div className={styles['team-settings__members-select']}>{select_t}</div>
                {selectedHcpForDelete.length ? (
                  <Button
                    buttonType="button"
                    buttonName={delete_member}
                    buttonMethod={(): void => setWarnDeleteMemberModal(() => MEMBER_TYPE.HCPS)}
                    buttonClass={styles['team-settings__btn--delete-member']}
                    disabledButton={!isPermissionCreateTeam}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className={styles['team-settings__members-admin-rights']}>{admin_rights}</div>
            </div>

            {values.teamHcps.map((teamMember: TeamMember) => {
              const isChecked = selectedHcpForDelete.includes(teamMember.id);
              return (
                <div
                  className={styles['team-settings__member-row']}
                  key={`HcpMember${teamMember.id}`}
                >
                  <HcpAvatar
                    hcpMemberHandler={hcpMemberHandler}
                    isChecked={isChecked}
                    teamMember={teamMember}
                  />
                  <div className={styles['team-settings__members-name-container']}>
                    <div className={styles['team-settings__member-name']}>
                      {`${teamMember.firstName} ${teamMember.lastName}`}
                    </div>
                    <div className={styles['team-settings__member-profession']}>
                      {teamMember.professionName}
                    </div>
                    <div className={styles['team-settings__member-hospital--sm']}>
                      {teamMember.hospitalName}
                    </div>
                    <div className={styles['team-settings__member-department--sm']}>
                      {teamMember.departmentName}
                    </div>
                    <div className={styles['team-settings__member-switch--sm']}>
                      <div className={styles['team-settings__member-switch-label--sm']}>
                        {admin_rights}
                      </div>
                      {getSwitchJsx('small', teamMember)}
                    </div>
                  </div>

                  {/* Full screen */}
                  <div className={styles['team-settings__members-hospital-container']}>
                    <div className={styles['team-settings__member-hospital']}>
                      {teamMember.hospitalName}
                    </div>
                    <div className={styles['team-settings__member-department']}>
                      {teamMember.departmentName}
                    </div>
                  </div>

                  {/* Full screen */}
                  <div className={styles['team-settings__member-switch']}>
                    {getSwitchJsx('default', teamMember)}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* HCP List */}
        <MemberList
          t={t}
          title_t=""
          add_member_t={add_hcp}
          listLoading={hcpListLoading}
          memberList={hcpList}
          total={totalLength}
          typeMember={MEMBER_TYPE.HCPS}
          isPermissionCreateTeam={isPermissionCreateTeam}
          getMemberListForSearch={getHcpListForSearch}
          addedMembers={addedHcps}
          setAddedMembers={setAddedHcps}
          setAddedMemberIds={setAddedHcpIds}
          selectedMembers={selectedHcp}
          isAddPatientsAutomatically={false}
          setAddPatientsAutomatically={(): null => null}
          isTooltip={false}
          isCreateTeam={false}
        />
        <div className={styles['team-settings__spacer']} />

        {/* Patient list */}
        <div className={styles['team-settings__subtitle']}>{patient_list}</div>

        <div className={styles['team-settings__members--switch-wrapper']}>
          <Switch
            checkedChildren={<span>{on}</span>}
            unCheckedChildren={<span>{off}</span>}
            checked={isAddPatientsAutomatically}
            onChange={(checked: boolean): void => setAddPatientsAutomatically(checked)}
            disabled={!isPermissionCreateTeam || !!loadingJSX}
          />
          <div className={styles['team-settings__members--switch-title']}>
            {add_all_my_patients}
          </div>
        </div>

        {values.teamPatients.length === 0 ? noData : <></>}

        <div className={styles['hcp-team__members-section']}>
          {!!values.teamPatients.length && (
            <div className={styles['team-settings__members-container']}>
              {/* Header */}
              <div className={styles['team-settings__members-header']}>
                <div className={styles['team-settings__members-select-wrapper']}>
                  <div className={styles['team-settings__members-select']}>{select_t}</div>
                  {selectedPatientForDelete.length ? (
                    <Button
                      buttonType="button"
                      buttonName={delete_member}
                      buttonMethod={() => setWarnDeleteMemberModal(() => MEMBER_TYPE.PATIENTS)}
                      buttonClass={styles['team-settings__btn--delete-member']}
                      disabledButton={!isPermissionCreateTeam}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {values.teamPatients.map((teamMember: TeamMember) => {
                const date = teamMember.removeRequestDate
                  ? moment(teamMember.removeRequestDate).format('MMMM DD YYYY')
                  : '';
                const text = patient_request
                  ? patient_request
                      .replace('<firstName>', teamMember.firstName)
                      .replace('<lastName>', teamMember.lastName)
                      .replace('<date>', date)
                  : '';
                const titleJsx = <div>{text}</div>;
                return (
                  <div
                    className={styles['team-settings__member-row--patient']}
                    key={`PatientMember${teamMember.id}`}
                  >
                    <div className={styles['team-settings__member-checkbox']}>
                      <CustomCheckbox
                        htmlId={`patientCustomCheckbox${String(teamMember.id)}`}
                        name={String(teamMember.id)}
                        checked={selectedPatientForDelete.includes(teamMember.id)}
                        onChangeMethod={(e: any): void => checkboxHandler(e, teamMember.id)}
                        isTouched={false}
                        hasErrors={false}
                        disabled={!isPermissionCreateTeam}
                        bigSize
                      />
                    </div>
                    <div className={styles['team-settings__members-name-container--patient']}>
                      <div className={styles['team-settings__name-wrapper']}>
                        <div className={styles['team-settings__member-name--patient']}>
                          {`${teamMember.firstName} ${teamMember.lastName}`}
                        </div>
                        <Tooltip title={teamMember.email}>
                          <div className={styles['team-settings__member-email--patient']}>
                            {teamMember.email}
                          </div>
                        </Tooltip>
                        <div className={styles['team-settings__members-organisation--sm']}>
                          {teamMember.hospitalName}
                        </div>
                      </div>
                      {teamMember.removeRequest && teamMember.removeRequestDate && (
                        <Tooltip title={titleJsx}>
                          <div className={styles['hcp-team__patient-request']}>i</div>
                        </Tooltip>
                      )}
                    </div>
                    {/* Full screen */}
                    <div className={styles['team-settings__members-organisation']}>
                      {teamMember.hospitalName}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {/* Patient List */}
          <MemberList
            t={t}
            title_t=""
            add_member_t={add_patients}
            listLoading={patientListLoading}
            memberList={patientList}
            total={total}
            typeMember={MEMBER_TYPE.PATIENTS}
            isPermissionCreateTeam={isPermissionCreateTeam}
            getMemberListForSearch={getPatientListForSearch}
            addedMembers={addedPatients}
            setAddedMembers={setAddedPatients}
            setAddedMemberIds={setAddedPatientIds}
            selectedMembers={selectedPatients}
            isAddPatientsAutomatically={isAddPatientsAutomatically}
            setAddPatientsAutomatically={setAddPatientsAutomatically}
            isTooltip
            isCreateTeam={false}
          />
        </div>

        <div className={styles['team-settings__spacer']} />

        {/* Buttons */}
        <div className={styles['team-settings__btns-container']}>
          <Button
            buttonType="button"
            buttonName={cancel_t}
            buttonMethod={closeSettings}
            buttonClass={styles['team-settings__btn--save']}
          />
          <Tooltip title={!isPermissionCreateTeam ? you_dont_have_permission : ''}>
            <div>
              <Button
                buttonType="submit"
                buttonName={save_t}
                buttonClass={styles['team-settings__btn--save']}
                disabledButton={
                  !isPermissionCreateTeam ||
                  !!loadingJSX ||
                  (!formik.dirty &&
                    addedHcps.length === 0 &&
                    addedPatients.length === 0 &&
                    !isAddPatientsAutomatically)
                }
              />
            </div>
          </Tooltip>
        </div>
        <Tooltip title={!isPermissionCreateTeam ? you_dont_have_permission : ''}>
          <div
            className={cx({
              [styles['team-settings__btn-delete-team-container']]: true,
              [styles.notAllowed]: !isPermissionCreateTeam,
            })}
            role="presentation"
            onClick={() => (isPermissionCreateTeam ? setDeleteTeamWarnModal(true) : () => null)}
          >
            <DeleteIcon />
            <span>{delete_team}</span>
          </div>
        </Tooltip>
      </form>

      {/* Popups */}
      {/* Toggle admin rights - warning */}
      {isToggleSwitchWarnModal.isShow && (
        <WarnNotifModal
          content={getSwitchAdminRightContent(isToggleSwitchWarnModal.message)}
          onClose={(): void => setToggleSwitchWarnModal(deepCopyObj(initWarnState))}
          cancelBtnName={cancel_t}
          actionBtnName={ok}
          actionMethod={toggleAdminRightsForHcp}
        />
      )}
      {/* Delete member */}
      {isWarnDeleteMemberModal && (
        <WarnNotifModal
          content={deleteMemberContent}
          onClose={onCloseWarnModal}
          cancelBtnName={cancel_t}
          actionBtnName={ok}
          actionMethod={onDeleteMembersFromState}
        />
      )}
      {/* Delete team */}
      {isDeleteTeamWarnModal && (
        <WarnNotifModal
          content={deleteTeamContent}
          onClose={(): void => setDeleteTeamWarnModal(false)}
          cancelBtnName={cancel_t}
          actionBtnName={delete_team}
          actionMethod={(): void => _deleteHospitalTeam({ hospitalTeamId })}
        />
      )}
    </div>
  );
};

export default TeamSettings;
