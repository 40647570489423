import React, { Suspense, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { publicRouters, privateRoutes } from './routes';
import { Loading } from '../../common/Loader';
import { path } from './book';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import { Dashboard } from '../../components';
import useCheckRole from '../../hooks/useCheckRole';
import { LIST_TYPE } from '../enums';
import { initialPage } from '../helper';

const AppRouter = (): JSX.Element => {
  const { isAdmin, isHcp, token } = useCheckRole();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const isPathAdmin = pathname && !!pathname.match(/admin/);
  console.log('***isPathAdmin=', isPathAdmin);
  const successPath = isAdmin
    ? `${path.adminDashboardList}?category=${LIST_TYPE.HCP_ADMIN}&page=${initialPage}`
    : `${path.dashboardList}?category=${LIST_TYPE.PATIENT}&page=${initialPage}`;
  const dashboardPath = isAdmin ? path.adminDashboard : path.dashboard;

  console.log('***successPath=', successPath);
  console.log('***location.pathname.includes(/signin)=', location.pathname.includes('/signin'));

  if (token && location.pathname.includes('/signin')) {
    console.log('***AppRouter_token=', token);

    history.push(successPath);
  }
  // useEffect(() => {
  //   console.log('***++AppRouter_INIT=');
  // }, []);

  return (
    <>
      {/* <HeaderApp /> */}
      <Suspense fallback={<Loading />}>
        <Switch>
          <PrivateRoute
            // {...route}
            // key={`privateRoute${String(ind)}`}
            routes={privateRoutes}
            path={dashboardPath}
            // path="/dashboard"
            successPath={successPath}
            component={Dashboard}
            isAuthenticated={token}
          />
          {publicRouters.map((route, ind) => (
            <PublicRoute
              // {...route}
              key={`publicRoute${String(ind)}`}
              component={() => <route.component {...(route.componentProps as any || {})}/>}
              path={route.path}
              exact={route.exact}
              isAuthenticated={!!token}
              successPath={successPath}
            />
          ))}
          <Route
            render={(): JSX.Element => (
              <Redirect to={isPathAdmin ? path.adminSignIn : path.signIn} />
            )}
          />
        </Switch>
      </Suspense>
      <ToastContainer />
    </>
  );
};
export default AppRouter;
